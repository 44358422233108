import React, { useState, useEffect } from "react";

import {
  Input,
  Button,
  Divider,
  Dropdown,
  DropdownTrigger,
  AvatarGroup,
  DropdownMenu,
  DropdownItem,
  Avatar,
} from "@nextui-org/react";
import { IoIosClose } from "react-icons/io";
import {
  extensions,
  props,
  MenuBar,
} from "../../components/markdown/componenteditor";
import { EditorProvider } from "@tiptap/react";
import Tags from "../../components/Tag/tags";
import { CreateData, UpdateData } from "../../controllers/strapiController";
import { selectOrgData } from "../../redux/actions";
import { useSelector } from "react-redux";
import { Colors, numColors } from "../../utils/constant";

const CustomerDrawer = ({
  toggleDrawer,
  dropdownref,
  modalref,
  tenant,
  customTags,
  userNames,
  data,
  type,
  accounts,
}) => {
  const orgDetails = useSelector(selectOrgData);
  const [descriptionContent, setDescriptionContent] = useState(
    type === "update" && data?.attributes?.Description
      ? data?.attributes?.Description
      : ""
  );
  const [title, setTitle] = useState(
    type === "update" ? data?.attributes?.Name ?? "" : ""
  );
  const [tags, setTags] = useState(
    type === "update" && data?.attributes?.tags?.data
      ? data?.attributes?.tags?.data.map((item) => {
          return item?.id;
        })
      : []
  );
  const [selectedAccounts, setSelectedAccounts] = useState(
    type === "update"
      ? new Set(
          data.attributes.accounts.data.map((item) => {
            return String(item.id);
          })
        )
      : new Set([])
  );
  const [start, setStart] = useState(false);
  const [email, setEmail] = useState(
    type === "update" && data?.attributes?.Email ? data?.attributes?.Email : ""
  );
  const [phone, setPhone] = useState(
    type === "update" && data?.attributes?.PhoneNumber
      ? data?.attributes?.PhoneNumber
      : ""
  );
  const collection_name = "customers";
  const [tagsDetail, setTagsDetail] = useState(
    type === "update" && data?.attributes?.tags?.data
      ? data?.attributes?.tags?.data
      : []
  );

  const handleClear = () => {
    setTitle("");
    setDescriptionContent("");
    setTags([]);
    setTagsDetail([]);
    setTagsDetail([]);
    setEmail("");
    setPhone("");
  };
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleUpdate = async () => {
    if (isEmailValid) {
      const customer_data = {
        Name: title,
        Description: descriptionContent,
        Email: email,
        PhoneNumber: phone,
        tags: {
          disconnect: data?.attributes?.tags.data.map((item) => {
            if (!tags.includes(Number(item.id) || String(item.id))) {
              return Number(item.id);
            }
          }),
          connect: tags.map((item) => {
            return {
              id: Number(item),
              position: {
                end: true,
              },
            };
          }),
        },
        accounts: {
          disconnect: data?.attributes?.accounts.data.map((item) => {
            if (
              !Array.from(selectedAccounts).includes(
                Number(item.id) || String(item.id)
              )
            ) {
              return Number(item.id);
            }
          }),
          connect: Array.from(selectedAccounts).map((item) => {
            return {
              id: Number(item),
              position: {
                end: true,
              },
            };
          }),
        },
      };
      try {
        await UpdateData(collection_name, data.id, customer_data);
        window.location.reload();
      } catch (error) {}
    }
  };
  const handleSubmit = async () => {
    if (isEmailValid) {
      const customer_data = {
        Name: title,
        Description: descriptionContent,
        Email: email,
        PhoneNumber: phone,
        organization: {
          disconnect: [],
          connect: [
            {
              id: orgDetails?.id,
              position: {
                end: true,
              },
            },
          ],
        },
        tags: {
          disconnect: [],
          connect: tags.map((item) => {
            return {
              id: Number(item),
              position: {
                end: true,
              },
            };
          }),
        },
        accounts: {
          disconnect: [],
          connect: Array.from(selectedAccounts).map((item) => {
            return {
              id: Number(item),
              position: {
                end: true,
              },
            };
          }),
        },
      };
      try {
        await CreateData(collection_name, customer_data);
        window.location.reload();
      } catch (error) {}
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Effect to check email validity whenever the email state changes
  useEffect(() => {
    setIsEmailValid(validateEmail(email));
  }, [email]);
  return (
    <div className="w-96 p-4 shadow-lg overflow-y-auto absolute top-0 right-0 h-full bg-white z-50">
      <div className="flex items-center justify-between">
        <Button size="sm" variant="flat" color="secondary">
          New Customer
        </Button>
        <div>
          <Button className="bg-white" size="sm" onClick={handleClear}>
            Reset
          </Button>
          <Button isIconOnly variant="light" size="sm" onClick={toggleDrawer}>
            <IoIosClose style={{ width: "50px" }} />
          </Button>
        </div>
      </div>
      {/* Add rest of your JSX */}
      <div className="text-xs bg-white ">
        <Input
          className="mt-4 text-xs rounded-lg bg-white"
          style={{
            fontSize: "12px",
            backgroundColor: "",
          }}
          classNames={{
            input: ["bg-white", "hover:bg-white"],
            innerWrapper: ["bg-white", "hover:bg-white"],
            inputWrapper: [
              "shadow-none",
              "border",
              "border-divider",

              "bg-white",
              "dark:bg-white",
              "hover:bg-white",
              "dark:hover:bg-white",
              "group-data-[focus=true]:bg-white",
              "dark:group-data-[focus=true]:bg-white",
              "data-[hover=true]:bg-white",
            ],
          }}
          type="text"
          placeholder="Customer Name"
          size="sm"
          variant="bordered"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          // onBlur={() => handleShow()}
        />
        <Divider className="mt-4" />
        <div className=" h-auto my-4 border-[2px] rounded-lg">
          <EditorProvider
            placeholder="Enter your description"
            editorProps={props}
            extensions={extensions(userNames)}
            slotBefore={
              <MenuBar
                descriptionContent={descriptionContent}
                setDescriptionContent={setDescriptionContent}
                initial={start}
                setInitial={setStart}
              />
            }
            content=""
          />
        </div>
        <Divider />

        <div className=" mt-4 flex gap-2 text-center items-center">
          <p className="flex w-32 gap-2 text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>
            Email<span className="text-red-500">*</span>
          </p>
          <div className=" w-full  flex ">
            <Input
              className="text-xs rounded-lg bg-white"
              style={{
                fontSize: "12px",
                backgroundColor: "",
              }}
              classNames={{
                input: ["bg-white", "hover:bg-white"],
                innerWrapper: ["bg-white", "hover:bg-white"],
                inputWrapper: [
                  "shadow-none",
                  "border",
                  "border-divider",

                  "bg-white",
                  "dark:bg-white",
                  "hover:bg-white",
                  "dark:hover:bg-white",
                  "group-data-[focus=true]:bg-white",
                  "dark:group-data-[focus=true]:bg-white",
                  "data-[hover=true]:bg-white",
                ],
              }}
              type="text"
              placeholder="Customer Email"
              size="sm"
              variant="bordered"
              name="title"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // onBlur={() => handleShow()}
            />
          </div>
        </div>
        <div className=" mt-4 flex gap-2 text-center items-center">
          <p className="flex w-32 gap-2 text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
              />
            </svg>
            Phone
          </p>
          <div className=" w-full  flex ">
            <Input
              className="text-xs rounded-lg bg-white"
              style={{
                fontSize: "12px",
                backgroundColor: "",
              }}
              classNames={{
                input: ["bg-white", "hover:bg-white"],
                innerWrapper: ["bg-white", "hover:bg-white"],
                inputWrapper: [
                  "shadow-none",
                  "border",
                  "border-divider",
                  "bg-white",
                  "dark:bg-white",
                  "hover:bg-white",
                  "dark:hover:bg-white",
                  "group-data-[focus=true]:bg-white",
                  "dark:group-data-[focus=true]:bg-white",
                  "data-[hover=true]:bg-white",
                ],
              }}
              type="text"
              placeholder="Add Phone number"
              size="sm"
              variant="bordered"
              name="title"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>

        <div className=" mt-4 flex gap-2 text-center items-center">
          <p className="flex w-32 gap-2 text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
              />
            </svg>
            Accounts
          </p>
          <Dropdown className=" w-full  flex ">
            <DropdownTrigger>
              <Button
                className="text-xs rounded-lg bg-white w-full flex justify-start"
                size="sm"
                variant="light"
              >
                {Array.from(selectedAccounts).length === 0 ? (
                  <p className="text-gray-500">Select accounts</p>
                ) : (
                  <AvatarGroup max={3} size="sm">
                    {Array.from(selectedAccounts).map((item) => (
                      <Avatar
                        style={{ height: "20px", width: "20px" }}
                        showFallback
                        name={accounts
                          ?.find((ac) => String(ac.id) === String(item))
                          ?.attributes?.Name?.toUpperCase()
                          .charAt(0)}
                        color={Colors[Number(item) % numColors]}
                      />
                    ))}
                  </AvatarGroup>
                )}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              disallowEmptySelection
              selectedKeys={selectedAccounts}
              onSelectionChange={setSelectedAccounts}
              selectionMode="multiple"
            >
              {accounts.map((item) => (
                <DropdownItem
                  key={String(item?.id)}
                  startContent={
                    <Avatar
                      style={{ height: "20px", width: "20px" }}
                      radius="sm"
                      showFallback
                      name={item?.attributes?.Name?.toUpperCase().charAt(0)}
                      color={Colors[Number(item?.id) % numColors]}
                    />
                  }
                >
                  {item?.attributes?.Name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>

        <div className="flex gap-2 mt-4 pt-2 items-start">
          <p className="w-32 gap-2 text-xs text-center flex items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 6h.008v.008H6V6Z"
              />
            </svg>
            Tags
          </p>

          {/* Add tags here. */}
          <Tags
            param={tenant.name}
            customTags={customTags}
            org={tenant.id}
            dropdownref={dropdownref}
            tags={tags}
            setTags={setTags}
            tagsDetail={tagsDetail}
            setTagsDetail={setTagsDetail}
            modalref={modalref}
          />
        </div>
      </div>
      <div className="flex items-center mt-2 justify-end">
        <div className="flex items-center">
          <Button
            size="sm"
            className={`ml-2 text-white ${
              title === "" ||
              descriptionContent === "" ||
              descriptionContent === "<p></p>" ||
              !isEmailValid || // Ensure email is valid
              email === "" ||
              Array.from(selectedAccounts).length === 0
                ? ``
                : `bg-[rgb(51,51,245)]`
            }`}
            disabled={
              title === "" ||
              descriptionContent === "" ||
              descriptionContent === "<p></p>" ||
              !email || // Ensure email is provided
              !isEmailValid ||
              Array.from(selectedAccounts).length === 0 // Ensure email is valid
            }
            onClick={type === "update" ? handleUpdate : handleSubmit}
          >
            {type === "update" ? "Update" : "+ Create"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomerDrawer;
