import React, { useEffect, useState } from "react";
import { Button, Image, Input } from "@nextui-org/react";
import { getAllData } from "../../controllers/strapiController.js";
import { NavigateSign } from "../../controllers/loginController.js";
import { useNavigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import { EyeSlashFilledIcon } from "../../Images/EyeSlashFilledIcon.jsx";
import { EyeFilledIcon } from "../../Images/EyeFilledIcon.jsx";
import HelixLoader from "../Loader/HelixLoader.jsx";

const SignUp = () => {
  
  const navigate = useNavigate();
  const Location = useLocation();

  const [isVisible, setIsVisible] = useState(false);
  const [Users, setUsers] = useState(null);
  const [ValidationConditions, setValidationConditions] = useState(false);
  const [decryptedText, setDecryptedText] = useState("");
  const [Loader, setLoader] = useState(false);
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


  const [LoginDetails, setLoginDetails] = useState({
    email: "",
    username: "",
    password: "",
  });

  const [LoginError, setLoginError] = useState({
    emailError: "",
    usernameError: "",
    passwordError: "",
    FinalError: "",
  });

  useEffect(() => {
    getAllData("users")
      .then((data) => setUsers(data))
      .catch((error) => {});

    if (Location.pathname === "/sign-in") {
      sessionStorage.removeItem("userData");
    }
  }, [Location.pathname]);

  const handleDecrypt = () => {
    try {
      const id = Users && Users.length > 0 && Users[0].Password2;
      const hash = id;

      const secretKey = `${process.env.REACT_APP_SECRET_KEY}`;
      const base64Decoded = atob(hash);
      const decrypted = CryptoJS.AES.decrypt(base64Decoded, secretKey).toString(
        CryptoJS.enc.Utf8
      );
      setDecryptedText(decrypted.toString());
    } catch (error) {
      setDecryptedText("Decryption failed");
    }
  };

  useEffect(() => {
    handleDecrypt();
  }, [Users]);

  const Validation = () => {
    let IsValid = true;

    if (LoginDetails.email === "") {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        emailError: "Email is required.",
      }));
    } else if (!EmailRegex.test(LoginDetails.email)) {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        emailError: "Enter valid email format.",
      }));
    } else {
      setLoginError((previous) => ({ ...previous, emailError: "" }));
    }

    if (LoginDetails.username === "") {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        usernameError: "Username is required.",
      }));
    } else {
      setLoginError((previous) => ({ ...previous, usernameError: "" }));
    }

    if (LoginDetails.password === "") {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        passwordError: "Password is required.",
      }));
    } else if (decryptedText !== LoginDetails.password) {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        passwordError: "Password didn't match.",
      }));
    } else {
      setLoginError((previous) => ({ ...previous, passwordError: "" }));
    }

    return IsValid;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      SubmitHandler();
    }
  };

  const SubmitHandler = async () => {
    setLoader(true);
    const isValid = Validation();

    if (isValid) {
      setValidationConditions(false);
      try {
        const navigate_route = await NavigateSign(
          LoginDetails.username,
          LoginDetails.email,
          LoginDetails.password
        );
        if (navigate_route) {
          setLoader(false);
          navigate(navigate_route);
        } else {
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
      }
    } else {
      setValidationConditions(true);
      setLoader(false);
    }
  };

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleSignIn = () => {
    navigate("/sign-in");
  };

  const navigate_forgot = () => {
    navigate("/passwordless-sign");
  };

  return (
    <>
      {Loader && (
        <div className="absolute z-[100] flex items-center justify-center inset-0 h-screen w-screen bg-white ">
          <HelixLoader />
        </div>
      )}
      <div className="flex h-screen  items-center justify-center ">
        <main className="mx-auto min-h-[590px] p-4 w-full max-w-[500px]">
          <Image
            width={50}
            alt="NextUI hero Image"
            src="https://avatars.githubusercontent.com/u/170258109?s=200&v=4"
          />
          <h1 className="mb-1.5 mt-8 sm:text-left text-xl tracking-[-0.16px] text-slate-12 font-bold">
            Sign up to Scrum
          </h1>
          <form data-gtm-form-interact-id="0" onKeyDown={handleKeyPress}>
            {LoginError.FinalError && (
              <p className="sm:text-left text-base text-slate-11 font-normal">
                {LoginError.FinalError}
              </p>
            )}
            <div className="mb-4 space-y-2 mt-10">
              <Input
                radius="sm"
                className="border-slate-6"
                isInvalid={
                  ValidationConditions &&
                  (LoginDetails.email === "" ||
                    !EmailRegex.test(LoginDetails.email)) &&
                  LoginDetails.email !== null
                    ? ValidationConditions &&
                      (LoginDetails.email === "" ||
                        !EmailRegex.test(LoginDetails.email))
                    : ""
                }
                errorMessage={
                  ValidationConditions &&
                  (LoginDetails.email === "" ||
                    !EmailRegex.test(LoginDetails.email)) &&
                  LoginDetails.email !== null
                    ? LoginError.emailError
                    : ""
                }
                key={"outside"}
                type="email"
                label="Email"
                variant={"faded"}
                onChange={(e) =>
                  setLoginDetails({ ...LoginDetails, email: e.target.value })
                }
                labelPlacement={"outside"}
                placeholder="Enter your email"
              />
            </div>

            <div className="mt-10 ">
              <Input
                radius="sm"
                className="border-slate-6 "
                isInvalid={
                  ValidationConditions &&
                  (LoginDetails.username === "" ||
                    decryptedText !== LoginDetails.username)
                }
                errorMessage={
                  ValidationConditions &&
                  (LoginDetails.username === "" ||
                    decryptedText !== LoginDetails.username) &&
                  LoginError.usernameError
                }
                type="text"
                label="Username"
                labelPlacement={"outside"}
                key={"outside"}
                variant={"faded"}
                onChange={(e) =>
                  setLoginDetails({ ...LoginDetails, username: e.target.value })
                }
                placeholder="Enter your username"
              />
            </div>

            <div className="mb-4 space-y-2 mt-10">
              <Input
                radius="sm"
                className="border-slate-6"
                onChange={(e) =>
                  setLoginDetails({ ...LoginDetails, password: e.target.value })
                }
                isInvalid={
                  ValidationConditions &&
                  (LoginDetails.password === "" ||
                    decryptedText !== LoginDetails.password)
                }
                errorMessage={
                  ValidationConditions &&
                  (LoginDetails.password === "" ||
                    decryptedText !== LoginDetails.password) &&
                  LoginError.passwordError
                }
                label={
                  <div
                    className="flex justify-between"
                    style={{ width: "442px" }}
                  >
                    <span>Password</span>
                    <span className="cursor-pointer" onClick={navigate_forgot}>
                      Forgot your password?
                    </span>
                  </div>
                }
                key={"outside"}
                placeholder="Enter your Password"
                variant={"faded"}
                labelPlacement={"outside"}
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility}
                  >
                    {isVisible ? (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
              />
            </div>

            <Button
              id="CreateAccount"
              radius="sm"
              onClick={SubmitHandler}
              className={`w-full gap-2 lt-500 text-white bg-black hover:bg-gray-700 hover:text-white`}
            >
              Sign Up
              <lord-icon
                src="https://cdn.lordicon.com/vduvxizq.json"
                trigger="hover"
                target="#CreateAccount"
                colors="primary:#ffffff"
                style={{
                  width: "18px",
                  height: "20px",
                }}
              />
            </Button>
            {/* 
            <div className="mb-6 mt-6 flex items-center justify-center">
              <Divider className="my-2" style={{ maxWidth: "44%" }} />
              <span className="text-xs text-default-400 px-4">OR</span>
              <Divider className="my-2" style={{ maxWidth: "44%" }} />
            </div>

            <div className="flex w-full justify-center items-center md:flex-nowrap mb-6 md:mb-0">
              <Button
                radius="sm"
                variant={"faded"}
                className="flex gap-1"
                style={{ width: "48%" }}
                onClick={handleSignIn}
              >
                <div className="flex items-center gap-2">
                  <i className="fas fa-key"></i>
                  Sign in
                </div>
              </Button>
            </div> */}
          </form>
        </main>
      </div>
    </>
  );
};

export default SignUp;
