import { Dropdown, DropdownItem, DropdownTrigger, DropdownMenu, Button } from '@nextui-org/react';
import React, { useEffect, useRef, useState } from 'react'

function InboxFilter({search,setSearch,isCreated,setIsCreated,sortOrder,setSortOrder,selectedGroup,setSelectedGroup,customizeItems,setCustomizeItems,customizeCompleteList}) {
    const searchRef = useRef();
    const [isSearchOpen,setIsSearchOpen] = useState(false);
    const inputRef = useRef();
    const groupItems = ["None","Owner","Tags","Customer","Stage","Members"];
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (
            searchRef.current &&
            !searchRef.current.contains(event.target) &&
            (!inputRef.current || !inputRef.current.contains(event.target))
          ) {
            setIsSearchOpen(false);
          }
        };
        if (isSearchOpen && search === "") {
          document.addEventListener("mousedown", handleClickOutside);
        } else {
          document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [isSearchOpen, search]);
  return (
    
      <div className="z-0 flex pb-4 mt-4 items-center justify-between">
          <div className="flex flex-wrap items-center gap-4">
            <div
              ref={searchRef}
              onClick={() => setIsSearchOpen(true)}
              className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${
                isSearchOpen
                  ? "pl-2 w-40 gap-2 justify-start"
                  : "w-8 justify-center"
              } rounded-full  h-8 `}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              {isSearchOpen && (
                <input
                  ref={inputRef}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ height: "20px", outline: "none" }}
                  className="w-full px-2 text-xs rounded-full"
                  placeholder="Search"
                />
              )}
            </div>
            <div
              onClick={(e) => setIsCreated(!isCreated)}
              className={`border cursor-pointer border-divider w-8 h-8 rounded-full flex ${
                isCreated ? " bg-[#DBDADF]" : " hover:bg-[#DBDADF]"
              } items-center justify-center`}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                />
              </svg>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" variant="light">
                  Sort By
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Sort options"
                variant="flat"
                selectionMode="single"
                selectedKeys={sortOrder}
                onSelectionChange={setSortOrder}
              >
                <DropdownItem key="latest">Latest</DropdownItem>
                <DropdownItem key="oldest">Oldest</DropdownItem>
                <DropdownItem key="high">Highest Tickets</DropdownItem>
                <DropdownItem key="low">Lowest Tickets</DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" variant="light">
                  Group
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                className=" max-h-[200px] overflow-y-auto"
                aria-label="Single selection"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedGroup}
                onSelectionChange={setSelectedGroup}
              >
                {groupItems.map((group) => (
                  <DropdownItem key={group}>
                    <div className=" capitalize">{group}</div>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" variant="light">
                  Customize
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Multiple selection"
                variant="flat"
                closeOnSelect={false}
                selectionMode="multiple"
                disallowEmptySelection={false}
                selectedKeys={customizeItems}
                onSelectionChange={setCustomizeItems}
              >
                {customizeCompleteList.map((name) => (
                  <DropdownItem key={name}>{name}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
    
  )
}

export default InboxFilter
