import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteSingleAttribute,
  getFilteredData,
  getOneData,
} from "../../controllers/strapiController";
import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownSection,
  Chip,
  DropdownMenu,
  DropdownItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  Divider,
  Breadcrumbs,
  BreadcrumbItem,
  Switch
} from "@nextui-org/react";
import { deleteData, getMultipleFilters } from "../../controllers/vectorDB";
import { renderCell } from "../../utils/constant";
import Issue from "../Issues/issuedetails";
import { PiCoffeeThin } from "react-icons/pi";
import { PermissionGroupSingleRole } from "../../utils/permission";
import Drawer from "../../components/Drawer/drawer";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import ScredriverWrench from "../../Images/Icons/ScredriverWrench";
import ChevronArrow from "../../Images/Icons/ChevronArrow";
import Puzzle from "../../Images/Icons/Puzzle";
const customizeCompleteList = [
  "Stage",
  "Owner",
  "Priority",
  "Target Closing Date",
  "Estimated days",
  "Tags",
];
const open = ["Queued"];
const progress = [
  "Work in progress",
  "Awaiting Product assist",
  "Awaiting Development",
  "In Development",
  "Awaiting Customer Response",
];
const closed = ["Accepted", "Resolved", "Cancelled"];
const Queues = () => {
  const [toggle, setToggle] = useState("Current");
  const [isTicketOpen, setIsTicketOpen] = useState(false);
  const { id } = useParams();
  const drawerRef = useRef();
  const updateRef = useRef();
  const ticketRef = useRef();
  const tagsref = useRef();
  const modalref = useRef();
  const searchRef = useRef();
  const inputRef = useRef();
  const sprintRef = useRef();
  const insightRef = useRef();
  const [ticket, setTicket] = useState();
  const [updateDrawer, setUpdateDrawer] = useState(false);
  const [filterPriority, setFilterPriority] = useState(new Set([]));
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTicket, setSearchTicket] = useState("");
  const [finalIssues, setFinalIssues] = useState([]);
  const [filterDropdown, setFilterDropdowm] = useState(new Set(["Work Type"]));
  const { org } = useParams();
  const [loader, setLoader] = useState();
  const [partIds, setPartIds] = useState([]);
  const [ownerIds, setOwnerIds] = useState([]);
  const [sprintTags, setSprintTags] = useState([]);
  const [pendingShown, setPendingShown] = useState([]);
  const [orgId, setOrgId] = useState(null);

  const [issues, setIssues] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [sprint, setSprint] = useState(null);
  const navigate = useNavigate();
  const [current, setCurrent] = useState(null);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [customizeItems, setCustomizeItems] = useState(new Set(["Stage"]));
  const handleCloseTicket = () => {
    setIsTicketOpen(!isTicketOpen);
  };
  const [userNames, setUserNames] = useState([]);
  const [pendingIssues, setPendingIssues] = useState([]);
  const [users, setUsers] = useState([]);
  const [res, setRes] = useState({});
  const [userIds, setUserIds] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const [userTicket, setUserTicket] = useState([]);
  const resolved = ["Accepted", "Resolved", "Cancelled"];
  const [startDate, setStartDate] = useState(null);
  const [isCreatedTickets, setIsCreatedTickets] = useState(false);
  const [duration, setDuration] = useState(null);
  const [prevIssues, setPrevIssues] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Current");
  const [moreSprints, setMoreSprints] = useState(new Set([]));
  const [issuesShown, setIssuesShown] = useState([]);
  const [insightDrawer, setInsightDrawer] = useState(false);
  const [userPerm, setUserPerm] = useState({
    name: "Role-2",
    class: "Issues",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });

  const [selectedStageKeys, setSelectedStageKeys] = useState(new Set([]));
  const handleStage = (e) => {
    let items = Array.from(selectedStageKeys);
    if (e.every((element) => items.includes(element))) {
      items = items.filter((element) => !e.includes(element));
    } else {
      items = items.concat(e);
    }

    setSelectedStageKeys(new Set(items));
  };
  const handleDeleteKey = (item) => {
    let updatedKeys = new Set(selectedStageKeys);
    updatedKeys.delete(item);
    setSelectedStageKeys(updatedKeys);
  };
  const handleClearFilters = () => {
    setFilterPriority(new Set([]));
    setSelectedStageKeys(new Set([]));
    setIsCreatedTickets(false);
    setSearchTicket("");
  };
  const CurrentIssues = () => {
    setToggle("Current");
  };
  const PlannedIssues = () => {
    setToggle("Planned");
    console.log("Planned");
  };
  const LaterIssues = () => {
    setToggle("Later");
  };
  const coulmnDef = Array.from(customizeItems).map((name) => {
    return {
      id: name.toLowerCase(),
      label: name,
    };
  });
  const customizeHeaderList = [
    {
      id: "items",
      label: "Items",
    },
    {
      id: "title",
      label: "Title",
    },
    {
      id: "assigned to",
      label: "Assigned to",
    },
    ...coulmnDef,
    {
      id: "delete",
      label: "",
    },
  ];
  const toggleTicket = (e) => {
    setTicket(e);
    setIsTicketOpen(!isTicketOpen);
  };

  const handleDelete = async (item) => {
    try {
      const resp = await deleteData(item.id, org);
      const promises = item.images.map((id) =>
        DeleteSingleAttribute("upload/files", Number(id.id))
      );
      const results = await Promise.all(promises);

      window.location.reload();
    } catch (error) {
      window.location.reload();
    }
  };

  useEffect(() => {
    const fetchIssues = async () => {
      setToggle("Queued");
      setIsTicketOpen(false);
      setSelectedTab("Current");
      try {
        const resp = await getFilteredData("sprints", "path", id);
        setSprint(resp.data[0]);
        setCurrent(Number(resp.data[0].attributes.Current));
        if (Number(resp.data[0].attributes.Current) > 1) {
          setMoreSprints(
            new Set([String(Number(resp.data[0].attributes.Current) - 1)])
          );
        }
        setStartDate(resp.data[0].attributes.StartDate);
        setDuration(Number(resp.data[0].attributes.Duration));
        setPartIds(
          resp.data[0].attributes.parts.data.map((item) => {
            return String(item.id);
          })
        );
        setOwnerIds(
          resp.data[0].attributes.owners.data.map((item) => {
            return String(item.id);
          })
        );
        setSprintTags(
          resp.data[0].attributes.Tags.data.map((item) => {
            return Number(item.id);
          })
        );
      } catch (error) {
        console.error("Error fetching sprints:", error);
      }
    };
    if (id) {
      fetchIssues();
    }
  }, [id]);

  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const issue = await getMultipleFilters(
          [{ key: "type", value: "issue" }],
          org
        );
        setIssues(issue);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchIssues();
  }, [sprint?.id, org, id, current]);

  console.log("issues", issues); //list of issues

  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        const resp = await getFilteredData("organizations", "Name", org);
        setOrgId(resp?.data[0]?.id);
      } catch (error) {}
    };
    if (org) {
      fetchOrgDetails();
    }
  }, [org]);

  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        const resp = await getOneData("organizations", orgId);

        let userArray = [];
        let tagsArray = [];
        for (let i = 0; i < resp.data.attributes.users.data.length; i++) {
          userArray.push(resp.data.attributes.users.data[i].id);
        }
        for (let i = 0; i < resp.data.attributes.tags.data.length; i++) {
          tagsArray.push(resp.data.attributes.tags.data[i].id);
        }
        setTagIds(tagsArray);
        setUserIds(userArray);
      } catch (error) {}
    };
    if (orgId) {
      fetchOrgDetails();
    }
  }, [orgId]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const promises = userIds.map((id) => getOneData("users", id));
        const results = await Promise.all(promises);
        setUsers(results);
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
        throw error;
      }
    };
    if (userIds.length > 0) {
      getAllDataByIds();
    }
  }, [userIds]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const promises = tagIds.map((id) => getOneData("tags", id));
        const results = await Promise.all(promises);
        let partarray = [];
        for (let i = 0; i < results.length; i++) {
          partarray.push(results[i].data);
        }
        setCustomTags(partarray);
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
        throw error;
      }
    };
    if (tagIds.length > 0) {
      getAllDataByIds();
    }
  }, [tagIds]);
  useEffect(() => {
    if (issues.length > 0 && current) {
      setPendingIssues(
        issues.filter(
          (item) =>
            Number(item?.payload?.currentSprint) < current &&
            !resolved.includes(String(item?.payload?.stage))
        )
      );
      setPrevIssues(
        issues.filter((item) => Number(item?.payload?.currentSprint) < current)
      );
    }
  }, [issues, current]);
  const handleNavigate = () => {
    navigate(`/${org}/sprint-boards`);
  };
  // useEffect(() => {
  //   if (Array.from(moreSprints).length > 0 && prevIssues.length > 0) {
  //     let filteredTickets = prevIssues;
  //     filteredTickets = filteredTickets.filter(
  //       (item) =>
  //         Number(item?.payload?.currentSprint) ===
  //         Number(Array.from(moreSprints)[0])
  //     );
  //     if (Array.from(selectedStageKeys).length !== 0) {
  //       filteredTickets = filteredTickets.filter((ticket) =>
  //         Array.from(selectedStageKeys).includes(ticket?.payload?.stage)
  //       );
  //     }
  //     if (isCreatedTickets) {
  //       filteredTickets = filteredTickets.filter((item) =>
  //         userTicket.includes(item)
  //       );
  //     }

  //       filteredTickets = filteredTickets.filter((item) =>
  //         item?.payload?.title
  //           .toLowerCase()
  //           .includes(searchTicket.toLowerCase())
  //       );

  //     if (Array.from(filterPriority).length > 0) {
  //       filteredTickets = filteredTickets.filter((item) =>
  //         Array.from(filterPriority).includes(item?.payload?.priority)
  //       );
  //     }
  //     setIssuesShown(filteredTickets);
  //   }
  // }, [
  //   moreSprints,
  //   prevIssues,
  //   selectedStageKeys,
  //   isCreatedTickets,
  //   searchTicket,
  //   filterPriority,
  //   current,
  //   sprint,
  //   id,
  // ]);

  useEffect(() => {
    let filteredTickets = issues;

    // Filter only work in progress
    if (toggle === "Current") {
      filteredTickets = issues.filter(
        (item) => String(item?.payload?.stage) === "Work in progress"
      );
    }
    // Filter issues that are inside any sprint and are only queued
    if (toggle === "Planned") {
      filteredTickets = issues.filter(
        (item) =>
          item?.payload?.sprint !== null &&
          item?.payload?.sprint !== undefined &&
          String(item?.payload?.stage) === "Queued"
      );
      console.log("Later", filteredTickets);
    }
    // Filter issues that are inside not in any sprint and are queued
    if (toggle === "Later") {
      filteredTickets = issues.filter(
        (item) =>
          (item?.payload?.sprint === null ||
            item?.payload?.sprint === undefined) &&
          String(item?.payload?.stage) === "Queued"
      );
      console.log("Later", filteredTickets);
    }

    if (Array.from(selectedStageKeys).length !== 0) {
      filteredTickets = filteredTickets.filter((ticket) =>
        Array.from(selectedStageKeys).includes(ticket?.payload?.stage)
      );
    }
    if (isCreatedTickets) {
      filteredTickets = filteredTickets.filter((item) =>
        userTicket.includes(item)
      );
    }

    filteredTickets = filteredTickets.filter((item) =>
      item?.payload?.title.toLowerCase().includes(searchTicket.toLowerCase())
    );

    if (Array.from(filterPriority).length > 0) {
      filteredTickets = filteredTickets.filter((item) =>
        Array.from(filterPriority).includes(item?.payload?.priority)
      );
    }
    setFinalIssues(filteredTickets);
    // setPendingShown(pend);
  }, [
    selectedStageKeys,
    isCreatedTickets,
    searchTicket,
    filterPriority,
    toggle,
    issues,
    current,
    sprint,
    id,
    pendingIssues,
  ]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!sprintRef.current || !sprintRef.current.contains(event.target))
      ) {
        setIsDrawerOpen(false);
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (insightRef.current && !insightRef.current.contains(event.target)) {
        setInsightDrawer(false);
      }
    };
    if (insightDrawer) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [insightDrawer]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        updateRef.current &&
        !updateRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target))
      ) {
        setUpdateDrawer(false);
      }
    };
    if (updateDrawer) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [updateDrawer]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && searchTicket === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, searchTicket]);
  useEffect(() => {
    if (issues.length > 0) {
      const filteredTickets = issues.filter(
        (ticket) => Number(ticket?.payload?.assigned) === userDataId
      );
      setUserTicket(filteredTickets);
    }
  }, [issues]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ticketRef.current &&
        !ticketRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!sprintRef.current || !sprintRef.current.contains(event.target))
      ) {
        setIsTicketOpen(!isTicketOpen);
      }
    };
    if (isTicketOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTicketOpen]);

  useEffect(() => {
    const convertedUsers = {};
    const userN = [];
    users.forEach((user) => {
      userN.push({
        id: user.id,
        name: user.FirstName
          ? `${user.FirstName} ${user.LastName ? user.LastName : ""}`
          : user.username,
        url: user.ProfilePicture?.formats?.small?.url || "",
      });
      convertedUsers[user.id] = {
        UserName: user.username,
        FirstName: user.FirstName,
        LastName: user.LastName,
        ProfilePicture: user.ProfilePicture?.formats?.small?.url || "",
      };
    });
    setUserNames(userN);
    setRes(convertedUsers);
  }, [users]);
  useEffect(() => {
    const fetchPerm = async () => {
      try {
        const resp = await PermissionGroupSingleRole(
          userDataId,
          orgId,
          "Role-2",
          "dev"
        );
        if (resp !== false) {
          setUserPerm(resp);
        }
      } catch (error) {}
    };
    if (orgId) {
      fetchPerm();
    }
  }, [orgId, userDataId]);
  return (
    <div className=" overflow-y-auto">
      {isTicketOpen && (
        <div ref={ticketRef}>
          <Issue
            userNames={userNames}
            inputRef={sprintRef}
            users={users}
            tenant={{ name: org, id: orgId }}
            pos="issue"
            dropdownref={tagsref}
            ticket={ticket}
            res={res}
            handleCloseTicket={handleCloseTicket}
            modalref={modalref}
            permission={userPerm.privileges[0].update}
          />
        </div>
      )}
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <Drawer
            inputRef={sprintRef}
            users={users}
            tenant={{ name: org, id: orgId }}
            modalref={modalref}
            res={res}
            dropdownref={tagsref}
            query="issue"
            toggleDrawer={() => setIsDrawerOpen(false)}
          />
        </div>
      )}
      <div className="mb-4 pt-8 mx-8 flex items-center justify-between ">
        <div className=" flex text-center justify-center cursor-pointer items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm" isDisabled>
            <BreadcrumbItem onClick={handleNavigate}>Queues</BreadcrumbItem>
          </Breadcrumbs>
        </div>

        <div className=" flex items-center">
          <Dropdown>
            <DropdownTrigger>
              <Button
                size="sm"
                isIconOnly
                color="default"
                variant="light"
                className="mx-2 border border-divider"
              >
                <svg
                  style={{ width: "15px", height: "15px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                  />
                </svg>
              </Button>
            </DropdownTrigger>
            <DropdownMenu className="w-[250px]">
              <DropdownSection showDivider>
                <DropdownItem
                  isReadOnly
                  startContent={
                    <div className=" flex justify-center items-center p-2 rounded-md bg-purple-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#AC70FF"
                        className="size-6"
                        style={{ width: "17px", height: "17px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                        />
                      </svg>
                    </div>
                  }
                  endContent={
                    <Switch size="sm" aria-label="Automatic updates" />
                  }
                >
                  <div className="flex">
                    <p className=" font-semibold text-xs">Smart Cluster</p>
                  </div>
                </DropdownItem>
              </DropdownSection>
              <DropdownSection title="Export view">
                <DropdownItem
                  startContent={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#6E9CFD"
                      className="size-6"
                      style={{ width: "17px", height: "17px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                  }
                >
                  <p className="text-xs"> Export as CSV</p>
                </DropdownItem>
                <DropdownItem
                  startContent={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#6E9CFD"
                      className="size-6"
                      style={{ width: "17px", height: "17px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                  }
                >
                  <p className="text-xs">Export as JSON</p>
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>
          {userPerm.privileges[0].create === true && (
            <Button
              size="sm"
              className="mx-2 text-white bg-[rgb(51,51,245)]"
              onClick={() => setIsDrawerOpen(true)}
            >
              + Issue
            </Button>
          )}
        </div>
      </div>

      <>
        <div className="w-full py-8 px-4 gap-4 flex justify-around">
          <Button
            onClick={() => CurrentIssues()}
            className={`w-full ${
              toggle === "Current"
                ? "bg-white border hover:bg-[rgb(238,238,241)] border-divider"
                : "bg-[rgb(238,238,241)]"
            }`}
          >
            <ScredriverWrench />
            <p className={`w-full text-start `}>Current</p>
          </Button>
          <Button
            onClick={() => PlannedIssues()}
            className={`w-full ${
              toggle === "Planned"
                ? "bg-white border border-divider hover:bg-[rgb(238,238,241)]"
                : "bg-[rgb(238,238,241)]"
            }`}
          >
            <ChevronArrow />
            <p className={`w-full text-start`}>Planned</p>
          </Button>
          <Button
            onClick={() => LaterIssues()}
            className={`w-full ${
              toggle === "Later"
                ? "bg-white border border-divider hover:bg-[rgb(238,238,241)]"
                : "bg-[rgb(238,238,241)]"
            }`}
          >
            <Puzzle />
            <p className={`w-full text-start`}>Later</p>
          </Button>
        </div>

        {finalIssues.length === 0 ? (
          <div className="flex mt-60 flex-col w-full items-center justify-center">
            <PiCoffeeThin
              style={{
                width: "50px",
                height: "50px",
                color: "#6B7280",
                opacity: "90%",
              }}
            />
            <p className="text-xs text-opacity-90 text-gray-500">
              No Issues. Time for coffee.
            </p>
          </div>
        ) : (
          <>
            <div className="z-0 flex px-8 mt-4 items-center justify-between">
              <div className="flex items-center gap-4">
                <div
                  ref={searchRef}
                  onClick={() => setIsSearchOpen(true)}
                  className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${
                    isSearchOpen
                      ? "pl-2 w-40 gap-2 justify-start"
                      : "w-8 justify-center"
                  } rounded-full  h-8 `}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                  </svg>
                  {isSearchOpen && (
                    <input
                      ref={inputRef}
                      value={searchTicket}
                      onChange={(e) => setSearchTicket(e.target.value)}
                      style={{ height: "20px", outline: "none" }}
                      className="w-full px-2 text-xs rounded-full"
                      placeholder="Search"
                    />
                  )}
                </div>
                <div
                  onClick={(e) => setIsCreatedTickets(!isCreatedTickets)}
                  className={`border cursor-pointer border-divider w-8 h-8 rounded-full flex ${
                    isCreatedTickets ? " bg-[#DBDADF]" : " hover:bg-[#DBDADF]"
                  } items-center justify-center`}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                    />
                  </svg>
                </div>

                {Array.from(filterDropdown).includes("Work Type") && (
                  <div className="flex">
                    <div className="p-2 border rounded-l-md text-xs">
                      <h2>Work Type</h2>
                    </div>
                    <div className=" border rounded-r-md text-xs">
                      <Button
                        size="sm"
                        radius="none"
                        color="primary"
                        variant="light"
                      >
                        Issue
                      </Button>
                    </div>
                  </div>
                )}
                {Array.from(filterDropdown).includes("Priority") && (
                  <div className="flex">
                    <div className="p-2 border rounded-l-md text-xs">
                      <h2>Priority</h2>
                    </div>
                    <div className=" border rounded-r-md text-xs">
                      <Dropdown>
                        <DropdownTrigger>
                          <Button size="sm" radius="none" variant="light">
                            {Array.from(filterPriority).length === 0
                              ? "Add"
                              : Array.from(filterPriority)[0]}{" "}
                            {filterPriority.size - 1 <= 0
                              ? ""
                              : `+${filterPriority.size - 1}`}
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          variant="flat"
                          closeOnSelect={false}
                          selectionMode="multiple"
                          selectedKeys={filterPriority}
                          onSelectionChange={setFilterPriority}
                        >
                          <DropdownItem key="P1">P1</DropdownItem>
                          <DropdownItem key="P2">P2</DropdownItem>
                          <DropdownItem key="P3">P3</DropdownItem>
                          <DropdownItem key="P4">P4</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                )}
                {Array.from(filterDropdown).includes("Stage") && (
                  <div className="flex">
                    <div className="p-2 border rounded-l-md text-xs">
                      <h2>Stage</h2>
                    </div>
                    <div className=" border rounded-r-md text-xs">
                      <Dropdown>
                        <DropdownTrigger>
                          <Button
                            className=" capitalize"
                            size="sm"
                            radius="none"
                            variant="light"
                          >
                            {Array.from(selectedStageKeys).length === 0
                              ? "Add"
                              : Array.from(selectedStageKeys)[0]}{" "}
                            {selectedStageKeys.size - 1 <= 0
                              ? ""
                              : `+${selectedStageKeys.size - 1}`}
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          className="h-80 w-[300px] overflow-y-auto"
                          variant="flat"
                          closeOnSelect={false}
                          selectionMode="multiple"
                          selectedKeys={selectedStageKeys}
                          onSelectionChange={setSelectedStageKeys}
                        >
                          {selectedStageKeys.size > 0 && (
                            <DropdownItem
                              isReadOnly
                              className="cursor-default border"
                            >
                              <div className=" h-auto w-full p-2 flex flex-wrap gap-2 overflow-y-auto">
                                {Array.from(selectedStageKeys).map((item) => (
                                  <Chip
                                    variant="bordered"
                                    size="sm"
                                    key={item}
                                    onClick={() => handleDeleteKey(item)}
                                    className="cursor-pointer text-gray-600 border border-divider gap-2"
                                    endContent={
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-4"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M6 18 18 6M6 6l12 12"
                                        />
                                      </svg>
                                    }
                                  >
                                    {item}
                                  </Chip>
                                ))}
                              </div>
                            </DropdownItem>
                          )}
                          <DropdownSection
                            showDivider
                            title={
                              <Button
                                onClick={() => handleStage(open)}
                                className="w-full justify-start"
                                size="sm"
                                variant="light"
                              >
                                Open
                              </Button>
                            }
                          >
                            <DropdownItem key="Queued">Queued</DropdownItem>
                          </DropdownSection>
                          <DropdownSection
                            showDivider
                            title={
                              <Button
                                onClick={() => handleStage(progress)}
                                className="w-full justify-start"
                                size="sm"
                                variant="light"
                              >
                                In progress
                              </Button>
                            }
                          >
                            <DropdownItem key="Work in progress">
                              Work in progress
                            </DropdownItem>
                            <DropdownItem key="Awaiting Product assist">
                              Awaiting Product assist
                            </DropdownItem>
                            <DropdownItem key="Awaiting Development">
                              Awaiting Development
                            </DropdownItem>
                            <DropdownItem key="In Development">
                              In Development
                            </DropdownItem>
                            <DropdownItem key="Awaiting Customer Response">
                              Awaiting Customer Response
                            </DropdownItem>
                          </DropdownSection>
                          <DropdownSection
                            title={
                              <Button
                                onClick={() => handleStage(closed)}
                                className="w-full justify-start"
                                size="sm"
                                variant="light"
                              >
                                Closed
                              </Button>
                            }
                          >
                            <DropdownItem key="Accepted">Accepted</DropdownItem>
                            <DropdownItem key="Resolved">Resolved</DropdownItem>
                            <DropdownItem key="Cancelled">
                              Cancelled
                            </DropdownItem>
                          </DropdownSection>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                )}
                <Dropdown>
                  <DropdownTrigger>
                    <Button
                      size="sm"
                      variant="light"
                      className=" border border-divider"
                      isIconOnly
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Multiple selection"
                    variant="flat"
                    closeOnSelect={false}
                    selectionMode="multiple"
                    disallowEmptySelection={false}
                    selectedKeys={filterDropdown}
                    onSelectionChange={setFilterDropdowm}
                  >
                    <DropdownItem key="Work Type">Work Type</DropdownItem>
                    <DropdownItem key="Stage">Stage</DropdownItem>
                    <DropdownItem key="Priority">Priority</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Button
                  onClick={() => handleClearFilters()}
                  size="sm"
                  variant="light"
                >
                  Clear
                </Button>
              </div>
              <div className="flex gap-2 items-center">
                <Dropdown>
                  <DropdownTrigger>
                    <Button size="sm" variant="light">
                      Sort
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu></DropdownMenu>
                </Dropdown>
                <Dropdown>
                  <DropdownTrigger>
                    <Button size="sm" variant="light">
                      Customize
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Multiple selection"
                    variant="flat"
                    closeOnSelect={false}
                    selectionMode="multiple"
                    disallowEmptySelection={false}
                    selectedKeys={customizeItems}
                    onSelectionChange={setCustomizeItems}
                  >
                    {customizeCompleteList.map((name) => (
                      <DropdownItem key={name}>{name}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>

            <div className="mt-4">
              <Divider />
            </div>
            <div className="w-full py-8">
              <Table
                removeWrapper
                radius="none"
                className="mt-4 px-4 text-xs"
                color="default"
                aria-label="Custom Table"
              >
                <TableHeader columns={customizeHeaderList}>
                  {(column) => (
                    <TableColumn key={column.id} className="text-xs">
                      {column.label}
                    </TableColumn>
                  )}
                </TableHeader>
                <TableBody emptyContent={"No tickets. Time for coffee."}>
                  {/* {toggle === "Current" &&
                    pendingShown.map((row, index) => (
                      <TableRow
                        className="cursor-pointer hover:bg-gray-100 text-xs  border-b border-divider"
                        onClick={(e) => toggleTicket(row)}
                        key={index + finalIssues.length}
                      >
                        {customizeHeaderList.map((item) => {
                          return renderCell(
                            row,
                            item.id,
                            res,
                            handleDelete,
                            customTags,
                            "pending",
                            false
                          );
                        })}
                      </TableRow>
                    ))} */}
                  {finalIssues.map((row, ind) => (
                    <TableRow
                      className="cursor-pointer hover:bg-gray-100 text-xs  border-b border-divider"
                      onClick={(e) => toggleTicket(row)}
                      key={ind}
                    >
                      {customizeHeaderList.map((item) => {
                        return renderCell(
                          row,
                          item.id,
                          res,
                          handleDelete,
                          customTags,
                          false,
                          false
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </>
    </div>
  );
};
export default Queues;
