import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectOrgData } from "../../redux/actions";
import { getFilteredBySingleRelation } from "../../controllers/strapiController";
import Icon from "../../components/Tag/Icon";
import HelixLoader from "../../components/Loader/HelixLoader";

const AccountTable = ({ search, setSelectedAccount, setIsOpenUpdate }) => {
  const orgData = useSelector(selectOrgData);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const resp = await getFilteredBySingleRelation(
          "accounts",
          "organization",
          orgData.id
        );
        setAccounts(resp.data);
        setFilteredAccounts(resp.data);
        setLoader(false);
      } catch (error) {}
    };
    if (orgData.id) {
      fetchAccounts();
    }
  }, [orgData]);
  useEffect(() => {
    const filter = accounts.filter(
      (item) =>
        item.attributes.Name.toLowerCase().includes(search.toLowerCase()) ||
        item.attributes.Website.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredAccounts(filter);
  }, [search, accounts]);
  if (loader) {
    return (
      <div className="flex flex-col items-center justify-center mt-64">
        <HelixLoader />
        
      </div>
    );
  }
  return (
    <div className="w-full px-6 pt-4">
      {filteredAccounts.length > 0 ? (
        <Table removeWrapper>
          <TableHeader>
            <TableColumn>Name</TableColumn>
            <TableColumn>Website</TableColumn>
          </TableHeader>
          <TableBody>
            {filteredAccounts.map((item) => (
              <TableRow
                className="cursor-pointer text-xs rounded-lg hover:bg-gray-100 border-b border-divider "
                onClick={() => {
                  setSelectedAccount(item);
                  setIsOpenUpdate(true);
                }}
                key={item.id}
              >
                <TableCell>{item.attributes.Name}</TableCell>
                <TableCell>{item.attributes.Website}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className="flex flex-col mt-60 text-xs gap-2 justify-center items-center text-slate-400">
          <Icon />
          No Accounts present
        </div>
      )}
    </div>
  );
};

export default AccountTable;
