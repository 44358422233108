import {
  Accordion,
  AccordionItem,
  Card,
  CardBody,
  CardHeader,
  Chip,
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { getAllData } from "../../controllers/strapiController";
import PrivilegeDrawer from "./PrivilegeDrawer";
import { lorem, roleIcons } from "../../utils/default";

function Privileges({
  orgPrivileges,
  devPrivileges,
  productPrivileges,
  supportPrivileges,
  analyticsPrivileges,
  update,
  groupId,
  setUpdate,
  type,
}) {
  const [category, setCategory] = useState("");
  const [nameClass, setNameClass] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [detail, setDetail] = useState();
  const drawerRef = useRef();
  const Dev = ["Issues", "Road Maps", "Queues", "Sprint Boards"];
  const Product = ["Components"];
  const Org = [
    "Tags",
    "User Management",
    "Organization details",
    "Integrations",
  ];
  const Support = ["Tickets", "Conversations"];
  const Analytics = ["Analytics", "Team Activity"];
  const [roles, setRoles] = useState([]);
  const handleEdit = async (category, Class) => {
    await setCategory(category);
    await setNameClass(Class);
    if (category === "Dev") {
      await setDetail(devPrivileges);
    }
    if (category === "Product") {
      await setDetail(productPrivileges);
    }
    if (category === "Org Related") {
      await setDetail(orgPrivileges);
    }
    if (category === "Support") {
      await setDetail(supportPrivileges);
    }
    if (category === "Analytics") {
      await setDetail(analyticsPrivileges);
    }
    setIsOpen(true);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const resp = await getAllData("user-group-roles");
        setRoles(resp.data);
      } catch (error) {}
    };
    fetchRoles();
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setIsOpen(!isOpen);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  const handleCloseDrawer = () => {
    setIsOpen(false);
  };
  return (
    <div className=" flex flex-col gap-2 p-4">
      {isOpen && (
        <div ref={drawerRef}>
          <PrivilegeDrawer
            type={type}
            setUpdate={setUpdate}
            groupId={groupId}
            category={category}
            Class={nameClass}
            closeDrawer={handleCloseDrawer}
            roles={roles}
            detail={detail}
            update={update}
          />
        </div>
      )}
      <Card shadow="none" className="border border-divider bg-white">
        <CardBody className=" w-full">
          <Accordion>
            <AccordionItem
              className=" text-sm font-light w-full"
              title={
                <div className="flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
                    />
                  </svg>

                  <p className=" font-normal"> Developer roles</p>
                </div>
              }
            >
              <p className=" text-sm pb-4">{lorem}</p>
              {Dev.map((dev) => (
                <Card shadow="none" className=" my-2 border border-divider">
                  <CardBody className="w-full">
                    <div className=" w-full flex items-center p-2 justify-between">
                      <div className="flex gap-2 items-center">
                        {roleIcons[dev].icon}
                        <p className=" text-base font-light">{dev}</p>
                      </div>
                      
                      <div
                        onClick={() => handleEdit("Dev", dev)}
                        className=" flex gap-2 items-center cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                          />
                        </svg>
                        <p className="  text-base font-light">Edit</p>
                      </div>
                    </div>
                    <p className=" text-xs p-2"> {roleIcons[dev].description}</p>
                    <div className="w-full p-2  flex justify-start gap-4">
                      {roles
                        ?.filter((item) => item.attributes.Class === dev)
                        .map((r) => (
                          <Chip color="primary" variant="flat" size="sm">
                            {r.attributes.Name}
                          </Chip>
                        ))}
                    </div>
                  </CardBody>
                </Card>
              ))}
            </AccordionItem>
          </Accordion>
        </CardBody>
      </Card>
      <Card shadow="none" className="border border-divider bg-white">
        <CardBody className=" w-full">
          <Accordion>
            <AccordionItem
              className=" text-sm font-light w-full"
              title={
                <div className="flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                    />
                  </svg>

                  <p className=" font-normal">Organization roles</p>
                </div>
              }
            >
              <p className=" text-sm pb-4">{lorem}</p>
              {Org.map((dev) => (
                <Card shadow="none" className=" my-2 border border-divider">
                  <CardBody className="w-full">
                    <div className=" w-full flex items-center p-2 justify-between">
                    <div className="flex gap-2 items-center">
                        {roleIcons[dev].icon}
                        <p className=" text-base font-light">{dev}</p>
                      </div>
                      <div
                        onClick={() => handleEdit("Org Related", dev)}
                        className=" flex gap-2 items-center cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                          />
                        </svg>
                        <p className="  text-base font-light">Edit</p>
                      </div>
                    </div>
                    <p className=" text-xs p-2"> {roleIcons[dev].description}</p>
                    <div className="w-full p-2  flex justify-start gap-4">
                      {roles
                        ?.filter((item) => item.attributes.Class === dev)
                        .map((r) => (
                          <Chip color="secondary" variant="flat" size="sm">
                            {r.attributes.Name}
                          </Chip>
                        ))}
                    </div>
                  </CardBody>
                </Card>
              ))}
            </AccordionItem>
          </Accordion>
        </CardBody>
      </Card>
      <Card shadow="none" className="border border-divider bg-white">
        <CardBody className=" w-full">
          <Accordion>
            <AccordionItem
              className=" text-sm font-light w-full"
              title={
                <div className="flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                    />
                  </svg>

                  <p className=" font-normal">Support Roles</p>
                </div>
              }
            >
              <p className=" text-sm pb-4">{lorem}</p>
              {Support.map((dev) => (
                <Card shadow="none" className=" my-2 border border-divider">
                  <CardBody className="w-full">
                    <div className=" w-full flex items-center p-2 justify-between">
                    <div className="flex gap-2 items-center">
                        {roleIcons[dev].icon}
                        <p className=" text-base font-light">{dev}</p>
                      </div>
                      <div
                        onClick={() => handleEdit("Support", dev)}
                        className=" flex gap-2 items-center cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                          />
                        </svg>
                        <p className="  text-base font-light">Edit</p>
                      </div>
                    </div>
                    <p className=" text-xs p-2"> {roleIcons[dev].description}</p>
                    <div className="w-full p-2  flex justify-start gap-4">
                      {roles
                        ?.filter((item) => item.attributes.Class === dev)
                        .map((r) => (
                          <Chip color="danger" variant="flat" size="sm">
                            {r.attributes.Name}
                          </Chip>
                        ))}
                    </div>
                  </CardBody>
                </Card>
              ))}
            </AccordionItem>
          </Accordion>
        </CardBody>
      </Card>
      <Card shadow="none" className="border border-divider bg-white">
        <CardBody className=" w-full">
          <Accordion>
            <AccordionItem
              className=" text-sm font-light w-full"
              title={
                <div className=" flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
                    />
                  </svg>
                  <p className=" font-normal">Analytics</p>
                </div>
              }
            >
              <p className=" text-sm pb-4">{lorem}</p>
              {Analytics.map((dev) => (
                <Card shadow="none" className="my-2 border border-divider">
                  <CardBody className="w-full">
                    <div className=" w-full flex items-center p-2 justify-between">
                    <div className="flex gap-2 items-center">
                        {roleIcons[dev].icon}
                        <p className=" text-base font-light">{dev}</p>
                      </div>
                      <div
                        onClick={() => handleEdit("Analytics", dev)}
                        className=" flex gap-2 items-center cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                          />
                        </svg>
                        <p className="  text-base font-light">Edit</p>
                      </div>
                    </div>
                    <p className=" text-xs p-2"> {roleIcons[dev].description}</p>
                    <div className="w-full p-2  flex justify-start gap-4">
                      {roles
                        ?.filter((item) => item.attributes.Class === dev)
                        .map((r) => (
                          <Chip color="warning" variant="flat" size="sm">
                            {r.attributes.Name}
                          </Chip>
                        ))}
                    </div>
                  </CardBody>
                </Card>
              ))}
            </AccordionItem>
          </Accordion>
        </CardBody>
      </Card>
      <Card shadow="none" className="border border-divider bg-white">
        <CardBody className=" w-full">
          <Accordion>
            <AccordionItem
              className=" text-sm font-light w-full"
              title={
                <div className=" flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
                    />
                  </svg>
                  <p className=" font-normal">Products</p>
                </div>
              }
            >
              <p className=" text-sm pb-4">{lorem}</p>
              {Product.map((dev) => (
                <Card shadow="none" className=" my-2 border border-divider">
                  <CardBody className="w-full">
                    <div className=" w-full flex items-center p-2 justify-between">
                    <div className="flex gap-2 items-center">
                        {roleIcons[dev].icon}
                        <p className=" text-base font-light">{dev}</p>
                      </div>
                      <div
                        onClick={() => handleEdit("Product", dev)}
                        className=" flex gap-2 items-center cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                          />
                        </svg>
                        <p className="  text-base font-light">Edit</p>
                      </div>
                    </div>
                    <p className=" text-xs p-2"> {roleIcons[dev].description}</p>
                    <div className="w-full p-2  flex justify-start gap-4">
                      {roles
                        ?.filter((item) => item.attributes.Class === dev)
                        .map((r) => (
                          <Chip color="success" variant="flat" size="sm">
                            {r.attributes.Name}
                          </Chip>
                        ))}
                    </div>
                  </CardBody>
                </Card>
              ))}
            </AccordionItem>
          </Accordion>
        </CardBody>
      </Card>
    </div>
  );
}

export default Privileges;
