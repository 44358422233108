import { Card, CardBody, CardHeader, Checkbox } from '@nextui-org/react';
import React, { useEffect, useState } from 'react'

function CheckboxGroup({role,perm,setPerm,type,category}) {
    const [ch,setCh] = useState(false);
    const [create,setCreate] = useState(null);
    const [read,setRead] = useState(null);
    const [update,setUpdate] = useState(null);
    const [del,setDel] = useState(null);
    const color = category === "Dev" ? "primary" : category === "Org Related" ? "secondary" : category === "Support" ? "danger" : category === "Product" ? "success" : "warning";
    useEffect(() => {
        if(perm && perm.length >0){
            const card = perm?.filter((item) => item.name === role.attributes.Name  && item.class === role.attributes.Class );
        setCreate(card[0].privileges[0].create === true);
        setRead(card[0].privileges[0].read === true);
        setUpdate(card[0].privileges[0].update === true);
        setDel(card[0].privileges[0].delete === true);
        setCh(true);
        }
    },[perm]);
    useEffect(() => {
        if(ch){
            const card = perm?.filter((item) => item.name === role.attributes.Name  && item.class === role.attributes.Class );
            const other = perm?.filter((item) => item.name !== role.attributes.Name || item.class !== role.attributes.Class);
            let obj = card[0];
            obj.privileges[0].read = read;
            obj.privileges[0].create = create;
            obj.privileges[0].update = update;
            obj.privileges[0].delete = del;
            setPerm([obj,...other]);
        }
    },[create,del,read,update])
  return (
    <Card>
      <CardHeader>{role.attributes.Name}</CardHeader>
      <CardBody>
        <p className=' text-sm font-light'>{role.attributes.Description}</p>
        <div className='flex justify-start gap-4 pt-4 items-center'>
            <Checkbox color={color} isDisabled={type==="Admin"} size='sm' className=' text-xs font-light' isSelected={create === null ? false : create} onValueChange={setCreate}>Create</Checkbox>
            <Checkbox color={color} isDisabled={type==="Admin"} size='sm' className=' text-xs font-light' isSelected={read === null ? false : read} onValueChange={setRead}>Read</Checkbox>
            <Checkbox color={color} isDisabled={type==="Admin"} size='sm' className=' text-xs font-light' isSelected={update === null ? false : update} onValueChange={setUpdate}>Update</Checkbox>
            <Checkbox color={color} isDisabled={type==="Admin"} size='sm' className=' text-xs font-light' isSelected={del === null ? false : del} onValueChange={setDel}>Delete</Checkbox>
        </div>
      </CardBody>
    </Card>
  )
}

export default CheckboxGroup
