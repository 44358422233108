import React, { useEffect, useRef } from 'react'
import "./styles.css"
import { Avatar } from '@nextui-org/react';
import { useParams } from 'react-router-dom';

function Chat({convo,userId,temp}) {
    const messagesEndRef = useRef(null);
    const {org} = useParams();
    function removePrefix(fullString, prefix) {
      if (fullString === null) {
        return "";
      }
      if (typeof(fullString) === "string" && fullString.startsWith(prefix)) {
        return fullString.substring(prefix.length);
      }
      return fullString; // return the full string if the prefix doesn't match
    }
    const Colors = ["secondary", "success", "warning", "danger", "primary"];

const numColors = Colors.length;
      useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
          }
      }, [convo.length,temp.length]);
      
  return (
    <div ref={messagesEndRef} className='w-full h-full flex flex-col gap-2 overflow-y-auto'>
      {convo.map((item,index) => (
        <div className={`w-full flex items-center gap-2 ${item.senderId === userId ? " justify-end" : " justify-start"}`}>
          {(item.senderId !== userId && (index ===0 || convo[index-1].senderId !== item.senderId )) && <Avatar size="sm" src='' color={Colors[Number(removePrefix(item.senderId,`${org}_`).length) % numColors  ]} showFallback name={removePrefix(item.senderId,`${org}_`)?.toUpperCase()?.charAt(0) } /> }
            <div className=' border border-divider rounded-lg p-2 flex flex-col' >
                <div className='mydiv' dangerouslySetInnerHTML={{__html:item.text}}></div>
                {/* <div className=' flex justify-end text-xs text-gray-400'>
                    {item.timeStamp}
                </div> */}
            </div>
        </div>
      )) }
      {temp.map((item) => (
        <div className={`w-full flex ${item.senderId === userId ? " justify-end" : " justify-start"}`}>
            <div className=' border border-divider rounded-lg p-2 flex flex-col' >
                <div className='mydiv' dangerouslySetInnerHTML={{__html:item.text}}></div>
                {/* <div className=' flex justify-end text-xs text-gray-400'>
                    {item.timeStamp}
                </div> */}
            </div>
        </div>
      )) }
      <div className='h-0' ref={messagesEndRef} />
    </div>
  )
}

export default Chat
