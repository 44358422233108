import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { BubbleMenu, useCurrentEditor } from "@tiptap/react";
import React, { useState } from "react";
import italic from "../../Images/Icons/italic.svg";
import bold from "../../Images/Icons/bold.svg";
import strike from "../../Images/Icons/strike.svg";
import quote from "../../Images/Icons/quote.svg";
import code from "../../Images/Icons/code.svg";

const Bubble = () => {
  const { editor } = useCurrentEditor();
  const hexArray = [
    { name: "Red", hex: "#F5A9A9" },
    { name: "Orange", hex: "#F6C6A6" },
    { name: "Yellow", hex: "#F6F2A6" },
    { name: "Green", hex: "#A9F5A9" },
    { name: "Blue", hex: "#A9D9F5" },
    { name: "Indigo", hex: "#A9A9F5" },
    { name: "Violet", hex: "#F5A9F2" },
  ];

  const [color, setColor] = useState(hexArray[0].hex);

  return (
    <div>
      {editor && (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
          <div className="bg-white border border-divider rounded-lg flex items-center gap-1">
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              className={
                editor.isActive("bold")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md  "
                    : "bg-transparent"
                  : ""
              }
            >
              <img
                src={bold}
                alt="bold"
                style={{ height: "20px", width: "20px" }}
                className=" rounded-md  text-black  "
              />
            </Button>
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
              className={
                editor.isActive("italic")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md   "
                    : "bg-transparent"
                  : ""
              }
            >
              <img
                alt="italic"
                src={italic}
                style={{ widhth: "20px", height: "20px" }}
                className=" rounded-md  text-black  "
              />
            </Button>
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              className={
                editor.isActive("underline")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md   "
                    : "bg-transparent"
                  : ""
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{ height: "20px", width: "20px" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.995 3.744v7.5a6 6 0 1 1-12 0v-7.5m-2.25 16.502h16.5"
                />
              </svg>
            </Button>
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleStrike().run()}
              disabled={!editor.can().chain().focus().toggleStrike().run()}
              className={
                editor.isActive("strike")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md   "
                    : "bg-transparent"
                  : ""
              }
            >
              <img
                src={strike}
                alt="strike"
                style={{ widhth: "20px", height: "20px" }}
                className=" rounded-md  text-black  "
              />
            </Button>
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              className={
                editor.isActive("blockquote")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md  "
                    : "bg-transparent"
                  : ""
              }
            >
              <img
                src={quote}
                style={{ widhth: "20px", height: "20px" }}
                alt="quote"
                className=" rounded-md  text-black  "
              />
            </Button>
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={() => editor.chain().focus().toggleCode().run()}
              disabled={!editor.can().chain().focus().toggleCode().run()}
              className={
                editor.isActive("code")
                  ? "is-active"
                    ? " bg-gray-200  rounded-md  "
                    : "bg-transparent"
                  : ""
              }
            >
              <img
                src={code}
                alt="code"
                style={{ widhth: "20px", height: "20px" }}
                className=" rounded-md  text-black  "
              />
            </Button>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  size="sm"
                  className=" flex items-center"
                  variant="light"
                >
                  <div
                    className={`w-5 h-5 rounded-full `}
                    style={{ backgroundColor: `${color}` }}
                  ></div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{ widhth: "20px", height: "20px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </Button>
              </DropdownTrigger>
              <DropdownMenu selectionMode="single">
                <DropdownItem
                  onClick={() => editor.chain().focus().unsetHighlight().run()}
                  disabled={!editor.isActive("highlight")}
                >
                  None
                </DropdownItem>
                {hexArray.map((item, index) => (
                  <DropdownItem
                    key={index}
                    className={
                      editor.isActive("highlight", { color: `${item.hex}` })
                        ? "is-active"
                          ? " bg-gray-200  rounded-md  "
                          : "bg-transparent"
                        : ""
                    }
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .toggleHighlight({ color: `${item.hex}` })
                        .run();
                      setColor(item.hex);
                    }}
                    startContent={
                      <div
                        className=" w-4 h-4 rounded-lg"
                        style={{ backgroundColor: `${item.hex}` }}
                      ></div>
                    }
                  >
                    {item.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </BubbleMenu>
      )}
    </div>
  );
};

export default Bubble;
