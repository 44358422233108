import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Input,
} from "@nextui-org/react";
import React, { useState, useEffect } from "react";
import { RiVipDiamondLine } from "react-icons/ri";
import OGP from "../../components/OGProtocol/OGP";

function ParentDropDown({ parentKeys, setParentKeys,partData}) {
  const [parsedParts, setParsedParts] = useState();
  const [search, setSearch] = useState("");
  const [options,setOptions] = useState([]);

  useEffect(() => {
    if (partData.length > 0) {
      const convertedParts = {};
      partData?.forEach((item) => {
        convertedParts[item?.id] = {
          Name: item?.attributes?.Name,
        Type: item?.attributes?.Type,
        ParentPath: item?.attributes?.ParentPath,
        Owner: item?.attributes?.users_permissions_user?.data?.id
        };
      });
      setParsedParts(convertedParts);
    }
  }, [partData]);
  useEffect(() => {
    if(partData.length>0 && parsedParts){
        const filteredParts = partData.filter((item) => (
            (item?.attributes?.Type !=="Product" ? `${item?.attributes?.ParentPath?.split('/')?.map((item) => parsedParts[Number(item)]?.Name).join('/ ')}/ ${item?.attributes?.Name}` : `${item?.attributes?.Name}`).toLowerCase().includes(search.toLowerCase())
        ))
        setOptions(filteredParts);
    }
    
  },[search,partData,parsedParts])
  return (
    <Dropdown>      
      <DropdownTrigger>
        <Button size="sm" className="bg-white">
          {Array.from(parentKeys).length===0 ? "Empty" : Array.from(parentKeys).length===1 ? parsedParts[Number(Array.from(parentKeys)[0])]?.Name : `${parsedParts[Number(Array.from(parentKeys)[0])]?.Name} +${Array.from(parentKeys).length-1}` }
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        variant="flat"
        className=" text-xs"
        closeOnSelect={false}
        selectionMode="multiple"
        selectedKeys={parentKeys}
        onSelectionChange={setParentKeys}
      >
        <DropdownSection showDivider>
          <DropdownItem classNames={{base : "gap-0 data-[selectable=true]:focus:bg-white", selectedIcon:"w-0"}} isReadOnly key="new">
            <Input
              size="sm"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search parent.."
            ></Input>
          </DropdownItem>
        </DropdownSection>
        <DropdownSection className="max-h-[200px] overflow-y-auto">
            {options?.map((part) => (
                <DropdownItem key={String(part?.id)} startContent={part?.attributes?.Type === "Product" ? <RiVipDiamondLine style={{ color: "#9C27B0" }} /> : <></>} > 
                    <span >{ part?.attributes?.Type !=="Product" ? `${part?.attributes?.ParentPath?.split('/')?.map((item) => parsedParts[Number(item)]?.Name).join('/ ')}/ ` : ``}</span>
                    <span className=" text-[#9C27B0]">{part?.attributes?.Name}</span>
                </DropdownItem>
            ))}
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  );
}

export default ParentDropDown;
