import React, { useEffect, useState } from 'react'

function useSocket(email,organization) {
    const [socket,setSocket] = useState(null);
    useEffect(() => {
        const ws = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_SERVER}?access_token=${email}&org=${organization}`);
        ws.onopen = () => {
            console.log("Connected");
            setSocket(ws);
        }

        ws.onclose = () => {
            console.log("Disconnected");
            setSocket(null);
        }
        
        return () => {
            ws.close();
        }
    },[]);

    return socket;
  
}

export default useSocket
