import React, { useEffect, useState, useRef } from "react";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Avatar,
  Button,
  Divider,
  Input,
  Kbd,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tab,
  Tabs,
  useDisclosure,
} from "@nextui-org/react";
import Drawer from "../Drawer/drawer";
import {
  getFilteredBySingleRelation,
  getFilteredBySingleRelationSingleData,
  getFilteredData,
  getOneData,
} from "../../controllers/strapiController";
import { UserPermissions } from "../../utils/default";
import { PermissionGroup } from "../../utils/permission";
import { IoIosNotificationsOutline } from "react-icons/io";
import { CiDark, CiLight, CiSettings, CiUser } from "react-icons/ci";
import Modals from "./Modal";
import Org from "./Org";
import { MdAutorenew } from "react-icons/md";
import InviteIcon from "../../Images/Icons/InviteIcon";
import { IoLogOutOutline } from "react-icons/io5";
import { orgInfo, selectOrgData, selectUserData, setRes, setUserData, setUsernames } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const SideNav = () => {
  const { org } = useParams();
  const dispatch = useDispatch();
  const drawerRef = useRef();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const users = useSelector(selectUserData);
  const orgData = useSelector(selectOrgData);
  const location = useLocation();
  const modalRef = useRef();
  const handleSearchButtonClick = () => {
    setIsOpen(true);
  };
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${org}/settings/account`);
  };
  const [searchItem, setSearchItem] = useState("");
  const [userPerm, setUserPerm] = useState(UserPermissions);
  const [loading, setLoading] = useState(true);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const [navItems, setNavItems] = useState(new Set(["Go Back", "Account"]));
  const UserId = UserLogged && UserLogged.user.id;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(!isOpen);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!UserLogged) {
      const pathname = location.pathname;
      let search = location.search;
      if (search !== "") {
        search = search.replace(/\?/g, "&");
      }
      window.location.href = `/sign-in?redirected-to=${pathname}${search}`;
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const resp = await getFilteredBySingleRelationSingleData(
          "organizations",
          "users",
          UserId,
          "Name",
          org
        );
        if (resp.data.length === 1) {
          dispatch(orgInfo(resp.data[0]));
        } else {
          navigate("/404");
        }
      } catch (error) {
        navigate("/404");
      }
    };
    if (org && UserId) {
      fetchOrg();
    }
  }, [org, UserId]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const results = await getFilteredBySingleRelation(
          "users",
          "organizations",
          orgData.id
        );
        dispatch(setUserData(results));
        setUserDetails(results.find((item) => item.id === UserId));
      } catch (error) {
        console.error("Error fetching data for all IDs:", error);
      }
    };
    if (orgData?.id) {
      getAllDataByIds();
    }
  }, [orgData]);
  useEffect(() => {
    if (users.length > 0) {
      const convertedUsers = {};
      const userN = [];
      users.forEach((user) => {
        userN.push({
          id: user.id,
          name: user.FirstName
            ? `${user.FirstName} ${user.LastName ? user.LastName : ""}`
            : user.username,
          url: user.ProfilePicture?.formats?.small?.url || "",
        });
        convertedUsers[user.id] = {
          UserName: user.username,
          FirstName: user.FirstName,
          LastName: user.LastName,
          ProfilePicture: user.ProfilePicture?.formats?.small?.url || "",
        };
      });
      dispatch(setUsernames(userN))
      dispatch(setRes(convertedUsers));
    }
  }, [users]);
  const searchButtonRef = useRef();
  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === "k") {
      event.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const renderNavItems = (navItems) =>
    navItems.map((item, index) => (
      <li key={index}>
        <NavLink className="rounded-md" to={item.path} id={item.id}>
          <span
            className={`flex py-2 items-center gap-2 rounded-md px-2 text-xs hover:bg-gray-100 text-black hover:text-foreground ${
              location.pathname === item.path ? "bg-gray-100" : ""
            }`}
          >
            <div>{item.icon}</div>
            {item.title}
          </span>
        </NavLink>
      </li>
    ));

  const Nav1 = [
    {
      icon: (
        <div className="flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/vduvxizq.json"
            trigger="hover"
            target="#Back"
            colors={`primary: "#000000"`}
            style={{ width: "12px", height: "12px", transform: "scaleX(-1)" }}
            className="flex justify-center items-center text-center "
          ></lord-icon>
        </div>
      ),
      title: "Go Back",
      path: `/${org}`,
      id: "Back",
    },
    {
      icon: (
        <div className="flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/yxczfiyc.json"
            trigger="hover"
            target="#general"
            state="morph-book"
            colors={`primary: "#000000"`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "General",
      path: `/${org}/settings/general`,
      id: "general",
    },
  ];

  const Nav2 = [
    {
      icon: (
        <div className="flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/fdxqrdfe.json"
            trigger="hover"
            state="morph-book"
            target="#Widget"
            colors={`primary: "#000000"`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Widget",
      path: `/${org}/settings/widget`,
      id: "Widget",
    },
    {
      icon: (
        <div className="flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/gfgzffjw.json"
            trigger="hover"
            state="morph-book"
            target="#tag"
            colors={`primary: "#000000"`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Tags",
      path: `/${org}/settings/tags`,
      id: "tag",
    },
    // {
    //   icon: (
    //     <div
    //       className="flex items-center cursor-pointer"
    //       onClick={toggleAccordion}
    //       aria-label="Accordion"
    //     >
    //       <lord-icon
    //         src="https://cdn.lordicon.com/pflszboa.json"
    //         alt="Accordion Icon"
    //         trigger="hover"
    //         state="morph-book"
    //         target="#integrations"
    //         colors={`primary: "#000000"`}
    //         style={{ width: "16px", height: "16px" }}
    //       />
    //     </div>
    //   ),
    //   title: "Integrations",
    //   path: `/${org}/settings/integrations`,
    //   id: "integrations",
    //   content: isOpen && <div></div>,
    // },
  ];

  const userNav = [
    {
      icon: (
        <div className="flex justify-center items-center text-xs">
          <lord-icon
            src="https://cdn.lordicon.com/kddybgok.json"
            trigger="hover"
            state="morph-book"
            target="#groups"
            colors={`primary: "#000000"`}
            className=" text-xs"
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Groups",
      path: `/${org}/settings/groups`,
      id:"groups"
    },
    {
      icon: (
        <div className="flex justify-center items-center text-xs">
          <lord-icon
            src="https://cdn.lordicon.com/kthelypq.json"
            trigger="hover"
            state="morph-book"
            target="#users"
            className="text-xs"
            colors={`primary: "#000000"`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Users",
      path: `/${org}/settings/users`,
      id:'users'
    },
    {
      icon: (
        <div className="flex justify-center items-center text-xs">
          <lord-icon
            src="https://cdn.lordicon.com/nzixoeyk.json"
            trigger="hover"
            state="morph-book"
            target="#invitation"
            className="text-xs"
            colors={`primary: "#000000"`}
            style={{ width: "16px", height: "16px" }}
          ></lord-icon>
        </div>
      ),
      title: "Invitations",
      path: `/${org}/settings/invitation`,
      id:"invitation"
    },
  ];
  useEffect(() => {
    const fetchPerm = async () => {
      try {
        const perm = await PermissionGroup(UserLogged.user.id, orgId);
        if (perm === false) {
          return;
        }
        setUserPerm(perm);
      } catch (error) {}
    };
    if (UserLogged && orgId) {
      fetchPerm();
    }
  }, [orgId]);
  useEffect(() => {
    if (userPerm) {
      let arr = Array.from(navItems);
      userPerm.map((item) => {
        if (item.privileges[0].read) {
          if (item.name === "Role-9") {
            arr.push("General");
          } else if (item.name === "Role-7") {
            arr.push("Users");
          } else if (item.name === "Role-14") {
            arr.push("Groups");
          } else if (item.name === "Role-15") {
            arr.push("Invitations");
          } else if (item.name === "Role-10") {
            arr.push("Integrations");
          } else if (item.name === "Role-8") {
            arr.push("Tags");
          }
        }
      });
      setNavItems(new Set(arr));
    }
  }, [userPerm]);
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const [UserDetails, setUserDetails] = useState(null);
  const [sideItems1, setSideItems1] = useState(new Set(["Teams"]));
  const [isInviteOpen, setInviteOpen] = useState(false);
  const Navigate = useNavigate();
  
  const handleNavigate = () => {
    Navigate(`/${org}/settings`);
  };
  const handleLogout = () => {
    Navigate(`/`);
  };
  // useEffect(() => {
  //   getOneData("users", UserId).then((data) => {
  //     setUserDetails(data);
  //   });
  // }, [Response]);
  const colorIndex = UserDetails && UserDetails.id % numColors;
  const UserProfile =
    UserDetails && UserDetails.ProfilePicture !== null
      ? process.env.REACT_APP_STAGE === "Dev"
        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${UserDetails.ProfilePicture.url}`
        : `${UserDetails.ProfilePicture.url}`
      : "";
  return (
    <div className="flex w-full h-screen">
      <Modal
        size="xl"
        className="h-[300px] py-4"
        hideCloseButton
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-2">
            <Input
              startContent={
                <lord-icon
                  src="https://cdn.lordicon.com/kkvxgpti.json"
                  trigger="hover"
                  style={{ height: "16px" }}
                ></lord-icon>
              }
              value={searchItem}
              onChange={(e) => setSearchItem(e.target.value)}
              placeholder="Search..."
              endContent={
                <Kbd keys={["command"]} size="sm">
                  K
                </Kbd>
              }
            />
          </ModalHeader>
          <ModalBody></ModalBody>
        </ModalContent>
      </Modal>

      <div className="w-64 h-screen left-0 bg-[#F9F9FA] dark:bg-gray-700 dark:text-white border-r-1 border-divider">
        <div className="flex ml-6 mt-6 items-center justify-between  gap-4 cursor-pointer text-xs">
          <Popover>
            <PopoverTrigger>
              <Avatar
                radius="full"
                className="rounded-md"
                size="sm"
                color={Colors[colorIndex]}
                src={UserProfile}
                name={
                  UserDetails?.FirstName
                    ? UserDetails.FirstName.slice(0, 1).toUpperCase()
                    : UserDetails?.email?.slice(0, 1).toUpperCase() || ""
                }
              />
            </PopoverTrigger>

            <PopoverContent
              isReadOnly
              key="profile"
              className="left-0 flex flex-col justify-start opacity-100 text-xs"
              startContent={
                <Avatar
                  radius="full"
                  className="rounded-md"
                  size="sm"
                  color={Colors[colorIndex]}
                  src={UserProfile}
                  name={
                    UserDetails?.FirstName
                      ? UserDetails.FirstName.slice(0, 1).toUpperCase()
                      : UserDetails?.email?.slice(0, 1).toUpperCase() || ""
                  }
                />
              }
            >
              <div className="flex flex-col p-2 w-full justify-start left-0 items-start text-xs">
                <div className=" flex gap-6 mb-2 cursor-pointer hover:bg-slate-100 hover:rounded-md w-full text-center">
                  <Avatar
                    radius="full"
                    className="rounded-md"
                    size="sm"
                    color={Colors[colorIndex]}
                    src={UserProfile}
                    name={
                      UserDetails?.FirstName
                        ? UserDetails.FirstName.slice(0, 1).toUpperCase()
                        : UserDetails?.email?.slice(0, 1).toUpperCase() || ""
                    }
                  />

                  <div>
                    <p className="text-center mt-2 text-xs">
                      {UserDetails?.email}
                    </p>
                    <p></p>
                  </div>
                </div>
                <Divider />
                <div className=" flex px-1 py-2 mt-2 cursor-pointer hover:bg-slate-100 hover:rounded-md  w-full gap-4 flex-row">
                  <IoIosNotificationsOutline
                    style={{ height: "16px", width: "16px" }}
                  />
                  <p className=" text-xs">Pause Notifications</p>
                </div>
                <div className=" flex px-1 py-2 mb-2 cursor-pointer hover:bg-slate-100 hover:rounded-md  w-full gap-4 flex-row">
                  <CiUser style={{ height: "16px", width: "16px" }} />
                  <p className=" text-xs"> Set youself as away</p>
                </div>
                <Divider />
                <div className="flex justify-between items-center w-full">
                  <Modals />
                </div>

                <div className=" flex flex-row cursor-pointer  hover:rounded-md  w-full">
                  <Org />
                </div>
                <Divider />
              </div>
              <div>
                <Tabs size="sm" aria-label="Tabs sizes" className="mb-2">
                  <Tab
                    title={
                      <div className="flex items-center space-x-2">
                        <CiDark />
                        <span className=" text-xs">Dark</span>
                      </div>
                    }
                  />
                  <Tab
                    title={
                      <div className="flex items-center space-x-2">
                        <CiLight />
                        <span className=" text-xs">Light</span>
                      </div>
                    }
                  />
                  <Tab
                    title={
                      <div className="flex items-center space-x-2">
                        <MdAutorenew />
                        <span className=" text-xs">Auto</span>
                      </div>
                    }
                  />
                </Tabs>
              </div>
              <Divider />
              <div className="w-full my-2">
                {Array.from(sideItems1).includes("Invitaion") && (
                  <div
                    onClick={() => setInviteOpen(true)}
                    className="flex px-1 py-2 cursor-pointer hover:bg-slate-100 hover:rounded-md  w-full gap-4 flex-row"
                  >
                    <InviteIcon style={{ height: "16px", width: "16px" }} />
                    <p className="text-xs">Invite members</p>
                  </div>
                )}
                <div
                  className=" flex gap-4 w-full  px-1 py-2 cursor-pointer hover:bg-slate-100 hover:rounded-md"
                  onClick={handleNavigate}
                >
                  <CiSettings
                    style={{ height: "16px", width: "16px" }}
                    onClick={handleNavigate}
                  />
                  <p className=" text-xs">Settings</p>
                </div>
              </div>
              <Divider />
              <div className="mt-2 mb-2 flex px-1 py-2 cursor-pointer hover:bg-slate-100 hover:rounded-md  w-full gap-4 flex-row">
                <IoLogOutOutline style={{ height: "16px", width: "16px" }} />
                <p onClick={handleLogout} className="text-xs">
                  {" "}
                  Log Out
                </p>
              </div>
            </PopoverContent>
          </Popover>
        </div>
        <div className="w-full flex items-center my-4">
          <Button
            size="md"
            onClick={handleSearchButtonClick}
            className="border border-divider w-48 ml-4 bg-white"
            variant="bordered"
            radius="md"
            ref={searchButtonRef}
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex gap-2 items-center justify-start">
                <lord-icon
                  src="https://cdn.lordicon.com/kkvxgpti.json"
                  trigger="hover"
                  style={{ height: "16px", width: "16px" }}
                ></lord-icon>
                Search
              </div>
              <div>
                <Kbd keys={["command"]} size="sm">
                  K
                </Kbd>
              </div>
            </div>
          </Button>
        </div>
        <div className="ml-4">
          <ul>
            {renderNavItems(
              Nav1.filter((item) => Array.from(navItems).includes(item.title))
            )}
            <li >
              <div id="Accounts"
                className={`flex text-xs p-2 rounded-md cursor-pointer gap-2 items-center hover:bg-gray-100 text-black hover:text-foreground ${
                  location.pathname === `/${org}/settings/account`
                    ? "bg-gray-100"
                    : ""
                }`}
                onClick={handleClick}
              >
                <lord-icon
                  src="https://cdn.lordicon.com/kthelypq.json"
                  trigger="hover"
                  target="#Accounts"
                  colors={`primary: "#000000"`}
                  style={{ width: "16px", height: "16px" }}
                ></lord-icon>
                <span>Account</span>
              </div>
            </li>

            <Divider className="my-2" />

            <li>
              <div className="flex flex-col rounded-md text-sm">
                <div>
                  <div
                    className="flex items-center p-2 cursor-pointer"
                    style={{ fontSize: "2px" }}
                  >
                    <span className="text-sm ml-2">User management</span>
                  </div>

                  <div>
                    {renderNavItems(
                      userNav.filter((item) =>
                        Array.from(navItems).includes(item.title)
                      )
                    )}
                  </div>
                </div>
              </div>
            </li>

            <Divider className="my-2" />

            {renderNavItems(
              Nav2.filter(
                (item) =>
                  Array.from(navItems).includes(item.title) ||
                  item.title === "Widget"
              )
            )}
          </ul>
        </div>
      </div>
      <div className="flex flex-col overflow-y-auto gap-2 w-full">
        <Outlet />
      </div>
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <Drawer toggleDrawer={toggleDrawer} />
        </div>
      )}
    </div>
  );
};

export default SideNav;
