import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Breadcrumbs,
  BreadcrumbItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Chip,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAllData,
  getFilteredBySingleRelation,
  getFilteredBySingleRelationSingleData,
} from "../../controllers/strapiController";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { PermissionGroupSingleRole } from "../../utils/permission";
import { EnhancementStage, RoadmapStages } from "../../utils/default";
import Icon from "../../components/Tag/Icon";
import OwnerDropdown from "../Components/OwnerDropdown";
import ParentDropDown from "../Components/ParentDropDown";
import CreateDrawer from "../Components/CreateDrawer";
import UpdateDrawer from "../Components/UpdateDrawer";
import Ticket from "../Tickets/ticketdetails";
import Issue from "../Issues/issuedetails";
import { getDocument } from "../../controllers/vectorDB";
import Paint from "../../Images/Icons/Paint";
import Beaker from "../../Images/Icons/Beaker";
import Puzzle from "../../Images/Icons/Puzzle";
import DeleteModal from "./DeleteModal";
import { useSelector } from "react-redux";
import { selectOrgData, selectResData, selectUserData } from "../../redux/actions";

const Roadmap = () => {
  const searchRef = useRef();
  const drawerRef = useRef();
  const ticketRef = useRef();
  const tickRef = useRef();
  const inputRef = useRef();
  const divRef = useRef();
  const issueRef = useRef();
  const tagsref = useRef();
  const modalref = useRef();
  const newModalref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isTicketOpen, setIsTicketOpen] = useState(false);
  const [isIssueOpen, setIsIssueOpen] = useState(false);
  const [customTags, setCustomTags] = useState([]);
  const [productData, setProductData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [partData, setPartData] = useState([]);
  const [ticket, setTicket] = useState({});
  const [issue, setIssue] = useState({});
  const currentOrg = useSelector(selectOrgData);
  const users = useSelector(selectUserData)
  const [dropdownOwner, setDropdownOwner] = useState(new Set([]));
  const [filterDropdown, setFilterDropdowm] = useState(new Set(["Type"]));
  const [selectedKeys, setSelectedKeys] = useState(new Set());
  const [customize, setCustomize] = useState(new Set([]));
  const [toggle, setToggle] = useState("Planned");
  const [searchTicket, setSearchTicket] = useState("");
  const [parentKeys, setParentKeys] = useState(new Set([]));
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [isProductManagementOpen, setIsProductManagementOpen] = useState(false);
  const [anyofKeys, setAnyofKeys] = React.useState(new Set(["Any of"]));
  const { org } = useParams();
  const [enhancements, setEnhancements] = useState([]);
  const navigate = useNavigate();
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const res = useSelector(selectResData);
  const [isCreatedTickets, setIsCreatedTickets] = useState(false);
  const closed = ["Accepted", "Resolved", "Cancelled"];
  const handleProduct = () => {
    setIsProductManagementOpen(false);
    setIsTicketOpen(false);
    setIsIssueOpen(false);
  };
  const [userPerm, setUserPerm] = useState({
    name: "Role-6",
    class: "Components",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });
  const handleDelete = (id) => {
    setDeleteId(id);
    setIsOpen(true);
  }
  const handleTicketChange = async (ticket) => {
    setIsIssueOpen(false);
    setIsTicketOpen(false);
    await setTicket(ticket);
    setIsTicketOpen(true);
  };

  const handleIssueChange = async (issue) => {
    setIsTicketOpen(false);
    setIsIssueOpen(false);
    await setIssue(issue);
    setIsIssueOpen(true);
  };
  const [userPermTick, setUserPermTick] = useState({
    name: "Role-11",
    class: "Tickets",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });
  const [userPermIss, setUserPermIss] = useState({
    name: "Role-2",
    class: "Issues",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });
  const handleClearFilter = () => {
    setParentKeys(new Set([]));
    setDropdownOwner(new Set([]));
    setAnyofKeys(new Set(["Any of"]));
  };
  const toggleProductManagement = (e) => {
    setProductData(e);
    setIsProductManagementOpen(!isProductManagementOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!newModalref.current || !newModalref.current.contains(event.target)) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsDrawerOpen(false);
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ticketRef.current &&
        !ticketRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!tickRef.current || !tickRef.current.contains(event.target)) &&
        (!issueRef.current || !issueRef.current.contains(event.target))
      ) {
        setIsProductManagementOpen(false);
        setIsTicketOpen(false);
        setIsIssueOpen(false);
      }
    };
    if (isProductManagementOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isProductManagementOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && searchTicket === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, searchTicket]);
  useEffect(() => {
    const getAllEnhancements = async () => {
      try {
        const issData = await getDocument("type", "issue", org);
        const tickData = await getDocument("type", "ticket", org);
        const resp = await getFilteredBySingleRelation(
          "parts",
          "organization",
          currentOrg.id
        );
        setPartData(resp.data);
        setEnhancements(
          resp.data
            .filter((item) => item.attributes.Type === "Enhancement")
            ?.map((part, index) => ({
              key: index.toString(),
              id: part?.id,
              type: part?.attributes?.Type,
              name: part?.attributes?.Name,
              owner: part?.attributes?.users_permissions_user?.data?.id,
              stage: part?.attributes?.Stage,
              part: part?.attributes?.latest_part,
              totaltickets: tickData.filter(
                (tick) => (String(tick?.payload?.part) === String(part?.id) && !closed.includes(tick?.payload?.stage))
              ),
              description: part?.attributes?.Description,
              tags: part?.attributes?.Tags,
              totalissues: issData.filter(
                (tick) => (String(tick?.payload?.part) === String(part?.id) && !closed.includes(tick?.payload?.stage))
              ),
              delete: part,
              parent: part?.attributes?.ParentPath,
            }))
        );
      } catch (error) {
        throw error;
      }
    };
    if (currentOrg?.id) {
      getAllEnhancements();
    }
  }, [currentOrg]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const results = await getFilteredBySingleRelation(
          "tags",
          "organization",
          currentOrg.id
        );
        setCustomTags(results.data);
      } catch (error) {
        throw error;
      }
    };
    if (currentOrg) {
      getAllDataByIds();
    }
  }, [currentOrg]);
  const [parsedParts, setParsedParts] = useState({});
  useEffect(() => {
    if (partData.length > 0) {
      const convertedParts = {};
      partData?.forEach((item) => {
        convertedParts[item?.id] = {
          Name: item?.attributes?.Name,
          Type: item?.attributes?.Type,
          ParentPath: item?.attributes?.ParentPath,
          Owner: item?.attributes?.users_permissions_user?.data?.id,
        };
      });
      setParsedParts(convertedParts);
    }
  }, [partData]);
  useEffect(() => {
    const fetchPerm = async () => {
      try {
        const resp = await PermissionGroupSingleRole(
          userDataId,
          currentOrg?.id,
          "Role-6",
          "pro"
        );
        const tick = await PermissionGroupSingleRole(
          userDataId,
          currentOrg?.id,
          "Role-11",
          "sup"
        );
        const iss = await PermissionGroupSingleRole(
          userDataId,
          currentOrg?.id,
          "Role-2",
          "dev"
        );

        if (resp !== false) {
          setUserPerm(resp);
        }
        if (tick !== false) {
          setUserPermTick(tick);
        }
        if (iss !== false) {
          setUserPermIss(iss);
        }
      } catch (error) { }
    };
    if (currentOrg?.id) {
      fetchPerm();
    }
  }, [currentOrg, userDataId]);
  useEffect(() => {
    let enh = enhancements;
    if (toggle === "Current") {
      enh = enhancements.filter((item) =>
        RoadmapStages.current.includes(item.stage)
      );
    }
    if (toggle === "Planned") {
      enh = enhancements.filter((item) =>
        RoadmapStages.planned.includes(item.stage)
      );
    }
    if (toggle === "Later") {
      enh = enhancements.filter((item) =>
        RoadmapStages.later.includes(item.stage)
      );
    }
    if (Array.from(parentKeys).length > 0) {
      let arr = [];
      for (let i = 0; i < enh.length; i++) {
        const set1 = new Set(enh[i].parent.split("/"));
        if (Array.from(parentKeys).some((element) => set1.has(element))) {
          arr.push(enh[i]);
        }
      }
      enh = arr;
    }
    if (searchTicket !== "") {
      enh = enh.filter((item) =>
        item.name.toLowerCase().includes(searchTicket.toLowerCase())
      );
    }
    if (isCreatedTickets) {
      enh = enh.filter((item) => String(item.owner) === String(userDataId));
    }
    if (Array.from(anyofKeys)[0] === "Any of") {
      if (Array.from(dropdownOwner).length > 0) {
        enh = enh.filter((item) =>
          Array.from(dropdownOwner).includes(String(item.owner))
        );
      }
    }
    if (Array.from(anyofKeys)[0] === "None of") {
      if (Array.from(dropdownOwner).length > 0) {
        enh = enh.filter(
          (item) => !Array.from(dropdownOwner).includes(String(item.owner))
        );
      }
    }
    setTableData(enh);
  }, [
    enhancements,
    toggle,
    searchTicket,
    isCreatedTickets,
    parentKeys,
    dropdownOwner,
    anyofKeys,
  ]);

  const columnDef = Array.from(customize).map((name) => {
    if (name === "totaltickets") {
      return { key: name, label: "Pending Tickets" };
    } else if (name === "totalissues") {
      return { key: name, label: "Pending Issues" };
    } else {
      return { key: name, label: "Stage" };
    }
  });

  const columns = [
    { key: "part", label: "Part" },
    { key: "name", label: "Name" },
    { key: "owner", label: "Owner" },
    ...columnDef,
    { key: "delete", label: "" }
  ];
  return (
    <div className=" overflow-y-auto">
      <DeleteModal partData={partData} parsed={parsedParts} id={deleteId} orgId={currentOrg?.id} setIsOpen={setIsOpen} isOpen={isOpen} />
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <CreateDrawer

            parsedParts={parsedParts}
            parentPath={""}
            customTags={customTags}
            parts={partData}
            users={users}
            org={org}
            orgid={currentOrg}
            type={"Enhancement"}
            divRef={divRef}
            parent={""}
            dropdownref={tagsref}
            handleclose={() => setIsDrawerOpen(false)}
            modalref={modalref}
            newModalref={newModalref}
            inputRef={inputRef}
          />
        </div>
      )}

      {isProductManagementOpen && (
        <div ref={ticketRef}>
          <UpdateDrawer
            tickPermission={userPermTick?.privileges[0]?.read}
            issPermission={userPermIss?.privileges[0]?.read}
            permission={userPerm.privileges[0].update}
            partData={partData}
            parsedParts={parsedParts}
            param={org}
            orgid={currentOrg}
            customTags={customTags}
            users={users}
            dropdownref={tagsref}
            modalref={modalref}
            res={res}
            handleclose={() => handleProduct()}
            data={productData}
            handleClickTicket={handleTicketChange}
            handleClickIssue={handleIssueChange}
          />
        </div>
      )}

      {isProductManagementOpen && isTicketOpen && (
        <div>
          <div ref={tickRef}>
            <Ticket
              users={users}
              tenant={{ name: org, id: currentOrg.id }}
              pos="part"
              res={res}
              dropdownref={tagsref}
              modalref={modalref}
              ticket={ticket}
              handleCloseTicket={() => setIsTicketOpen(false)}
            />
          </div>
        </div>
      )}
      {isProductManagementOpen && isIssueOpen && (
        <div>
          <div ref={issueRef}>
            <Issue
              users={users}
              tenant={{ name: org, id: currentOrg.id }}
              pos="part"
              res={res}
              dropdownref={tagsref}
              modalref={modalref}
              ticket={issue}
              handleCloseTicket={() => setIsIssueOpen(false)}
            />
          </div>
        </div>
      )}
      <div className=" pt-8 mx-8 flex items-center justify-between ">
        <div className=" flex items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm" isDisabled>
            <BreadcrumbItem>Roadmap</BreadcrumbItem>
          </Breadcrumbs>
        </div>
        <div className="gap-4 flex items-center">
          <Button size="sm" onClick={() => navigate(`/${org}/product-trails`)}>
            <FontAwesomeIcon />
            Explore Trails
          </Button>
          {userPerm?.privileges[0]?.create && (
            <Button
              size="sm"
              onClick={() => setIsDrawerOpen(true)}
              className="capitalize text-white bg-[rgb(51,51,245)]"
            >
              + Part
            </Button>
          )}
        </div>
      </div>

      <div className="w-full py-8 px-4 gap-4 flex justify-around">
        <Button
          onClick={() => setToggle("Current")}
          className={`w-full ${toggle === "Current"
              ? "bg-white border hover:bg-[rgb(238,238,241)] border-divider"
              : "bg-[rgb(238,238,241)]"
            }`}
        >
          <div className="flex items-center w-full text-start gap-2">
            <Beaker />

            <p className={`w-full text-start `}>Current</p>
          </div>
        </Button>
        <Button
          onClick={() => setToggle("Planned")}
          className={`w-full ${toggle === "Planned"
              ? "bg-white border hover:bg-[rgb(238,238,241)] border-divider"
              : "bg-[rgb(238,238,241)]"
            }`}
        >
          <div className="flex items-center w-full text-start gap-2">
            <Paint />
            <p className={`w-full text-start `}>Planned</p>
          </div>
        </Button>
        <Button
          onClick={() => setToggle("Later")}
          className={`w-full ${toggle === "Later"
              ? "bg-white border hover:bg-[rgb(238,238,241)] border-divider"
              : "bg-[rgb(238,238,241)]"
            }`}
        >
          <div className="flex items-center w-full text-start gap-2">
            <Puzzle />
            <p className={`w-full text-start `}>Later</p>
          </div>
        </Button>
      </div>
      <div className="z-0 flex pb-4 px-8 border-b-1 border-divider mt-4 items-center justify-between">
        <div className="gap-4 flex">
          <div
            ref={searchRef}
            onClick={() => setIsSearchOpen(true)}
            className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${isSearchOpen
                ? "pl-2 w-40 gap-2 justify-start"
                : "w-8 justify-center"
              } rounded-full  h-8 `}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
            {isSearchOpen && (
              <input
                ref={inputRef}
                value={searchTicket}
                onChange={(e) => setSearchTicket(e.target.value)}
                style={{ height: "20px", outline: "none" }}
                className="w-full px-2 text-xs rounded-full"
                placeholder="Search"
              />
            )}
          </div>
          <div
            onClick={(e) => setIsCreatedTickets(!isCreatedTickets)}
            className={`border cursor-pointer border-divider w-8 h-8 rounded-full flex ${isCreatedTickets ? " bg-[#DBDADF]" : " hover:bg-[#DBDADF]"
              } items-center justify-center`}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
              />
            </svg>
          </div>
          {Array.from(filterDropdown).includes("Type") && (
            <div className="flex border rounded-sm  ">
              <div className=" flex items-center">
                <h2 className="text-xs ml-2 mr-2"> Type</h2>
                <div className="border-l border-gray-300 h-full " />
              </div>
              <div className="  rounded-r-md text-xs">
                <Button size="sm" className="bg-white text-xs">
                  Enhancement
                </Button>
              </div>
            </div>
          )}
          {Array.from(filterDropdown).includes("Owner") && (
            <div className="flex border rounded-sm  ">
              <div className=" flex items-center">
                <h2 className="text-xs ml-2 mr-2">Owner</h2>
                <div className="border-l border-gray-300 h-full " />
              </div>
              <div className="  rounded-r-md text-xs">
                <Dropdown>
                  <DropdownTrigger>
                    <Button size="sm" className="bg-white">
                      {Array.from(anyofKeys)[0]}
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Single selection example"
                    variant="flat"
                    closeOnSelect={false}
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={anyofKeys}
                    onSelectionChange={setAnyofKeys}
                  >
                    <DropdownItem key="Any of">Any of</DropdownItem>
                    <DropdownItem key="None of">None of</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="border-l border-gray-300 h-full " />
              <div className="   border-divider flex rounded-r-md text-sm">
                <OwnerDropdown
                  res={res}
                  users={users}
                  dropdownOwner={dropdownOwner}
                  setDropdownOwner={setDropdownOwner}
                />
              </div>
            </div>
          )}
          {Array.from(filterDropdown).includes("Parent") && (
            <div className="flex border rounded-sm  ">
              <div className=" flex items-center">
                <h2 className="text-xs ml-2 mr-2">Parent</h2>
                <div className="border-l border-gray-300 h-full " />
              </div>
              <div className="  rounded-r-md text-xs">
                <ParentDropDown
                  partData={partData}
                  parentKeys={parentKeys}
                  setParentKeys={setParentKeys}
                />
              </div>
            </div>
          )}
          <Dropdown>
            <DropdownTrigger>
              <Button
                size="sm"
                variant="light"
                className=" border border-divider"
                isIconOnly
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Multiple selection"
              variant="flat"
              closeOnSelect={false}
              selectionMode="multiple"
              disallowEmptySelection={false}
              selectedKeys={filterDropdown}
              onSelectionChange={setFilterDropdowm}
            >
              <DropdownItem key="Type">Type</DropdownItem>
              <DropdownItem key="Owner">Owner</DropdownItem>
              <DropdownItem key="Parent">Parent</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Button onClick={() => handleClearFilter()} size="sm" variant="light">
            Clear
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <Dropdown>
            <DropdownTrigger>
              <Button variant="light" size="sm" className="capitalize">
                Customize
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Single selection example"
              variant="flat"
              closeOnSelect={false}
              selectionMode="multiple"
              selectedKeys={customize}
              onSelectionChange={setCustomize}
            >
              <DropdownItem key="totaltickets">Pending Tickets</DropdownItem>
              <DropdownItem key="totalissues">Pending Issues</DropdownItem>
              <DropdownItem key="stage">Stage</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      {tableData.length === 0 ? (
        <p className="flex flex-col mt-60 text-xs gap-2  justify-center items-center  text-slate-400">
          <Icon />
          No Enhancements here
        </p>
      ) : (
        <div className=" px-4">
          <Table
            removeWrapper
            className="mt-10  "
            selectedKeys={selectedKeys}
            onSelectionChange={setSelectedKeys}
          >
            <TableHeader columns={columns}>
              {(column) => (
                <TableColumn key={column.key}>{column.label}</TableColumn>
              )}
            </TableHeader>
            <TableBody items={tableData}>
              {(item) => (
                <TableRow
                  className=" cursor-pointer hover:bg-gray-100 text-xs  border-b border-divider"
                  key={item.key}
                  onClick={() => toggleProductManagement(item)}
                >
                  {columns.map((column) => (
                    <TableCell className="text-xs" key={column.key}>
                      {column.key === "part" ? (
                        <Chip
                          className=""
                          color={
                            item["type"] === "Product"
                              ? "primary"
                              : item["type"] === "Capability"
                                ? "secondary"
                                : item["type"] === "Enhancement"
                                  ? "warning"
                                  : item["type"] === "Feature"
                                    ? "success"
                                    : "danger"
                          }
                          variant="flat"
                          size="sm"
                        >
                          {item["type"] === "Product"
                            ? "PRO"
                            : item["type"] === "Capability"
                              ? "CAP"
                              : item["type"] === "Enhancement"
                                ? "ENH"
                                : item["type"] === "Feature"
                                  ? "FEA"
                                  : "RUN"}
                          -{item[column.key]}
                        </Chip>
                      ) : column.key === "stage" ? (
                        <p>{item.stage ? item.stage : "-"}</p>
                      ) : column.key === "owner" ? (
                        <Avatar
                          showFallback
                          color={Colors[Number(item[column.key]) % numColors]}
                          name={
                            res[item[column.key]]?.FirstName
                              ? res[
                                item[column.key]
                              ]?.FirstName[0].toUpperCase()
                              : res[item[column.key]]?.UserName[0].toUpperCase()
                          }
                          size="sm"
                          radius="sm"
                          src={
                            process.env.REACT_APP_STAGE === "Dev"
                              ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[item[column.key]]?.ProfilePicture
                              }`
                              : `${res[item[column.key]]?.ProfilePicture}`
                          }
                        />
                      ) : column.key === "totaltickets" ||
                        column.key === "totalissues" ? (
                        item[column.key]?.length
                      ) : column.key === "delete" ? <Dropdown><DropdownTrigger><Button isIconOnly size="sm" variant="light" color="danger"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                      </Button></DropdownTrigger><DropdownMenu><DropdownItem onClick={() => handleDelete(item.id)} variant="flat" color="danger">Delete Permanently</DropdownItem></DropdownMenu></Dropdown> : (
                        item[column.key]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default Roadmap;
