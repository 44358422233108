import {
  Card,
  CardBody,
  Divider,
  Input,
  Snippet,
  Switch,
  Tab,
  Tabs,
} from "@nextui-org/react";
import React from "react";
import SwitchShow from "./Switch";

function Config({ appId, buttonText, setButtonText, chatTabs, setChatTabs, hex }) {

  return (
    <div className=" flex pt-8 flex-col gap-8">
      <Card shadow="none" className=" border border-divider">
        <CardBody className="flex flex-col gap-2 px-4">
          <p className=" text-sm"> Your unique App ID</p>
          <p className=" text-xs"> Copy and paste in your resolvSDK</p>
          <Snippet
            variant="bordered"
            classNames={{ base: "border border-divider" }}
            size="sm"
            symbol=""
          >
            {appId}
          </Snippet>
        </CardBody>
      </Card>
      <Card shadow="none" className=" border border-divider">
        <CardBody className="flex flex-col gap-2 px-4">
          <p className=" text-sm"> Start a new Conversation</p>
          <Divider />
          <div className=" flex justify-between items-center">
            <p className=" text-xs">Button Text</p>
            <Input
              maxLength={50}
              size="sm"
              className="w-[60%]"
              variant="bordered"
              classNames={{
                input: ["bg-white", "hover:bg-white"],
                innerWrapper: ["bg-white", "hover:bg-white"],
                inputWrapper: [
                  "shadow-none",
                  "border",
                  "border -divider",
                  "bg-white",
                  "dark:bg-white",
                  "hover:bg-white",
                  "dark:hover:bg-white",
                  "group-data-[focus=true]:bg-white",
                  "dark:group-data-[focus=true]:bg-white",
                  "data-[hover=true]:bg-white",
                ],
              }}
              endContent={
                <p
                  className={`text-xs ${buttonText.length >= 50 ? "text-red-500" : ""
                    }`}
                >{`${buttonText.length}/50`}</p>
              }
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
            />
          </div>
        </CardBody>
      </Card>
      <div className=" flex flex-col gap-6">
        <h1>Tabs</h1>
        {chatTabs?.map((item) => (
          <Card shadow="none" className=" border border-divider">
            <CardBody className="flex flex-col gap-2 px-4">
              <div className=" flex items-center gap-2">
                <svg
                  xmlns={item?.icon?.xmlns}
                  fill={item?.icon?.fill}
                  viewBox={item?.icon?.viewBox}
                  strokeWidth={item?.icon?.strokeWidth}
                  stroke={item?.icon?.stroke}
                  className="size-6"
                >
                  <path
                    strokeLinecap={item?.icon?.path?.strokeLinecap}
                    strokeLinejoin={item?.icon?.path?.strokeLinejoin}
                    d={item?.icon?.path?.d}
                  ></path>
                </svg>
                <p>{item.title}</p>
              </div>

              <div className=" flex gap-12 justify-between">
                <div className=" text-xs">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.
                </div>
                <SwitchShow hex={hex} chatTab={chatTabs} def={item.isVisible} setChatTab={setChatTabs} title={item.title} />
              </div>
            </CardBody>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Config;
