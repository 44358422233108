import React, { useState, useEffect } from "react";

import { Input, Button, Divider, Chip } from "@nextui-org/react";
import { IoIosClose } from "react-icons/io";
import {
  extensions,
  props,
  MenuBar,
} from "../../components/markdown/componenteditor";
import { EditorProvider } from "@tiptap/react";
import Tags from "../../components/Tag/tags";
import { CreateData, UpdateData } from "../../controllers/strapiController";
import { selectOrgData } from "../../redux/actions";
import { useSelector } from "react-redux";
import { areArraysEqual } from "../../utils/helper";

const AccountsDrawer = ({
  toggleDrawer,
  dropdownref,
  modalref,
  tenant,
  customTags,
  userNames,
  data,
  type,
}) => {
  const orgDetails = useSelector(selectOrgData);
  const [descriptionContent, setDescriptionContent] = useState(
    type === "update" && data?.attributes?.Description
      ? data?.attributes?.Description
      : ""
  );
  const [title, setTitle] = useState(
    type === "update" ? data?.attributes?.Name ?? "" : ""
  );
  const [tags, setTags] = useState(
    type === "update" && data?.attributes?.tags?.data
      ? data?.attributes?.tags?.data.map((item) => {
          return item?.id;
        })
      : []
  );
  const [start, setStart] = useState(false);
  const [website, setWebsite] = useState(
    type === "update" && data?.attributes?.Website
      ? data?.attributes?.Website
      : ""
  );
  const collection_name = "accounts";
  const [tagsDetail, setTagsDetail] = useState(
    type === "update" && data?.attributes?.tags?.data
      ? data?.attributes?.tags?.data
      : []
  );

  const handleClear = () => {
    setTitle("");
    setDescriptionContent("");
    setTags([]);
    setTagsDetail([]);
    setWebsite("");
  };

  const isNotChanged = () => {
    if (
      title === data.attributes.Name &&
      descriptionContent === data.attributes.Description &&
      website === data.attributes.Website
    ) {
      const arr = data.attributes?.tags?.data?.map((item) => {
        return item.id;
      });
      if (areArraysEqual(arr, tags)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleUpdate = async () => {
    if (isNotChanged()) {
      return;
    }

    const customer_data = {
      Name: title,
      Description: descriptionContent,
      Website: website,
      tags: {
        disconnect: data?.attributes?.tags.data.map((item) => {
          if (!tags.includes(Number(item.id) || String(item.id))) {
            return Number(item.id);
          }
        }),
        connect: tags.map((item) => {
          return {
            id: Number(item),
            position: {
              end: true,
            },
          };
        }),
      },
    };
    try {
      await UpdateData(collection_name, data.id, customer_data);
      window.location.reload();
    } catch (error) {}
  };
  const handleSubmit = async () => {
    const customer_data = {
      Name: title,
      Description: descriptionContent,

      Website: website,
      organization: {
        disconnect: [],
        connect: [
          {
            id: orgDetails?.id,
            position: {
              end: true,
            },
          },
        ],
      },
      tags: {
        disconnect: [],
        connect: tags.map((item) => {
          return {
            id: Number(item),
            position: {
              end: true,
            },
          };
        }),
      },
    };
    try {
      await CreateData(collection_name, customer_data);
      window.location.reload();
    } catch (error) {}
  };
  return (
    <div className="w-96 p-4 shadow-lg overflow-y-auto absolute top-0 right-0 h-full bg-white z-50">
      <div className="flex items-center justify-between">
        {type === "update" ? (
          <Chip color="success" size="sm" variant="flat">
            ACC-{data.id}
          </Chip>
        ) : (
          <Button size="sm" variant="flat" color="secondary">
            New Account
          </Button>
        )}
        <div>
          <Button className="bg-white" size="sm" onClick={handleClear}>
            Reset
          </Button>
          <Button isIconOnly variant="light" size="sm" onClick={toggleDrawer}>
            <IoIosClose style={{ width: "50px" }} />
          </Button>
        </div>
      </div>
      {/* Add rest of your JSX */}
      <div className="text-xs bg-white ">
        <Input
          className="mt-4 text-xs rounded-lg bg-white"
          style={{
            fontSize: "12px",
            backgroundColor: "",
          }}
          classNames={{
            input: ["bg-white", "hover:bg-white"],
            innerWrapper: ["bg-white", "hover:bg-white"],
            inputWrapper: [
              "shadow-none",
              "border",
              "border-divider",

              "bg-white",
              "dark:bg-white",
              "hover:bg-white",
              "dark:hover:bg-white",
              "group-data-[focus=true]:bg-white",
              "dark:group-data-[focus=true]:bg-white",
              "data-[hover=true]:bg-white",
            ],
          }}
          type="text"
          placeholder="Name"
          size="sm"
          variant="bordered"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          // onBlur={() => handleShow()}
        />
        <Divider className="mt-4" />
        <div className=" h-auto my-4 border-[2px] rounded-lg">
          <EditorProvider
            placeholder="Enter your description"
            editorProps={props}
            extensions={extensions(userNames)}
            slotBefore={
              <MenuBar
                descriptionContent={descriptionContent}
                setDescriptionContent={setDescriptionContent}
                initial={start}
                setInitial={setStart}
              />
            }
            content=""
          />
        </div>
        <Divider />
        <div className=" mt-4 flex gap-2 text-center items-center">
          <p className="flex w-32 gap-2 text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
              />
            </svg>
            Website Link
          </p>
          <div className=" w-full  flex ">
            <Input
              className="text-xs rounded-lg bg-white"
              style={{
                fontSize: "12px",
                backgroundColor: "",
              }}
              classNames={{
                input: ["bg-white", "hover:bg-white"],
                innerWrapper: ["bg-white", "hover:bg-white"],
                inputWrapper: [
                  "shadow-none",
                  "border",
                  "border-divider",

                  "bg-white",
                  "dark:bg-white",
                  "hover:bg-white",
                  "dark:hover:bg-white",
                  "group-data-[focus=true]:bg-white",
                  "dark:group-data-[focus=true]:bg-white",
                  "data-[hover=true]:bg-white",
                ],
              }}
              type="text"
              placeholder="Add Website Link"
              size="sm"
              variant="bordered"
              name="title"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-2 mt-4 pt-2 items-start">
          <p className="w-32 gap-2  text-xs text-center flex items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 6h.008v.008H6V6Z"
              />
            </svg>
            Tags
          </p>

          {/* Add tags here. */}
          <Tags
            param={tenant.name}
            customTags={customTags}
            org={tenant.id}
            dropdownref={dropdownref}
            tags={tags}
            setTags={setTags}
            tagsDetail={tagsDetail}
            setTagsDetail={setTagsDetail}
            modalref={modalref}
          />
        </div>
      </div>
      <div className="flex items-center mt-2 justify-end">
        <div className="flex items-center">
          <Button
            size="sm"
            className={`ml-2 text-white ${
              title === "" ||
              descriptionContent === "" ||
              descriptionContent === "<p></p>" ||
              website === "" ||
              (type === "update" && isNotChanged())
                ? ``
                : `bg-[rgb(51,51,245)]`
            }`}
            disabled={
              title === "" ||
              descriptionContent === "" ||
              descriptionContent === "<p></p>" ||
              website === "" ||
              (type === "update" && isNotChanged())
            }
            onClick={type === "update" ? handleUpdate : handleSubmit}
          >
            {type === "update" ? "Update" : "+ Create"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountsDrawer;
