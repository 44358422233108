import { Button, Input, Modal, ModalBody, ModalContent, Spinner } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DeleteSingleAttribute, getFilteredBySingleRelationSingleData } from "../../controllers/strapiController";
import { getMultipleFilters, patchData } from "../../controllers/vectorDB";
import PartsPicker from "../../components/PartsPicker/PartsPicker";
import PartPicker from "../../components/Drawer/PartPicker";

function DeleteModal({ isOpen, id, orgId, setIsOpen,partData,parsed }) {
  const [runnableIds, setRunnableIds] = useState([]);
  const [start, setStart] = useState(false);
  const [loading,setLoading] = useState(true);
  const [ticketIds,setTicketIds] = useState([]);
  const [selectedPart,setSelectedPart] = useState(new Set([]));
  const { org } = useParams();
  const [deleteInput,setDeleteInput] = useState("");
  const deleteParts = async(idArray) => {
    try{
        const promises = idArray.map((item) => DeleteSingleAttribute("parts",item));
        await Promise.all(promises);
    }
    catch(error){

    }
  }
  const reassignTickets = async(idArray,partId) => {
    try{
        const promises = idArray.map((item) => patchData({part:String(partId)},item,org));
        await Promise.all(promises);
    }
    catch(error){

    }
  }
  const handleDelete = async() => {
    try{
        const partArray = [...runnableIds,id];
        await reassignTickets(ticketIds,Array.from(selectedPart)[0]);
        await deleteParts(partArray);
        window.location.reload();
    }
    catch(error){

    }
  }
  useEffect(() => {
    const handleFetch = async () => {
      try {
        const resp = await getFilteredBySingleRelationSingleData(
          "parts",
          "organization",
          orgId,
          "Type",
          "Runnable"
        );
        const arr = [];
        resp.data.map((item) => {
          if (
            item.attributes.ParentPath.split("/").includes(
              String(id) || Number(id)
            )
          ) {
            arr.push(item.id);
          }
        });
        setRunnableIds(arr);
        setStart(true);
      } catch (error) {}
    };
    if (orgId && id) {
      handleFetch();
    }
  }, [orgId, id]);
  useEffect(() => {
    const fetchTickets = async () => {
      const idArray = [...runnableIds, id];
      try {
        const promises = idArray.map((i) =>
          getMultipleFilters([{ key: "part", value: String(i) }], org)
        );
        const resp = await Promise.all(promises);
        let arr = [];
        resp.map((item) => {
            arr = [...arr,...item]
        });
        const tickIds = arr.map((item) => {return item.payload.id});
        setTicketIds(tickIds);
        setLoading(false)
      } catch (error) {}
    };
    if (start) {
      fetchTickets();
    }
  }, [start]);
  return (
    <Modal size="2xl" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalContent>
        {loading ? <Spinner/> : <ModalBody className="flex flex-col my-8 gap-4 px-8">
            {ticketIds.length > 0 && <div className="flex w-full items-center justify-between">
                <p>Select Part to reassign tickets</p>
                <div className="w-60"><PartPicker parsedParts={parsed} selected={selectedPart} setSelected={setSelectedPart} parts={partData.filter((item) => Number(item.id) !== Number(id) && !runnableIds.includes(item.id) ) }/></div>
                
                </div>}
                <div className=" flex flex-col gap-2">
                <p className="text-sm">Write "DELETE" to Permanently delete the part</p>
                <Input className="w-full" color="danger" value={deleteInput} onChange={(e) => setDeleteInput(e.target.value)}/>
                </div>
                <div className="w-full flex items-center justify-end">
                    <Button color="danger" onClick={() => handleDelete()} disabled={deleteInput !== "DELETE" || (ticketIds.length >0 && Array.from(selectedPart).length === 0 )}>Delete</Button>
                </div>
                
        </ModalBody>}
      </ModalContent>
    </Modal>
  );
}

export default DeleteModal;
