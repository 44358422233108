import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOneData } from "../../controllers/strapiController";
import { Button, Chip, Divider } from "@nextui-org/react";
import { postData } from "../../controllers/vectorDB";
import "./styles.css";

function LearnMore() {
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const { org } = useParams();
  const { _id } = useParams();
  const navigate = useNavigate();
  const [temp, setTemp] = useState(null);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const handleUse = async (temp) => {
    if (temp === null) {
      return;
    }
    const id = Date.now();
    const formData = {
      authors: [userDataId],
      type: "blueprint",
      title: "Untitled",
      description: temp.attributes.temp,
      imageIds: [],
      tags: [],
      createdAt: id,
      updatedAt: id,
    };
    try {
      await postData(id, formData, org);
      navigate(`/${org}/blueprints/docs/${id}`);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    const fetchTemp = async () => {
      try {
        const resp = await getOneData("blueprint-templates", _id);
        setTemp(resp.data);
      } catch (error) {}
    };
    if (_id) {
      fetchTemp();
    }
  }, [_id]);

  return (
    <div className="flex-1 flex overflow-y-auto">
      <div className="h-full overflow-y-auto w-96 border-r-1 p-8 flex flex-col gap-8">
        <div className=" flex items-center gap-4">
          <Button
            onClick={() => navigate(`/${org}/blueprints/create`)}
            isIconOnly
            size="sm"
            variant="light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
              />
            </svg>
          </Button>
          <p>Explore all Blueprint Templates</p>
        </div>
        <div className=" flex flex-col gap-2">
          <h1 className=" font-semibold text-3xl">{temp?.attributes?.Name}</h1>
          <p className=" text-gray-400">By Resolv</p>
        </div>
        <div className="font-light text-sm">
          <div
            dangerouslySetInnerHTML={{ __html: temp?.attributes?.desc }}
          ></div>
        </div>
        <div className="flex justify-center w-full">
          <Button
            onClick={() => handleUse(temp)}
            size="sm"
            className="w-[80%] bg-[rgb(51,51,245)] text-white"
          >
            Use Template
          </Button>
        </div>
        <Divider />
        <div className=" flex flex-wrap w-full gap-2">
          {temp?.attributes?.Tags?.map((item, i) => (
            <Chip
              variant="flat"
              key={i}
              size="sm"
              className=" gap-2 text-xs"
              color={Colors[Number(temp?.id) % numColors]}
            >
              {item}
            </Chip>
          ))}
        </div>
      </div>
      <div className="h-full px-20 overflow-y-auto flex flex-1 gap-8 flex-col">
        <div className=" pt-8 ">
          <div className="w-full rounded-t-lg h-[16px] bg-[#1C2A4B] flex gap-[6px] items-center px-4">
            <div className="h-[8px] w-[9px] rounded-full bg-white"></div>
            <div className="h-[8px] w-[8px] rounded-full bg-white"></div>
            <div className="h-[8px] w-[8px] rounded-full bg-white"></div>
          </div>
          <div className=" border viewtemp tiptap shadow-lg border-divider overflow-y-auto rounded-b-lg w-full h-[600px]">
            <div
              className="viewtemp tiptap px-8 pb-4"
              dangerouslySetInnerHTML={{ __html: temp?.attributes?.temp }}
            ></div>
          </div>
        </div>
        <div className=" flex flex-col pb-8 gap-4">
          <p className=" font-semibold text-3xl">How to use this template</p>
          <div
            className="mydiv"
            dangerouslySetInnerHTML={{ __html: temp?.attributes?.doc }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default LearnMore;
