import React, { useEffect, useState } from "react";
import { getSingleTicket } from "../../controllers/vectorDB";
import {
  Card,
  CardBody,
  Chip,
} from "@nextui-org/react";

function TicketCards({ ticketIds, org }) {
  const [tickets, setTickets] = useState([]);
  const [final, setFinal] = useState([]);
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const promises = ticketIds.map((item) =>
          getSingleTicket(Number(item), org)
        );
        const resp = await Promise.all(promises);
        setTickets(resp);
       
      } catch (error) {
        console.log("Error", error);
      }
    };
    if (ticketIds.length > 0) {
      fetchTickets();
    } else {
     
    }
  }, [ticketIds]);

  useEffect(() => {
    const settingTickets = () => {
      const actualTickets = tickets
        .filter((t) => {
          if (t.length > 0) {
            return true;
          }
        })
        .map((item) => {
          return item[0];
        });
      setFinal(actualTickets);
      
    };
    if (tickets.length > 0) {
      settingTickets();
    }
  }, [tickets]);
  if (final.length === 0) {
    return <></>;
  } else {
    return (
      <div className=" flex flex-col w-full gap-4">
        {final.map((item, index) => (
          <Card
            key={index}
            fullWidth
            className="border border-divider"
            shadow="none"
          >
            <CardBody className=" flex flex-col gap-4">
              <div className=" flex items-center justify-between">
                <div className=" flex items-center gap-4">
                  <Chip variant="flat" size="sm" color="primary">
                    TKT-{item.payload.latest}
                  </Chip>
                  <p className="text-sm">{item.payload.title}</p>
                </div>
              </div>

              <div className=" text-xs font-light">
                {item.payload.description.replace(/<[^>]*>/g, "").slice(0, 25)}{" "}
              </div>
            </CardBody>
          </Card>
        ))}
      </div>
    );
  }
}

function ChatTicket({ threads, org }) {
  return (
    <div className=" h-[400px] w-full overflow-y-auto">
      { threads.map((item, index) => (
        <div key={index} className="flex flex-col gap-2">
          <TicketCards ticketIds={item.payload.tickets} org={org} />
        </div>
      ))}
    </div>
  );
}

export default ChatTicket;
