import { cn, Switch } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Helper function to validate hex code

function SwitchShow({ chatTab, setChatTab, def, title, hex }) {
  const [isSelected, setIsSelected] = useState(def);
  // const data = useSelector(state => state.hex);
  useEffect(() => {
    if (def !== isSelected) {
      let updatedArr = chatTab.map((it) =>
        it.title === title ? { ...it, isVisible: isSelected } : it
      );
      setChatTab(updatedArr);
    }
  }, [isSelected]);
  return (
    <><Switch
      classNames={{
        wrapper: `group-data-[selected=true]:bg-[${hex}]`
      }}
   
      size="sm"
      isSelected={isSelected}
      onValueChange={setIsSelected}
    /></>
  );
}

export default SwitchShow;
