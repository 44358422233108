import React, { useEffect, useRef, useState } from "react";
import { Avatar, Card, CardBody, Divider, Tab, Tabs } from "@nextui-org/react";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { getFilteredBySingleRelationSingleData } from "../../controllers/strapiController";
import { getDocument } from "../../controllers/vectorDB";

function UserDrawer({ user, handleClose }) {
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const [loading, setLoading] = useState(false);
  const { org } = useParams();
  const drawerRef = useRef(null);
  const navigate = useNavigate();
  const [issueData, setIssueData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [filteredIssueData, setFilteredIssueData] = useState([]);
  const [filteredTicketData, setFilteredTicketData] = useState([]);
  const [currentOrg, setCurrentOrg] = useState(null);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;

  const removeHtmlTags = (text) => {
    if (!text) return "";
    return text.replace(/<\/?[^>]+>/gi, "");
  };
  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const resp = await getFilteredBySingleRelationSingleData(
          "organizations",
          "users",
          userDataId,
          "Name",
          org
        );
        if (resp.data.length === 1) {
          setCurrentOrg(resp.data[0]);
        } else {
          navigate("/404");
        }
      } catch (error) {
        navigate("/404");
      }
    };

    if (org && userDataId) {
      fetchOrg();
    }
  }, [org, userDataId, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);
  useEffect(() => {
    setLoading(true);
    async function fetchDocument() {
      try {
        const issues = await getDocument("type", "issue", org);
        const tickets = await getDocument("type", "ticket", org);

        if (issues) {
          const userIdStr = String(user.id);
          const filteredIssues = issues.filter((issue) => {
            const assignedStr = String(issue.payload.assigned);
            const stage = issue.payload.stage;
            return assignedStr === userIdStr && stage !== "Resolved";
          });
          filteredIssues.forEach((issue) => {});

          setIssueData(issues);
          setFilteredIssueData(filteredIssues);
        }

        if (tickets) {
          const userIdStr = String(user.id);

          const filteredTickets = tickets
            .filter((ticket) => {
              const assignedStr = String(ticket.payload.assigned);
              const stage = ticket.payload.stage;
              return assignedStr === userIdStr && stage !== "Resolved";
            })
            .sort((a, b) => b.payload.latest - a.payload.latest);

          setTicketData(
            tickets.sort((a, b) => b.payload.latest - a.payload.latest)
          );
          setFilteredTicketData(filteredTickets);
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchDocument();
  }, [org, user.id]);

  const colorIndex = user && user.id % Colors.length;
  const color = Colors[colorIndex];

  const hasFullName = (name) => {
    return name?.FirstName && name?.LastName;
  };

  const getFullName = (name) => {
    return hasFullName(name) ? `${name.FirstName} ${name.LastName}` : "";
  };

  const getAvatarInitials = (name) => {
    if (hasFullName(name)) {
      return `${name.FirstName.slice(0, 1).toUpperCase()}`;
    } else if (name?.username) {
      return name.username.charAt(0).toUpperCase();
    }
    return "";
  };

  const truncateText = (text, wordLimit) => {
    const cleanedText = removeHtmlTags(text);
    const words = cleanedText.split(" ");
    if (words.length <= wordLimit) return cleanedText;
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  return (
    <div
      ref={drawerRef}
      className="w-96 p-4 border border-divider overflow-y-auto absolute top-0 right-0 h-full bg-white z-50"
    >
      <button className="absolute top-2 right-2" onClick={handleClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="flex mt-10 justify-center gap-4">
        {user?.ProfilePicture ? (
          <Avatar
            src={
              user?.ProfilePicture?.formats?.small?.url
                ? process.env.REACT_APP_STAGE === "Dev"
                  ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user.ProfilePicture.formats.small.url}`
                  : `${user.ProfilePicture.formats.small.url}`
                : null
            }
            alt={getFullName(user)}
            className="h-48 w-48"
            radius="md"
          />
        ) : (
          <div
            className={`h-48 w-48 rounded-md flex items-center justify-center bg-${color}`}
          >
            <span className="text-white text-6xl">
              {getAvatarInitials(user)}
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-col mt-4 text-center font-normal text-lg gap-4">
        {hasFullName(user) ? (
          <p>{getFullName(user)}</p>
        ) : (
          <p>{user?.username || ""}</p>
        )}
      </div>
      <p className="text-center font-light">{user?.email}</p>
      <Divider className="mt-4" />
      <div className="flex flex-col mb-2 mt-4 justify-between gap-4">
        <div className="flex gap-14 text-center">
          <p className="text-xs text-center flex justify-center items-center">
            Email
          </p>
          <p className="w-24 text-xs text-center flex items-center">
            {user?.email}
          </p>
        </div>
        <div className="flex gap-16 text-center">
          <p className="text-xs text-center flex justify-center items-center">
            Role
          </p>
          <p className="w-24 text-xs text-center flex items-center">
            {user?.JobTitle || "--"}
          </p>
        </div>
        <div className="flex gap-14 text-center">
          <p className="text-xs text-center flex justify-center items-center">
            Status
          </p>
          <p className="w-24 text-xs text-center flex items-center">
            {user?.Status}
          </p>
        </div>
        <div className="flex gap-8 text-center">
          <p className="text-xs text-center flex justify-center items-center">
            User Since
          </p>
          <p className="w-24 text-xs text-center flex items-center">
            {user?.createdAt
              ? format(new Date(user.createdAt), "yyyy-MM-dd")
              : ""}
          </p>
        </div>
      </div>
      <Divider />
      <Tabs aria-label="Options" className="mt-4">
        <Tab key="groups" title="Groups">
          <div className="p-4">
            {currentOrg?.attributes?.groups?.data.length === 0 ? (
              <p>No groups available</p>
            ) : (
              currentOrg?.attributes?.groups?.data.map((group) => (
                <Card
                  key={group.id}
                  className="mb-4 shadow-none border border-divider cursor-pointer"
                >
                  <CardBody onClick={() => navigate(`/${org}/settings/groups`)}>
                    <div className="flex gap-4 items-center">
                      {group?.attributes?.ProfilePicture ? (
                        <Avatar
                          src={
                            group?.attributes?.ProfilePicture?.formats?.small
                              ?.url
                              ? process.env.REACT_APP_STAGE === "Dev"
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${group.attributes.ProfilePicture.formats.small.url}`
                                : `${group.attributes.ProfilePicture.formats.small.url}`
                              : null
                          }
                          alt={group.attributes.Name}
                          className="h-6 w-6"
                        />
                      ) : (
                        <div
                          className={`h-6 w-6 rounded-md flex items-center justify-center bg-${color}`}
                        >
                          <span className="text-white text-xs">
                            {group.attributes.Name.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      )}
                      <p className="font-semibold">{group.attributes.Name}</p>
                    </div>
                    <p className="truncate">
                      {truncateText(group.attributes.Description, 10)}
                    </p>
                  </CardBody>
                </Card>
              ))
            )}
          </div>
        </Tab>
        <Tab key="ongoing-work" title="Ongoing Work">
          <div className="p-4">
            <h2 className="text-lg font-normal">Pending Issues</h2>
            {loading ? (
              <p>Loading...</p>
            ) : filteredIssueData.length === 0 ? (
              <p>No current issue assigned to {user?.username} </p>
            ) : (
              filteredIssueData.map((issue) => (
                <Card
                  key={issue.id}
                  className="mb-4 shadow-none border border-divider cursor-pointer"
                >
                  <CardBody>
                    <div className="flex items-center gap-4">
                      {issue?.attributes?.ProfilePicture ? (
                        <Avatar
                          src={
                            issue?.attributes?.ProfilePicture?.formats?.small
                              ?.url
                              ? process.env.REACT_APP_STAGE === "Dev"
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${issue.attributes.ProfilePicture.formats.small.url}`
                                : `${issue.attributes.ProfilePicture.formats.small.url}`
                              : null
                          }
                          alt={issue.payload.title}
                          className="h-6 w-6"
                        />
                      ) : (
                        <div
                          className={`h-6 w-6 rounded-md flex items-center justify-center bg-${color}`}
                        >
                          <span className="text-white text-xs">
                            {issue.payload.title.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      )}
                    </div>
                    <div>
                      <h3 className="font-semibold">{issue.payload.title}</h3>
                      <p>{truncateText(issue.payload.description, 10)}</p>
                    </div>
                  </CardBody>
                </Card>
              ))
            )}

            <h2 className="text-lg font-normal mt-4">Pending Tickets</h2>
            {loading ? (
              <p>Loading...</p>
            ) : filteredTicketData.length === 0 ? (
              <p>No current ticket assigned to {user?.username}</p>
            ) : (
              filteredTicketData.map((ticket) => (
                <Card
                  key={ticket.id}
                  className="mb-4 shadow-none border border-divider cursor-pointer"
                >
                  <CardBody>
                    <div className="flex items-center gap-4">
                      {ticket?.attributes?.ProfilePicture ? (
                        <Avatar
                          src={
                            ticket?.attributes?.ProfilePicture?.formats?.small
                              ?.url
                              ? process.env.REACT_APP_STAGE === "Dev"
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${ticket.attributes.ProfilePicture.formats.small.url}`
                                : `${ticket.attributes.ProfilePicture.formats.small.url}`
                              : null
                          }
                          alt={ticket.payload.title}
                          className="h-6 w-6"
                        />
                      ) : (
                        <div
                          className={`h-6 w-6 rounded-md flex items-center justify-center bg-${color}`}
                        >
                          <span className="text-white text-xs">
                            {ticket.payload.title.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      )}
                    </div>
                    <div>
                      <h3 className="font-semibold">{ticket.payload.title}</h3>
                      <p>{truncateText(ticket.payload.description, 10)}</p>
                    </div>
                  </CardBody>
                </Card>
              ))
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default UserDrawer;
