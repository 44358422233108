// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mydiv ul {
    padding-left: 16px; /* For ordered lists */
    list-style-type: disc; /* For unordered lists */
}

.mydiv ol {
    padding-left: 16px; /* For ordered lists */
    list-style-type: decimal; /* For unordered lists */
}

.mention {
    background-color: #F4F4F5;
    border-radius: 0.4rem;
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
    color: purple;
    padding: 0.1rem 0.3rem;
  }

  ul[data-type="taskList"] {
    list-style: none;
    margin-left: 0;
    padding: 0;
 

    li {
      align-items: center;
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        -webkit-user-select: none;
                user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
     margin-top: 3px;
    }

    ul[data-type="taskList"] {
      margin: 0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Inbox/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,EACK,sBAAsB;IAC7C,qBAAqB,EAAE,wBAAwB;AACnD;;AAEA;IACI,kBAAkB,EACQ,sBAAsB;IAChD,wBAAwB,EAAE,wBAAwB;AACtD;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,mCAA2B;YAA3B,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,cAAc;IACd,UAAU;;;IAGV;MACE,mBAAmB;MACnB,aAAa;;MAEb;QACE,cAAc;QACd,oBAAoB;QACpB,yBAAiB;gBAAjB,iBAAiB;MACnB;;MAEA;QACE,cAAc;MAChB;IACF;;IAEA;MACE,eAAe;KAChB,eAAe;IAChB;;IAEA;MACE,SAAS;IACX;EACF","sourcesContent":[".mydiv ul {\n    padding-left: 16px;\n    list-style-type: disc; /* For ordered lists */\n    list-style-type: disc; /* For unordered lists */\n}\n\n.mydiv ol {\n    padding-left: 16px;\n    list-style-type: decimal; /* For ordered lists */\n    list-style-type: decimal; /* For unordered lists */\n}\n\n.mention {\n    background-color: #F4F4F5;\n    border-radius: 0.4rem;\n    box-decoration-break: clone;\n    color: purple;\n    padding: 0.1rem 0.3rem;\n  }\n\n  ul[data-type=\"taskList\"] {\n    list-style: none;\n    margin-left: 0;\n    padding: 0;\n \n\n    li {\n      align-items: center;\n      display: flex;\n\n      > label {\n        flex: 0 0 auto;\n        margin-right: 0.5rem;\n        user-select: none;\n      }\n\n      > div {\n        flex: 1 1 auto;\n      }\n    }\n\n    input[type=\"checkbox\"] {\n      cursor: pointer;\n     margin-top: 3px;\n    }\n\n    ul[data-type=\"taskList\"] {\n      margin: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
