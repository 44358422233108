import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Select,
  SelectItem,
  Progress,
} from "@nextui-org/react";
import {
  CreateData,
  UpdateOneUserData,
  getAllData,
} from "../../controllers/strapiController";
import { createDefault } from "../../utils/defaultFunction";
import { useNavigate } from "react-router-dom";

export default function Modals() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();
  const base = `${process.env.REACT_APP_SCRUM_IP_ADDRESS}`;
  const [orgName, setOrgName] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [orgUrl, setOrgUrl] = useState("");
  const [orgregion, setOrgRegion] = useState(new Set([]));
  const userLogged = JSON.parse(sessionStorage.getItem("userData"));
  const userId = userLogged && userLogged.user.id;
  const [loading, setLoading] = useState(-1);
  const [furl, setFUrl] = useState(null);

  const sanitizeOrgName = (name) => {
    return name.replace(/[^a-zA-Z0-9-_ ]/g, "");
  };

  const handleOrgNameChange = (value) => {
    const sanitizedValue = sanitizeOrgName(value);
    setOrgName(sanitizedValue);
    const url = generateURL(sanitizedValue);
    setOrgUrl(url);
  };

  const handleCreateOrg = async () => {
    const name = generateURL(orgName);
    const url = generateURL(orgName);
    const region = Array.from(orgregion)[0].trim();
    const tenant = orgName;
    if (name !== "" && url !== "" && region !== "" && tenant !== "") {
      const update = {
        OrgRoute: `/${name}`,
      };
      const newOrg = {
        Name: name,
        URL: url,
        Region: region,
        Tenant: tenant,
        users: {
          disconnect: [],
          connect: [
            {
              id: userId,
              position: {
                end: true,
              },
            },
          ],
        },
        ticket_latest: 0,
        issue_latest: 0,
        opportunity_latest: 0,
        Product_latest: 0,
        Capability_latest: 0,
        Feature_latest: 0,
        Enhancement_latest: 0,
        Runnable_latest: 0,
      };
      try {
        const resp = await UpdateOneUserData("users", userId, update);
        const data = await CreateData("organizations", newOrg);
        const runDefault = await createDefault(name, userId, setLoading);
        setFUrl(url);
      } catch (error) {
        console.error("Form submission failed:", error);
      }
    }
  };


  const generateURL = (name) => {
    const lowercaseName = name.toLowerCase().replace(/\s+/g, "-");
    return lowercaseName;
  };

  useEffect(() => {
    const handleOrg = async () => {
      const resp = await getAllData("organizations");
      let arr = [];
      resp?.data?.map((item) => {
        arr.push(item.attributes.URL);
      });
      setOrganizations(arr);
    };
    handleOrg();
  }, []);
  useEffect(() => {
    if (loading === 100 && furl) {
      navigate(`/${furl}`);
      window.location.reload();
    }
  }, [loading]);

  return (
    <>
      <div className="flex items-center justify-between w-full mt-2">
        <div>Organizations</div>
        <Button
          onPress={onOpen}
          className="flex justify-center items-center"
          size="sm"
          isIconOnly
          variant="light"
        >
          +
        </Button>
      </div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {loading >=0 ? <div className=" w-full flex items-center justify-center"><Progress size="sm" value={loading} showValueLabel={false} /></div> : <>
            <ModalHeader className="flex flex-col gap-2">Add org</ModalHeader>
            <ModalBody>
              <p className="text-xs">Name</p>
              <Input
                placeholder="Organization name"
                value={orgName}
                className="text-default-400"
                onChange={(e) => handleOrgNameChange(e.target.value)}
              />
              <Input
                color={organizations.includes(orgUrl) ? "danger" : "default"}
                type="text"
                name="URL"
                className="text-xs mt-6"
                labelPlacement="outside"
                value={orgUrl}
                placeholder="URL"
                readOnly
                startContent={
                  <div className="pointer-events-none flex items-center">
                    <span className="text-default-400 text-small">{base}/</span>
                  </div>
                }
              />
              <p className="text-xs">Company Region</p>
              <Select
                className="w-full mt-6"
                placeholder="Enter your region"
                selectedKeys={orgregion}
                onSelectionChange={setOrgRegion}
              >
                <SelectItem key="us-east-2">us-east-2</SelectItem>
                <SelectItem key="us-east-1">us-east-1</SelectItem>
                <SelectItem key="us-west-1">us-west-1</SelectItem>
                <SelectItem key="us-west-2">us-west-2</SelectItem>
                <SelectItem key="af-south-1">af-south-1</SelectItem>
                <SelectItem key="ap-east-1">ap-east-1</SelectItem>
              </Select>
            </ModalBody>
            <ModalFooter className="flex justify-center">
              <Button
                className="w-[50%] gap-2 lt-500 text-white bg-black hover:bg-gray-700 hover:text-white"
                radius="sm"
                disabled={
                  organizations.includes(orgUrl) ||
                  !(
                    generateURL(orgName) !== "" &&
                    Array.from(orgregion).length >0 && Array.from(orgregion)[0].trim() !== "" &&
                    orgName !== ""
                  )
                }
                color="default"
                onClick={handleCreateOrg}
              >
                Create
              </Button>
            </ModalFooter>
          </>}
        </ModalContent>
      </Modal>
    </>
  );
}
