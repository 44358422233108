import {Checkbox, Input, Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateData,
  UpdateData,
  getFilteredByTwoRelation,
  getOneData,
} from "../../controllers/strapiController";
import TaskList from "./TaskList";
import { getDocument } from "../../controllers/vectorDB";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";

const Tasks = () => {
  const { org } = useParams();
  const [open, setOpen] = useState([]);
  const [closed, setClosed] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [orgTask, setOrgTask] = useState(null);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [issues, setIssues] = useState([]);

  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const navigate = useNavigate();

  const handleDateChange = async (item, newDeadline, state) => {
    const newItem = { ...item, deadline: newDeadline };

    let updatedOpen = [...open];
    let updatedClosed = [...closed];

    if (state === "open") {
      const index = updatedOpen.findIndex(
        (task) =>
          task.name === item.name &&
          task.issue.toString() === item.issue.toString() &&
          task.stage === item.stage &&
          task.deadline === item.deadline
      );
      if (index !== -1) {
        updatedOpen.splice(index, 1);
      }
      updatedOpen = [newItem, ...updatedOpen];
    } else {
      const index = updatedClosed.findIndex(
        (task) =>
          task.name === item.name &&
          task.issue.toString() === item.issue.toString() &&
          task.stage === item.stage &&
          task.deadline === item.deadline
      );
      if (index !== -1) {
        updatedClosed.splice(index, 1);
      }
      updatedClosed = [newItem, ...updatedClosed];
    }

    try {
      const resp = await UpdateData("tasks", orgTask[0].id, {
        Tasks: [...updatedOpen, ...updatedClosed],
      });
      fetchTask(); // Refresh tasks after update
    } catch (error) {
      console.error("Error updating task deadline:", error);
    }
  };

  const handleDelete = async (taskToDelete) => {
    try {

      const updatedOpen = open.filter((task) => task.index !== taskToDelete.index);
      const updatedClosed = closed.filter((task) => task.index !== taskToDelete.index);

      setOpen(updatedOpen);
      setClosed(updatedClosed);

      await UpdateData("tasks", orgTask[0].id, { Tasks: [...updatedOpen, ...updatedClosed] });

      await fetchTask();
    } catch (error) {
      console.error("Error in handleDelete:", error);
    }
  };

  const fetchUser = async () => {
    try {
      const resp = await getOneData("users", userDataId);
      let ifUserinOrg = false;
      for (let i = 0; i < resp.organizations.length; i++) {
        if (resp.organizations[i].Name === org) {
          setOrgId(resp.organizations[i].id);
          ifUserinOrg = true;
        }
      }
      if (!ifUserinOrg) {
        navigate("/404");
      }
    } catch (error) {
     
    }
  };

  const fetchTask = async () => {
    try {
      const resp = await getFilteredByTwoRelation(
        "tasks",
        "user",
        userDataId,
        "organization",
        orgId
      );

  

      setOrgTask(resp.data);

      if (resp.data.length === 1) {
        const tasks = resp.data[0].attributes.Tasks;
        const sortedOpen = tasks.filter((item) => item.stage === "open").sort((a, b) => b.index - a.index);
        const sortedClosed = tasks.filter((item) => item.stage === "closed").sort((a, b) => b.index - a.index);
        setOpen(sortedOpen);
        setClosed(sortedClosed);
      }
    } catch (error) {
   
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" && name.trim() !== "") {
      try {
        const timestamp = Date.now();

        const newTask = {
          index: timestamp,
          name: name,
          issue: [],
          stage: "open",
          deadline: date,
        };

        const updatedOpen = [...open, newTask];
        const sortedUpdatedOpen = updatedOpen.sort((a, b) => b.index - a.index);
        setOpen(sortedUpdatedOpen);
        setName("");

        const updatedTasks = [...sortedUpdatedOpen, ...closed];
        await UpdateData("tasks", orgTask[0].id, {
          Tasks: updatedTasks,
        });
      } catch (error) {
      
      }
    }
  };

  const createTask = async () => {
    const timestamp = Date.now();
    const formData = {
      Tasks: [],
      user: {
        disconnect: [],
        connect: [
          {
            id: userDataId,
            position: {
              end: true,
            },
          },
        ],
      },
      organization: {
        disconnect: [],
        connect: [
          {
            id: orgId,
            position: {
              end: true,
            },
          },
        ],
      },
    };
    try {
      const createdTask = await CreateData("tasks", formData);
      fetchTask();
    } catch (error) {
 
    }
  };

  useEffect(() => {
    if (userDataId && org) {
      fetchUser();
    }
  }, [userDataId, org]);

  useEffect(() => {
    if (orgId && userDataId) {
      fetchTask();
    }
  }, [orgId, userDataId]);

  useEffect(() => {
    if (orgTask && orgTask.length === 0) {
      createTask();
    }
  }, [orgTask]);

  useEffect(() => {
    if (orgTask && orgTask.length === 1) {
      const tasks = orgTask[0].attributes.Tasks;
      const sortedOpen = tasks.filter((item) => item.stage === "open").sort((a, b) => b.index - a.index);
      const sortedClosed = tasks.filter((item) => item.stage === "closed").sort((a, b) => b.index - a.index);
      setOpen(sortedOpen);
      setClosed(sortedClosed);
    }
  }, [orgTask]);

  useEffect(() => {
    async function fetchDocument() {
      try {
        const tickData = await getDocument("type", "issue", org);
        if (tickData) {
          setIssues(tickData);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    }
    fetchDocument();
  }, [org]);

  return (
    <div className="flex flex-col h-screen overflow-y-auto items-center pt-8">
      <div className="w-full border-b border-divider pb-8 flex justify-center">
      <div className=" flex text-center justify-center items-center">
            <Breadcrumb />
          </div>
        <p className="w-[90%] text-sm">
          My Tasks {open && <span className="font-light ml-4">{open.length}</span>}
        </p>
      </div>
      <Tabs className="pt-8" size="sm">
        <Tab
          className="w-full flex flex-col items-center justify-center"
          title="Open"
        >
          <div className="pt-8 flex flex-col w-[90%]">
            <div className="w-full  px-2 flex justify-start pb-2 items-center">
              <div>
              </div>
              <Input
                placeholder="Add task..."
                className="w-full"
                value={name}
                onKeyDown={handleKeyDown}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {open?.map((item, index) => (
              <div className="border-b border-divider" key={item.name + index}>
                <TaskList
                  issues={issues}
                  item={item}
                  open={open}
                  closed={closed}
                  taskId={orgTask[0].id}
                  state="open"
                  fetchTask={fetchTask}
                  onDelete={handleDelete}
                  onDateChange={handleDateChange}
                />
              </div>
            ))}
          </div>
        </Tab>
        <Tab
          className="w-full flex flex-col items-center justify-center"
          title="Closed"
        >
          <div className="pt-8 flex flex-col w-[90%]">
            <div className="w-full px-2 flex justify-start pb-2 items-center">
              <div>
                <Checkbox defaultSelected></Checkbox>
              </div>
              <Input
                placeholder="Add task..."
                className="w-full"
                value={name}
                onKeyDown={handleKeyDown}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {closed?.map((item, index) => (
              <div className="border-b border-divider" key={item.name + index}>
                <TaskList
                  issues={issues}
                  item={item}
                  open={open}
                  closed={closed}
                  taskId={orgTask[0].id}
                  state="closed"
                  fetchTask={fetchTask}
                  onDelete={handleDelete}
                />
              </div>
            ))}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Tasks;
