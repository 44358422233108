import "./styles.css";

import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";
import Focus from "@tiptap/extension-focus";
import Placeholder from "@tiptap/extension-placeholder";
import { useCurrentEditor } from "@tiptap/react";
import italic from "../../Images/Icons/italic.svg";
import bold from "../../Images/Icons/bold.svg";
import code from "../../Images/Icons/code.svg";
import list from "../../Images/Icons/list.svg";
import strike from "../../Images/Icons/strike.svg";
import quote from "../../Images/Icons/quote.svg";
import numberlist from "../../Images/Icons/numberlist.svg";
import Image from "@tiptap/extension-image";
import { useEffect } from "react";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Mention from "@tiptap/extension-mention";
import suggestionExtension from "./suggestion";

export const extensions = (nameArray) => {
  return [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    Focus.configure({
      className: "has-focus",
      mode: "all",
    }),
    Mention.configure({
      HTMLAttributes: {
        class: "mention",
      },
      suggestion: suggestionExtension(nameArray),
    }),
    Placeholder.configure({
      emptyEditorClass: "Add Description",
      placeholder: "Add Description",
    }),
    TaskList,
    TaskItem.configure({
      nested: false,
    }),
    Image.configure({
      inline: true,
    }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
    }),
  ];
};
export const MenuBar = ({
  descriptionContent,
  setDescriptionContent,
  clear,
  initial,
  setInitial,
}) => {
  const { editor } = useCurrentEditor();
  const html = editor.getHTML();
  useEffect(() => {
    if (clear > 0) {
      editor.commands.clearContent();
      setDescriptionContent("");
    }
  }, [clear]);
  useEffect(() => {
    if (editor && !initial) {
      editor.commands.setContent(descriptionContent);
      setInitial(true);
    }
  }, [editor, initial]);
  useEffect(() => {
    if (initial) {
      setDescriptionContent(html);
    }
  }, [html, initial]);
  if (!editor) {
    return null;
  }
  return (
    <div className="flex gap-4 p-2">
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={
          editor.isActive("bold")
            ? "is-active"
              ? " bg-gray-200  rounded-md  "
              : "bg-transparent"
            : ""
        }
      >
        <img
          src={bold}
          alt="bold"
          style={{ height: "15px", width: "15px" }}
          className="hover:bg-gray-200 rounded-md  text-black  "
        />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={
          editor.isActive("italic")
            ? "is-active"
              ? " bg-gray-200  rounded-md   "
              : "bg-transparent"
            : ""
        }
      >
        <img
          alt="italic"
          src={italic}
          style={{ widhth: "15px", height: "15px" }}
          className="hover:bg-gray-200 rounded-md  text-black  "
        />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={
          editor.isActive("strike")
            ? "is-active"
              ? " bg-gray-200  rounded-md   "
              : "bg-transparent"
            : ""
        }
      >
        <img
          src={strike}
          alt="strike"
          style={{ widhth: "15px", height: "15px" }}
          className="hover:bg-gray-200 rounded-md  text-black  "
        />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={
          editor.isActive("code")
            ? "is-active"
              ? " bg-gray-200  rounded-md  "
              : "bg-transparent"
            : ""
        }
      >
        <img
          src={code}
          alt="code"
          style={{ widhth: "15px", height: "15px" }}
          className="hover:bg-gray-200 rounded-md  text-black  "
        />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={
          editor.isActive("blockquote")
            ? "is-active"
              ? " bg-gray-200  rounded-md  "
              : "bg-transparent"
            : ""
        }
      >
        <img
          src={quote}
          style={{ widhth: "15px", height: "15px" }}
          alt="quote"
          className="hover:bg-gray-200 rounded-md  text-black  "
        />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={
          editor.isActive("bulletlist")
            ? "is-active"
              ? " bg-gray-200  rounded-md   "
              : "bg-transparent"
            : ""
        }
      >
        <img
          src={list}
          style={{ widhth: "15px", height: "15px" }}
          alt="list"
          className="hover:bg-gray-200 rounded-md  text-black  "
        />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={
          editor.isActive("orderedlist")
            ? "is-active"
              ? " bg-gray-200  rounded-md   "
              : "bg-transparent"
            : ""
        }
      >
        <img
          src={numberlist}
          style={{ widhth: "15px", height: "15px" }}
          alt="numberlist"
          className="hover:bg-gray-200 rounded-md  text-black  "
        />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        className={editor.isActive("taskList") ? "is-active" : ""}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
          />
        </svg>
      </button>
    </div>
  );
};
export const props = {
  attributes: {
    class: "prose max-w-none [&_ol]:list-decimal [&_ul]:list-disc text-xs"
  },
};
// export default () => {
//   return (
//     <EditorProvider editorProps={props} slotBefore={<MenuBar />} extensions={extensions} content={content}></EditorProvider>
//   )
// }
