import { Card, CardBody, Chip } from '@nextui-org/react'
import React from 'react'

function InboxSearch({tickets,ticketIds,setTicketIds,handleUpdate,ticketAdd,setTicketAdd}) {
    const handleSelect = async(id) => {
        if(!ticketIds.includes(Number(id))) {
                const arr= ticketIds;
                arr.push(id);
            try{
                await handleUpdate({tickets : arr})
                setTicketIds(arr);
                setTicketAdd(!ticketAdd);
            }
            catch(error){
                console.log("Error:",error)
            }
        }
      }
  return (
    <div className=" flex flex-col gap-4 w-full">
      {tickets.map((item,index) => {
        if(item.payload.type === "ticket"){
            return (
                <Card
                key={index}
                onClick={() => handleSelect(item.payload.id)}
                fullWidth
              className="border border-divider"
              isPressable
              shadow="none"
            >
                <CardBody className=" flex flex-col gap-4">
                    <div className=" flex items-center gap-4">
                        <Chip variant="flat" size="sm" color="primary">TKT-{item.payload.latest}</Chip>
                        <p className="text-sm">{item.payload.title}</p>
                    </div>
                    <div className=" text-xs font-light">{item.payload.description.replace(/<[^>]*>/g, '').slice(0,25)} </div>
                </CardBody>
            </Card>
            )
        }
      })}
    </div>
  )
}

export default InboxSearch
