import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import './commandstyles.css';

const DropdownMenu = forwardRef(({ items, command }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    setSelectedIndex(0);
  }, [items]);


  const upHandler = () => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  const selectItem = (index) => {
    const item = items[index];
    if (item) {
      command(item);
    }
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: (event) => {
      if (event.event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div
      className="dropdown-menu-blueprints shadow-lg"
    >
      <div className='text-sm text-gray-400 pl-2'>Basic Blocks</div>
      {items.length ? (
        items.map((item, index) => (
          <button
            key={index}
            className={index === selectedIndex ? 'is-selected' : '' }
            style={{height:"50px"}}
            onClick={() => selectItem(index)}
          >
            <div className=' h-full py-1 flex gap-2 items-center'>
              <div className=' border  border-divider rounded-lg h-[3rem] w-[3rem] flex items-center justify-center'>
              <svg
                      xmlns={item.icon.xmlns}
                      fill={item.icon.fill}
                      viewBox={item.icon.viewBox}
                      strokeWidth={item.icon.strokeWidth}
                      stroke={item.icon.stroke}
                      className="size-6"
                    >
                      <path
                        strokeLinecap={item.icon.path.strokeLinecap}
                        strokeLinejoin={item.icon.path.strokeLinejoin}
                        d={item.icon.path.d}
                      ></path>
                    </svg>
              </div>
            
              <div className=' flex flex-col '>
                <p className=''>{item.title}</p>
                <p className=' text-gray-400'>{item.description}</p>
              </div>
            </div>
            
          </button>
        ))
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  );
});

export default DropdownMenu;
