import pluralize from "pluralize";
import { LoginUserGrabJwt, RegisterUserGrabJwt } from "./loginController";
import { tenant_exist } from "./SupertokenController";

const REACT_APP_ADMIN_EMAIL = "crcladmin@crclverse.com";
const REACT_APP_ADMIN_PASSWORD = "Crcladmin@2023";
// here we fetch the collection form backend and display the schema in the form of a form

export const fetchCollectionJson = async (collectionNAme) => {
  let data = await getalltagdata(collectionNAme);
  //  let data = await response.json();
  //converting the upcoming json to array
  const newJson =
    data &&
    data.data &&
    data.data.schema &&
    Object.entries(data.data.schema.attributes);

  // adding new values as id and value field to our fetched array

  newJson &&
    newJson.map((data, i) => {
      newJson[i].push({ id: i });
      newJson[i].push({ value: "" });
      return data;
    });

  return newJson;
};

export const fetchAllCollections = async () => {
  let jwt;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
    jwt = "";

    const response = await fetch(
      `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/content-type-builder/content-types/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      }
    );

    if (response.ok) {
      let data = await response.json();
      return data;
    }
  }
};

// Testing Signup
export const getNavbar = async (collectionName) => {
  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/?populate=*`,
    {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //   'Content-Type': 'application/json',
        // "x-jwt-token": process.env.REACT_APP_X_JWT_TOKEN,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body: JSON.stringify(data) // body data type must match "Content-Type" header
    }
  );

  let data = await response.json();
  return data;
};

export const getAlldata = async (collectionName) => {
  let jwt;
  const userData = JSON.parse(localStorage.getItem("userData"));

  if (userData && userData.jwt) {
    jwt = userData.jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/?populate=*&pagination[limit]=10000000000000000000000000`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let data = await response.json();
  return data;
};
export const getDataWithFilterUrl = async (collectionName, filteredValue) => {
  let jwt;
  const userData = JSON.parse(localStorage.getItem("userData"));

  if (userData && userData.jwt) {
    jwt = userData.jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}?${filteredValue}&populate=*&pagination[limit]=10000000000000000000000000`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let data = await response.json();
  return data;
};
export const getAlldataInclude = async (collectionName) => {
  let jwt;
  const userData = JSON.parse(localStorage.getItem("userData"));

  if (userData && userData.jwt) {
    jwt = userData.jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/?publicationState=preview&populate=*`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let data = await response.json();
  return data;
};

export const getOnedata = async (collectionName, id) => {
  let jwt;
  const userData = JSON.parse(localStorage.getItem("userData"));

  if (userData && userData.jwt) {
    jwt = userData.jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/${id}/?populate=*`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let data = await response.json();
  return data;
};

export const createPayload = async (elements) => {
  const payload = { data: {} };

  (await elements) &&
    elements.forEach((data) => {
      if (data[1].type === "media" && data[3].value === "") {
        let leftkey = data[0];
        let rightkey = null;
        payload.data[leftkey] = rightkey;
      } else if (data[1].type === "relation" && data[3].value === "") {
        let leftkey = data[0];
        let rightkey = null;
        payload.data[leftkey] = rightkey;
      } else {
        let leftkey = data[0];
        let rightkey = data[3].value;
        payload.data[leftkey] = rightkey;
      }
    });

  (await elements) &&
    elements.forEach((data) => {
      if (data[0] === "users_permissions_user") {
        payload.data["users_permissions_user"] = {
          connect: [
            {
              id:
                JSON.parse(localStorage.getItem("userData")) != null
                  ? JSON.parse(localStorage.getItem("userData")).user.id
                  : "",
              position: { end: true },
            },
          ],
          disconnect: [],
        };

        return payload;
      }
    });

  (await elements) &&
    elements.forEach((data) => {
      if (data[0] === "organization") {
        payload.data["organization"] = {
          connect: [
            {
              id: data[3].value,
              position: { end: true },
            },
          ],
          disconnect: [],
        };

        return payload;
      }
    });

  return payload;
};

export const PublishData = async (payload, collectionName) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(payload),
    }
  );

  let data = await response.json();
  return data;
};

export const UpdateSingleTypeData = async (payload, collectionName) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(payload.data),
    }
  );

  let data = await response.json();
  return data;
};

// let newarray = [];

export const handlechange = async (e, i, elements) => {
  // pushing the new value to each field whenever user types an input

  await elements.forEach(async (field) => {
    if (field[2].id === i) {
      if (field[1].type === "media") {
        let formData = new FormData();
        formData.append("files", e.target.files[0]);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload`,
            {
              method: "POST",
              body: formData,
            }
          );
          const data = await response.json();

          field[3].value = data[0].id;
          field[3].url = data[0].url;
        } catch (error) {}
      } else if (
        field[1].type === "relation" &&
        field[1].relation === "manyToOne"
      ) {
        field[3].value = e.selectedItem.id;
      } else if (
        field[1].type === "relation" &&
        field[0] === "users_permissions_user"
      ) {
        field[3].value =
          JSON.parse(localStorage.getItem("userData")) != null
            ? JSON.parse(localStorage.getItem("userData")).user.username
            : "";
      } else if (field[1].type === "boolean") {
        if (e === "") {
          field[3].value = false;
        } else {
          field[3].value = e;
        }
      } else if (field[1].type === "enumeration") {
        field[3].value = e;
      } else if (
        field[1].type === "integer" ||
        field[1].type === "biginteger" ||
        field[1].type === "decimal" ||
        field[1].type === "float"
      ) {
        field[3].value = e.target.value;
      } else if (field[1].type === "relation") {
        field[3].value = e.selectedItem.id;
      } else if (field[1].type === "date" || field[1].type === "datetime") {
        const originalDate = new Date(e);
        const formattedDate = `${originalDate.getFullYear()}-${(
          originalDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${originalDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        field[3].value = formattedDate;
      } else {
        field[3].value = e.target.value;
      }
    }
  });
  return elements;
};

export const getalltagdata = async (TagName) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/content-type-builder/content-types/api::${TagName}.${TagName}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let data = await response.json();
  return data;
};

// filterss
export const getfilterData = async (collectionName, fieldkey, fieldvalue) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}?filters[${fieldkey}][$eq]=${fieldvalue}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let data = await response.json();
  return data;
};

export const getfilterDatalvl2 = async (
  collectionName,
  firstfieldkey,
  secondfieldkey,
  fieldvalue
) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}?filters[${firstfieldkey}][${secondfieldkey}][$eq]=${fieldvalue}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let data = await response.json();
  return data;
};

export const doPluralize = (text) => {
  // let temp = text;
  if (text === pluralize(text)) {
    return `${text}`;
  }
  return pluralize(text);
};

export const getRecentActivityData = async (api) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }
  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${api}?populate=*`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let data = await response.json();
  return data;
};

export const shortenString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + "...";
  }
  return str;
};

export const DeleteOnedata = async (collectionName, id) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/${id}/?populate=*`,
    {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let data = await response.json();
  return data;
};

export const UserAdminTesting = async (id) => {
  const url = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users/${id}/?populate=*`;
  let jwt;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
    jwt = "";
  }

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = response.json();
  let Mydata =
    data &&
    data.then((useData) => {
      if (useData && useData.role && useData.role.name === "Admin") {
        return useData;
      } else {
        return false;
      }
    });

  return Mydata;
};

export const DeleteOnedataInNavbar = async (id) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("adminData"))) {
    jwt = JSON.parse(localStorage.getItem("adminData")).jwt;
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/content-manager/collection-types/api::navbar.navbar/${id}`,
    {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let data = await response.json();
  return data;
};

// update role................

export const updateRoleafterpayment = async (
  collectionName,
  userId,
  id,
  username = null,
  email = null,
  password = null
) => {
  let jwt;
  let payload;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  if (username != null) {
    payload = {
      username: username,
    };
  } else if (email != null) {
    payload = {
      email: email,
    };
  } else if (password != null) {
    payload = {
      password: password,
    };
  } else if (id != null) {
    payload = {
      role: {
        disconnect: [],
        connect: [
          {
            id: id,
            position: {
              end: true,
            },
          },
        ],
      },
    };
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/${userId}/?populate=*`,
    {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(payload),
    }
  );

  let data = await response.json();
  return data;
};

// getroles
export const getRoles = async (api) => {
  let jwt;

  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const JWTResponse = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/jwt`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let jwtdata = await JWTResponse.json();
  const jwtToken = jwtdata.data.attributes.JWT;

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/users-permissions/roles/${api}?populate=*`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  const data = await response.json();

  if (response.status === 200) {
    return data;
  }

  if (response.status === 401) {
    const mypayload = {
      email: REACT_APP_ADMIN_EMAIL,
      password: REACT_APP_ADMIN_PASSWORD,
    };

    const AdminJWTResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/admin/login`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(mypayload),
      }
    );

    let AdminJWTdata = await AdminJWTResponse.json();
    let AdminJWTToken = AdminJWTdata.data.token;

    let newcrclinfopayload = {
      data: {
        JWT: AdminJWTToken,
      },
    };

    await fetch(
      `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/jwt/?populate=*`,
      {
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(newcrclinfopayload),
      }
    );

    return getRoles(api);
  }
};

// Update data function
export const UpdateAlldata = async (collectionName) => {
  let jwt;

  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/?populate=*`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  let data = await response.json();
  return data;
};
export const updateData = async (collectionName, newData) => {
  let jwt;

  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const payload = {
    data: newData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/?populate=*`,
    {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(payload),
    }
  );

  let updatedData = await response.json();
  return updatedData;
};

export const createData = async (collectionName, newData) => {
  let jwt;

  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const payload = {
    data: newData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/?populate=*`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  let data = await response.json();
  return data;
};

export const CreateCollection = async (newdata) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("adminData"))) {
    jwt = JSON.parse(localStorage.getItem("adminData")).data.token;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/content-type-builder/content-types/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(newdata),
    }
  );

  const data = await response.json();
  return data;
};

export const GetCollection = async (collectionName) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("adminData"))) {
    jwt = JSON.parse(localStorage.getItem("adminData")).data.token;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/content-type-builder/content-types/api::${collectionName}.${collectionName}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );
  const data = await response.json();
  return data;
};

export const UpdateCollection = async (collectionName, newData) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("adminData"))) {
    jwt = JSON.parse(localStorage.getItem("adminData")).data.token;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/content-type-builder/content-types/api::${collectionName}.${collectionName}`,
    {
      method: "PUT",
      mode: "cors",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    }
  );
  const data = await response.json();
  return data;
};

export const CreateSinglrTypedata = async (collectionName, newdata) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("adminData"))) {
    jwt = JSON.parse(localStorage.getItem("adminData")).data.token;
  } else {
    jwt = "";
  }
  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/content-manager/single-types/api::${collectionName}.${collectionName}`,
    {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newdata),
    }
  );
  const data = await response.json(newdata);
  return data;
};

export const UpdateOneData = async (collectionName, id, newData) => {
  let jwt;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).data.token;
  } else {
    jwt = "";
  }

  const payload = {
    data: newData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/${id}`,
    {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json", // Specify the content type as JSON
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(payload), // Convert newData to JSON string
    }
  );
  let updatedData = await response.json();
  return updatedData;
};

export const UpdateOneUserData = async (collectionName, id, newData) => {
  let jwt;

  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/${id}`,
    {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json", // Specify the content type as JSON
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(newData), // Convert newData to JSON string
    }
  );
  let updatedData = await response.json();
  return updatedData;
};

export const CollectionCompare = async () => {
  let jwt;
  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).data.token;
  } else {
    jwt = "";
  }
};

/*
Inviting a new user to the CRCL
payload to pass -> email

Steps to creating a new user:

1. Does the User exist API needs to be done here.

2. Creating the new user (done)

3. The default payload must be:
  a. email (sent through the payload) (done)
  b. username (equal to the email) (done)
  c. password: try something complex. Current Default password is: N!0r@2023 (done)
  d. Making the connection link to this user that created the invite. 
4. Novu [keep it independent]
  a. Novu Email Template create
  b. Novu API create for invited user
  c. Make the new link inside this itself and call novu 9000 endpoint API to send the email.

*/

export const FilterUser = async (email) => {
  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users/?populate=*`,
    {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit

      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body: JSON.stringify(data) // body data type must match "Content-Type" header
    }
  );

  let data = await response.json();
  const filteredData = data && data.find((item) => item.email === email);
  if (!filteredData) {
    return false;
  }
  return filteredData;
};

// Get all media files

export const GetAllMedia = async () => {
  let jwt;

  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload/files/?populate=*&pagination[limit]=1000000000000000000000`,
    {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //   'Content-Type': 'application/json',
        // "x-jwt-token": process.env.REACT_APP_X_JWT_TOKEN,
        // 'Content-Type': 'application/x-www-form-urlencoded',

        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body: JSON.stringify(data) // body data type must match "Content-Type" header
    }
  );

  let data = await response.json();
  return data;
};

// export const NavigateSign = async (email) => {
//     // Check if user exists.
//     let emailExists = false;
//     const response = await fetch(
//         `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users/?populate=*&filters[email][$eq]=${email}`,
//         {
//             method: "GET",
//             mode: "cors",
//             cache: "no-cache",
//             redirect: "follow",
//             referrerPolicy: "no-referrer",
//         }
//     )

//     const isEmailExist = await response.json();

//     if (isEmailExist.length !== 0) {
//         emailExists = true;
//     }

//     let navigate_route;
//     const password = "JhNOK01mFsaoZh"

//     if (!emailExists) {
//         const tenantExists = await tenant_exist();  // Assuming tenant_exist is a function

//         // Member Onboarding
//         if (tenantExists) {
//             navigate_route = "/";
//             const Signin = await RegisterUserGrabJWT(email, password, 1);
//             const login = await LoginUserGrabJwt(email, password);

//         }

//         // Admin Onboarding
//         else {
//             const Signin = await RegisterUserGrabJWT(email, password, 5);
//             const login = await LoginUserGrabJwt(email, password);
//             navigate_route = "/create-organisation";
//         }
//     }

//     else {
//         const tenantExists = await tenant_exist();
//         if (tenantExists) {
//             const login = await LoginUserGrabJwt(email, password);
//             console.log("login**++", login)
//             navigate_route = "/";

//         } else {
//             const login = await LoginUserGrabJwt(email, password);
//             console.log("login**++", login)
//             navigate_route = "/create-organisation";
//         }
//     }

//     return navigate_route;
// }

export const NavigateSign = async (email, password2) => {
  // Check if user exists.
  let emailExists = false;
  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users/?populate=*&filters[email][$eq]=${email}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  const isEmailExist = await response.json();

  if (isEmailExist.length !== 0) {
    emailExists = true;
  }

  let navigate_route;
  const password = "JhNOK01mFsaoZh";

  if (!emailExists) {
    const tenantExists = await tenant_exist(); // Assuming tenant_exist is a function

    // Member Onboarding
    if (tenantExists) {
      navigate_route = "/";
      const Signin = await RegisterUserGrabJwt(email, password2, 1);
      const login = await LoginUserGrabJwt(email, password);
      localStorage.setItem("userData", JSON.stringify(login));
    }

    // Admin Onboarding
    else {
      const Signin = await RegisterUserGrabJwt(email, password2, 5);
      const login = await LoginUserGrabJwt(email, password);
      localStorage.setItem("userData", JSON.stringify(login));
      navigate_route = "/create-organisation";
    }
  } else {
    const tenantExists = await tenant_exist();
    if (tenantExists) {
      const login = await LoginUserGrabJwt(email, password);
      localStorage.setItem("userData", JSON.stringify(login));
      navigate_route = "/";
    } else {
      const login = await LoginUserGrabJwt(email, password);
      localStorage.setItem("userData", JSON.stringify(login));
      navigate_route = "/create-organisation";
    }
  }

  return navigate_route;
};

// STRING LIMIT FUNCTION
export const StringLimit = (word, maxLength) => {
  if (word.length > maxLength) {
    return word.substring(0, maxLength - 3) + "...";
  }
  return word;
};

export const GetFilteredData = async (fieldKey, fieldValue) => {
  let jwt;

  if (JSON.parse(localStorage.getItem("userData"))) {
    jwt = JSON.parse(localStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  let URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${fieldKey}?filters[Name][$eq]=${fieldValue}`;
  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = response.json();
  return data;
};

// Function for Get the Items for a realtion (Like for Dropdown) :-
export const getItemForRelation = async (metricID) => {
  let values = [];
  const filterURL = "filters[Metric_Id][$eqi]=" + metricID;

  try {
    const data = await getDataWithFilterUrl("responses", filterURL);
    const responseData = data.data;

    responseData.forEach((item) => {
      values.push({ id: item.id, name: item.attributes.JsonSchema.Name });
    });
    return values;
  } catch (err) {
    throw err; // re-throw the error to propagate it to the caller
  }
};

export async function updateFormSchemaWithRealtion(
  formSchema,
  index,
  setFormSchema
) {
  if (formSchema[index][1].values === undefined) {
    let newFormSchema = [...formSchema];
    newFormSchema[index][1].values = await getItemForRelation(
      formSchema[index][1].relatedTo
    );
    setFormSchema(newFormSchema);
  }
}

export const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

export const separateDasFromUrl = (value) => {
  return value.replaceAll("-", " ");
};

// ROLES APIs
export const getAllRoles = async () => {
  let URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users-permissions/roles`;

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = await response.json().then((role) => {
    return role.roles;
  });

  const FilterData = data && data.find((role) => role.name === "Public");
  return FilterData;
};

// Keys Generate and Stored in DB

export const submitAPIKey = async (api_key, key_name, user_email) => {
  const PayloadData = {
    api_key: api_key,
    users_permissions_user: user_email,
    Name: key_name,
  };

  let result = await PublishData({ data: PayloadData }, "key");
  return result;
};

export const sendCreateAccountOTP = async (payload) => {
  console.log(payload);

  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send-create-account-otp`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  let data = await response.json();

  return data;
};
