import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Signin from "./components/Authentication/Signin";
import NotFoundPage from "./components/Error/404";
import Tickets from "./pages/Tickets/tickets";
import ProductManagement from "./pages/Components/Component";
import Flowchart from "./pages/ProductTrails/FlowChart/FlowChart";
import PasswordLessSignin from "./components/Authentication/PasswordLessSignin";
import VerifySignIn from "./components/Authentication/OtpVerification";
import Sidebar from "./components/Sidebar/sidebar";
import Inbox from "./pages/Inbox/Inbox";
import General from "./pages/General/General";
import Account from "./pages/Settings/Account";
import User from "./pages/UserManagement/User";
import Tag from "./pages/Tags/Tag";
import Sidenav from "./components/Sidebar/Sidenav";
import Issues from "./pages/Issues/issues";
import Errorpage from "./components/Error/ErrorCode";
import Onboarding from "./components/Onboarding/Onboarding";
import SprintBoard from "./pages/SprintBoard/Sprint/SprintBoard";
import Roadmap from "./pages/Roadmap/Roadmap";
import Queues from "./pages/Queues/Queues";
import Analytics from "./pages/Analytics/Analytics";
import TeamActivity from "./pages/TeamActivity/TeamActivity";
import Groups from "./pages/Groups/Groups";
import GroupsNav from "./pages/Groups/GroupsNav";
import Invitations from "./pages/Invitations/Invitations";
import Tasks from "./pages/Tasks/Tasks";
import SprintDetails from "./pages/Sprints/SprintDetails";
import Update from "./pages/Updates/Update";
import HelixLoader from "./components/Loader/HelixLoader";
import Integrations from "./pages/Integrations/Integrations";
import IssuePage from "./pages/Issues/IssuePage";
import Widget from "./pages/Widget/Widget";
import OGP from "./components/OGProtocol/OGP";
import { HelmetProvider } from "react-helmet-async";
import Blueprints from "./pages/Blueprints/Blueprints";
import BlueprintTable from "./pages/Blueprints/BlueprintTable";
import BlueprintList from "./pages/Blueprints/BlueprintList";
import BlueprintDoc from "./pages/Blueprints/BlueprintDoc";
import Customers from "./pages/Customers/Customers";
import SignUp from "./components/Authentication/Signup";
import NewSignUp from "./components/Authentication/NewSignUp";
import OTP from "./components/Authentication/OtpVerification";
import LearnMore from "./pages/Blueprints/LearnMore";

const App = () => {
  const [serverIssue, setServerIssue] = useState(false);
  const [loader, setLoader] = useState(true);

  const fetchWithTimeout = (url, options, timeout = 5000) => {
    return Promise.race([
      fetch(url, options),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Timeout")), timeout)
      ),
    ]);
  };

  useEffect(() => {
    document.title = "Scrumtix";

    const checkBackendConnection = async () => {
      try {
        const response = await fetchWithTimeout(
          `${process.env.REACT_APP_STRAPI_IP_ADDRESS}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        setServerIssue(true);
      } finally {
        setLoader(false);
      }
    };

    checkBackendConnection();
  }, []);

  if (serverIssue) {
    return <Errorpage />;
  }
  return (
    <div>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Navigate to="/sign-in" />} />
            <Route exact path="/sign-in" element={<Signin />} />
            <Route exact path="/sign-up" element={<NewSignUp />} />
            <Route exact path="/verify-otp" element={<OTP />} />
            <Route exact path="/sign-in/redirected-to/*" element={<Signin />} />
            <Route exact path="/onboarding" element={<Onboarding />} />
            <Route
              exact
              path="/passwordless-sign"
              element={<PasswordLessSignin />}
            />
            <Route exact path="/:org/settings" element={<Sidenav />}>
              <Route exact path="" element={<General />} />
              <Route
                exact
                path="/:org/settings/account"
                element={<Account />}
              />
              <Route exact path="/:org/settings/users" element={<User />} />
              <Route exact path="/:org/settings/tags" element={<Tag />} />
              <Route exact path="/:org/settings/widget" element={<Widget />} />
              <Route
                exact
                path="/:org/settings/integrations"
                element={<Integrations />}
              />
              <Route
                exact
                path="/:org/settings/general"
                element={<General />}
              />
              <Route
                exact
                path="/:org/settings/groups/"
                element={<GroupsNav />}
              >
                <Route
                  exact
                  path="/:org/settings/groups/:id"
                  element={<Groups />}
                />
              </Route>
              <Route
                exact
                path="/:org/settings/invitation"
                element={<Invitations />}
              />
            </Route>

            <Route exact path="/:org" element={<Sidebar />}>
              <Route exact path="" element={<Inbox />} />
              <Route
                exact
                path="/:org/components"
                element={<ProductManagement />}
              />
              <Route
                exact
                path="/:org/team-activity"
                element={<TeamActivity />}
              />
              <Route exact path="/:org/analytics" element={<Analytics />} />
              <Route exact path="/:org/tickets" element={<Tickets />} />
              <Route exact path="/:org/queues" element={<Queues />} />
              <Route
                exact
                path="/:org/sprint/:id"
                element={<SprintDetails />}
              />
              <Route
                exact
                path="/:org/sprint-boards"
                element={<SprintBoard />}
              />
              <Route exact path="/:org/blueprints" element={<Blueprints />} >
                <Route exact path="" element={<BlueprintTable />} />
                <Route exact path="/:org/blueprints/create" element={<BlueprintList />} />
                <Route exact path="/:org/blueprints/docs/:docId" element={<BlueprintDoc />} />
                <Route exact path="/:org/blueprints/view/:_id" element={<LearnMore />} />
              </Route>
              <Route exact path="/:org/roadmap" element={<Roadmap />} />
              <Route exact path="/:org/customers" element={<Customers />} />
              <Route exact path="/:org/issues" element={<Issues />} />
              <Route
                exact
                path="/:org/issues/:issueId"
                element={<IssuePage />}
              />
              <Route exact path="/:org/updates" element={<Update />} />
              <Route
                exact
                path="/:org/product-trails"
                element={<Flowchart />}
              />
              
              <Route exact path="/:org/tasks" element={<Tasks />} />
            </Route>
            <Route exact path="/verify-signin" element={<VerifySignIn />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
};

export default App;
