import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DeleteSingleAttribute,
  UpdateData,
  getFilteredBySingleRelation,
} from "../../controllers/strapiController";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { EditorProvider } from "@tiptap/react";
import UserModal from "../UserModal/UserPicker";
import PartsPicker from "../PartsPicker/PartsPicker";
import { getMultipleFilters, patchData } from "../../controllers/vectorDB";
import Icon from "../../pages/SprintBoard/Icon/Icon";
import "../../index.css";
import {
  MenuBar,
  props,
  extensions,
} from "../../components/markdown/componenteditor";
import { basicIcons } from "../../utils/default";

const Sprint = ({ orgId, org, Update, Delete }) => {
  const navigate = useNavigate();
  const [sprints, setSprints] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenIcon, setIsOpenIcon] = useState(false);
  const { id } = useParams();
  const [prevParts, setPrevParts] = useState([]);
  const [prevOwner, setPrevOwner] = useState([]);
  const [sprintId, setSprintId] = useState(null);
  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [start, setStart] = useState(false);
  const [description, setDescription] = useState("");
  const [owners, setOwners] = useState(new Set([]));
  const [parts, setParts] = useState(new Set([]));
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [partData, setPartData] = useState([]);
  const [parsedParts, setParsedParts] = useState({});
  const [submit, setSubmit] = useState(true);
  const [userNames,setUserNames] = useState([]);

  const handleIconSelect = (icon) => {
    setSelectedIcon(icon);
    setIsOpenIcon(false);
  };

  const getIconByName = (name) => {
    return basicIcons.find((icon) => icon.name === name) || basicIcons[0];
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const resp = await getFilteredBySingleRelation(
          "users",
          "organizations",
          orgId
        );
        const resp2 = await getFilteredBySingleRelation(
          "parts",
          "organization",
          orgId
        );
        setUsers(resp);
        setPartData(resp2.data);
      } catch (error) {
        console.error("Error fetching users and parts:", error);
      }
    };
    if (orgId) {
      fetchUsers();
    }
  }, [orgId]);

  useEffect(() => {
    
    const userN = [];
    users.forEach((user) => {
      userN.push({
        id : user.id,
        name : user.FirstName ? `${user.FirstName} ${user.LastName ? user.LastName : ""}` : user.username,
        url : user.ProfilePicture?.formats?.small?.url || "",
      })
      
    });
    setUserNames(userN);
  }, [users]);
  useEffect(() => {
    if (partData.length > 0) {
      const convertedParts = {};
      partData.forEach((item) => {
        convertedParts[item?.id] = {
          Name: item?.attributes?.Name,
          Type: item?.attributes?.Type,
          ParentPath: item?.attributes?.ParentPath,
          Owner: item?.attributes?.users_permissions_user?.data?.id,
        };
      });
      setParsedParts(convertedParts);
    }
  }, [partData]);

  useEffect(() => {
    const fetchSprints = async () => {
      try {
        const resp = await getFilteredBySingleRelation(
          "sprints",
          "organization",
          orgId
        );
        setSprints(resp.data);
      } catch (error) {
        console.error("Error fetching sprints:", error);
      }
    };
    if (orgId) {
      fetchSprints();
    }
  }, [orgId, submit]);

  const handleOpenModal = async (item) => {
    let partIds = item.attributes.parts.data.map((part) => String(part.id));
    let ownerIds = item.attributes.owners.data.map((part) => String(part.id));
    setName(item.attributes.Title);
    setDescription(item.attributes.Description);
    setStart(false);
    setOwners(new Set(ownerIds));
    setParts(new Set(partIds));
    setPrevOwner(ownerIds);
    setPrevParts(partIds);
    setSprintId(item.id);
    setSelectedIcon(getIconByName(item.attributes.Icon));
    console.log(item.attributes.icon, "dddddd");
    setIsOpen(true);
  };

  const handleUpdate = async () => {
    const formData = {
      Title: name,
      Description: description,
      Icon: selectedIcon.name,
      owners: {
        disconnect: prevOwner
          .filter((item) => !Array.from(owners).includes(item))
          .map(Number),
        connect: Array.from(owners).map((item) => ({
          id: Number(item),
          position: { end: true },
        })),
      },
      parts: {
        disconnect: prevParts
          .filter((item) => !Array.from(parts).includes(item))
          .map(Number),
        connect: Array.from(parts).map((item) => ({
          id: Number(item),
          position: { end: true },
        })),
      },
    };
    try {
      await UpdateData("sprints", sprintId, formData);
      setSubmit(!submit);
      setIsOpen(false);
    } catch (error) {
      console.error("Error updating sprint:", error);
    }
  };

  const removeSprint = async (issIds) => {
    const formData = {
      sprint: null,
      currentSprint: null,
      stageSprint: null,
    };
    try {
      const promises = issIds.map((item) => patchData(formData, item, org));
      await Promise.all(promises);
    } catch (error) {
      console.error("Error removing sprint:", error);
    }
  };
  const handleDelete = async () => {
    try {
      const issues = await getMultipleFilters(
        [
          { key: "type", value: "issue" },
          { key: "sprint", value: String(sprintId) },
        ],
        org
      );
      const arr = issues.map((iss) => iss.payload.id);
      await removeSprint(arr);
      await DeleteSingleAttribute("sprints", sprintId);
      const Newsprints = sprints.filter((item) => Number(item.id) !== (sprintId)) 
      setSprints(Newsprints);
      setIsOpen(false);
      navigate(`/${org}`);
    } catch (error) {
      console.error("Error deleting sprint:", error);
    }
  };

  const hasSprints = sprints.length > 0;
  return (
    <div>
      {hasSprints && (
        <>
          <div className="flex py-2 items-center gap-2 rounded-md px-2 text-sm">
            <lord-icon
              src="https://cdn.lordicon.com/rpgflpkp.json"
              style={{ height: "18px", width: "18px" }}
            ></lord-icon>
            Sprints
          </div>
          {sprints.map((sprint) => {
            const iconName = sprint.attributes.Icon;
            const icon = getIconByName(iconName);
            return (
              <div
                key={sprint.id}
                className={`flex py-2 w-full ${
                  id === sprint.attributes.path
                    ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                    : "hover:bg-[#F3F4F6]"
                } items-center gap-2 rounded-md text-xs text-black hover:text-foreground`}
              >
                <Link
                  className="w-full group"
                  to={`/${org}/sprint/${sprint.attributes.path}`}
                >
                  <div className="flex px-2 w-full items-center justify-between">
                    <div className="flex gap-4">
                      <svg
                        xmlns={icon.xmlns}
                        fill={icon.fill}
                        viewBox={icon.viewBox}
                        strokeWidth={icon.strokeWidth}
                        stroke={icon.stroke}
                        className={icon.className}
                      >
                        <path d={icon.path.d} />
                      </svg>
                      <p>{sprint.attributes.Title}</p>
                    </div>
                    {Update && (
                      <svg
                        onClick={() => handleOpenModal(sprint)}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                    )}
                  </div>
                </Link>
              </div>
            );
          })}
        </>
      )}
      <Modal size="2xl" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalBody className="p-8">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <p className="text-xs">Name</p>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  size="sm"
                />
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-xs">Description</p>
                <div className="h-auto mb-4 border rounded-lg">
                  <EditorProvider
                    placeholder="Enter your description"
                    editorProps={props}
                    extensions={extensions(userNames)}
                    slotBefore={
                      <MenuBar
                        descriptionContent={description}
                        setDescriptionContent={setDescription}
                        initial={start}
                        setInitial={setStart}
                      />
                    }
                    content=""
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-xs">Owners</p>
                <UserModal
                  member={owners}
                  users={users}
                  setMember={setOwners}
                />
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-xs">Parts</p>
                <PartsPicker
                  selected={parts}
                  setSelected={setParts}
                  parts={partData}
                  parsedParts={parsedParts}
                />
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-xs">Icon</p>
                <Popover>
                  <PopoverTrigger>
                    <div
                      auto
                      light
                      className="bg-white max-w-max cursor-pointer"
                      onClick={() => setIsOpenIcon(!isOpenIcon)}
                    > 
                      {selectedIcon ? (
                        <svg
                          xmlns={selectedIcon.xmlns}
                          fill={selectedIcon.fill}
                          viewBox={selectedIcon.viewBox}
                          strokeWidth={selectedIcon.strokeWidth}
                          stroke={selectedIcon.stroke}
                          className={selectedIcon.className}
                          style={{ width: "24px", height: "24px" }}
                        >
                          <path d={selectedIcon.path.d} />
                        </svg>
                      ) : (
                        "Select Icon"
                      )}
                    </div>
                  </PopoverTrigger>
                  <PopoverContent>
                    <div
                      aria-label="Icon selection"
                      onClick={() => setIsOpenIcon(false)}
                    >
                      <Icon
                        onSelectIcon={handleIconSelect}
                        basicIcons={basicIcons}
                        setIsOpen={setIsOpenIcon}
                        isOpen={isOpenIcon}
                      />
                    </div>
                  </PopoverContent>
                </Popover>
              </div>

              <div className="pt-8 pb-4 flex items-center justify-between">
                {Delete && (
                  <Dropdown>
                    <DropdownTrigger>
                      <Button size="sm" color="danger">
                        Delete
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu>
                      <DropdownItem onClick={handleDelete} color="danger">
                        Delete permanently
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}
                <Button
                  onClick={handleUpdate}
                  className="bg-[rgb(51,51,245)] text-white"
                  size="sm"
                >
                  Update
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Sprint;
