export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_HEX = 'SET_HEX';
export const SET_ORG='SET_ORG';
export const SET_RES='SET_RES';
export const SET_USERNAMES='SET_USERNAMES';
export const SET_UPDATES='SET_UPDATES' 

export const setUserData = (data) => ({
    type: SET_USER_DATA,
    payload: data,
});

export const setUpdates = (data) => ({
    type: SET_UPDATES,
    payload: data,
});
 
export const orgInfo=(data)=>({
    type: SET_ORG,
    payload: data,
})
 
export const setRes=(data)=>({
    type: SET_RES,
    payload: data,
})
 
export const setUsernames=(data)=>({
    type: SET_USERNAMES,
    payload: data,
})





// Select user data
export const selectUserData = (state) => state.data;

// Select organization data
export const selectOrgData = (state) => state.org;

// Select res data
export const selectResData = (state) => state.res;

// Select ActiveTab
export const selectUserNames = (state) => state.usernames;

// Select ActiveTab
export const selectUpdates = (state) => state.updates;
