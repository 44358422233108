import {
  Accordion,
  AccordionItem,
  Button,
  Input,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import SearchIcon from "../../Images/Icons/Search";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getFilteredBySingleRelation } from "../../controllers/strapiController";
import GroupModal from "./GroupModal";
import { PermissionGroupSingleRole } from "../../utils/permission";
import { useSelector } from "react-redux";
import { selectOrgData, selectUserData } from "../../redux/actions";

const GroupsNav = () => {
  const { org } = useParams();
  const { id } = useParams();
  
  
  
  const orgDetails = useSelector(selectOrgData);
  const users = useSelector(selectUserData)
  
  const navigate = useNavigate();
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [groups, setGroups] = useState([]);
  const [isOpen,setIsOpen] = useState(false);
  const [search,setSearch] = useState("");
  const [filteredGroups,setFilteredGroups] = useState([]);
  const [existingNames,setExistingNames] = useState([]);
  const [selectedKeys,setSelectedKeys] = useState(new Set([]));
  const [userPerm,setUserPerm] = useState({
    name: "Role-14",
    class: "User Management",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false
      }
    ]
  })
 
  useEffect(()=> {
    const fetchPerm = async() => {
      try{
        const resp = await PermissionGroupSingleRole(userDataId,orgDetails?.id,"Role-14","org")
        
        if(resp!==false){
          setUserPerm(resp);
        }
        
      }
      catch(error){
        
      }
    }
    if(orgDetails?.id){
      fetchPerm();
    }
  },[orgDetails,userDataId])
 
  useEffect(() => {
    const getAllGroups = async() =>{
      try{
        const resp = await getFilteredBySingleRelation("groups","organization",orgDetails.id)
        setGroups(resp.data);
        setFilteredGroups(resp.data);
        const existing = resp.data.map((item) => {return item.attributes.path});
        setExistingNames(existing);
      }
      catch(error){
      
      }
    }
    if(orgDetails?.id){
        getAllGroups();
    }
  },[orgDetails]);
  useEffect(() => {
    if(groups.length>0){
        groups[0].attributes.Type === "Admin" ? setSelectedKeys(new Set(["3"])) : groups[0].attributes.Type === "Internal" ? setSelectedKeys(new Set(["2"])) : setSelectedKeys(new Set(["1"]));
        navigate(`/${org}/settings/groups/${groups[0]?.attributes?.path}`)
    }
  },[groups]);
  useEffect(() => {
    const filter = groups.filter((item) => item?.attributes?.Name?.toLowerCase().includes(search.toLowerCase()));
    setFilteredGroups(filter);
  },[search])
  return (
    <div className="flex">
      <GroupModal names={existingNames} isOpen={isOpen} onClose={()=>setIsOpen(false)} users={users} userId={userDataId} orgId={orgDetails?.id}/>
      <div className="border-r border-r-divider h-screen w-[250px] p-4">
        {/* Header */}
        <div className="flex items-center justify-between text-sm font-light">
          Groups
          {userPerm.privileges[0].create && <Button
            onClick={()=>setIsOpen(true)}
            isIconOnly
            size="sm"
            className="text-white bg-[rgb(51,51,245)]"
          >
            <div className="text-sm">+</div>
          </Button>}
        </div>
        <div className="mt-8">
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            size="sm"
            startContent={
              <SearchIcon
                className={` text-xs pointer-events-none flex-shrink-0 `}
              />
            }
          />
        </div>
        <div className="mt-8">
          <Accordion selectedKeys={selectedKeys}
      onSelectionChange={setSelectedKeys}>
            <AccordionItem
              key="1"
              subtitle="External user groups"
              aria-label="Accordion"
              style={{ fontSize: "12px" }}
              className="text-xs"
            >
              <div className=" flex flex-col gap-2">
              {filteredGroups.filter((item) => item?.attributes?.Type === "External").length === 0 ?  
              <p className="text-xs font-light text-gray-500">No external groups</p>
              :
                filteredGroups.filter((item) => item?.attributes?.Type === "External").map((group,index) => (
                 
                    
                      <div key={index} onClick={() => navigate(`/${org}/settings/groups/${group.attributes.path}`)} className={`text-xs p-1 px-2 flex items-center cursor-pointer justify-between font-light ${id === group.attributes.path ? "bg-[#F4F4F5]" : "hover:bg-[#F3F4F6]"} text-gray-500`}>
                        {group?.attributes?.Name}
                        {/* Total number of users inside the user group */}
                        <div className="text-xs text-gray-500">{group?.attributes?.members?.data?.length}</div>
                      </div>
                    
                  
                ))}
              </div>
            </AccordionItem>
          </Accordion>
          <Accordion selectedKeys={selectedKeys}
      onSelectionChange={setSelectedKeys}>
            <AccordionItem
              key="2"
              subtitle="Internal user groups"
              aria-label="Accordion"
              style={{ fontSize: "12px" }}
              className="text-xs"
            >
              {/* Sort in alphabetical order */}
              <div className=" flex flex-col gap-2">
              {filteredGroups.filter((item) => item?.attributes?.Type === "Internal").length === 0 ?  
              <p className="text-xs text-gray-500">No Internal group</p>
              :
              filteredGroups.filter((item) => item?.attributes?.Type === "Internal").map((group,index) => (
                  
                      <div key={index} onClick={() => navigate(`/${org}/settings/groups/${group.attributes.path}`)} className={`text-xs p-1 px-2 flex items-center cursor-pointer justify-between font-light ${id === group.attributes.path ? "bg-[#F4F4F5]" : "hover:bg-[#F3F4F6]"} text-gray-500`}>
                        {group?.attributes?.Name}
                        {/* Total number of users inside the user group */}
                        <div className="text-xs text-gray-500">{group?.attributes?.members?.data?.length}</div>
                      </div>
                    
                ))}
              </div>
            </AccordionItem>
          </Accordion>
          <Accordion selectedKeys={selectedKeys}
      onSelectionChange={setSelectedKeys}>
            <AccordionItem
              key="3"
              subtitle="Admin user groups"
              aria-label="Accordion"
              style={{ fontSize: "12px" }}
              className="text-xs"
            >
              {/* Sort in alphabetical order */}
              <div className=" flex flex-col gap-2">
              {filteredGroups.filter((item) => item?.attributes?.Type === "Admin").length === 0 ?  
              <p className="text-xs text-gray-500">No admin groups</p>
              :
              filteredGroups.filter((item) => item?.attributes?.Type === "Admin").map((group,index) => (
                  
                      <div key={index} onClick={() => navigate(`/${org}/settings/groups/${group.attributes.path}`)} className={`text-xs p-1 px-2  flex items-center cursor-pointer justify-between font-light ${id === group.attributes.path ? "bg-[#F4F4F5]" : "hover:bg-[#F3F4F6]"} text-gray-500`}>
                        {group?.attributes?.Name}
                        {/* Total number of users inside the user group */}
                        <div className="text-xs text-gray-500">{group?.attributes?.members?.data?.length}</div>
                      </div>
                    ))}
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <div className={`flex flex-col gap-2 w-full`}>
        <Outlet />
      </div>
    </div>
  );
};

export default GroupsNav;
