import React, { useState, useEffect } from "react";
import { patchData } from "../../controllers/vectorDB";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownTrigger,
  DatePicker,
  Input,
  Button,
  Avatar,
  Divider,
  Chip,
  DropdownSection,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tooltip,
} from "@nextui-org/react";
import { extensions, props, MenuBar } from "../../components/markdown/markdown";
import { EditorProvider } from "@tiptap/react";
import { parseDate } from "@internationalized/date";
import Tags from "../../components/Tag/tags";
import {
  DeleteSingleAttribute,
  getFilteredBySingleRelation
} from "../../controllers/strapiController";
import PartPicker from "../../components/Drawer/PartPicker";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { basicIcons, priorityData } from "../../utils/default";
import ScredriverWrench from "../../Images/Icons/ScredriverWrench";
import ChevronArrow from "../../Images/Icons/ChevronArrow";
import WarningIcon from "../../Images/Icons/WarningIcon";
import LinkIssues from "../../components/Drawer/LinkIssues";
import { issuesTicketLink } from "../../utils/linkissues";
import { sendUpdateTickets } from "../../utils/mentions";
const Issue = ({
  ticket,
  res,
  handleCloseTicket,
  dropdownref,
  modalref,
  pos,
  tenant,
  users,
  divRef,
  inputRef,
  permission,
  userNames,
}) => {
  const [descriptionContent, setDescriptionContent] = useState(
    ticket?.payload?.description
  );
  const [selectedSprint, setSelectedSprint] = useState(
    ticket?.payload?.sprint ? new Set([ticket?.payload?.sprint]) : new Set([])
  );
  const [sprints, setSprints] = useState([]);
  const progress = [
    "Work in progress",
    "Awaiting Product assist",
    "Awaiting Development",
    "In Development",
    "Awaiting Customer Response",
  ];

  const closed = ["Accepted", "Resolved", "Cancelled"];
  const [customTags, setCustomTags] = useState([]);
  const [ownerInfo, setOwnerInfo] = useState();
  const [title, setTitle] = useState(ticket?.payload?.title);
  const [logs, setLogs] = useState(ticket?.payload?.logs ?? []);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [tags, setTags] = useState(ticket?.payload?.tags);
  const [tagsDetail, setTagsDetail] = useState([]);
  const [start, setStart] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedPriority, setSelectedPriority] = useState(
    ticket?.payload?.priority
      ? new Set([ticket?.payload?.priority])
      : new Set([])
  );



  const [selectedReporter, setSelectedReporter] = useState(
    new Set([ticket?.payload?.assigned])
  );
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        window.location.origin +
        location.pathname +
        `/${ticket?.payload?.latest}`
      );
      toast("Link copied", {
        icon:'🔗',
        duration: 2000,
        position: "bottom-right",
      });
    } catch (err) {
      toast("Cannot copy link!", {
        duration: 2000,
        position: "bottom-right",
      });
    }
  };
  const [reporterInfo, setReporterInfo] = useState({
    username: res[ticket?.payload?.assigned]?.UserName,
    id: ticket?.payload?.assigned,
    name: res[ticket?.payload?.assigned]?.FirstName,
    url:
      process.env.REACT_APP_STAGE === "Dev"
        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[ticket?.payload?.assigned]?.ProfilePicture
        }`
        : `${res[ticket?.payload?.assigned]?.ProfilePicture}`,
  });
  const [selectedDate, setSelectedDate] = useState(
    ticket?.payload?.targetclosedate
  );
  const [selectedKeys, setSelectedKeys] = useState(new Set([]));
  const [selectedEstimatedDays, setSelectedEstimatedDays] = useState(
    ticket?.payload?.estimatedays
  );
  const [selectedPart, setSelectedPart] = useState(
    new Set([Number(ticket?.payload?.part)])
  );
  const [selectedIssueIds,setSelectedIssueIds] = useState(ticket?.payload?.linkedTickets ? new Set(ticket?.payload?.linkedTickets) : new Set([]));
  const [imageIds, setImageIds] = useState(ticket?.payload?.imageIds);
  const [partData, setPartData] = useState([]);
  const [parsedSprints, setParsedSprints] = useState({});
  function convertToDateStr(dateObj) {
    const { year, month, day } = dateObj;
    const monthStr = String(month).padStart(2, "0");
    const dayStr = String(day).padStart(2, "0");
    return `${year}-${monthStr}-${dayStr}`;
  }
  const [stage, setStage] = useState(new Set([ticket?.payload?.stage]));
  const [filteredSprints, setFilteredSprints] = useState([]);
  const [search, setSearch] = useState("");
  const [onHover, setOnHover] = useState("");
  const [sprintNumber, setSprintNumber] = useState(
    ticket?.payload?.currentSprint ? Number(ticket?.payload?.currentSprint) : ""
  );
  const Colors = ["secondary", "success", "warning", "danger", "primary"];

  const numColors = Colors.length;
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleSelectSprint = (id, num) => {
    if (
      Array.from(selectedSprint)[0] === id &&
      (Number(sprintNumber) === Number(num) ||
        (sprintNumber === num && num === "Backlog"))
    ) {
      setSelectedSprint(new Set([]));
      setSprintNumber("");
      return;
    }
    setSelectedSprint(new Set([id]));
    setSprintNumber(num);
  };
  const handleMouseEnter = (id) => {
    setOnHover(id);
  };
  const handleMouseLeave = () => {
    setOnHover("");
  };
  const updateReporter = (user) => {
    setReporterInfo({
      username: user.username,
      id: user.id,
      name: user.FirstName,
    });
  };
  const checkLogs = () => {
    function areArraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
    
      // Sort both arrays
      const sortedArr1 = arr1.slice().sort();
      const sortedArr2 = arr2.slice().sort();
    
      // Compare sorted arrays
      return sortedArr1.every((value, index) => value === sortedArr2[index]);
    }
    const logTimestamp = Date.now();
    const changes = [];
    const fieldsToCheck = [
      { field: "title", current: title, previous: ticket?.payload?.title },
      { field: "description", current: descriptionContent, previous: ticket?.payload?.description },
      { field: "stage", current: Array.from(stage)[0], previous: ticket?.payload?.stage },
      { field: "priority", current: Array.from(selectedPriority)[0], previous: ticket?.payload?.priority },
      { field: "tags", current: tags, previous: ticket?.payload?.tags },
      // { field: "linked tickets", current: Array.from(selectedIssueIds) , previous: ticket?.payload?.linkedTickets },
      { field: "assigned", current: Array.from(selectedReporter)[0], previous: ticket?.payload?.assigned },
      { field: "estimated days", current: selectedEstimatedDays, previous: ticket?.payload?.estimatedays },
      { field: "part", current: String(Array.from(selectedPart)[0]), previous: String(ticket?.payload?.part) }
    ];

    fieldsToCheck.forEach(({ field, current, previous }) => {
      if (current !== previous) {
        changes.push({ operation: "updated", field, to: current, by: userDataId, timestamp: logTimestamp });
      }
    });

    if(!areArraysEqual(Array.from(selectedIssueIds), ticket?.payload?.linkedTickets)){
      changes.push({
        operation: "updated",
        field: "linked tickets",
        to: Array.from(selectedIssueIds),
        by: userDataId,
        timestamp: logTimestamp,
      });
    }
    // Check for date change
    if (
      selectedDate?.day !== ticket?.payload?.targetclosedate?.day ||
      selectedDate?.month !== ticket?.payload?.targetclosedate?.month ||
      selectedDate?.year !== ticket?.payload?.targetclosedate?.year
    ) {
      changes.push({
        operation: "updated",
        field: "target close date",
        to: selectedDate,
        by: userDataId,
        timestamp: logTimestamp,
      });
    }

    // Check for sprint changes
    const sprintChanged =
      (Array.from(selectedSprint).length > 0) &&
      ((String(Array.from(selectedSprint)[0]) !== ticket?.payload?.sprint) ||
        (String(sprintNumber) !== ticket?.payload?.currentSprint));
    if (sprintChanged) {
      changes.push({
        operation: "updated",
        field: "sprint",
        to: Array.from(selectedSprint).length === 0 ? null : { sprint: Array.from(selectedSprint)[0], currentSprint: sprintNumber },
        by: userDataId,
        timestamp: logTimestamp,
      });
    }

    return changes;
  };

  const handleSubmit = async () => {

    const arr = imageIds.filter((item) =>
      descriptionContent.includes(item.url)
    );
    const deletearr = imageIds.filter(
      (item) => !descriptionContent.includes(item.url)
    );
    const logArray = checkLogs();
    const finalLogs = [...logs, ...logArray];
    if (logArray.length > 0) {
      setLogs((prev) => [...prev, ...logArray])
    }
    else {
      return;
    }
    const formData = {
      title: title,
      imageIds: arr,
      imagePresent: arr.length === 0 ? false : true,
      description: descriptionContent,
      owner: ownerInfo.id,
      linkedTickets : Array.from(selectedIssueIds),
      stage: Array.from(stage)[0],
      priority: Array.from(selectedPriority)[0],
      tags: tags,
      assigned: Array.from(selectedReporter)[0],
      targetclosedate: selectedDate,
      estimatedays: selectedEstimatedDays,
      part: Array.from(selectedPart)[0],
      logs: finalLogs,
      sprint:
        Array.from(selectedSprint).length === 0
          ? null
          : String(Array.from(selectedSprint)[0]),
      currentSprint: sprintNumber === "" ? null : String(sprintNumber),
    };
    try {
      await issuesTicketLink("issue",Array.from(selectedIssueIds),ticket.payload.id, tenant.name);
      await sendUpdateTickets(ownerInfo.id,Array.from(selectedReporter)[0],descriptionContent,userDataId,"issue","update",users,res,`issues/${ticket?.payload?.latest}`,Array.from(selectedPart)[0],ticket?.payload?.latest , tenant.id)
      await patchData(formData, ticket.payload.id, tenant.name);
      const promises = deletearr.map((id) =>
        DeleteSingleAttribute("upload/files", Number(id.id))
      );
      await Promise.all(promises);
      window.location.reload();
    } catch (error) { }
  };
  useEffect(() => {
    const getAllDataByIds = async () => {
      if (!tenant?.id) return;

      try {
        // Fetch sprints
        const sprintsResp = await getFilteredBySingleRelation("sprints", "organization", tenant.id);
        setSprints(sprintsResp.data);

        // Fetch parts
        const partsResp = await getFilteredBySingleRelation("parts", "organization", tenant.id);
        setPartData(partsResp.data);

        // Fetch tags
        const tagsResp = await getFilteredBySingleRelation("tags", "organization", tenant.id);
        setCustomTags(tagsResp.data);

      } catch (error) {
        console.error("Error fetching data for all IDs:", error.status, error.message);
        throw error;
      }
    };

    getAllDataByIds();
  }, [tenant]);

  useEffect(() => {
    let arr = [];
    if (ticket?.payload?.tags && ticket?.payload?.tags.length !== 0) {
      arr.push("tags");
    }
    if (
      ticket?.payload?.targetclosedate &&
      ticket?.payload?.targetclosedate !== ""
    ) {
      arr.push("date");
    }
    if (ticket?.payload?.estimatedays && ticket?.payload?.estimatedays !== "") {
      arr.push("days");
    }
    setSelectedKeys(new Set(arr));
  }, [ticket]);
  useEffect(() => {
    const filtered = customTags.filter((option) =>
      ticket?.payload?.tags.includes(option.id)
    );
    setTagsDetail(filtered);
  }, [customTags]);
  const [parsedParts, setParsedParts] = useState({});
  useEffect(() => {
    const convertedParts = {};
    partData?.forEach((item) => {
      convertedParts[item?.id] = {
        Name: item?.attributes?.Name,
        Type: item?.attributes?.Type,
        ParentPath: item?.attributes?.ParentPath,
        Owner: item?.attributes?.users_permissions_user?.data?.id,
      };
    });
    setParsedParts(convertedParts);
  }, [partData]);
  useEffect(() => {
    if (sprints.length > 0) {
      const convertedParts = {};
      sprints?.forEach((item) => {
        convertedParts[item?.id] = {
          Name: item?.attributes?.Title,
        };
      });
      setParsedSprints(convertedParts);
    }
  }, [sprints]);
  useEffect(() => {
    if (sprints.length > 0) {
      const filtered = sprints.filter((option) =>
        option.attributes.Title.toLowerCase().includes(search.toLowerCase())
      );

      setFilteredSprints(filtered);
    }
  }, [search, sprints]);
  useEffect(() => {
    if (Array.from(selectedPart).length > 0 && parsedParts) {
      const idOwner = String(parsedParts[Array.from(selectedPart)[0]]?.Owner);
      setOwnerInfo((prev) => ({
        ...prev,
        id: idOwner,
        username: res[idOwner]?.UserName,
        name: res[idOwner]?.FirstName,
        url:
          process.env.REACT_APP_STAGE === "Dev"
            ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[idOwner]?.ProfilePicture}`
            : `${res[idOwner]?.ProfilePicture}`,
      }));
    }
  }, [selectedPart, parsedParts]);

  return (
    <div
      className={`w-96 p-4 shadow-lg overflow-y-auto absolute top-0 ${pos === "issue" ? "right-0" : "right-96"
        }  h-full bg-white z-50`}
    >
      <Toaster />
      <div className="flex items-center justify-between">
        <Chip
          size="sm"
          className="gap-2"
          variant="flat"
          radius="full"
          color="primary"
        >
          ISS-{ticket?.payload?.latest}
        </Chip>
        <div className=" flex items-center">
          <Button
            onClick={() =>
              navigate(`/${tenant.name}/issues/${ticket?.payload?.latest}`)
            }
            size="sm"
            variant="light"
          >
            Show More
          </Button>
            <Tooltip content="Copy link">
            <Button
            onClick={() => handleCopyLink()}
            isIconOnly
            size="sm"
            variant="light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
              />
            </svg>
          </Button>
            </Tooltip>
          

          <Button
            isIconOnly
            variant="light"
            size="sm"
            onClick={handleCloseTicket}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </Button>
        </div>
      </div>
      <Input
        className="my-4"
        variant="bordered"
        type="text"
        placeholder="Add Title"
        classNames={{
          input: ["bg-white", "hover:bg-white"],
          innerWrapper: ["bg-white", "hover:bg-white"],
          inputWrapper: [
            "shadow-none",
            "border",
            "border -divider",
            "bg-white",
            "dark:bg-white",
            "hover:bg-white",
            "dark:hover:bg-white",
            "group-data-[focus=true]:bg-white",
            "dark:group-data-[focus=true]:bg-white",
            "data-[hover=true]:bg-white",
          ],
        }}
        size="sm"
        style={{ fontSize: "12px" }}
        name="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Divider />
      <div className=" h-auto my-4 border-[2px] rounded-lg">
        <EditorProvider
          editorProps={props()}
          slotBefore={
            <MenuBar
              descriptionContent={descriptionContent}
              imageArray={imageIds}
              setImageArray={setImageIds}
              setDescriptionContent={setDescriptionContent}
              initial={start}
              setInitial={setStart}
            />
          }
          extensions={extensions(userNames)}
          content={descriptionContent}
        ></EditorProvider>
      </div>
      <Divider />
      <div className="h-4"></div>
      <div>
        <div className="flex gap-2 text-center items-center">
          <p className="flex w-32 text-xs gap-2 text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
              />
            </svg>
            Part<span className="text-red-500">*</span>
          </p>
          <div className=" w-full text-xs  flex ">
            {partData.length > 0 && (
              <PartPicker
                divRef={divRef}
                inputRef={inputRef}
                parsedParts={parsedParts}
                selected={selectedPart}
                setSelected={setSelectedPart}
                parts={partData}
              />
            )}
          </div>
        </div>
      </div>
      {!(selectedPart.size === 0) && (
        <div className=" mt-2 flex flex-col gap-4">
          <Divider className="mt-2" />
          <div className=" flex items-center gap-2">


            <p className="text-xs w-32 gap-2 text-center flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.867 19.125h.008v.008h-.008v-.008Z"
                />
              </svg>
              Stage<span className=" text-red-600 "> *</span>
            </p>
            <div className="w-40">
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className="text-xs text-center justify-start flex items-center"
                  >
                    {Array.from(stage)[0]}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={stage}
                  onSelectionChange={setStage}
                >
                  <DropdownSection title="Open">
                    <DropdownItem key="Queued">Queued</DropdownItem>
                  </DropdownSection>
                  <DropdownSection title="In progress">
                    {progress.map((item) => (
                      <DropdownItem key={item}> {item}</DropdownItem>
                    ))}
                  </DropdownSection>
                  <DropdownSection title="Closed">
                    {closed.map((item) => (
                      <DropdownItem key={item}> {item}</DropdownItem>
                    ))}
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <p className="w-32 gap-2   text-xs text-center flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              Assigned to<span className=" text-red-600 "> *</span>
            </p>
            <div className="w-40">
              <Dropdown size="sm">
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className=" justify-start capitalize"
                  >
                    {selectedReporter === "" ? (
                      <p className=" text-xs ">Assign user</p>
                    ) : (
                      <div className="flex gap-2 items-center">
                        <Avatar
                          color={Colors[Number(reporterInfo.id) % numColors]}
                          style={{ width: "22px", height: "22px" }}
                          showFallback
                          radius="sm"
                          name={
                            reporterInfo.name
                              ? reporterInfo.name?.charAt(0).toUpperCase()
                              : reporterInfo.username?.charAt(0).toUpperCase()
                          }
                          className="flex-shrink-0"
                          size="sm"
                          src={reporterInfo.url}
                        />
                        <div className="flex flex-col">
                          <span className="text-xs">
                            {reporterInfo.name
                              ? reporterInfo?.name?.length > 25
                                ? `${reporterInfo.name.slice(0, 25)}...`
                                : reporterInfo.name
                              : reporterInfo?.username?.length > 25
                                ? `${reporterInfo.username.slice(0, 25)}...`
                                : reporterInfo.username}
                          </span>
                        </div>
                      </div>
                    )}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Select Tenant"
                  variant="flat"
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={selectedReporter}
                  onSelectionChange={setSelectedReporter}
                >
                  {users.map((report) => {
                    const colorIndex = report && report.id % numColors;
                    return (
                      <DropdownItem
                        onClick={(e) => updateReporter(report)}
                        key={report.id}
                      >
                        <div className="flex gap-2 items-center">
                          <Avatar
                            style={{ width: "22px", height: "22px" }}
                            showFallback
                            name={
                              report.FirstName
                                ? report.FirstName?.charAt(0).toUpperCase()
                                : report.username?.charAt(0).toUpperCase()
                            }
                            className="flex-shrink-0"
                            radius="sm"
                            size="sm"
                            color={Colors[colorIndex]}
                            src={
                              report.ProfilePicture?.formats?.small?.url
                                ? process.env.REACT_APP_STAGE === "Dev"
                                  ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${report.ProfilePicture.formats.small.url}`
                                  : `${report.ProfilePicture.formats.small.url}`
                                : ""
                            }
                          />
                          <div className="flex flex-col">
                            <span className="text-xs">
                              {report.FirstName
                                ? report.FirstName
                                : report.username}{" "}
                              {report?.LastName}
                            </span>
                          </div>
                        </div>
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <p className="w-32 gap-2 text-xs text-center flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
                />
              </svg>
              Priority<span className=" text-red-600"> *</span>
            </p>
            <div className="w-40">
              <Dropdown variant="light" size="sm">
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className="w-24 text-xs items-center justify-start text-center flex"
                  >
                    {Array.from(selectedPriority).length > 0 ? (
                      <div className="flex gap-2">
                        {priorityData[Array.from(selectedPriority)[0]]?.icon}
                        <p className="text-xs">{priorityData[Array.from(selectedPriority)[0]]?.label}</p>
                      </div>
                    ) : (
                      <p className="text-xs text-start">Select Priority</p>
                    )}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Priority selection"
                  variant="flat"
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={selectedPriority}
                  onSelectionChange={setSelectedPriority}
                >
                  {Object.keys(priorityData).map((priority) => (
                    <DropdownItem
                      key={priority}
                      className="flex items-center w-full gap-2"
                    >
                      <div className="flex gap-2">
                        {priorityData[priority].icon}
                        <p className="text-xs">{priorityData[priority].label}</p>
                      </div>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          {sprints.length > 0 && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2   text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  />
                </svg>
                Sprint
              </p>
              <div className="w-40">
                <Dropdown>
                  <DropdownTrigger>
                    <div className="w-full pl-4 text-xs cursor-pointer">
                      {Array.from(selectedSprint).length === 0
                        ? "Add sprint"
                        : `${parsedSprints[Number(Array.from(selectedSprint)[0])]
                          ?.Name
                        }: ${sprintNumber === "Backlog"
                          ? "Backlog"
                          : `Sprint ${sprintNumber}`
                        }`}
                    </div>
                  </DropdownTrigger>
                  <DropdownMenu
                    ref={inputRef}
                    selectedKeys={selectedSprint}
                    onSelectionChange={setSelectedSprint}
                    selectionMode="single"
                  >
                    <DropdownSection isReadOnly>
                      <DropdownItem classNames={{ base: "gap-0 data-[selectable=true]:focus:bg-white", selectedIcon: "w-0" }} isReadOnly key="new">
                        <Input
                          className="w-full"
                          size="sm"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search sprint.."
                          style={{ fontSize: "12px" }}
                        ></Input>
                      </DropdownItem>
                    </DropdownSection>
                    <DropdownSection className="overflow-y-auto max-h-[100px]">
                      {filteredSprints.map((item) => {
                        const iconDetail = basicIcons.filter(
                          (icon) =>
                            icon.name.toLowerCase() ===
                            item?.attributes?.Icon?.toLowerCase()
                        );
                       
                        return (
                          <DropdownItem
                            onMouseEnter={() => handleMouseEnter(item.id)}
                            onMouseLeave={() => handleMouseLeave()}
                            className="hover:bg-[#F4F4F5]"
                            isReadOnly
                            key={item.id}
                          >
                            <Popover
                              isOpen={onHover === item.id}
                              showArrow
                              placement="left-start"
                            >
                              <PopoverTrigger>
                                <div className="w-full text-xs flex gap-4 items-center">
                                  <svg
                                    xmlns={iconDetail[0]?.xmlns}
                                    fill={iconDetail[0]?.fill || "none"}
                                    viewBox={
                                      iconDetail[0]?.viewBox || "0 0 24 24"
                                    }
                                    strokeWidth={
                                      iconDetail[0]?.strokeWidth || 1.5
                                    }
                                    stroke={
                                      iconDetail[0]?.stroke || "currentColor"
                                    }
                                    className={
                                      iconDetail[0]?.className || "size-4"
                                    }
                                  >
                                    <path
                                      strokeLinecap={
                                        iconDetail[0]?.path?.d || "round"
                                      }
                                      strokeLinejoin={
                                        iconDetail[0]?.path?.d || "round"
                                      }
                                      d={
                                        iconDetail[0]?.path?.d ||
                                        "m4.5 12.75 6 6 9-13.5"
                                      }
                                    />
                                  </svg>
                                  <p>{item.attributes.Title}</p>
                                  {Array.from(selectedSprint)[0] === item.id && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="size-4"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m4.5 12.75 6 6 9-13.5"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </PopoverTrigger>
                              <PopoverContent className="flex flex-col items-center gap-2 justify-center px-2 py-2">
                                <Button
                                  endContent={
                                    Array.from(selectedSprint)[0] === item.id &&
                                      sprintNumber ===
                                      Number(item.attributes.Current) ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-4"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m4.5 12.75 6 6 9-13.5"
                                        />
                                      </svg>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  onClick={() =>
                                    handleSelectSprint(
                                      item.id,
                                      Number(item.attributes.Current)
                                    )
                                  }
                                  variant="light"
                                  size="sm"
                                  className="w-40"
                                >
                                  <div className="w-full text-xs flex p-1 gap-2 items-center">
                                    <ScredriverWrench size="size-4" />

                                    <p>Sprint {item.attributes.Current}</p>
                                  </div>
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleSelectSprint(
                                      item.id,
                                      Number(item.attributes.Current) + 1
                                    )
                                  }
                                  endContent={
                                    Array.from(selectedSprint)[0] === item.id &&
                                      sprintNumber ===
                                      Number(item.attributes.Current) + 1 ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-4"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m4.5 12.75 6 6 9-13.5"
                                        />
                                      </svg>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  variant="light"
                                  size="sm"
                                  className="w-40"
                                >
                                  <div className="w-full text-xs flex p-1 gap-2 items-center">
                                    <ChevronArrow size="size-4" />

                                    <p>
                                      Sprint{" "}
                                      {Number(item.attributes.Current) + 1}
                                    </p>
                                  </div>
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleSelectSprint(item.id, "Backlog")
                                  }
                                  endContent={
                                    Array.from(selectedSprint)[0] === item.id &&
                                      sprintNumber === "Backlog" ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-4"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m4.5 12.75 6 6 9-13.5"
                                        />
                                      </svg>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  variant="light"
                                  size="sm"
                                  className="w-40"
                                >
                                  <div className="w-full text-xs flex gap-2 p-1 items-center">
                                    <WarningIcon size="size-4" />
                                    <p>Backlog</p>
                                  </div>
                                </Button>
                              </PopoverContent>
                            </Popover>
                          </DropdownItem>
                        )
                      })}
                    </DropdownSection>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          )}
          {Array.from(selectedKeys).includes("tags") && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2 text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 6h.008v.008H6V6Z"
                  />
                </svg>
                Tags</p>
              <Tags
                param={tenant.name}
                customTags={customTags}
                org={tenant.id}
                dropdownref={dropdownref}
                tags={tags}
                setTags={setTags}
                tagsDetail={tagsDetail}
                setTagsDetail={setTagsDetail}
                modalref={modalref}
              />
            </div>
          )}
          {Array.from(selectedKeys).includes("date") && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2   text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>
                Target Close Date
              </p>
              <div className="w-40">
                <DatePicker
                  variant="bordered"
                  value={
                    selectedDate === ""
                      ? null
                      : parseDate(convertToDateStr(selectedDate))
                  }
                  selectedDate={selectedDate}
                  onChange={handleDateChange}
                  size="sm"
                />
              </div>
            </div>
          )}
          {Array.from(selectedKeys).includes("owner") && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2  text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                Owner
              </p>
              <div className="w-40">
                <Button
                  size="sm"
                  variant="light"
                  className="w-40 justify-start"
                >
                  <div className="flex gap-2 items-center">
                    <Avatar
                      color={Colors[Number(ownerInfo?.id) % numColors]}
                      style={{ width: "22px", height: "22px" }}
                      showFallback
                      radius="sm"
                      name={
                        ownerInfo.name
                          ? ownerInfo?.name?.charAt(0).toUpperCase()
                          : ownerInfo?.username?.charAt(0).toUpperCase()
                      }
                      className="flex-shrink-0"
                      size="sm"
                      src={ownerInfo?.url}
                    />
                    <div className="flex flex-col">
                      <span className="text-xs">
                        {ownerInfo.name ? ownerInfo.name : ownerInfo.username}
                      </span>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          )}
          {Array.from(selectedKeys).includes("days") && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2   text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                  />
                </svg>
                Estimated Days
              </p>
              <div className="w-40">
                <Input
                  value={selectedEstimatedDays}
                  variant="bordered"
                  placeholder="Add days"
                  classNames={{
                    input: ["bg-white", "hover:bg-white"],
                    innerWrapper: ["bg-white", "hover:bg-white"],
                    inputWrapper: [
                      "shadow-none",
                      "border",
                      "border-divider",
        
                      "bg-white",
                      "dark:bg-white",
                      "hover:bg-white",
                      "dark:hover:bg-white",
                      "group-data-[focus=true]:bg-white",
                      "dark:group-data-[focus=true]:bg-white",
                      "data-[hover=true]:bg-white",
                    ],
                  }}
                  onChange={(e) => setSelectedEstimatedDays(e.target.value)}
                  type="number"
                  size="sm"
                />
              </div>
            </div>
          )}
          <div className="w-24">
            <Dropdown>
              <DropdownTrigger>
                <Button variant="light" size="sm" className="capitalize">
                  + Add Attributes
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Multiple selection example"
                variant="flat"
                closeOnSelect={false}
                selectionMode="multiple"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
              >
                <DropdownItem key="tags">
                  <p className="text-xs"> Tags</p>
                </DropdownItem>
                <DropdownItem key="owner">
                  <p className="text-xs"> Owner</p>
                </DropdownItem>
                <DropdownItem key="date">
                  <p className="text-xs">Target Closing Date </p>
                </DropdownItem>
                <DropdownItem key="days">
                  <p className="text-xs"> Estimated Efforts</p>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      )}
      <Divider className="my-4" />
      <LinkIssues popoverRef={modalref} inputRef={inputRef} type={"ticket"} selected={selectedIssueIds} setSelected={setSelectedIssueIds} />
      <div className="flex items-center mt-2 justify-end">
        <div className="flex items-center">

          {permission && (
            <Button
              size="sm"
              className={`ml-2 text-white ${title === "" ||
                descriptionContent === "" ||
                descriptionContent === "<p></p>" ||
                Array.from(selectedPart).length === 0 ||
                Array.from(selectedPriority).length === 0
                ? ``
                : `bg-[rgb(51,51,245)]`
                } `}
              disabled={
                title === "" ||
                descriptionContent === "" ||
                descriptionContent === "<p></p>" ||
                Array.from(selectedPart).length === 0 ||
                Array.from(selectedPriority).length === 0
              }
              onClick={handleSubmit}
            >
              Update
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Issue;
