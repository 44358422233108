import React, { useState } from "react";
import { SketchPicker, TwitterPicker } from "react-color";
import { useDispatch } from "react-redux";
import { setHexData } from "../../redux/actions";
const customColors = [ "#ffffff", "#000000","#ff5733","#8e44ad" ];
function ColorPicker({ color, setColor }) {
  const dispatch = useDispatch();
  const handleChangeComplete = (color) => {
    dispatch(setHexData(color.hex))
    setColor(color.hex);
  };

  return (
    <div>
      <TwitterPicker colors={[...TwitterPicker.defaultProps.colors,...customColors]} color={color} onChangeComplete={handleChangeComplete} />
    </div>
  );
}

export default ColorPicker;
