import { SET_USER_DATA,SET_RES,SET_UPDATES,SET_USERNAMES,SET_ORG } from './actions';
 
const initialState = {
    data: [],
    usernames :[],
    res:null,
    updates:null
};
 
const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case SET_ORG: return {
            ...state,
            org: action.payload,
        }
        case SET_RES:return {
            ...state,
            res:action.payload,
        }
        case SET_UPDATES:return {
            ...state,
            updates:action.payload,
        }
        case SET_USERNAMES:return {
            ...state,
            usernames:action.payload
        }
        default:
            return state;
    }
 
};
 
export default rootReducer;