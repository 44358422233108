import { Button, Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Select, SelectItem, Textarea } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import UserModal from '../../components/UserModal/UserPicker';
import { DeleteSingleAttribute, UpdateData, UpdateSingleCollectionData, getFilteredBySingleRelation } from '../../controllers/strapiController';
import { useNavigate, useParams } from 'react-router-dom';

function GroupDetails({group ,users,orgId}) {
    const [members,setMembers] = useState(new Set(group?.attributes?.members?.data?.map(member => String(member.id))));
    const navigate = useNavigate();
    const {org} = useParams();
    const [type,setType] = useState(new Set([group?.attributes?.Type]))
    const [owner,setOwner]=  useState(new Set([String(group?.attributes?.owner?.data?.id)]))
    const [groupName,setGroupName] = useState(String(group?.attributes?.Name));
    const [desc,setDesc] = useState(String(group?.attributes?.Description));
    const [existingName,setExistingNames] = useState(new Set([]));
    useEffect(()=> {
        const fetchNames = async()=>{
            try{
                const resp = await getFilteredBySingleRelation("groups","organization",orgId);
                let arr = resp.data.map((item) => {return item.attributes.path});
                arr = arr.filter((item) => item!==group.attributes.Name);
                setExistingNames(new Set(arr));
            }
           catch(error){

           }
        }
        if(group){
            fetchNames();
        }
    },[group])
    const generateURL = (name) => {
        const lowercaseName = name.toLowerCase().replace(/\s+/g, "-");
        return lowercaseName;
      };
    const handleDelete= async()=> {
        try{
            const resp = await DeleteSingleAttribute("groups",group.id);
            navigate(`/${org}/settings/groups`);
        }
        catch(error){

        }
    }
    const handleUpdate= async()=> {
        let arr = Array.from(members);
        arr.push(Array.from(owner)[0]);
        const formData = {
            Name : groupName,
            Description : desc,
            path: generateURL(groupName),
            Type : Array.from(type)[0],
            owner: {
                disconnect: [],
                connect: [
                  {
                    id: Number(Array?.from(owner)[0]),
                    position: {
                      end: true,
                    },
                  },
                ],
              },
              members: {
                disconnect: group.attributes.members.data.filter((item) => !arr.includes(item.id)).map((mem) => {return String(mem.id)}) ,
                connect: arr.map((item) => {
                  return {
                    id: Number(item),
                    position: {
                      end: true,
                    },
                  };
                }),
              },
        }
        try{
            const resp = await UpdateData("groups",group.id,formData);
            window.location.reload();
        }
        catch(error){

        }
    }
  return (
    <div>
      <Card shadow='none' className='mt-4 border border-divider'>
        <CardBody className='flex p-4 flex-col gap-8'>
            <div className='flex flex-col gap-1' >
                <p className=' text-sm font-light'>Group Name:</p>
                <Input classNames={{
              input: [
                "bg-white",
                "hover:bg-white",
              ],
              innerWrapper: ["bg-white","hover:bg-white"],
              inputWrapper: [
                "shadow-none",
                "border","border -divider",
            "bg-white",
            "dark:bg-white",
            "hover:bg-white",
            "dark:hover:bg-white",
            "group-data-[focus=true]:bg-white",
            "dark:group-data-[focus=true]:bg-white",
            "data-[hover=true]:bg-white"
              ],
            }} color={Array.from(existingName).includes(generateURL(groupName)) ? "danger" : "default"} size="sm" variant='bordered' value={groupName} onChange={(e)=> setGroupName(e.target.value)} />

            </div>
            <div className='flex flex-col gap-1' >
                <p className=' text-sm font-light'>Group Description:</p>
                <Textarea classNames={{
              input: [
                "bg-white",
                "hover:bg-white",
              ],
              innerWrapper: ["bg-white","hover:bg-white"],
              inputWrapper: [
                "shadow-none",
                "border","border -divider",
            "bg-white",
            "dark:bg-white",
            "hover:bg-white",
            "dark:hover:bg-white",
            "group-data-[focus=true]:bg-white",
            "dark:group-data-[focus=true]:bg-white",
            "data-[hover=true]:bg-white"
              ],
            }} size="sm" variant='bordered' value={desc} onChange={(e)=> setDesc(e.target.value)} />

            </div>
           {group?.attributes?.Type !== "Admin" && <div className='flex flex-col gap-1' >
                <p className=' text-sm font-light'>Type:</p>
                <Select
           
            size="sm"
            disallowEmptySelection
            selectedKeys={type}
            onSelectionChange={setType}
          >
            <SelectItem key="Internal">Internal User Group</SelectItem>
            <SelectItem key="External">External User Group</SelectItem>
          </Select>

            </div>}
            
            <div className='flex flex-col gap-1' >
                <p className=' text-sm font-light'>Group Owner:</p>
                <UserModal users={users} member={owner} setMember={setOwner} single={true} />
                
            </div>
            <div className='flex flex-col gap-1' >
                <p className=' text-sm font-light'>Group Members:</p>
                <UserModal users={users} member={members} setMember={setMembers} single={false} />
                
            </div>
            <div className=' flex justify-between'>
                <Dropdown>
                    <DropdownTrigger>
                        <Button size='sm' color='danger'>Delete</Button>
                    </DropdownTrigger>
                    <DropdownMenu>
                        <DropdownItem onClick={()=> handleDelete()} variant='flat' color='danger'>Delete Permanently</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
               
                <Button disabled={Array.from(existingName).includes(generateURL(groupName)) || groupName==="" || desc ==="" || Array.from(owner).length===0 || Array.from(members).length===0} onClick={()=> handleUpdate()} size='sm' color='primary'>Update</Button>
            </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default GroupDetails
