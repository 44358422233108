import { Button, Chip, Input, Tab, Tabs, Textarea } from "@nextui-org/react";
import React, { useRef, useState } from "react";
import { lorem } from "../../utils/default";
import { Colors, numColors } from "../../utils/constant";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { uploadFile } from "../../controllers/strapiController";
import { postIngest } from "../../controllers/vectorDB";
import { useParams } from "react-router-dom";

const Desc = ({ title, setTitle, desc, setDesc }) => {
  return (
    <div className=" flex flex-col gap-4">
      <div className=" flex flex-col gap-2">
        <p>
          Document Title<span className="text-red-500">*</span>
        </p>
        <Input
          placeholder="Give a title to your document"
          className="w-[400px]"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          size="sm"
        />
      </div>
      <div className=" flex flex-col gap-2">
        <p>Description</p>
        <Textarea
          placeholder="Give a description to your document"
          className="w-[60%]"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        ></Textarea>
      </div>
    </div>
  );
};

const Tags = ({ setTags }) => {
  const [val, setVal] = useState("");
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setTags((prevTags) => new Set(prevTags).add(event.target.value));
      setVal("");
    }
  };
  return (
    <div className=" flex flex-col gap-4">
      <div className=" flex flex-col gap-2">
        <p>Tags</p>
        <Textarea
          placeholder="Press enter to add multiple tags"
          value={val}
          onChange={(e) => setVal(e.target.value)}
          onKeyDown={handleKeyDown}
          variant="bordered"
          className="w-[60%]"
        ></Textarea>
      </div>
    </div>
  );
};

const RenderTags = ({ tags, setTags }) => {
  const removeTag = (tagToRemove) => {
    setTags((prevTags) => {
      const newTags = new Set(prevTags);
      newTags.delete(tagToRemove);
      return newTags;
    });
  };
  return (
    <div className=" flex flex-wrap items-center gap-2">
      {Array.from(tags).map((item,index) => (
        <Chip
            key={index}
          size="sm"
          variant="flat"
          color={Colors[item.length % numColors]}
          className=" cursor-pointer"
          onClick={() => removeTag(item)}
        >
          {item}
        </Chip>
      ))}
    </div>
  );
};

const Ingest = () => {
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const {org} = useParams();
  const fileInputRef = useRef();
  const [tab, setTab] = useState("url");
  const [title, setTitle] = useState("");
  const [disabled,setDisabled] = useState(false);
  const [desc, setDesc] = useState("");
  const [tags, setTags] = useState(new Set([]));
  const [url, setUrl] = useState("");
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // Get the first selected file
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile); // Store the file in state
    } else {
      setFile(null);
    }
  };
  const handleReset = () => {
    setTitle("");
    setDesc("");
    setUrl("");
    setTags(new Set([]));
    setFile(null);
  };

  const handleSubmit = async (type) => {
    if (title === "" || (type === "url" ? !isValidURL(url) : file === null)) {
      return;
    }
    setDisabled(true)
    const finalTitle = title;
    const finalDesc = desc;
    const tagArray = Array.from(tags);
    const id = Date.now();
    let finalUrl = url;
    let strapiId = null;
    if (type === "pdf") {
      try {
        const resp = await UploadFileHandler();
        if (!resp?.id || !resp?.url) {
            setDisabled(false)
          return;
        }
        finalUrl = resp.url;
        strapiId = resp.id;
      } catch (error) {
        setDisabled(false)
      }
    }
    try {
      await submitFinal(
        finalTitle,
        finalDesc,
        tagArray,
        id,
        finalUrl,
        strapiId
      );
      setDisabled(false);
      handleReset();
    } catch (error) {
        setDisabled(false)
    }
  };
  const submitFinal = async (
    finalTitle,
    finalDesc,
    tagsArr,
    id,
    finalURL,
    strapiId
  ) => {
    const formData = {
      source: "pdf",
      document_url: finalURL,
      document_id: String(id),
      document_title: finalTitle,
      tags: tagsArr,
      user_id: String(userDataId),
      document_description: finalDesc,
      metadata: {
        strapiId: strapiId ? String(strapiId) : "null",
      },
    };
    try{
        await postIngest(id,formData,org);
    }
    catch(error){
        console.log("error",error)
    }
  };
  const UploadFileHandler = async () => {
    if (!file) {
      return;
    }
    let formData = new FormData();
    formData.append("files", file);
    try {
      const response = await uploadFile(formData);
      if (response) {
        const URLresponse = response && response[0];
        return URLresponse;
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  function isValidURL(string) {
    try {
      new URL(string); // If valid, this will succeed.
      return true; // URL is valid.
    } catch (_) {
      return false; // URL is invalid.
    }
  }
  return (
    <div className="flex flex-col gap-4 mx-8 pt-8">
      <Tabs
        selectedKey={tab}
        onSelectionChange={setTab}
        className="pt-4"
        size="sm"
      >
        <Tab key={"url"} title="Share a link"></Tab>

        <Tab key={"pdf"} title="Upload pdf"></Tab>
      </Tabs>
      <div className="flex flex-col gap-4">
        <RenderTags setTags={setTags} tags={tags} />
        <Desc title={title} setTitle={setTitle} desc={desc} setDesc={setDesc} />
        {tab === "url" ? (
          <div className=" flex flex-col gap-2">
            <p>
              Document URL<span className="text-red-500">*</span>
            </p>
            <Input
              placeholder="🔗 Give a URL for your document"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="w-[60%]"
            />
          </div>
        ) : (
          <div>
            <Button
              onClick={() => fileInputRef?.current?.click()}
              variant="bordered"
            >
              {file ? file.name : "Upload a pdf"}
              <input
                ref={fileInputRef}
                type="file"
                accept="application/pdf"
                onChange={(event) => handleFileChange(event)}
                className="hidden"
              />
            </Button>
          </div>
        )}

        <Tags setTags={setTags} />
      </div>
      <div className="w-[60%] gap-4 flex justify-end items-center">
        <Button onClick={handleReset} size="sm">
          Cancel
        </Button>
        <PrimaryButton
          disabled={(
            title === "" || (tab === "url" ? !isValidURL(url) : file === null)) || disabled
          }
          handler={() => handleSubmit(tab)}
          text={"Upload file"}
        />
      </div>
    </div>
  );
};

export default Ingest;
