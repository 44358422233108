import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  Input,
  DatePicker,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import { Editor, EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  MenuBar,
  extensions,
  props,
} from "../../../components/markdown/componenteditor";
import {
  CreateData,
  UpdateData,
  getAllData,
  getFilteredBySingleRelation,
  getOneData,
} from "../../../controllers/strapiController";
import { useNavigate, useParams } from "react-router-dom";
import Tags from "../../../components/Tag/tags";
import UserModal from "../../../components/UserModal/UserPicker";
import PartsPicker from "../../../components/PartsPicker/PartsPicker";
import { getLocalTimeZone, today, parseDate } from "@internationalized/date";
import Icon from "../Icon/Icon";
import { basicIcons } from "../../../utils/default";
import { useSelector } from "react-redux";
import { selectOrgData, selectUserData } from "../../../redux/actions";

function SprintDrawer({
  handleDrawerClose,
  divRef,
  popoverref,
  modalref,
  dropdownref,
  inputRef,
  type,
  sprint,
  userNames
}) {
  const { org } = useParams();
  const [sprintTitle, setSprintTitle] = useState(
    type === "update" ? sprint?.title : ""
  );
  console.log(sprint,"sprint")
  const [selectedPriority, setSelectedPriority] = useState( type === "update" ? new Set([sprint.priority])  : new Set([]));

  const priorityData = {
    Urgent: {
      color: 'text-red-500',
      label:"Urgent",
      id:"P1",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 text-red-500">
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    High: {
      color: 'text-yellow-500',
      id:"P2",
      label:"High",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 text-yellow-500">
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    Normal: {
      color: 'text-blue-500',
      label:"Normal",
      id:"P3",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 text-blue-500">
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    Low: {
      color: 'text-gray-500',
      id:"P4",
      label:"Low",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 text-gray-500">
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
  };

  const idToPriority = Object.keys(priorityData).reduce((acc, key) => {
    acc[priorityData[key].id] = key;
    return acc;
  }, {});

  const priorityLabel = idToPriority[selectedPriority];
  const selectedPriorityData = priorityLabel ? priorityData[priorityLabel] : null;
  const navigate = useNavigate();
  const {onOpen } = useDisclosure();
  console.log(selectedPriorityData)
  console.log(priorityLabel)
  console.log(idToPriority)
  console.log(selectedPriority)
  const [sprintDuration, setSprintDuration] = useState(
    type === "update" ? sprint.duration : ""
  );
  const [sprintStartDate, setSprintStartDate] = useState(
    type === "update" ? parseDate(sprint.date) : null
  );
  const [descriptionContent, setDescriptionContent] = useState(
    type === "update" ? sprint.description : ""
  );

  const [selectedIcon, setSelectedIcon] = useState(
    type === "update" ? sprint.Icon : ""
  );
  const [start, setStart] = useState(false);
  const [selectedPart, setSelectedPart] = useState(
    type === "update" ? new Set(sprint.part) : new Set([])
  );
  const iconData = basicIcons.find((icon) => icon.name === sprint?.icon);
  const selected = Array.from(selectedPriority);
  const selectedData = priorityData[selected] || {};
  const [partData, setPartData] = useState([]);

  const [owner, setOwner] = useState(
    type === "update" ? new Set(sprint.owners) : new Set([])
  );
  const users = useSelector(selectUserData);
  const currentOrg = useSelector(selectOrgData);

  
 

  const [tags, setTags] = useState(type === "update" ? sprint.tags : []);
  const [tagsDetail, setTagsDetail] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const [isOpenIcon, setIsOpenIcon] = useState(false);
  const handleIconButtonClick = () => {
    onOpen();
  };
  

  const generateURL = (name) => {
    const lowercaseName = name.toLowerCase().replace(/\s+/g, "-");
    return lowercaseName;
  };

  const handleUpdate = async () => {
    let isValid = true;
    if (sprintTitle.trim() === "") {
      isValid = false;
    }

    if (descriptionContent.trim() === "") {
      isValid = false;
    }

    if (selectedPriority === "") {
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    const newSprint = {
      Title: sprintTitle,
      path: generateURL(sprintTitle),
      Description: descriptionContent,
      Icon: selectedIcon?.name,
      Priority: Array.from(selectedPriority)[0],
      owners: {
        disconnect: sprint.owners.filter(
          (item) => !Array.from(owner).includes(item)
        ),
        connect: Array.from(owner).map((item) => {
          return {
            id: Number(item),
            position: {
              end: true,
            },
          };
        }),
      },
      Tags: {
        disconnect: sprint?.tags?.filter((item) => !tags?.includes(item)),
        connect: tags.map((item) => {
          return {
            id: Number(item),
            position: {
              end: true,
            },
          };
        }),
      },
      parts: {
        disconnect: sprint.part.filter(
          (item) => !Array.from(selectedPart).includes(item)
        ),
        connect: Array.from(selectedPart).map((item) => {
          return {
            id: Number(item),
            position: {
              end: true,
            },
          };
        }),
      },
    };
    try {
      const resp = await UpdateData("sprints", sprint.id, newSprint);
      if (resp) {
        // window.location.reload();
        console.log(newSprint,"66")
      }
    } catch (error) {}
  };
  const handleCreateSprint = async () => {
    let isValid = true;
    if (sprintTitle.trim() === "") {
      isValid = false;
    }
    if (sprintDuration.trim() === "" || isNaN(sprintDuration)) {
      isValid = false;
    }
    if (!sprintStartDate) {
      isValid = false;
    }

    if (descriptionContent.trim() === "") {
      isValid = false;
    }

    if (selectedPriority === "") {
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const newSprint = {
        Title: sprintTitle,
        Current: 1,
        StartDate: parseDateToString(sprintStartDate),
        path: generateURL(sprintTitle),
        Duration: sprintDuration.trim(),
        Description: descriptionContent,
        Icon: selectedIcon?.name,
        Priority: Array.from(selectedPriority)[0],
        owners: {
          disconnect: [],
          connect: Array.from(owner).map((item) => {
            return {
              id: Number(item),
              position: {
                end: true,
              },
            };
          }),
        },
        Tags: {
          disconnect: [],
          connect: tags.map((item) => {
            return {
              id: Number(item),
              position: {
                end: true,
              },
            };
          }),
        },
        parts: {
          disconnect: [],
          connect: Array.from(selectedPart).map((item) => {
            return {
              id: Number(item),
              position: {
                end: true,
              },
            };
          }),
        },
        organization: {
          disconnect: [],
          connect: [
            {
              id: Number(currentOrg?.id),
              position: {
                end: true,
              },
            },
          ],
        },
      };
      await CreateData("sprints", newSprint);
      handleDrawerClose();
      navigate(`/${org}/sprint/${generateURL(sprintTitle)}`);
      // window.location.reload();
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  };

  const handleSprintNameChange = (value) => {
    setSprintTitle(value);
  };

  const handleIconSelect = (icon) => {
    setSelectedIcon(icon);
    setIsOpenIcon(false);
  };

  const handleSprintDurationChange = (value) => {
    if (!isNaN(value)) {
      setSprintDuration(value);
    }
  };
  function parseDateToString(dateObj) {
    const day = String(dateObj.day).padStart(2, "0");
    const month = String(dateObj.month).padStart(2, "0");
    const year = dateObj.year;

    return `${day}-${month}-${year}`;
  }
  const handleSprintStartedDateChange = (date) => {
    setSprintStartDate(date);
  };

  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const resp = await getFilteredBySingleRelation("tags","organization",currentOrg.id)
        setCustomTags(resp.data);
      } catch (error) {}
    };
    if (currentOrg?.id) {
      getAllDataByIds();
    }
  }, [currentOrg]);

  useEffect(() => {
    if (type === "update") {
      const filtered = customTags.filter((option) =>
        tags.includes(Number(option.id))
      );
      setTagsDetail(filtered);
    }
  }, [customTags, tags]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const resp = await getFilteredBySingleRelation("parts","organization",currentOrg.id)
        setPartData(resp.data);
      } catch (error) {
        console.error("Error fetching data for all IDs:", error);
      }
    };
    if (currentOrg?.id) {
      getAllDataByIds();
    }
  }, [currentOrg]);
  const [parsedParts, setParsedParts] = useState({});
  useEffect(() => {
    if (partData.length > 0) {
      const convertedParts = {};
      partData?.forEach((item) => {
        convertedParts[item?.id] = {
          Name: item?.attributes?.Name,
          Type: item?.attributes?.Type,
          ParentPath: item?.attributes?.ParentPath,
          Owner: item?.attributes?.users_permissions_user?.data?.id,
        };
      });
      setParsedParts(convertedParts);
    }
  }, [partData]);

  return (
    <div className="w-96 p-4 text-xs shadow-lg overflow-y-auto absolute top-0 right-0 h-full bg-white z-50">
      <button className="absolute top-4 right-4" onClick={handleDrawerClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <h2 className="text-sm">Create Sprint Board</h2>
      <Divider className="my-4" />

      <div className="flex flex-col gap-4">
        <div>
          <label htmlFor="sprintTitle" className="block text-sm  text-gray-700">
            Sprint Title
          </label>
          <Input
            size="sm"
            id="sprintTitle"
            className="mt-2"
            placeholder="Enter sprint title"
            value={sprintTitle}
            onChange={(e) => handleSprintNameChange(e.target.value)}
            variant="bordered"
            classNames={{
              input: ["bg-white", "hover:bg-white"],
              innerWrapper: ["bg-white", "hover:bg-white"],
              inputWrapper: [
                "shadow-none",
                "border",
                "border -divider",
                "bg-white",
                "dark:bg-white",
                "hover:bg-white",
                "dark:hover:bg-white",
                "group-data-[focus=true]:bg-white",
                "dark:group-data-[focus=true]:bg-white",
                "data-[hover=true]:bg-white",
              ],
            }}
          />
        </div>

        <Divider className="mt-4" />

        <div className="h-auto my-4 border-[2px] rounded-lg">
          <EditorProvider
            editorProps={props}
            slotBefore={
              <MenuBar
                descriptionContent={descriptionContent}
                setDescriptionContent={setDescriptionContent}
                initial={start}
                setInitial={setStart}
              />
            }
            extensions={extensions(userNames)}
            content={descriptionContent}
          ></EditorProvider>
        </div>

        <div className="flex gap-2 flex-col">
          <div className="flex gap-2 text-center items-center">
            <p className=" w-32 gap-2 flex text-xs ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              # Duration<span className="text-red-500">*</span>
            </p>
            <Input
              isDisabled={type === "update"}
              size="sm"
              placeholder="Sprint duration"
              value={sprintDuration}
              className="w-40 bg-white"
              onChange={(e) => handleSprintDurationChange(e.target.value)}
            />
          </div>

          <div className="flex gap-2 items-center">
            <div className=" w-32 gap-2 flex text-xs ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
                />
              </svg>
              Start Date<span className="text-red-500">*</span>
            </div>
            <DatePicker
              isDisabled={type === "update"}
              minValue={type !== "update" ? today(getLocalTimeZone()) : null}
              size="sm"
              value={sprintStartDate}
              onChange={handleSprintStartedDateChange}
              className="w-40 mt-2"
              placeholder="Select start date"
              clearable
              aria-label="Start Date"
            />
          </div>

          <div className="flex flex-col gap-2 mt-6 ">
            <div className=" flex gap-2">
              <p className=" w-32 gap-2 text-xs  flex  items-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                Owner
              </p>
              <div className=" min-w-40 flex ">
                {users.length > 0 && (
                  <UserModal
                    divRef={divRef}
                    inpuRef={inputRef}
                    member={owner}
                    setMember={setOwner}
                    users={users}
                  />
                )}
              </div>
            </div>
            <div className="flex gap-2 text-center items-center">
              <p className="flex w-32 gap-2 text-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                  />
                </svg>
                Part<span className="text-red-500">*</span>
              </p>
              <div className=" min-w-40  flex ">
                {partData.length > 0 && (
                  <PartsPicker
                    divRef={divRef}
                    inputRef={inputRef}
                    parsedParts={parsedParts}
                    selected={selectedPart}
                    setSelected={setSelectedPart}
                    parts={partData}
                  />
                )}
              </div>
            </div>
            <div className="flex gap-2 mt-4 items-center">
              <p className=" flex gap-2  text-xs w-32">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                Icon<span className="text-red-600"> *</span>
              </p>

              <div className="cursor-pointer">
                <Popover ref={popoverref}>
                  <PopoverTrigger>
                    <Button
                      auto
                      light
                      className="bg-white"
                      onClick={() => setIsOpenIcon(!isOpenIcon)}
                      value={selectedIcon?.name}
                    >
                      {selectedIcon ? (
                        <svg
                          xmlns={selectedIcon.xmlns}
                          fill={selectedIcon.fill}
                          viewBox={selectedIcon.viewBox}
                          strokeWidth={selectedIcon.strokeWidth}
                          stroke={selectedIcon.stroke}
                          className={selectedIcon.className}
                          style={{ width: "24px", height: "24px" }}
                        >
                          <path d={selectedIcon.path?.d} />
                        </svg>
                      ) : (
                        <p>{iconData ? (
                          <svg
                          xmlns={iconData.xmlns}
                          fill={iconData.fill}
                          viewBox={iconData.viewBox}
                          strokeWidth={iconData.strokeWidth}
                          stroke={iconData.stroke}
                          className={iconData.className}
                          style={{ width: "24px", height: "24px" }}
                        >
                          <path d={iconData.path?.d} />
                        </svg>
                        ) : (
                          <p>Select Icon</p>
                        )}</p>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <div
                      aria-label="Icon selection"
                      onClick={() => setIsOpenIcon(false)}
                    >
                      <Icon
                        onSelectIcon={handleIconSelect}
                        basicIcons={basicIcons}
                        setIsOpen={setIsOpenIcon}
                        isOpen={isOpenIcon}
                      />
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
            <div className="flex gap-2 items-center">
          <p className="w-32 gap-2 text-xs text-center flex items-center">
            <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
                      />
                    </svg>
              Priority<span className=" text-red-600"> *</span>
            </p>
            <Dropdown variant="light" size="sm">
      <DropdownTrigger>
        <Button size="sm" variant="light" className="capitalize justify-start">
          {selected ? (
            <div className="flex items-center gap-2">
              {selectedData.icon}
              <p className="text-xs">{selected}</p>
            </div>
          ) : (
            <p className="text-xs text-start">Select Priority</p>
          )}
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Priority selection"
        variant="flat"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selectedPriority}
        onSelectionChange={setSelectedPriority}
      >
        {Object.keys(priorityData).map((priority) => (
          <DropdownItem
            className="flex items-center w-full gap-2"
            key={priority}
            onClick={() => setSelectedPriority(new Set([priority]))}
          >
            <div className="flex flex-row gap-2">
              {priorityData[priority].icon}
              <p className="w-14">{priority}</p>
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
          </div>
            <div className="flex gap-2 items-center">
              <p className="gap-2 w-32  text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 6h.008v.008H6V6Z"
                  />
                </svg>
                Tags
              </p>
              <Tags
                param={org}
                org={currentOrg}
                customTags={customTags}
                dropdownref={dropdownref}
                modalref={modalref}
                inpuRef={inputRef}
                divRef={divRef}
                tags={tags}
                setTags={setTags}
                tagsDetail={tagsDetail}
                setTagsDetail={setTagsDetail}
              />
            </div>

            <div className="flex justify-end mt-4">
              <Button
                size="sm"
                className={`text-white flex justify-end my-8 ${
                  sprintTitle === "" ||
                  descriptionContent === "" ||
                  descriptionContent === "<p></p>"
                    ? "bg-gray-300"
                    : "bg-[rgb(51,51,245)]"
                }`}
                disabled={
                  sprintTitle === "" ||
                  descriptionContent === "" ||
                  descriptionContent === "<p></p>"
                }
                onClick={type === "update" ? handleUpdate : handleCreateSprint}
              >
                {`${type === "update" ? "Update" : "Create"}`}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SprintDrawer;
