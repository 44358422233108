import { Button, Checkbox, Chip, DatePicker, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input } from "@nextui-org/react";
import React, { useState, useEffect, useRef } from "react";
import { UpdateData } from "../../controllers/strapiController";
import { parseDate, today, getLocalTimeZone, toCalendarDateTime } from "@internationalized/date";

function TaskList({ item, state, fetchTask, taskId, open, closed, issues, onDelete, onDateChange }) {
  const [taskName, setTaskName] = useState(item.name);
  const [issue, setIssue] = useState(new Set(item.issue));
  const [hover, setHover] = useState(false);
  const [deadline, setDeadline] = useState(item.deadline ? parseDate(item.deadline) : null); // Initialize with parsed date or null
  const [isEditable, setIsEditable] = useState(false);
  const [localTasks, setLocalTasks] = useState({ open, closed });
  const inputRef = useRef(null); // Reference to the input element

  useEffect(() => {
    setLocalTasks({ open, closed });
  }, [open, closed]);

  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditable]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsEditable(false);
      }
    }

    if (isEditable) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditable]);

  const updateTasks = async (updatedTasks) => {
    try {
      await UpdateData("tasks", taskId, { Tasks: updatedTasks });
      fetchTask();
    } catch (error) {
      console.error("Error updating tasks:", error);
    }
  };


  const handleDelete = async () => {
    await onDelete(item); // Call the passed down function
  };

  const handleCheck = async () => {
    let array = state === "open" ? localTasks.open : localTasks.closed;
    const index = array.findIndex(
      (obj) =>
        obj.name === item.name &&
        obj.issue.toString() === item.issue.toString() &&
        obj.stage === item.stage &&
        obj.deadline === item.deadline
    );
    if (index !== -1) {
      array.splice(index, 1);
    }
    const updatedItem = { ...item, stage: state === "open" ? "closed" : "open" };
    const updatedTasks = state === "open" ? [...array, ...localTasks.closed, updatedItem] : [...array, ...localTasks.open, updatedItem];
    await updateTasks(updatedTasks);
  };

  const handleDateChange = async (e) => {
    const newDeadline = `${e.year}-${e.month.toString().padStart(2, "0")}-${e.day.toString().padStart(2, "0")}`;
    await onDateChange(item, newDeadline, state); // Call the passed function
  };

  const handleUpdateIssues = async () => {
    let array = state === "open" ? localTasks.open : localTasks.closed;
    const index = array.findIndex(
      (obj) =>
        obj.name === item.name &&
        obj.issue.toString() === item.issue.toString() &&
        obj.stage === item.stage &&
        obj.deadline === item.deadline
    );
    if (index !== -1) {
      array.splice(index, 1);
    }
    const updatedItem = { ...item, issue: Array.from(issue) };
    const updatedTasks = state === "open" ? [updatedItem, ...array, ...localTasks.closed] : [updatedItem, ...array, ...localTasks.open];
    await updateTasks(updatedTasks);
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter" && taskName.trim() !== "") {
      let array = state === "open" ? localTasks.open : localTasks.closed;
      const index = array.findIndex(
        (obj) =>
          obj.name === item.name &&
          obj.issue.toString() === item.issue.toString() &&
          obj.stage === item.stage &&
          obj.deadline === item.deadline
      );
      if (index !== -1) {
        array.splice(index, 1);
      }
      const updatedItem = { ...item, name: taskName };
      const updatedTasks = state === "open" ? [updatedItem, ...array, ...localTasks.closed] : [updatedItem, ...array, ...localTasks.open];
      await updateTasks(updatedTasks);
    }
  };

  const checkIssue = async () => {
    if (Array.from(issue).length !== item.issue.length) {
      await handleUpdateIssues();
      return;
    } else {
      const sortedArray1 = Array.from(issue).slice().sort();
      const sortedArray2 = item.issue.slice().sort();
      for (let i = 0; i < sortedArray1.length; i++) {
        if (sortedArray1[i] !== sortedArray2[i]) {
          await handleUpdateIssues();
          return;
        }
      }
    }
  };

  const checkHover = async () => {
    await checkIssue();
    setHover(false);
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => checkHover()}
      className="w-full h-[70px] px-2 rounded-lg hover:bg-[#F4F4F5] flex justify-between items-center"
    >
      <div className="w-full flex items-center gap-2 pr-4">
        <div>
          <Checkbox
          size="sm"
            onChange={() => handleCheck()}
            isSelected={state === "closed"}
          ></Checkbox>
        </div>

        {isEditable ? (
          <Input
            ref={inputRef}
            className="text-sm rounded-lg w-full bg-transparent hover:bg-[#F4F4F5]"
            style={{
              fontSize: "12px",
              backgroundColor: "",
            }}
            classNames={{
              input: ["bg-transparent", "hover:bg-[#F4F4F5]"],
              innerWrapper: ["bg-transparent", "hover:bg-[#F4F4F5]"],
              inputWrapper: [
                "shadow-none",
                "border-none",
                // "border-divider",
                "rounded-lg",
                "bg-transparent",
                "dark:bg-transparent",
                "hover:bg-transparent",
                "dark:hover:bg-transparent",
                "group-data-[focus=true]:bg-transparent",
                "dark:group-data-[focus=true]:bg-transparent",
                "data-[hover=true]:bg-transparent",
              ],
            }}
            value={taskName}
            onKeyDown={handleKeyDown}
            onChange={(e) => setTaskName(e.target.value)}
          ></Input>
        ) : (
          <div className="w-full text-xs flex items-center gap-2">
            {taskName}
            {hover && (
              <Button
                size="sm"
                variant="light"
                isIconOnly
                onClick={() => setIsEditable(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                  />
                </svg>
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="flex items-center gap-4">
        {((item.issue.length !== 0 && !hover) || hover) && (
          <Dropdown onClose={() => checkIssue()}>
            <DropdownTrigger>
              <div className="flex items-center cursor-pointer p-2 text-xs font-light h-[40px] rounded-lg min-w-[200px] bg-transparent hover:bg-gray-200" size="sm" variant="light">
                {Array.from(issue).length === 0 ? (
                  "Add Issue"
                ) : Array.from(issue).length <= 3 ? (
                  <div className="flex gap-2 items-center">
                    {Array.from(issue).map((issueItem) => (
                      <Chip
                        key={issueItem}
                        variant="flat"
                        className=""
                        color="primary"
                        size="sm"
                      >
                        {issueItem}
                      </Chip>
                    ))}
                  </div>
                ) : (
                  <div className="flex gap-2 items-center">
                    {Array.from(issue).slice(0, 3).map((issueItem) => (
                      <Chip
                        key={issueItem}
                        variant="flat"
                        className=""
                        color="primary"
                        size="sm"
                      >
                        {issueItem}
                      </Chip>
                    ))}
                    +{Array.from(issue).length - 3}
                  </div>
                )}
              </div>
            </DropdownTrigger>
            <DropdownMenu
              className=" w-60 max-h-[300px] overflow-y-auto"
              selectionMode="multiple"
              closeOnSelect={false}
              selectedKeys={issue}
              onSelectionChange={setIssue}
              size="sm"
            >
              {issues.map((issueItem) => (
                <DropdownItem key={`Iss-${issueItem.payload.latest}`}>
                  <div className="flex flex-col gap-2">
                    <div className="flex w-full items-center gap-2">
                      <Chip
                        variant="flat"
                        className=""
                        size="sm"
                        color="primary"
                      >
                        ISS-{issueItem.payload.latest}
                      </Chip>
                      <p className="text-sm">{issueItem.payload.title.length > 15 ? `${issueItem.payload.title.slice(0, 15)}...` : issueItem.payload.title}</p>
                    </div>
                    <div className="w-full px-2 flex flex-wrap">
                      <p className=" text-xs font-light">{issueItem.payload.description.replace(/<[^>]*>/g, '').length > 25 ? `${issueItem.payload.description.replace(/<[^>]*>/g, '').slice(0, 25)}...` : issueItem.payload.description.replace(/<[^>]*>/g, '')}</p>
                    </div>
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}

        <div className="w-[150px]">
          {((item.deadline !== "" && !hover) || hover) && <DatePicker
            label="Deadline"
            variant="bordered"
            minValue={item.deadline !== "" ? null : today(getLocalTimeZone())}
            value={item.deadline === "" ? null : parseDate(item.deadline)}
            onChange={(e) => handleDateChange(e)}
            className="w-full"
          />}
        </div>

        <div className="w-[50px]">
          {hover && (
            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" color="danger" variant="light" isIconOnly>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </Button>
              </DropdownTrigger>
              <DropdownMenu size="sm">
                <DropdownItem onClick={() => handleDelete()}>
                  Delete Permanently
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
}

export default TaskList;