import React, { useState } from "react";
import { Snippet } from "@nextui-org/react";

const ColorPicker = ({ onColorSelect }) => {
  const [selectedColor, setSelectedColor] = useState({
    name: "Gray",
    hex: "#E5E5E5",
  });
  const basicColors = [
    { name: "COLD MORNING", hex: "#E5E5E5" },
    { name: "PASTEL BLUE", hex: "#A5BCFB" },
    { name: "PERMAFROST", hex: "#99E5F5" },
    { name: "FREEZY BREEZY", hex: "#99F0E4" },
    { name: "ULTRA MINT", hex: "#AAF0BD" },
    { name: "LEMON MERINGUE", hex: "#F5E399" },
    { name: "PEACH CRAYON", hex: "#FFCBA8" },
    { name: "BALLET SLIPPERS", hex: "#FBA3B1" },
    { name: "PRINCESS PERFUME", hex: "#FF82D6" },
    { name: "LILAC", hex: "#CEA3FA" },
    { name: "DARK SOULS", hex: "#A3A3A3" },
    { name: "SKINNY JEANS", hex: "#5985FF" },
    { name: "VAPORWAVE BLUE", hex: "#32D7F7" },
    { name: "FRESH TURQUOISE", hex: "#45E5D0" },
    { name: "WEIRD GREEN", hex: "#48F276" },
    { name: "SUNFLOWER ISLAND", hex: "#FFCC02" },
    { name: "PASTEL ORANGE", hex: "#FF944E" },
    { name: "FIERY ROSE", hex: "#FF5772" },
    { name: "LOVE RED", hex: "#FF4D6A" },
    { name: "DRUNKEN FLAMINGO", hex: "#FF59C8" },
    { name: "PURPLE HEDONIST", hex: "#B466FF" },
  ];

  const handleColorClick = (color) => {
    setSelectedColor(color);
    onColorSelect(color);
  };

  return (
    <div className="flex flex-wrap">
      {basicColors.map((color, index) => (
        <div
          key={index}
          className="w-6 h-6 cursor-pointer m-1 rounded-full flex items-center justify-center"
          style={{ backgroundColor: color.hex }}
          onClick={() => handleColorClick(color)}
        ></div>
      ))}
      <div className="mt-4 flex justify-end w-full">
        <Snippet symbol="" className="text-black ml-5 bg-white">
          {selectedColor.hex.toUpperCase()}
        </Snippet>
      </div>
    </div>
  );
};

export default ColorPicker;
