import { Button } from '@nextui-org/react'
import React from 'react'

function Inboxtabs({toggle,setToggle}) {
  return (
    <div className="w-full py-8 px-4 gap-4 flex justify-around">
        <Button
            startContent={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
          </svg>
          }
          onClick={() => setToggle("primary")}
          className={`w-full ${
            toggle === "primary"
              ? "bg-white border hover:bg-[rgb(238,238,241)] border-divider"
              : "bg-[rgb(238,238,241)]"
          }`}
        >
          <div className="flex items-center w-full text-start gap-2">
            <p className={`w-full text-start `}>Primary</p>
          </div>
        </Button>
        <Button
            startContent={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          </svg>
          }
          onClick={() => setToggle("guest")}
          className={`w-full ${
            toggle === "guest"
              ? "bg-white border hover:bg-[rgb(238,238,241)] border-divider"
              : "bg-[rgb(238,238,241)]"
          }`}
        >
          <div className="flex items-center w-full text-start gap-2">
            <p className={`w-full text-start `}>Guest</p>
          </div>
        </Button>
        <Button
            startContent={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
          </svg>
          }
          onClick={() => setToggle("spam")}
          className={`w-full ${
            toggle === "spam"
              ? "bg-white border hover:bg-[rgb(238,238,241)] border-divider"
              : "bg-[rgb(238,238,241)]"
          }`}
        >
          <div className="flex items-center w-full text-start gap-2">
            <p className={`w-full text-start `}>Spam</p>
          </div>
        </Button>
      </div>
  )
}

export default Inboxtabs
