import React, { useState, useEffect } from "react";
import GroupsNav from "./GroupsNav";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
} from "@nextui-org/react";
import { getAllData, getFilteredBySingleRelationSingleData, getOneData } from "../../controllers/strapiController";
import { useNavigate, useParams } from "react-router-dom";
import UserGroupModal from "./UserModal";
import Privileges from "./Privileges";
import { PermissionGroupSingleRole } from "../../utils/permission";
import GroupDetails from "./settings";
import { useSelector } from "react-redux";
import { selectOrgData, selectUserData } from "../../redux/actions";

const Groups = () => {
  const [nonMembers, setNonMembers] = useState([]);
  const { id } = useParams();
  const [update, setUpdate] = useState(true);
  const [selectedTab, setSelectedTab] = useState("users");
  const [isOpen, setIsOpen] = useState(false);
  const users = useSelector(selectUserData);
  const orgDetails = useSelector(selectOrgData)
  const [organizations, setOrganizations] = useState([]);
  const [group, setGroup] = useState(null);
  const [owner, setOwner] = useState(null);
  const navigate = useNavigate();
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [members, setMembers] = useState([]);
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const [userPerm, setUserPerm] = useState({
    name: "Role-14",
    class: "User Management",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false
      }
    ]
  })



  useEffect(() => {
    const fetchPerm = async () => {
      try {
        const resp = await PermissionGroupSingleRole(userDataId, orgDetails?.id, "Role-14", "org")
        if (resp !== false) {
          setUserPerm(resp);
        }

      }
      catch (error) {
      }
    }
    if (orgDetails?.id) {
      fetchPerm();
    }
  }, [orgDetails, userDataId])

  useEffect(() => {
    const fetchGroup = async () => {
      try {

        const resp = await getFilteredBySingleRelationSingleData("groups", "organization", orgDetails.id, "path", id);
        setGroup(resp.data[0]);
        const owner = users.find((item) => item.id === resp.data[0].attributes.owner.data.id);
        setOwner(owner);
        const memIds = resp.data[0].attributes.members.data.map((item) => { return item.id })
        const mem = users.filter((item) => memIds.includes(item.id));
        setMembers(mem);
      } catch (error) {
        console.error("Error fetching group:", error);
      }
    };
    if (id && orgDetails?.id) {
      fetchGroup();
    }
  }, [id, orgDetails, users]);

  // useEffect(() => {
  //   const fetchGroupMembers = async () => {
  //     try {
  //       if (group) {
  //         const ownerData = await getOneData("users", group.data.attributes.owner.data.id);
  //         setOwner(ownerData);

  //         const memberIds = group.data.attributes.members.data.map(member => member.id);
  //         const memberData = await Promise.all(memberIds.map(id => getOneData("users", id)));
  //         setMembers(memberData);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching group members:", error);
  //     }
  //   };

  //   if (group) {
  //     fetchGroupMembers();
  //   }
  // }, [group]);

  useEffect(() => {
    if (owner && members.length > 0 && users.length > 0) {
      const nonMemberList = users.filter(user => user.id !== owner.id && !members.some(member => member.id === user.id));
      setNonMembers(nonMemberList);
    }
  }, [owner, members, users]);
  return (
    <div className=" overflow-y-auto h-screen">
      <UserGroupModal isOpen={isOpen} isClose={() => setIsOpen(false)} users={nonMembers} groupId={group?.id} />
      <div className="mt-8 mx-8">
        {/* Name of the group */}
        <div className="flex items-center justify-between font-light">
          {group?.attributes?.Name}
        </div>

        {/* Description of the group */}
        <div className="flex items-center justify-between text-xs font-light mt-4">
          {group?.attributes?.Description}
        </div>
        {/* Owner of the group */}
        <div className="flex gap-2 items-center mt-4">
          <div className="items-center text-xs font-light text-gray-500">
            Owner
          </div>
          <Tooltip
            placement="bottom"
            size="sm"
            content="Group owner is the default assignee for new work items that are routed to group"
          >
            <lord-icon
              src="https://cdn.lordicon.com/yxczfiyc.json"
              style={{ width: "16px", height: "16px" }}
            ></lord-icon>
          </Tooltip>
          <div className="flex gap-2 ml-4 text-xs font-light text-gray-500 items-center">
            <Avatar
              showFallback
              name={owner?.username?.charAt(0).toUpperCase()}
              radius="sm"
              color={Colors[Number(owner?.id) % numColors]}
              size="sm"
              src={
                process.env.REACT_APP_STAGE === 'Dev'
                  ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${owner?.ProfilePicture?.formats?.small?.url}`
                  : `${owner?.ProfilePicture?.formats?.small?.url}`
              }
            />
            {owner?.FirstName ? owner?.FirstName : owner?.username} {owner?.LastName}
          </div>
        </div>
      </div>
      <div className="mt-8 w-full">
        <Divider />
      </div>
      {/* Users and Privileges */}
      <div className="mt-8 mx-8">
        <Tabs selectedKey={selectedTab} onSelectionChange={setSelectedTab} aria-label="Options" size="sm">
          <Tab key="users" title="Users">
            {userPerm.privileges[0].update && <div className="flex justify-end">
              <Button size="sm" onClick={() => setIsOpen(true)} className="text-white bg-[rgb(51,51,245)]">
                <div className="text-xs font-light">+ User</div>
              </Button>
            </div>}
            <div className="mt-4">
              <Table removeWrapper>
                <TableHeader>
                  <TableColumn>
                    <div className="items-center text-xs font-light text-gray-500">
                      Name
                    </div>
                  </TableColumn>

                  <TableColumn>
                    <div className="items-center text-xs font-light text-gray-500">
                      Email
                    </div>
                  </TableColumn>
                  <TableColumn>
                    <div className="items-center text-xs font-light text-gray-500">
                      Display Name
                    </div>
                  </TableColumn>
                </TableHeader>
                <TableBody>
                  {/* Onclick of the row same drawer opens */}
                  {members.map((member) => (
                    <TableRow key="1"
                      className=" border-b border-divider">
                      <TableCell>
                        <div className="items-center flex gap-4 text-xs font-light">
                          <Avatar
                            showFallback
                            name={member?.username?.charAt(0).toUpperCase()}
                            radius="sm"
                            color={Colors[Number(member?.id) % numColors]}
                            size="sm"
                            src={
                              process.env.REACT_APP_STAGE === 'Dev'
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${member?.ProfilePicture?.formats?.small?.url}`
                                : `${member?.ProfilePicture?.formats?.small?.url}`
                            }
                          />
                          {member?.FirstName} {member?.LastName}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="items-center text-xs font-light">
                          {member?.email}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="items-center text-xs font-light ">
                          {member?.username}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Tab>
          {userPerm.privileges[0].update &&

            <Tab key="privileges" title="Privileges">
              <Privileges type={group?.attributes?.Type} groupId={group?.id} setUpdate={setUpdate} update={update} orgPrivileges={group?.attributes?.org_privileges} productPrivileges={group?.attributes?.product_privileges} analyticsPrivileges={group?.attributes?.analytics_privileges} supportPrivileges={group?.attributes?.support_privileges} devPrivileges={group?.attributes?.dev_privileges} />
            </Tab>

          }
          {userPerm.privileges[0].update &&
            <Tab key="settings" title="Settings">
              <GroupDetails group={group} users={users} />

            </Tab>
          }
        </Tabs>
      </div>
    </div>
  );
};

export default Groups;
