import { Divider, Table, TableBody, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { deleteDataInbox, getAllInbox } from '../../controllers/vectorDB';
import { tableCell } from './tableCell';
import InboxFilter from './InboxFilter';
import InboxGroups from './InboxGroups';
import { useSelector } from 'react-redux';
import { selectResData } from '../../redux/actions';

function InboxTable({ toggle, handleDrawer, customTags }) {
  const res = useSelector(selectResData)
  const customizeList = ["Owner", "Tags", "Members"];
  const [threads, setThreads] = useState([]);
  const emailId = JSON.parse(sessionStorage.getItem("userData")).user.email;
  const [filteredThreads, setFilteredThreads] = useState([])
  const { org } = useParams();
  const [customizeItems, setCustomizeItems] = useState(new Set(["Tags"]));
  const [toggleOwner, setToggleOwner] = useState(false);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(new Set(["latest"]));
  const [selectedGroup, setSelectedGroup] = useState(new Set(["None"]));
  const [groupKeys, setGroupKeys] = useState(new Set([]));

  const coulmnDef = Array.from(customizeItems).map((name) => {
    return {
      id: name.toLowerCase(),
      label: name,
    };
  });
  const handleDelete = async (id) => {
    try {
      await deleteDataInbox(id, org);
      const arr = threads.filter((item) => Number(item.payload.id) !== Number(id));
      setThreads(arr);
      setFilteredThreads(arr);
    }
    catch (error) {
      console.log("Error", error)
    }
  }

  const customizeHeaderList = [
    {
      id: "customer",
      label: "Customer",
    },
    {
      id: "source",
      label: "Source",
    },
    {
      id: "stage",
      label: "Stage",
    },
    ...coulmnDef,
    {
      id: "delete",
      label: ""
    }
  ];
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const resp = await getAllInbox(org);
        setThreads(resp);
        setFilteredThreads(resp);
      }
      catch (error) {
        console.log("Error", error)
      }
    }
    if (org) {
      fetchAll();
    }
  }, [org]);
  useEffect(() => {
    if (threads.length > 0 && Array.from(selectedGroup)[0] !== "None") {
      let groups = [];
      const key = Array.from(selectedGroup)[0];
      const group_by = key === "Customer" ? "customerId" : key === "Owner" ? "assignedId" : key === "Stage" ? "stage" : key === "Members" ? "members" : "tags";
      threads.map((thread) => {
        if (group_by === "customerId" || group_by === "assignedId" || group_by === "stage") {
          groups.push(thread.payload[group_by]);
        }
        else {
          thread.payload[group_by].map((item) => {
            groups.push(item);
          })
        }
      });
      setGroupKeys(new Set(groups));
    }
  }, [threads, selectedGroup])
  useEffect(() => {
    if (threads.length > 0) {
      let filteredThreads = threads;
      filteredThreads = filteredThreads.filter((thread) => thread.payload.customerMail.toLowerCase().includes(search.toLowerCase()));

      if (toggleOwner) {
        filteredThreads = filteredThreads.filter((thread) => `${org}_${emailId}` === thread.payload.assignedId)
      }
      if (Array.from(sortOrder)[0] === "latest") {
        filteredThreads = filteredThreads.sort((a, b) => b.payload.updatedAt - a.payload.updatedAt);
      }
      if (Array.from(sortOrder)[0] === "oldest") {
        filteredThreads = filteredThreads.sort((a, b) => a.payload.updatedAt - b.payload.updatedAt);
      }
      if (Array.from(sortOrder)[0] === "high") {
        filteredThreads = filteredThreads.sort((a, b) => b.payload.tickets.length - a.payload.tickets.length);
      }
      if (Array.from(sortOrder)[0] === "low") {
        filteredThreads = filteredThreads.sort((a, b) => a.payload.tickets.length - b.payload.tickets.length);
      }
      setFilteredThreads(filteredThreads);
    }
  }, [search, toggleOwner, sortOrder, threads])
  return (
    <div>
      <div className='w-full'>
        <div className='w-full flex-1 px-4 overflow-y-auto'>
          <InboxFilter search={search} setSearch={setSearch} isCreated={toggleOwner} setIsCreated={setToggleOwner} sortOrder={sortOrder} setSortOrder={setSortOrder} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} setCustomizeItems={setCustomizeItems} customizeItems={customizeItems} customizeCompleteList={customizeList} />
        </div>
        <div className='w-full'>
          <Divider />
        </div>
        <div className='w-full flex-1 px-4 overflow-y-auto'>


          {Array.from(selectedGroup)[0] === "None" ?
            <Table
              removeWrapper
              className="mt-10"
            >
              <TableHeader columns={customizeHeaderList}>
                {(column) => (
                  <TableColumn key={column.id} className="text-xs">
                    {column.label}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody emptyContent={"Inbox is empty."}>
                {filteredThreads.map((thread, index) => (
                  <TableRow onClick={() => handleDrawer(thread.payload.id)} className=" cursor-pointer hover:bg-gray-100 text-xs  border-b border-divider"
                    key={index}>
                    {customizeHeaderList.map((item) => {
                      return tableCell(res, item.id, thread, customTags, handleDelete, org);
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table> :
            <InboxGroups customTags={customTags} customizeHeaderList={customizeHeaderList} handleDelete={handleDelete} handleDrawer={handleDrawer} res={res} keys={Array.from(groupKeys)} groupBy={Array.from(selectedGroup)[0]} filteredThreads={filteredThreads} />}
        </div>
      </div>

    </div >

  )
}

export default InboxTable
