import React from 'react'

function InsightsDrawer() {
  return (
    <div className='w-[40vw] p-4 shadow-lg overflow-y-auto absolute top-0 right-0 h-full bg-white z-50'>
      
    </div>
  )
}

export default InsightsDrawer
