import { LoginUserGrabJwt, NavigateSign } from "./loginController";


export const passwordlessCode = async (email) => {
    const mypayload = {
        "email": email
    }


    const response = await fetch(


        `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/auth/signinup/code`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                // Authorization: process.env.REACT_APP_5000_BEARER_TOKEN,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(mypayload)
        }
    )
    let data = await response.json();

    return data;
}




export const passwordlessSignupConsume = async (code) => {
    // 3 things are needed, user input code, deviceID, and preAuthSessionId
    let user_email;
    const deviceId = sessionStorage.getItem('deviceId');
    const preAuthSessionId = sessionStorage.getItem('preAuthSessionId')
    const mypayload = {
        userInputCode: code,
        deviceId: deviceId,
        preAuthSessionId: preAuthSessionId
    }
    const response = await fetch(


        `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/auth/signinup/code/consume`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                // Authorization: process.env.REACT_APP_5000_BEARER_TOKEN,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(mypayload)
        }
    )
    let data = await response.json();
    /*{
        "status": "OK",
        "createdNewRecipeUser": true,
        "user": {
            "id": "94f3aae6-2075-4776-bcf3-bb18a05d47de",
            "isPrimaryUser": false,
            "tenantIds": [
                "public"
            ],
            "emails": [
                "sreevatsankomundur@gmail.com"
            ],
            "phoneNumbers": [],
            "thirdParty": [],
            "loginMethods": [
                {
                    "recipeId": "passwordless",
                    "recipeUserId": "94f3aae6-2075-4776-bcf3-bb18a05d47de",
                    "tenantIds": [
                        "public"
                    ],
                    "email": "sreevatsankomundur@gmail.com",
                    "timeJoined": 1706594273277,
                    "verified": true
                }
            ],
            "timeJoined": 1706594273277
        }
    } */
    if (data.status === "OK") {
        user_email = data.user.emails[0];
        //Login to strapi
        const password = "JhNOK01mFsaoZh"
        const LoginResponse = await LoginUserGrabJwt(user_email, password);
    }

    return response;

}

export const passwordlessSigninConsume = async (code) => {
    let user_email;
    const deviceId = sessionStorage.getItem('deviceId');
    const preAuthSessionId = sessionStorage.getItem('preAuthSessionId')
    const mypayload = {
        userInputCode: code,
        deviceId: deviceId,
        preAuthSessionId: preAuthSessionId
    }
    const response = await fetch(


        `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/auth/signinup/code/consume`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                // Authorization: process.env.REACT_APP_5000_BEARER_TOKEN,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(mypayload)
        }
    )
    let data = await response.json();
    if (data.status === "OK") { 
        user_email = data.user.emails[0];
        const route = await NavigateSign(user_email);
        return route
    }


}

export const tenant_exist = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/tenant/`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: process.env.REACT_APP_5000_BEARER_TOKEN,
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
            }
        );

        if (response.ok) {
            // If the response status is OK (200), the tenant exists
            return true;
        } else if (response.status === 404) {
            // If the response status is 404, the tenant does not exist
            return false;
        } else {
            // Handle other error statuses if needed
            throw new Error(`Error: ${response.status}`);
        }
    } catch (error) {
        return false;
    }
}


export const GoogleAuth = async () => {
    const authorizationUrl = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/auth/authorisationurl?rid=thirdparty&thirdPartyId=google&redirectURIOnProviderDashboard=${process.env.REACT_APP_IP_ADDRESS}`;

    const response = await fetch(authorizationUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            // Add any additional headers if needed
            // Authorization: process.env.REACT_APP_5000_BEARER_TOKEN,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
}


export const getGoogleInfo = async (code) => {

    try {

        // Exchange the authorization code for an access token
        const tokenResponse = await fetch('https://oauth2.googleapis.com/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code,
                client_id: '938444172467-tta5gvj3032ve8hfq8vgn3df1rff8ah4.apps.googleusercontent.com',
                client_secret: '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',
                redirect_uri: 'http://localhost:3000/authenticate/google',
                grant_type: 'authorization_code',
            }),
        });

        if (!tokenResponse.ok) {
            throw new Error(`Token request failed with status ${tokenResponse.status}`);
        }

        const tokenData = await tokenResponse.json();

        if (tokenData.error) {
            throw new Error(`Token request error: ${tokenData.error.message}`);
        }

        const { access_token } = tokenData;

        if (!access_token) {
            throw new Error('Access token is undefined');
        }

        // Use the access token to fetch user details
        const userResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo', {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });

        const userDetails = await userResponse.json();
        return userDetails;
        // res.send('User details logged in the console.'); // You can customize the response as needed
    } catch (error) {
        // res.status(500).send('Error occurred. Check the server logs for details.');
    }
};
