import React from "react";
import { helix } from "ldrs";
function HelixLoader() {
  helix.register();
  return (
    <div>
      <l-helix size="50" speed="2.5"  color="#b63bce" ></l-helix>
    </div>
  );
}

export default HelixLoader;
