import { BreadcrumbItem, Breadcrumbs } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { getFilteredBySingleRelationSingleData } from '../../controllers/strapiController';
import { useNavigate, useParams } from 'react-router-dom';

function Breadcrumb() {
    const [currentOrg, setCurrentOrg] = useState(null);
    const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
    const navigate = useNavigate();
    const { org } = useParams();

    useEffect(() => {
        const fetchOrg = async () => {
            try {
                const resp = await getFilteredBySingleRelationSingleData("organizations", "users", userDataId, "Name", org);

                if (resp.data.length === 1) {
                    setCurrentOrg(resp.data[0]);
                } else {
                    navigate("/404");
                }
            } catch (error) {
                navigate("/404");
            }
        };

        if (org && userDataId) {
            fetchOrg();
        }
    }, [org, userDataId, navigate]);

    const handleNavigate = () => {
        navigate(`/${org}`);
    };

    return (
        <div className=' cursor-pointer'>
            <Breadcrumbs size="sm" isDisabled>
                <BreadcrumbItem onClick={handleNavigate} size="xs" >
                    {currentOrg?.attributes?.Tenant || 'Loading...'}
                </BreadcrumbItem>
                <BreadcrumbItem></BreadcrumbItem>
            </Breadcrumbs>
        </div>
    );
}

export default Breadcrumb;
