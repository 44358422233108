import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import {
  Avatar,
  AvatarGroup,
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import CustomerDrawer from "./CustomerDrawer";
import { useParams } from "react-router-dom";
import { getFilteredBySingleRelation } from "../../controllers/strapiController";
import { useSelector } from "react-redux";
import { selectOrgData, selectUserNames } from "../../redux/actions";
import HelixLoader from "../../components/Loader/HelixLoader";

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTicket, setSearchTicket] = useState("");
  const drawerRef = useRef();
  const customerRef = useRef();
  const [loader,setLoader] = useState(true);
  const [customTags, setCustomTags] = useState([]);
  const userNames = useSelector(selectUserNames);
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const tagsref = useRef();
  const modalref = useRef();
  const searchRef = useRef();
  const inputRef = useRef();
  const { org } = useParams();
  const [accounts,setAccounts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [updateDrawer, setUpdateDrawer] = useState(false);
  const orgData = useSelector(selectOrgData);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const filterCustomers = () => {
      if (!searchTicket) {
        setFilteredCustomers(customers);
      } else {
        const lowerCaseSearchTicket = searchTicket.toLowerCase();
        const filtered = customers.filter((customer) => {
          const name = customer.attributes.Name
            ? customer.attributes.Name.toLowerCase()
            : "";
          const email = customer.attributes.Email
            ? customer.attributes.Email.toLowerCase()
            : "";
          const phoneNumber = customer.attributes.PhoneNumber
            ? customer.attributes.PhoneNumber.toLowerCase()
            : "";

          return (
            name.includes(lowerCaseSearchTicket) ||
            email.includes(lowerCaseSearchTicket) ||
            phoneNumber.includes(lowerCaseSearchTicket)
          );
        });
        setFilteredCustomers(filtered);
      }
    };

    filterCustomers();
  }, [searchTicket, customers]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target))
      ) {
        toggleDrawer();
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        customerRef.current &&
        !customerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target))
      ) {
        setUpdateDrawer(false);
      }
    };
    if (updateDrawer) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [updateDrawer]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && searchTicket === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, searchTicket]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tags = await getFilteredBySingleRelation(
          "tags",
          "organization",
          orgData.id
        );
        const customers = await getFilteredBySingleRelation(
          "customers",
          "organization",
          orgData.id
        );
        const accounts = await getFilteredBySingleRelation(
          "accounts",
          "organization",
          orgData.id,["Website","Name"]
        );
        setCustomers(customers.data);
        setCustomTags(tags.data);
        setAccounts(accounts.data);
        setLoader(false);
      } catch (error) {}
    };
    if (orgData?.id) {
      fetchData();
    }
  }, [orgData]);
  if (loader) {
    return (
      <div className="flex flex-col items-center justify-center mt-64">
        <HelixLoader />
        
      </div>
    );
  }
  return (
    <div>
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <CustomerDrawer
            userNames={userNames}
            customTags={customTags}
            tenant={{ name: org, id: orgData?.id }}
            dropdownref={tagsref}
            modalref={modalref}
            toggleDrawer={toggleDrawer}
            type="create"
            accounts={accounts}
          />
        </div>
      )}
      {updateDrawer && (
        <div ref={customerRef}>
          <CustomerDrawer
            data={selectedCustomer}
            userNames={userNames}
            customTags={customTags}
            tenant={{ name: org, id: orgData?.id }}
            dropdownref={tagsref}
            modalref={modalref}
            toggleDrawer={() => setUpdateDrawer(false)}
            type="update"
            accounts={accounts}
          />
        </div>
      )}
      <div className="h-[130px] bg-white">
        <div className="flex mx-8 justify-between pt-8">
          <div className="flex text-center justify-center items-center">
            <Breadcrumb />
            <Breadcrumbs size="sm" isDisabled>
              <BreadcrumbItem>Customers</BreadcrumbItem>
            </Breadcrumbs>
          </div>

          <div className="flex items-center">
            <Button
              size="sm"
              className="mx-2 text-white bg-[rgb(51,51,245)]"
              onClick={toggleDrawer}
            >
              + Customer
            </Button>
          </div>
        </div>
        <div className="z-0 flex pb-4 px-8 border-b-1 mt-4 border-divider items-center justify-between">
          <div className="flex items-center gap-4">
            <div
              ref={searchRef}
              onClick={() => setIsSearchOpen(true)}
              className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${
                isSearchOpen
                  ? "pl-2 w-40 gap-2 justify-start"
                  : "w-8 justify-center"
              } rounded-full  h-8`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              {isSearchOpen && (
                <input
                  ref={inputRef}
                  value={searchTicket}
                  onChange={(e) => setSearchTicket(e.target.value)}
                  style={{ height: "20px", outline: "none" }}
                  className="w-full px-2 text-xs rounded-full"
                  placeholder="Search"
                />
              )}
            </div>
          </div>
        </div>

        {/* Table */}
        {filteredCustomers.length === 0 ? (
          <div className="flex justify-center items-center h-48">
            <p className="text-gray-500">No customers found...</p>
          </div>
        ) : (
          <Table className="pt-8 px-8" removeWrapper>
            <TableHeader>
              <TableColumn>Name</TableColumn>
              <TableColumn>Account</TableColumn>
              <TableColumn>Email</TableColumn>
              <TableColumn>Phone Number</TableColumn>
            </TableHeader>
            <TableBody>
              {filteredCustomers.map((customer) => {
                const colorIndex = customer && customer.id % numColors;
                return (
                  <TableRow
                    onClick={() => {setSelectedCustomer(customer); setUpdateDrawer(true)}}
                    key={customer.id}
                    className="cursor-pointer text-xs rounded-lg hover:bg-gray-100 border-b border-divider "
                  >
                    <TableCell>
                      <div className="text-xs">
                        <div className="flex gap-4 items-center">
                          <Avatar
                            size="sm"
                            radius="sm"
                            color={Colors[colorIndex]}
                            name={customer?.attributes?.Name.toUpperCase().charAt(0)}
                          />
                          <div className="text-sm">
                            {customer?.attributes?.Name}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                    <AvatarGroup max={3} size="sm">
                    {customer?.attributes?.accounts?.data.map((item) => (
                      <Avatar
                        style={{ height: "28px", width: "28px" }}
                        showFallback
                        name={item.attributes.Name.toUpperCase().charAt(0)}
                        color={Colors[Number(item.id) % numColors]}
                      />
                    ))}
                  </AvatarGroup>
                    </TableCell>
                    <TableCell>{customer?.attributes?.Email}</TableCell>
                    <TableCell>{customer?.attributes?.PhoneNumber}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
}

export default Customers;
