import React, { useEffect, useState, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  useReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import { Input } from "@nextui-org/react";
import Dagre from "@dagrejs/dagre";
import {  getFilteredBySingleRelation } from "../../../controllers/strapiController";
import MessageDrawer from "../MessageDrawer/MessageDrawer";
import { CustomNode } from "./customNode";
import CreateDrawer from "../../Components/CreateDrawer";
import Drawer from "../../../components/Drawer/drawer";
import Icon from "../../../components/Tag/Icon";
import { useParams } from "react-router-dom";
import SearchIcon from "../../../Images/Icons/Search";
import { RiVipDiamondLine } from "react-icons/ri";
import {  FaPlayCircle} from "react-icons/fa";
import { PermissionGroupSingleRole } from "../../../utils/permission";
import { useSelector } from "react-redux";
import { selectOrgData, selectResData, selectUserData } from "../../../redux/actions";

const dagreGraph = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges, direction = "LR") => {
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: node.width || 200,
      height: node.height || 50,
    });
  });
  edges.forEach((edge) => dagreGraph.setEdge(edge.source, edge.target));

  Dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.position = {
      x: nodeWithPosition.x - node.width / 2,
      y: nodeWithPosition.y - node.height / 2,
    };
  });

  return { nodes, edges };
};

const nodeTypes = {
  selectorNode: CustomNode,
};

function Flow() {
  const { org } = useParams();
  const [search, setSearch] = useState("");
  const [customTags, setCustomTags] = useState([]);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const currentOrg = useSelector(selectOrgData);
  const users = useSelector(selectUserData)
  const [createItem, setCreateItem] = useState();
  const [ticketItem, setTicketItem] = useState();
  const [messDrawer, setMessDrawer] = useState(false);
  const [messagePart, setMessagePart] = useState({});
  const res = useSelector(selectResData)
  const [partsData, setPartsData] = useState([]);
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [visibleParts, setVisibleParts] = useState([]);
  const [parsedParts, setParsedParts] = useState({});
  const [options, setOptions] = useState([]);
  const [userPermTick, setUserPermTick] = useState({
    name: "Role-11",
    class: "Tickets",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });
  const [userPermIss, setUserPermIss] = useState({
    name: "Role-2",
    class: "Issues",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });
  const [userPerm, setUserPerm] = useState({
    name: "Role-6",
    class: "Components",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });
  const toggleCreateDrawer = async (e) => {
    if (userPerm.privileges[0].create === false) {
      return;
    }
    setMessDrawer(false);
    setIsDrawerOpen(false);
    setIsCreateOpen(false);
    await setCreateItem(e);
    setIsCreateOpen(true);
  };

  const toggleTicketDrawer = async (e) => {
    setIsCreateOpen(false);
    setMessDrawer(false);
    setIsDrawerOpen(false);
    await setTicketItem(e);
    setIsDrawerOpen(true);
  };

  const toggleMessDrawer = async (e) => {
    setIsCreateOpen(false);
    setIsDrawerOpen(false);
    setMessDrawer(true);
    await setMessagePart(e);
    setMessDrawer(true);
  };
  const toggleVisibleParts = (prop) => {
    let parentid;
    if (prop.type === "Product") {
      parentid = String(prop.id);
    } else {
      parentid = `${prop.ParentPath}/${prop.id}`;
    }
    const arr = visibleParts.filter(
      (item) => String(item.attributes.ParentPath) === parentid
    );
    if (arr.length === 0) {
      const add = partsData.filter(
        (item) => String(item.attributes.ParentPath) === parentid
      );
      setVisibleParts((prev) => [...prev, ...add]);
    } else {
      let add = [];
      for (let i = 0; i < visibleParts.length; i++) {
        const splitArray = visibleParts[i].attributes.ParentPath.split("/");
        if (!splitArray.includes(String(prop.id))) {
          add.push(visibleParts[i]);
        }
      }
      setVisibleParts(add);
    }
  };
  const layoutDirection = "LR";
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const resp = await getFilteredBySingleRelation("tags","organization",currentOrg.id)
        setCustomTags(resp.data);
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
        throw error;
      }
    };
    if (currentOrg?.id) {
      getAllDataByIds();
    }
  }, [currentOrg]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const resp = await getFilteredBySingleRelation("parts","organization",currentOrg.id)
        setPartsData(resp.data);
        setVisibleParts(
          resp.data.filter((item) => item.attributes.Type === "Product")
        );
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
        throw error;
      }
    };
    if (currentOrg?.id) {
      getAllDataByIds();
    }
  }, [currentOrg]);


  useEffect(() => {
    if (visibleParts?.length > 0) {
      const newNodes = [];
      const newEdges = [];
      visibleParts.forEach((item) => {
        const node = {
          id: String(item.id),
          type: "selectorNode",
          draggable: false,
          style: { border: "0px", outline: "none" },
          sourcePosition: "right",
          targetPosition: "left",
          data: {
            permissions: {
              ticket: userPermTick?.privileges[0].create,
              issue: userPermIss?.privileges[0].create,
              product: userPerm?.privileges[0].create,
            },
            toggleChild: toggleVisibleParts,
            item: item,
            toggle: toggleMessDrawer,
            toggleCreate: toggleCreateDrawer,
            ticket: toggleTicketDrawer,
          },
          position: { x: 0, y: 0 },
          width: 200,
          height: 100,
        };
        newNodes.push(node);

        if (item?.attributes?.Type !== "Product") {
          const arr = item?.attributes?.ParentPath.split("/");
          const edge = {
            id: `e${arr[arr?.length - 1]}${item.id}`,
            source: arr[arr?.length - 1],
            target: String(item.id),
            animated: false,
          };
          newEdges.push(edge);
        }
      });

      const layoutedElements = getLayoutedElements(
        newNodes,
        newEdges,
        layoutDirection
      );
      setNodes(layoutedElements.nodes);
      setEdges(layoutedElements.edges);
      fitView();
    }
  }, [
    visibleParts,
    layoutDirection,
    fitView,
    userPerm,
    userPermIss,
    userPermTick,
  ]);
  useEffect(() => {
    if (partsData.length > 0) {
      const convertedParts = {};
      partsData?.forEach((item) => {
        convertedParts[item?.id] = {
          Name: item?.attributes?.Name,
          Type: item?.attributes?.Type,
          ParentPath: item?.attributes?.ParentPath,
          Owner: item?.attributes?.users_permissions_user?.data?.id,
        };
      });
      setParsedParts(convertedParts);
    }
  }, [partsData]);
  useEffect(() => {
    if (partsData.length > 0 && parsedParts) {
      const filteredParts = partsData.filter((item) =>
        (item?.attributes?.Type !== "Product"
          ? `${item?.attributes?.ParentPath?.split("/")
              ?.map((item) => parsedParts[Number(item)]?.Name)
              .join("/ ")}/ ${item?.attributes?.Name}`
          : `${item?.attributes?.Name}`
        )
          .toLowerCase()
          .includes(search.toLowerCase())
      );
      setOptions(filteredParts);
    }
  }, [search, partsData, parsedParts]);
  useEffect(() => {
    const fetchPerm = async () => {
      try {
        const resp = await PermissionGroupSingleRole(
          userDataId,
          currentOrg?.id,
          "Role-6",
          "pro"
        );
        const tick = await PermissionGroupSingleRole(
          userDataId,
          currentOrg?.id,
          "Role-11",
          "sup"
        );
        const iss = await PermissionGroupSingleRole(
          userDataId,
          currentOrg?.id,
          "Role-2",
          "dev"
        );

        if (resp !== false) {
          setUserPerm(resp);
        }
        if (iss !== false) {
          setUserPermIss(iss);
        }
        if (tick !== false) {
          setUserPermTick(tick);
        }
      } catch (error) {}
    };
    if (currentOrg?.id) {
      fetchPerm();
    }
  }, [currentOrg, userDataId]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, animated: false, style: { stroke: "#fff" } }, eds)
      ),
    []
  );

  return (
    <>
      {nodes && nodes.length > 0 && (
        <div className="flex flex-col justify-center gap-2 mx-2 mt-4">
          <Input
            placeholder="Search for a product part"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            startContent={
              <SearchIcon
                className={`text-xs pointer-events-none flex-shrink-0`}
              />
            }
          />
          {search !== "" && (
            <div className="bg-white shadow-lg p-2 rounded-lg flex flex-col items-center">
              {options.length > 0 ? (
                options?.map((part) => (
                  <div
                    onClick={() => toggleMessDrawer(part)}
                    className="w-full text-xs py-2 pl-8 cursor-pointer rounded-lg flex gap-2 items-center justify-start hover:bg-[#F4F4F5]"
                  >
                    <div>
                      {part?.attributes?.Type === "Product" ? (
                        <RiVipDiamondLine
                          className="rounded-md size-6 p-1.5"
                          style={{
                            color: "#006FEE",
                          }}
                        />
                      ) : part?.attributes?.Type === "Capability" ? (
                        <svg
                          style={{
                            color: "#7828C8",
                            backgroundColor: "#E4D4F4",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="rounded-md size-6 p-1.5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                          />
                        </svg>
                      ) : part?.attributes?.Type === "Runnable" ? (
                        <FaPlayCircle
                          style={{
                            color: "#F31260",
                            backgroundColor: "#F3126033",
                          }}
                          className="rounded-md size-6 p-1.5"
                        />
                      ) : part?.attributes?.Type === "Enhancement" ? (
                        <svg
                          style={{
                            color: "#C4841D",
                            backgroundColor: "#F5A52433",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="rounded-md size-6 p-1.5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 0 1 9 14.437V9.564Z"
                          />
                        </svg>
                      ) : part?.attributes?.Type === "Feature" ? (
                        <svg
                          style={{
                            color: "#12A150",
                            backgroundColor: "#17C96433",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="rounded-md size-6 p-1.5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                          />
                        </svg>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      <span>
                        {part?.attributes?.Type !== "Product"
                          ? `${part?.attributes?.ParentPath?.split("/")
                              ?.map((item) => parsedParts[Number(item)]?.Name)
                              .join("/ ")} / `
                          : ``}
                      </span>
                      <span className="text-[#9C27B0]">
                        {part?.attributes?.Name}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-xs">{`No part named "${search}"`}</p>
              )}
            </div>
          )}
        </div>
      )}
      {nodes.length === 0 && (
        <p className="flex flex-col mt-60 text-xs gap-2 justify-center items-center text-slate-400">
          <Icon />
          Your organization has no components
        </p>
      )}

      {messDrawer && (
        <div>
          <MessageDrawer
            partsData={partsData}
            users={users}
            tenant={org}
            data={messagePart}
            handleClose={() => setMessDrawer(false)}
          />
        </div>
      )}

      {isCreateOpen && (
        <div>
          <CreateDrawer
            customTags={customTags}
            org={org}
            parsedParts={parsedParts}
            parentPath={createItem.path}
            orgid={currentOrg}
            users={users}
            parts={partsData}
            type={createItem.type}
            parent={createItem.parent}
            handleclose={() => setIsCreateOpen(false)}
          />
        </div>
      )}

      {isDrawerOpen && (
        <div>
          <Drawer
            tenant={{ name: org, id: currentOrg.id }}
            users={users}
            res={res}
            query={ticketItem.query}
            part={ticketItem.part}
            toggleDrawer={() => setIsDrawerOpen(false)}
          />
        </div>
      )}

      <ReactFlow
        proOptions={{ hideAttribution: true }}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView
        onConnect={onConnect}
      />
    </>
  );
}

function FlowWithProvider() {

  return (
    <ReactFlowProvider>
      <Flow />
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;
