import React, { useEffect, useRef, useState } from 'react'
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { Avatar, BreadcrumbItem, Breadcrumbs, Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import Drawer from '../../components/Drawer/drawer';
import CustomerDrawer from './CustomerDrawer';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllData } from '../../controllers/strapiController';

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTicket, setSearchTicket] = useState("");
  const drawerRef = useRef();
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const tagsref = useRef();
  const modalref = useRef();
  const searchRef = useRef();
  const inputRef = useRef();
  const divRef = useRef();
  const [userIds, setUserIds] = useState([]);
  const sprintRef = useRef();
  const { org } = useParams();
  const [orgId, setOrgId] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const navigate = useNavigate();
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [loader, setLoader] = useState({
    fir: true,
    sec: true,
    thi: true,
    fou: true,
    fiv: true,
    six: true,
  });
  const collection_name = "customers";

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const handleOrg = async () => {
      const resp = await getAllData("organizations");
      setOrganizations(resp.data);

      if (resp.data.length === 0) {
        navigate("/404");
      }
    };
    if (org) {
      handleOrg();
    }
  }, [org]);

  useEffect(() => {
    getAllData(collection_name)
      .then((data) => {
        setCustomers(data.data);
        setFilteredCustomers(data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const filterCustomers = () => {
      if (!searchTicket) {
        setFilteredCustomers(customers);
      } else {
        const lowerCaseSearchTicket = searchTicket.toLowerCase();
        const filtered = customers.filter((customer) => {
          const name = customer.attributes.Name ? customer.attributes.Name.toLowerCase() : "";
          const email = customer.attributes.Email ? customer.attributes.Email.toLowerCase() : "";
          const phoneNumber = customer.attributes.PhoneNumber ? customer.attributes.PhoneNumber.toLowerCase() : "";

          return (
            name.includes(lowerCaseSearchTicket) ||
            email.includes(lowerCaseSearchTicket) ||
            phoneNumber.includes(lowerCaseSearchTicket)
          );
        });
        setFilteredCustomers(filtered);
      }
    };

    filterCustomers();
  }, [searchTicket, customers]);

  useEffect(() => {
    if (organizations.length > 0) {
      const arr = organizations.filter(
        (tenant) => tenant.attributes.Name === org
      );

      if (arr.length === 1) {
        const check = arr[0]?.attributes?.users?.data?.filter(
          (user) => String(userDataId) === String(user.id)
        );
        if (check.length > 0) {
          let userArray = [];
          let partArray = [];
          let tagsArray = [];
          for (let i = 0; i < arr[0].attributes.users.data.length; i++) {
            userArray.push(arr[0].attributes.users.data[i].id);
          }
          for (let i = 0; i < arr[0].attributes.parts.data.length; i++) {
            partArray.push(arr[0].attributes.parts.data[i].id);
          }
          for (let i = 0; i < arr[0].attributes.tags.data.length; i++) {
            tagsArray.push(arr[0].attributes.tags.data[i].id);
          }
          setTagIds(tagsArray);
          setUserIds(userArray);
          setOrgId(arr[0].id);
          setLoader((prevLoader) => ({ ...prevLoader, fir: false }));
        } else {
          navigate("*");
        }
      } else {
        navigate("*");
      }
    }
  }, [organizations]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!sprintRef.current || !sprintRef.current.contains(event.target))
      ) {
        toggleDrawer();
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);

  return (
    <div>
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <CustomerDrawer
            tenant={{ name: org, id: orgId?.id }}
            dropdownref={tagsref}
            modalref={modalref}
            toggleDrawer={toggleDrawer}
          />
        </div>
      )}
      <div className="h-[130px] bg-white">
        <div className="flex mx-8 justify-between pt-8">
          <div className="flex text-center justify-center items-center">
            <Breadcrumb />
            <Breadcrumbs size="sm" isDisabled>
              <BreadcrumbItem>Customers</BreadcrumbItem>
            </Breadcrumbs>
          </div>

          <div className="flex items-center">
            <Button
              size="sm"
              className="mx-2 text-white bg-[rgb(51,51,245)]"
              onClick={toggleDrawer}
            >
              + Customer
            </Button>
          </div>
        </div>
        <div className="z-0 flex pb-4 px-8 border-b-1 mt-4 border-divider items-center justify-between">
          <div className="flex items-center gap-4">
            <div
              ref={searchRef}
              onClick={() => setIsSearchOpen(true)}
              className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${isSearchOpen
                ? "pl-2 w-40 gap-2 justify-start"
                : "w-8 justify-center"
                } rounded-full  h-8`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              {isSearchOpen && (
                <input
                  ref={inputRef}
                  value={searchTicket}
                  onChange={(e) => setSearchTicket(e.target.value)}
                  style={{ height: "20px", outline: "none" }}
                  className="w-full px-2 text-xs rounded-full"
                  placeholder="Search"
                />
              )}
            </div>
          </div>
        </div>

        {/* Table */}
        {filteredCustomers.length === 0 ? (
          <div className="flex justify-center items-center h-48">
            <p className="text-gray-500">No customers found...</p>
          </div>
        ) : (
          <Table className="pt-8 px-8" removeWrapper>
            <TableHeader>
              <TableColumn>Name</TableColumn>
              <TableColumn>Email</TableColumn>
              <TableColumn>Phone Number</TableColumn>
            </TableHeader>
            <TableBody>
              {filteredCustomers.map((customer) => {
                const colorIndex = customer && customer.id % numColors;
                return (
                  <TableRow key={customer.id} className=' border-b border-divider'>


                    <TableCell>
                      <div className='text-xs'>
                        < div className='flex gap-4 items-center' >
                          <Avatar
                            size="sm"
                            radius="sm"
                            color={Colors[colorIndex]}
                            name={customer?.attributes?.Name.charAt(0)}
                          />
                          <div className='text-sm'>{customer?.attributes?.Name}</div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{customer?.attributes?.Email}</TableCell>
                    <TableCell>{customer?.attributes?.PhoneNumber}</TableCell>

                  </TableRow>
                );
              })}
            </TableBody >
          </Table >
        )}
      </div >
    </div >
  );
}

export default Customers;
