import React, { useState, useEffect, useRef } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownTrigger,
  Breadcrumbs,
  BreadcrumbItem,
  Button,
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  Chip,
  Switch,
  Pagination,
  DropdownSection,
  Divider,
} from "@nextui-org/react";
import GroupTickets from "../Tickets/ticketgroup";
import { PiCoffeeThin } from "react-icons/pi";
import { MdFormatListBulletedAdd } from "react-icons/md";
import Drawer from "../../components/Drawer/drawer";
import { deleteData, getDocument } from "../../controllers/vectorDB";
import Ticket from "../Tickets/ticketdetails";
import { renderCell } from "../../utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteSingleAttribute,
  getAllData,
  getOneData,
} from "../../controllers/strapiController";
import HelixLoader from "../../components/Loader/HelixLoader";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
const customizeCompleteList = [
  "Stage",
  "Owner",
  "Priority",
  "Target Closing Date",
  "Estimated days",
  "Tags",
  "Part",
];
const open = ["Queued"];
const progress = [
  "Work in progress",
  "Awaiting Product assist",
  "Awaiting Development",
  "In Development",
  "Awaiting Customer Response",
];
const closed = ["Accepted", "Resolved", "Cancelled"];

const TeamActivity = () => {
  const { org } = useParams();
  const drawerRef = useRef();
  const ticketRef = useRef();
  const tagsref = useRef();
  const modalref = useRef();
  const navigate = useNavigate();
  const [orgId, setOrgId] = useState();
  const [searchTicket, setSearchTicket] = useState("");
  const searchRef = useRef();
  const inputRef = useRef();
  const sprintRef = useRef();
  const [filterDropdown, setFilterDropdowm] = useState(new Set(["Work Type"]));
  const [ticketDataComplete, setTicketDataComplete] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [userTicket, setUserTicket] = useState([]);
  const [users, setUsers] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [res, setRes] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTicketOpen, setIsTicketOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [ticket, setTicket] = useState();
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [partIds, setPartIds] = useState([]);
  const [tagIds, setTagIds] = useState([]);

  const [filterPriority, setFilterPriority] = useState(new Set([]));

  const [customTags, setCustomTags] = useState([]);
  const [partData, setPartData] = useState([]);
  const [customizeItems, setCustomizeItems] = useState(new Set(["Stage"]));
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [isCreatedTickets, setIsCreatedTickets] = useState(false);

  const coulmnDef = Array.from(customizeItems).map((name) => {
    return {
      id: name.toLowerCase(),
      label: name,
    };
  });

  const customizeHeaderList = [
    {
      id: "items",
      label: "Items",
    },
    {
      id: "title",
      label: "Title",
    },
    {
      id: "assigned to",
      label: "Assigned to",
    },
    ...coulmnDef,
    {
      id: "delete",
      label: "",
    },
  ];
  const [selectedGroup, setSelectedGroup] = useState(new Set(["assigned to"]));
  const groupItems = [
    "None",
    "owner",
    "assigned to",
    "created by",
    "stage",
    "part",
    "priority",
    "channels",
    "group",
    "needs response",
    "severity",
    "SLA Name",
    "Source Channel",
    "Subtype",
    "CSA Rating",
    "tags",
  ];
  const rowsPerPage = 13;
  const [selectedStageKeys, setSelectedStageKeys] = useState(new Set([]));
  const pages = Math.ceil(ticketData.length / rowsPerPage);
  const handlePage = (page) => {
    setTableData([]);
    let data = [];
    if (page === pages) {
      for (let i = (page - 1) * rowsPerPage; i < ticketData.length; i++) {
        data.push(ticketData[i]);
      }
    } else {
      for (let i = (page - 1) * rowsPerPage; i < page * rowsPerPage; i++) {
        data.push(ticketData[i]);
      }
    }
    setTableData(data);
    setPage(page);
  };

  const groupByField = (items, groupBy) => {
    if (groupBy === "created by") {
      groupBy = "created_by";
    }
    const groups = items.reduce((groups, item) => {
      const key = item.payload[groupBy] || "unassigned";
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
      return groups;
    }, {});
    const entries = Object.entries(groups);
    entries.sort(([keyA], [keyB]) => {
      if (keyA === "unassigned") return 1;
      if (keyB === "unassigned") return -1;
      return 0;
    });
    const sortedGroups = Object.fromEntries(entries);
    return sortedGroups;
  };
  const handleDelete = async (item) => {
    try {
      const resp = await deleteData(item.id, org);
      const promises = item.images.map((id) =>
        DeleteSingleAttribute("upload/files", Number(id.id))
      );
      const results = await Promise.all(promises);
      window.location.reload();
    } catch (error) {
      window.location.reload();
    }
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const toggleTicket = (e) => {
    setTicket(e);
    setIsTicketOpen(!isTicketOpen);
  };
  const handleCloseTicket = () => {
    setIsTicketOpen(!isTicketOpen);
  };
  const handleClearFilters = () => {
    setFilterPriority(new Set([]));
    setSelectedStageKeys(new Set([]));
    setIsCreatedTickets(false);
    setSearchTicket("");
  };
  const handleStage = (e) => {
    let items = Array.from(selectedStageKeys);
    if (e.every((element) => items.includes(element))) {
      items = items.filter((element) => !e.includes(element));
    } else {
      items = items.concat(e);
    }

    setSelectedStageKeys(new Set(items));
  };
  const handleDeleteKey = (item) => {
    let updatedKeys = new Set(selectedStageKeys);
    updatedKeys.delete(item);
    setSelectedStageKeys(updatedKeys);
  };
  useEffect(() => {
    const handleOrg = async () => {
      const resp = await getAllData("organizations");
      setOrganizations(resp.data);
      if (resp.data.length === 0) {
        navigate("/404");
      }
    };
    if (org) {
      handleOrg();
    }
  }, [org]);
  useEffect(() => {
    if (organizations.length > 0) {
      const arr = organizations.filter(
        (tenant) => tenant.attributes.Name === org
      );
      if (arr.length === 1) {
        const check = arr[0]?.attributes?.users?.data?.filter(
          (user) => String(userDataId) === String(user.id)
        );
        if (check.length > 0) {
          let userArray = [];
          let partArray = [];
          let tagsArray = [];
          for (let i = 0; i < arr[0].attributes.users.data.length; i++) {
            userArray.push(arr[0].attributes.users.data[i].id);
          }
          for (let i = 0; i < arr[0].attributes.parts.data.length; i++) {
            partArray.push(arr[0].attributes.parts.data[i].id);
          }
          for (let i = 0; i < arr[0].attributes.tags.data.length; i++) {
            tagsArray.push(arr[0].attributes.tags.data[i].id);
          }
          setTagIds(tagsArray);
          setPartIds(partArray);
          setUserIds(userArray);
          setOrgId(arr[0].id);
        } else {
          navigate("*");
        }
      } else {
        navigate("*");
      }
    }
  }, [organizations]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const promises = userIds.map((id) => getOneData("users", id));
        const results = await Promise.all(promises);
        setUsers(results);
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
        throw error;
      }
    };
    if (userIds.length > 0) {
      getAllDataByIds();
    }
  }, [userIds]);
  useEffect(() => {
    const convertedUsers = {};
    users.forEach((user) => {
      convertedUsers[user.id] = {
        UserName: user.username,
        FirstName: user.FirstName,
        LastName: user.LastName,
        ProfilePicture: user.ProfilePicture?.formats?.small?.url || "",
      };
    });
    setRes(convertedUsers);
  }, [users]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const promises = partIds.map((id) => getOneData("parts", id));
        const results = await Promise.all(promises);
        let partarray = [];
        for (let i = 0; i < results.length; i++) {
          partarray.push(results[i].data);
        }
        setPartData(partarray);
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
        throw error;
      }
    };
    if (partIds.length > 0) {
      getAllDataByIds();
    }
  }, [partIds]);
  const [parsedParts, setParsedParts] = useState({});
  useEffect(() => {
    if (partData.length > 0) {
      const convertedParts = {};
      partData?.forEach((item) => {
        convertedParts[item?.id] = {
          Name: item?.attributes?.Name,
          Type: item?.attributes?.Type,
          ParentPath: item?.attributes?.ParentPath,
          Owner: item?.attributes?.users_permissions_user?.data?.id,
        };
      });
      setParsedParts(convertedParts);
    }
  }, [partData]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const promises = tagIds.map((id) => getOneData("tags", id));
        const results = await Promise.all(promises);
        let partarray = [];
        for (let i = 0; i < results.length; i++) {
          partarray.push(results[i].data);
        }
        setCustomTags(partarray);
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
        throw error;
      }
    };
    if (tagIds.length > 0) {
      getAllDataByIds();
    }
  }, [tagIds]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!sprintRef.current || !sprintRef.current.contains(event.target))
      ) {
        toggleDrawer();
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && searchTicket === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, searchTicket]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ticketRef.current &&
        !ticketRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target))
      ) {
        setIsTicketOpen(!isTicketOpen);
      }
    };
    if (isTicketOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTicketOpen]);

  useEffect(() => {
    setLoader(true);
    async function fetchDocument() {
      try {
        const tickData = await getDocument("type", "ticket", org);
        if (tickData) {
          setTicketDataComplete(
            tickData.sort((a, b) => a.payload.latest - b.payload.latest)
          );
          setTicketData(
            tickData.sort((a, b) => a.payload.latest - b.payload.latest)
          );
          setLoader(false);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    }
    if (org) {
      fetchDocument();
    }
  }, [org]);
  useEffect(() => {
    if (ticketDataComplete.length > 0) {
      const filteredTickets = ticketDataComplete.filter(
        (ticket) => Number(ticket.payload.owner) === userDataId
      );
      setUserTicket(filteredTickets);
    }
  }, [ticketDataComplete]);
  useEffect(() => {
    let filteredTickets = ticketDataComplete;
    if (Array.from(selectedStageKeys).length !== 0) {
      filteredTickets = ticketDataComplete.filter((ticket) =>
        Array.from(selectedStageKeys).includes(ticket.payload.stage)
      );
    }
    if (isCreatedTickets) {
      filteredTickets = filteredTickets.filter((item) =>
        userTicket.includes(item)
      );
    }
    if (searchTicket !== "") {
      filteredTickets = filteredTickets.filter((item) =>
        item.payload.title.toLowerCase().includes(searchTicket.toLowerCase())
      );
    }
    if (Array.from(filterPriority).length > 0) {
      filteredTickets = filteredTickets.filter((item) =>
        Array.from(filterPriority).includes(item.payload.priority)
      );
    }
    setTicketData(filteredTickets);
  }, [selectedStageKeys, isCreatedTickets, searchTicket, filterPriority]);

  useEffect(() => {
    const handlePageChange = () => {
      setTableData([]);
      let data = [];
      if (page === pages) {
        for (let i = (page - 1) * rowsPerPage; i < ticketData.length; i++) {
          data.push(ticketData[i]);
        }
      } else {
        for (let i = (page - 1) * rowsPerPage; i < page * rowsPerPage; i++) {
          data.push(ticketData[i]);
        }
      }
      setTableData(data);
    };
    if (ticketData.length > 0) {
      handlePageChange();
    }
  }, [ticketData]);
  return (
    <div className="h-[100%] flex">
      {isTicketOpen && (
        <div ref={ticketRef}>
          <Ticket
            users={users}
            tenant={{ name: org, id: orgId }}
            pos="ticket"
            dropdownref={tagsref}
            ticket={ticket}
            res={res}
            handleCloseTicket={handleCloseTicket}
            modalref={modalref}
          />
        </div>
      )}
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <Drawer
            inputRef={sprintRef}
            users={users}
            tenant={{ name: org, id: orgId }}
            res={res}
            dropdownref={tagsref}
            query="ticket"
            toggleDrawer={toggleDrawer}
            modalref={modalref}
          />
        </div>
      )}
      <div className="relative flex-grow bg-white">
        <div className="top-0 h-[130px] z-10 sticky bg-white">
          <div className="flex mx-8 justify-between pt-8">
            <div className=" flex text-center justify-center items-center">
              <Breadcrumb />
              <Breadcrumbs size="sm" isDisabled>
                <BreadcrumbItem>Team Activity</BreadcrumbItem>
              </Breadcrumbs>
            </div>
            <div className="flex items-center">
              <Button
                className="mx-2 border border-divider"
                size="sm"
                color="default"
                variant="light"
                startContent={<MdFormatListBulletedAdd />}
              >
                Save as
              </Button>
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    size="sm"
                    isIconOnly
                    color="default"
                    variant="light"
                    className="mx-2 border border-divider"
                  >
                    <svg
                      style={{ width: "15px", height: "15px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                      />
                    </svg>
                  </Button>
                </DropdownTrigger>
                <DropdownMenu className="w-[250px]">
                  <DropdownSection showDivider>
                    <DropdownItem
                      isReadOnly
                      startContent={
                        <div className=" flex justify-center items-center p-1 rounded-md bg-purple-100">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#AC70FF"
                            className="size-6"
                            style={{ width: "17px", height: "17px" }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                            />
                          </svg>
                        </div>
                      }
                      endContent={
                        <Switch size="sm" aria-label="Automatic updates" />
                      }
                    >
                      <div className="flex">
                        <p className=" font-semibold text-xs">Smart Cluster</p>
                      </div>
                    </DropdownItem>
                  </DropdownSection>
                  <DropdownSection title="Export view">
                    <DropdownItem
                      startContent={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#6E9CFD"
                          className="size-6"
                          style={{ width: "17px", height: "17px" }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                      }
                    >
                      <p className="text-xs"> Export as CSV</p>
                    </DropdownItem>
                    <DropdownItem
                      startContent={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#6E9CFD"
                          className="size-6"
                          style={{ width: "17px", height: "17px" }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                      }
                    >
                      <p className="text-xs">Export as JSON</p>
                    </DropdownItem>
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>

              <Button
                size="sm"
                className="mx-2 text-white bg-[rgb(51,51,245)]"
                onClick={toggleDrawer}
              >
                + Ticket
              </Button>
            </div>
          </div>
          <div className="z-0 flex pb-4 px-8 border-b-1 border-divider mt-4 items-center justify-between">
            <div className="flex flex-wrap items-center gap-4">
              <div
                ref={searchRef}
                onClick={() => setIsSearchOpen(true)}
                className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${isSearchOpen
                    ? "pl-2 w-40 gap-1 justify-start"
                    : "w-8 justify-center"
                  } rounded-full  h-8 `}
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
                {isSearchOpen && (
                  <input
                    ref={inputRef}
                    value={searchTicket}
                    onChange={(e) => setSearchTicket(e.target.value)}
                    style={{ height: "20px", outline: "none" }}
                    className="w-full px-2 text-xs rounded-full"
                    placeholder="Search"
                  />
                )}
              </div>

              {Array.from(filterDropdown).includes("Work Type") && (
                <div className="flex border rounded-sm  ">
                  <div className=" flex items-center">
                    <h2 className="text-xs ml-2 mr-2">Type</h2>
                    <div className="border-l border-gray-300 h-full " />
                  </div>

                  <div className="  rounded-r-md text-xs">
                    <Button
                      size="sm"
                      radius="none"
                      color="warning"
                      variant="light"
                    >
                      Tickets
                    </Button>
                  </div>
                </div>
              )}
              {Array.from(filterDropdown).includes("Priority") && (
                <div className="flex border rounded-sm">
                  <div className="flex items-center">
                    <h2 className="text-xs ml-2 mr-2">Priority</h2>
                    <div className="border-l border-gray-300 h-full" />
                  </div>
                  <div className="rounded-r-md text-xs">
                    <Dropdown>
                      <DropdownTrigger>
                        <Button size="sm" radius="none" variant="light">
                          {Array.from(filterPriority).length === 0
                            ? "Add"
                            : Array.from(filterPriority)[0]}{" "}
                          {filterPriority.size - 1 <= 0
                            ? ""
                            : `+${filterPriority.size - 1}`}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        variant="flat"
                        closeOnSelect={false}
                        selectionMode="multiple"
                        selectedKeys={Array.from(filterPriority)}
                        onSelectionChange={(keys) => {
                          setFilterPriority(new Set(keys));
                        }}
                      >
                        <DropdownItem key="P1">P1</DropdownItem>
                        <DropdownItem key="P2">P2</DropdownItem>
                        <DropdownItem key="P3">P3</DropdownItem>
                        <DropdownItem key="P4">P4</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              )}
              {Array.from(filterDropdown).includes("Stage") && (
                <div className="flex border rounded-sm  ">
                  <div className=" flex items-center">
                    <h2 className="text-xs ml-2 mr-2">Stage</h2>
                    <div className="border-l border-gray-300 h-full " />
                  </div>
                  <div className="  rounded-r-md text-xs">
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          className=" capitalize"
                          size="sm"
                          radius="none"
                          variant="light"
                        >
                          {Array.from(selectedStageKeys).length === 0
                            ? "Add"
                            : Array.from(selectedStageKeys)[0]}{" "}
                          {selectedStageKeys.size - 1 <= 0
                            ? ""
                            : `+${selectedStageKeys.size - 1}`}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        className="h-80 w-[300px] overflow-y-auto"
                        variant="flat"
                        closeOnSelect={false}
                        selectionMode="multiple"
                        selectedKeys={selectedStageKeys}
                        onSelectionChange={setSelectedStageKeys}
                      >
                        {selectedStageKeys.size > 0 && (
                          <DropdownItem
                            isReadOnly
                            className="cursor-default border"
                          >
                            <div className=" h-auto w-full p-2 flex flex-wrap gap-2 overflow-y-auto">
                              {Array.from(selectedStageKeys).map((item) => (
                                <Chip
                                  variant="bordered"
                                  size="sm"
                                  key={item}
                                  onClick={() => handleDeleteKey(item)}
                                  className="cursor-pointer text-gray-600 gap-2 border border-divider"
                                  endContent={
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="size-4"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18 18 6M6 6l12 12"
                                      />
                                    </svg>
                                  }
                                >
                                  {item}
                                </Chip>
                              ))}
                            </div>
                          </DropdownItem>
                        )}
                        <DropdownSection
                          showDivider
                          title={
                            <Button
                              onClick={() => handleStage(open)}
                              className="w-full justify-start"
                              size="sm"
                              variant="light"
                            >
                              Open
                            </Button>
                          }
                        >
                          <DropdownItem key="Queued">Queued</DropdownItem>
                        </DropdownSection>
                        <DropdownSection
                          showDivider
                          title={
                            <Button
                              onClick={() => handleStage(progress)}
                              className="w-full justify-start"
                              size="sm"
                              variant="light"
                            >
                              In progress
                            </Button>
                          }
                        >
                          <DropdownItem key="Work in progress">
                            Work in progress
                          </DropdownItem>
                          <DropdownItem key="Awaiting Product assist">
                            Awaiting Product assist
                          </DropdownItem>
                          <DropdownItem key="Awaiting Development">
                            Awaiting Development
                          </DropdownItem>
                          <DropdownItem key="In Development">
                            In Development
                          </DropdownItem>
                          <DropdownItem key="Awaiting Customer Response">
                            Awaiting Customer Response
                          </DropdownItem>
                        </DropdownSection>
                        <DropdownSection
                          title={
                            <Button
                              onClick={() => handleStage(closed)}
                              className="w-full justify-start"
                              size="sm"
                              variant="light"
                            >
                              Closed
                            </Button>
                          }
                        >
                          <DropdownItem key="Accepted">Accepted</DropdownItem>
                          <DropdownItem key="Resolved">Resolved</DropdownItem>
                          <DropdownItem key="Cancelled">Cancelled</DropdownItem>
                        </DropdownSection>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              )}
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className=" border border-divider"
                    isIconOnly
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Multiple selection"
                  variant="flat"
                  closeOnSelect={false}
                  selectionMode="multiple"
                  disallowEmptySelection={false}
                  selectedKeys={filterDropdown}
                  onSelectionChange={setFilterDropdowm}
                >
                  <DropdownItem key="Work Type">Work Type</DropdownItem>
                  <DropdownItem key="Stage">Stage</DropdownItem>
                  <DropdownItem key="Priority">Priority</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Button
                onClick={() => handleClearFilters()}
                size="sm"
                variant="light"
              >
                Clear
              </Button>
            </div>

            <div className="flex gap-2 items-center">
              <Dropdown>
                <DropdownTrigger>
                  <Button size="sm" variant="light">
                    Customize
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Multiple selection"
                  variant="flat"
                  closeOnSelect={false}
                  selectionMode="multiple"
                  disallowEmptySelection={false}
                  selectedKeys={customizeItems}
                  onSelectionChange={setCustomizeItems}
                >
                  {customizeCompleteList.map((name) => (
                    <DropdownItem key={name}>{name}</DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
        {loader ? (
          <div className="flex flex-col items-center justify-center mt-52">
            <HelixLoader />
            <p>Loading....</p>
          </div>
        ) : ticketData.length === 0 ? (
          <div className="flex mt-60 flex-col w-full items-center justify-center">
            <PiCoffeeThin
              style={{
                width: "50px",
                height: "50px",
                color: "#6B7280",
                opacity: "90%",
              }}
            />
            <p className="text-xs text-opacity-90 text-gray-500">
              No Tickets. Time for coffee.
            </p>
          </div>
        ) : (
          <div className="h-full pt-[130px] overflow-y-auto w-full absolute top-0">
            {Array.from(selectedGroup)[0] === "Owner" && (
              <Table
                removeWrapper
                radius="none"
                className="mt-4 px-8 text-xs"
                color="default"
                aria-label="Custom Table"
              >
                <TableHeader columns={customizeHeaderList}>
                  {(column) => (
                    <TableColumn key={column.id} className="text-xs">
                      {column.label}
                    </TableColumn>
                  )}
                </TableHeader>
                <TableBody emptyContent={"No tickets. Time for coffee."}>
                  {tableData.map((row, ind) => (
                    <TableRow
                      className=" cursor-pointer hover:bg-gray-100 text-xs  border-b border-divider"
                      onClick={() => toggleTicket(row)}
                      key={(page - 1) * rowsPerPage + ind}
                    >
                      {customizeHeaderList.map((item) => {
                        return renderCell(
                          row,
                          item.id,
                          res,
                          parsedParts,
                          handleDelete,
                          customTags
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}{" "}
            {Array.from(selectedGroup)[0] !== "Owner" && (
              <GroupTickets
                handleDelete={handleDelete}
                customTags={customTags}
                parsedParts={parsedParts}
                customizeHeaderList={customizeHeaderList}
                tickets={groupByField(ticketData, Array.from(selectedGroup)[0])}
                orderby={Array.from(selectedGroup)[0]}
                res={res}
                toggleTicket={toggleTicket}
              />
            )}
          </div>
        )}

        {ticketData.length >= rowsPerPage &&
          Array.from(selectedGroup)[0] === "Owner" && (
            <div
              className={` flex my-8 text-xs w-full justify-center ${tableData.length < 14 ? "absolute bottom-0" : " pb-8"
                }`}
            >
              <Pagination
                isCompact
                showControls
                showShadow
                color="danger"
                page={page}
                total={pages}
                onChange={(page) => handlePage(page)}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default TeamActivity;
