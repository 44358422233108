import {
  Accordion,
  AccordionItem,
  Avatar,
  Chip,
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React from "react";
import { useParams } from "react-router-dom";
import { tableCell } from "./tableCell";

function InboxGroups({
  groupBy,
  keys,
  filteredThreads,
  res,
  customizeHeaderList,
  customTags,
  handleDelete,
  handleDrawer,
}) {
  const { org } = useParams();
  const Colors = ["secondary", "success", "warning", "danger", "primary"];

  const numColors = Colors.length;
  const renderHeading = (item, groupBy) => {
    if (item === null) return null;

    const prefixRemovedItem = removePrefix(item, `${org}_`);

    if (
      groupBy === "Owner" ||
      groupBy === "Customer" ||
      groupBy === "Members"
    ) {
      return (
        <div className="flex gap-2 items-center" key={item}>
          <Avatar
            color={Colors[prefixRemovedItem.length % numColors]}
            size="sm"
            radius="sm"
            src={res[prefixRemovedItem]?.ProfilePicture ?? ""}
            showFallback
            name={
              typeof prefixRemovedItem === "string"
                ? prefixRemovedItem?.toUpperCase()?.charAt(0) ?? ""
                : ""
            }
          />
          <p>
            {res[prefixRemovedItem]
              ? `${res[prefixRemovedItem].FirstName ?? ""} ${res[prefixRemovedItem].LastName ?? ""
              }`
              : prefixRemovedItem}
          </p>
        </div>
      );
    }

    if (groupBy === "Stage") {
      return <p key={item}>{item}</p>;
    }

    if (groupBy === "Tags") {
      const tag = customTags.find((it) => Number(it.id) === Number(item));
      if (tag) {
        return (
          <Chip
            key={item}
            variant="bordered"
            size="sm"
            className="border border-divider gap-2 text-xs"
            startContent={
              <div
                style={{ backgroundColor: tag.attributes.Color }}
                className="w-2 h-2 rounded-full"
              ></div>
            }
          >
            {tag.attributes.Name}
          </Chip>
        );
      }
    }

    return null;
  };
  function removePrefix(fullString, prefix) {
    if (fullString === null) {
      return "";
    }
    if (typeof fullString === "string" && fullString.startsWith(prefix)) {
      return fullString.substring(prefix.length);
    }
    return fullString; // return the full string if the prefix doesn't match
  }
  return (
    <div className=" h-full flex">
      <div className=" h-full flex flex-col pt-4 gap-4 w-[400px] px-2 border-r border-divider">
        {keys.length>0 ? keys.map((item) => renderHeading(item, groupBy)) : <div className=" text-sm text-gray-400">No coversation present</div>}
      </div>
      <div className=" h-full flex flex-col gap-4 w-full px-2">
        {keys.map((item) => {
          if (item !== null) {
            return (
              <Accordion>
                <AccordionItem title={renderHeading(item, groupBy)}>
                  <Table removeWrapper className="">
                    <TableHeader columns={customizeHeaderList}>
                      {(column) => (
                        <TableColumn key={column.id} className="text-xs">
                          {column.label}
                        </TableColumn>
                      )}
                    </TableHeader>
                    <TableBody emptyContent={"Inbox is empty."}>
                      {filteredThreads
                        .filter((itemThread) => {
                          if (
                            groupBy === "Owner" ||
                            groupBy === "Customer" ||
                            groupBy === "Stage"
                          ) {
                            const payloadKey =
                              groupBy === "Owner"
                                ? "assignedId"
                                : groupBy === "Customer"
                                  ? "customerId"
                                  : "stage";
                            if (itemThread.payload[payloadKey] === item) {
                              return true;
                            }
                          } else {
                            const payloadKey =
                              groupBy === "Tags" ? "tags" : "members";
                            if (itemThread.payload[payloadKey].includes(item)) {
                              return true;
                            }
                          }
                        })
                        .map((thread, index) => (
                          <TableRow
                            onClick={() => handleDrawer(thread.payload.id)}
                            className=" cursor-pointer hover:bg-gray-100 text-xs  border-b border-divider"
                            key={index}
                          >
                            {customizeHeaderList.map((it) => {
                              return tableCell(
                                res,
                                it.id,
                                thread,
                                customTags,
                                handleDelete,
                                org
                              );
                            })}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </AccordionItem>
              </Accordion>
            );
          }
        })}
      </div>
    </div>
  );
}

export default InboxGroups;
