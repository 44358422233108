import React, { useEffect, useState, useRef } from "react";
import {
  NavLink,
  Outlet,
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { IoIosNotificationsOutline } from "react-icons/io";
import { CiUser, CiDark, CiLight, CiSettings } from "react-icons/ci";
import { MdAutorenew } from "react-icons/md";
import Sprint from "./Sprint";
import {
  Input,
  Button,
  Divider,
  Avatar,
  Tabs,
  Tab,
  Kbd,
  Modal,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalBody,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@nextui-org/react";
import Drawer from "../Drawer/drawer";
import {
  CreateData,
  UpdateOneUserData,
  getAllData,
  getFilteredBySingleRelation,
  getFilteredBySingleRelationSingleData,
  getFilteredByTwoRelation,
  getOneData,
} from "../../controllers/strapiController";
import Org from "./Org";
import Modals from "./Modal";
import InviteIcon from "../../Images/Icons/InviteIcon";
import InviteModal from "../../pages/Invitations/modal";
import { IoLogOutOutline } from "react-icons/io5";
import { PermissionGroup } from "../../utils/permission";
import { UserPermissions } from "../../utils/default";
import { useDispatch, useSelector } from "react-redux";
import {
  orgInfo,
  selectOrgData,
  selectResData,
  selectUserData,
  setRes,
  setUpdates,
  setUserData,
  setUsernames,
} from "../../redux/actions";
import HelixLoader from "../Loader/HelixLoader";

const Sidebar = () => {
  const { org } = useParams();
  const navigate = useNavigate();
  const drawerRef = useRef();
  const [isOpenn, setIsOpenn] = useState(false);
  const tagsref = useRef();
  const modalref = useRef();
  const handleSearchButtonClick = () => {
    setIsOpenn(!isOpenn);
  };
  const [loader,setLoader] = useState({fir:true,sec:true,thi:true})
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const dispatch = useDispatch();
  const [isInviteOpen, setInviteOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [sideItems1, setSideItems1] = useState(
    new Set(["Blueprints", "Customers", "Resolv AI","Accounts"])
  );
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const users = useSelector(selectUserData);
  const orgData = useSelector(selectOrgData);
  const resData = useSelector(selectResData);
  const [userDetails, setUserDetails] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const location = useLocation();
  const Navigate = useNavigate();
  const [isOpenPop, setIsOpenPop] = useState(false);

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const [userPerm, setUserPerm] = useState(UserPermissions);
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const [userPermSprint, setUserPermSprint] = useState({
    name: "Role-4",
    class: "Sprint Boards",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });
  const numColors = Colors.length;
  const colorIndex = UserId && UserId % numColors;

  const handleNavigate = () => {
    Navigate(`/${org}/settings`);
  };

  const handleLogout = () => {
    Navigate(`/`);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalref.current && !modalref.current.contains(event.target)) {
        setIsOpenn(!isOpenn);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenn]);
  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const resp = await getFilteredBySingleRelationSingleData(
          "organizations",
          "users",
          UserId,
          "Name",
          org
        );
        if (resp.data.length >0) {
          dispatch(orgInfo(resp.data[0]));
          setLoader((prev) => ({...prev,fir:false}));
        } else {
          navigate("/404");
        }
      } catch (error) {
        navigate("/404");
      }
      
    };
    if (org && UserId) {
      fetchOrg();
    }
  }, [org, UserId]);
  useEffect(() => {
    const UpdateData = async () => {
      const data = { OrgRoute: `/${orgData?.attributes?.Name}` };
      try {
        await UpdateOneUserData("users", UserId, data);
      } catch (error) {}
    };
    if (orgData?.attributes?.Name) {
      UpdateData();
    }
  }, [orgData]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const results = await getFilteredBySingleRelation(
          "users",
          "organizations",
          orgData.id
        );
        dispatch(setUserData(results));
        setUserDetails(results.find((item) => item.id === UserId));
        if (results.length > 0) {
          const convertedUsers = {};
          const userN = [];
          results.forEach((user) => {
            userN.push({
              username: user.username,
              id: user.id,
              name: user.FirstName
                ? `${user.FirstName} ${user.LastName ? user.LastName : ""}`
                : user.username,
              url: user.ProfilePicture?.formats?.small?.url || "",
            });
            convertedUsers[user.id] = {
              Email: user.email,
              UserName: user.username,
              FirstName: user.FirstName,
              LastName: user.LastName,
              ProfilePicture: user.ProfilePicture?.formats?.small?.url || "",
            };
          });
          dispatch(setUsernames(userN));
          dispatch(setRes(convertedUsers));
        }
        const updates = await getFilteredByTwoRelation(
          "updates",
          "organization",
          orgData.id,
          "user",
          UserId
        );
         if (updates?.data?.length > 0) {
          dispatch(
            setUpdates({
              id: updates.data[0].id,
              updates: updates.data[0].attributes.updates ?? [],
            })
          );
        }
        setLoader((prev) => ({...prev,sec:false}))
      } catch (error) {
        console.error("Error fetching data for all IDs:", error);
      }
    };
    if (orgData?.id) {
      getAllDataByIds();
    }
  }, [orgData]);
  useEffect(() => {
    const fetchPerm = async () => {
      try {
        const perm = await PermissionGroup(UserId, orgData?.id);
        if (perm === false) {
          setLoader((prev) => ({...prev,thi:false}))
          return;
        }
        setUserPerm(perm);
        setLoader((prev) => ({...prev,thi:false}))
      } catch (error) {}
    };
    if (UserId && orgData?.id) {
      fetchPerm();
    }
  }, [orgData, UserId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target))
      ) {
        setIsDrawerOpen(false);
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);
  const searchButtonRef = useRef();
  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === "k") {
      event.preventDefault();
      setIsOpenn(!isOpenn);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpenn]);

  useEffect(() => {
    if (!UserLogged) {
      const pathname = location.pathname;
      let search = location.search;
      if (search !== "") {
        search = search.replace(/\?/g, "&");
      }
      window.location.href = `/sign-in?redirected-to=${pathname}${search}`;
    } else {
    }
  }, []);

  const UserProfile =
    userDetails && userDetails.ProfilePicture !== null
      ? process.env.REACT_APP_STAGE === "Dev"
        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${userDetails.ProfilePicture.url}`
        : `${userDetails.ProfilePicture.url}`
      : "";

  const NavBase = [
    {
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/vspbqszr.json"
          trigger="hover"
          state="morph-book"
          target="#updates"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Updates",
      path: `/${org}/updates`,
      id: "updates",
    },
    {
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/vdjwmfqs.json"
          trigger="hover"
          state="hover"
          target="#MyTasks"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "My Tasks",
      path: `/${org}/tasks`,
      id: "MyTasks",
    },
  ];
  const Nav1 = [
    {
      ids: 1,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/xtnsvhie.json"
          trigger="hover"
          state="morph-book"
          target="#inbox"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Inbox",
      path: `/${org}`,
      id: "inbox",
    },
    {
      ids: 3,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/ciawvzjk.json"
          trigger="hover"
          state="morph-book"
          target="#Accounts"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Accounts",
      path: `/${org}/accounts`,
      id: "Accounts",
    },
    {
      ids: 2,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/gyblqrqz.json"
          trigger="hover"
          state="morph-book"
          target="#tickets"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Tickets",
      path: `/${org}/tickets`,
      id: "tickets",
    },
    {
      ids: 4,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/kddybgok.json"
          trigger="hover"
          state="morph-book"
          target="#customers"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Customers",
      path: `/${org}/customers`,
      id: "customers",
    },
    {
      ids: 5,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/fdxqrdfe.json"
          trigger="hover"
          state="morph-book"
          target="#team-activity"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Team Activity",
      path: `/${org}/team-activity`,
      id: "team-activity",
    },
    {
      ids: 6,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/whrxobsb.json"
          trigger="hover"
          state="morph-book"
          target="#analytics"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Analytics",
      path: `/${org}/analytics`,
      id: "analytics",
    },
  ];
  const Nav2 = [
    {
      ids: 1,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/ygvjgdmk.json"
          trigger="hover"
          state="morph-book"
          target="#issues"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Issues",
      path: `/${org}/issues`,
      id: "issues",
    },
    {
      ids: 2,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/eouimtlu.json"
          trigger="hover"
          state="morph-book"
          target="#queues"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Queues",
      path: `/${org}/queues`,
      id: "queues",
    },
    {
      ids: 3,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/hqymfzvj.json"
          trigger="hover"
          state="morph-book"
          target="#sprint-boards"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Sprint Board",
      path: `/${org}/sprint-boards`,
      id: "sprint-boards",
    },
    {
      ids: 4,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/ktsahwvc.json"
          trigger="hover"
          state="morph-book"
          target="#resolv-ai"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Resolv AI",
      path: `/${org}/resolv-ai`,
      id: "resolv-ai",
    },
  ];

  const Nav3 = [
    {
      ids: 1,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/nizfqlnk.json"
          trigger="hover"
          target="#productTrails"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Product Trails",
      path: `/${org}/product-trails`,
      id: "productTrails",
    },
    {
      ids: 2,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/eouimtlu.json"
          trigger="hover"
          state="morph-book"
          target="#components"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Components",
      path: `/${org}/components`,
      id: "components",
    },
    {
      ids: 3,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/zyzoecaw.json"
          trigger="hover"
          state="morph-book"
          target="#roadmap"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Roadmap",
      path: `/${org}/roadmap`,
      id: "roadmap",
    },
    {
      ids: 4,
      icon: (
        <lord-icon
          src="https://cdn.lordicon.com/lyrrgrsl.json"
          trigger="hover"
          state="morph-book"
          target="#blueprints"
          colors={`primary: "#000000"`}
          style={{ width: "16px", height: "16px" }}
        ></lord-icon>
      ),
      title: "Blueprints",
      path: `/${org}/blueprints`,
      id: "blueprints",
    },
  ];
  useEffect(() => {
    if (userPerm) {
      let arr = Array.from(sideItems1);
      userPerm.map((item) => {
        if (item.privileges[0].read) {
          if (item.name === "Role-11") {
            arr.push("Tickets");
          } else if (item.name === "Role-13") {
            arr.push("Team Activity");
          } else if (item.name === "Role-12") {
            arr.push("Analytics");
          } else if (item.name === "Role-2") {
            arr.push("Issues");
          } else if (item.name === "Role-6") {
            arr.push("Components");
            arr.push("Product Trails");
          } else if (item.name === "Role-1") {
            arr.push("Inbox");
          } else if (item.name === "Role-3") {
            arr.push("Queues");
          } else if (item.name === "Role-4") {
            setUserPermSprint(item);
            arr.push("Sprint Board");
          } else if (item.name === "Role-5") {
            arr.push("Roadmap");
          } else if (item.name === "Role-15") {
            if (item.privileges[0].create === true) {
              arr.push("Invitaion");
            }
          }
        }
      });
      setSideItems1(new Set(arr));
    }
  }, [userPerm]);
  if(loader.fir || loader.sec || loader.thi){
    return (<div className="flex flex-col items-center justify-center mt-64">
      <HelixLoader />
      
    </div>)
  }
  return (
    <div className="flex w-full h-screen">
      <Modals
        setIsPop={setIsOpenPop}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      />
      <InviteModal
        orgid={orgData}
        isOpen={isInviteOpen}
        onClose={() => setInviteOpen(false)}
      />
      <Modal
        size="xl"
        className="h-[300px] py-4"
        hideCloseButton
        isOpen={isOpenn}
        onClose={() => setIsOpenn(false)}
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-2">
            <Input
              startContent={
                <lord-icon
                  src="https://cdn.lordicon.com/kkvxgpti.json"
                  trigger="hover"
                  style={{ height: "16px" }}
                ></lord-icon>
              }
              value={searchItem}
              onChange={(e) => setSearchItem(e.target.value)}
              placeholder="Search..."
              endContent={
                <Kbd keys={["command"]} size="sm">
                  K
                </Kbd>
              }
            />
          </ModalHeader>
          <ModalBody></ModalBody>
        </ModalContent>
      </Modal>
      <div className="scroll-container w-64 h-screen left-0 bg-[#F9F9FA] dark:bg-gray-700 dark:text-white border-r-1 border-divider overflow-y-auto">
        <div className="p-4">
          <div className="flex items-center mx-2 my-4 justify-between">
            <div className="flex items-center  gap-4 cursor-pointer text-xs">
              <div>
                <Popover
                  isOpen={isOpenPop}
                  onOpenChange={(open) => setIsOpenPop(open)}
                >
                  <PopoverTrigger>
                    <Avatar
                      radius="full"
                      className="rounded-md"
                      size="sm"
                      color={Colors[colorIndex]}
                      src={UserProfile}
                      name={
                        userDetails?.FirstName
                          ? userDetails.FirstName.slice(0, 1).toUpperCase()
                          : userDetails?.email?.slice(0, 1).toUpperCase() || ""
                      }
                    />
                  </PopoverTrigger>

                  <PopoverContent
                    isReadOnly
                    key="profile"
                    className="left-0 flex flex-col justify-start opacity-100 text-xs"
                    startContent={
                      <Avatar
                        radius="full"
                        className="rounded-md"
                        size="sm"
                        color={Colors[colorIndex]}
                        src={UserProfile}
                        name={
                          userDetails?.FirstName
                            ? userDetails.FirstName.slice(0, 1).toUpperCase()
                            : userDetails?.email?.slice(0, 1).toUpperCase() ||
                              ""
                        }
                      />
                    }
                  >
                    <div className="flex flex-col p-2 w-full justify-start left-0 items-start text-xs">
                      <div className=" flex gap-6 mb-2 cursor-pointer hover:bg-slate-100 hover:rounded-md w-full text-center">
                        <Avatar
                          radius="full"
                          className="rounded-md"
                          size="sm"
                          color={Colors[colorIndex]}
                          src={UserProfile}
                          name={
                            userDetails?.FirstName
                              ? userDetails.FirstName.slice(0, 1).toUpperCase()
                              : userDetails?.email?.slice(0, 1).toUpperCase() ||
                                ""
                          }
                        />

                        <div>
                          <p className="text-center mt-2 text-xs">
                            {userDetails?.email}
                          </p>
                          <p></p>
                        </div>
                      </div>
                      <Divider />
                      <div className=" flex px-1 py-2 mt-2 cursor-pointer hover:bg-slate-100 hover:rounded-md  w-full gap-4 flex-row">
                        <IoIosNotificationsOutline
                          style={{ height: "16px", width: "16px" }}
                        />
                        <p className=" text-xs">Pause Notifications</p>
                      </div>
                      <div className=" flex px-1 py-2 mb-2 cursor-pointer hover:bg-slate-100 hover:rounded-md  w-full gap-4 flex-row">
                        <CiUser style={{ height: "16px", width: "16px" }} />
                        <p className=" text-xs"> Set youself as away</p>
                      </div>
                      <Divider />
                      <div className="flex justify-between items-center w-full">
                        <div className="flex items-center justify-between w-full mt-2">
                          <div>Organizations</div>
                          <Button
                            onPress={onOpen}
                            className="flex justify-center items-center"
                            size="sm"
                            isIconOnly
                            variant="light"
                          >
                            +
                          </Button>
                        </div>
                      </div>

                      <div className=" flex flex-row cursor-pointer  hover:rounded-md  w-full">
                        <Org />
                      </div>
                      <Divider />
                    </div>
                    <div>
                      <Tabs size="sm" aria-label="Tabs sizes" className="mb-2">
                        <Tab
                          title={
                            <div className="flex items-center space-x-2">
                              <CiDark />
                              <span className=" text-xs">Dark</span>
                            </div>
                          }
                        />
                        <Tab
                          title={
                            <div className="flex items-center space-x-2">
                              <CiLight />
                              <span className=" text-xs">Light</span>
                            </div>
                          }
                        />
                        <Tab
                          title={
                            <div className="flex items-center space-x-2">
                              <MdAutorenew />
                              <span className=" text-xs">Auto</span>
                            </div>
                          }
                        />
                      </Tabs>
                    </div>
                    <Divider />
                    <div className="w-full my-2">
                      {Array.from(sideItems1).includes("Invitaion") && (
                        <div
                          onClick={() => setInviteOpen(true)}
                          className="flex px-1 py-2 cursor-pointer hover:bg-slate-100 hover:rounded-md  w-full gap-4 flex-row"
                        >
                          <InviteIcon
                            style={{ height: "16px", width: "16px" }}
                          />
                          <p className="text-xs">Invite members</p>
                        </div>
                      )}
                      <div
                        className=" flex gap-4 w-full  px-1 py-2 cursor-pointer hover:bg-slate-100 hover:rounded-md"
                        onClick={handleNavigate}
                      >
                        <CiSettings
                          style={{ height: "16px", width: "16px" }}
                          onClick={handleNavigate}
                        />
                        <p className=" text-xs">Settings</p>
                      </div>
                    </div>
                    <Divider />
                    <div className="mt-2 mb-2 flex px-1 py-2 cursor-pointer hover:bg-slate-100 hover:rounded-md  w-full gap-4 flex-row">
                      <IoLogOutOutline
                        style={{ height: "16px", width: "16px" }}
                      />
                      <p onClick={handleLogout} className="text-xs">
                        {" "}
                        Log Out
                      </p>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
              <lord-icon
                src="https://cdn.lordicon.com/lecprnjb.json"
                trigger="hover"
                className="ml-4 w-full"
                style={{ width: "18px", height: "18px" }}
                onClick={handleNavigate}
              ></lord-icon>
            </div>
            <div>
              <Button
                onClick={toggleDrawer}
                size="sm"
                variant="light"
                className="border border-divider bg-white"
                isIconOnly
              >
                <div className="text-md">+</div>
              </Button>
            </div>
          </div>
          <div className="w-full flex items-center my-4">
            <Button
              size="md"
              onClick={handleSearchButtonClick}
              className="border border-divider w-full  bg-white"
              variant="bordered"
              radius="md"
              ref={searchButtonRef}
            >
              <div className="flex items-center justify-between w-full">
                <div className="flex gap-2 items-center justify-start">
                  <lord-icon
                    src="https://cdn.lordicon.com/kkvxgpti.json"
                    trigger="hover"
                    style={{ height: "16px", width: "16px" }}
                  ></lord-icon>
                  Search
                </div>
                <div>
                  <Kbd keys={["command"]} size="sm">
                    K
                  </Kbd>
                </div>
              </div>
            </Button>
          </div>

          <ul>
            {NavBase.map((item, index) => (
              <li key={index}>
                <NavLink className=" rounded-md" to={item.path} id={item.id}>
                  <span
                    className={`flex py-2 items-center gap-2 rounded-md px-2 text-xs  text-black hover:text-foreground ${
                      location.pathname === item.path
                        ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                        : "hover:bg-[#F3F4F6]"
                    }`}
                  >
                    <div className="flex justify-center">{item.icon}</div>
                    {item.title}
                  </span>
                </NavLink>
              </li>
            ))}

            <Divider className="my-4" />
            {Nav1.filter((item) =>
              Array.from(sideItems1).includes(item.title)
            ).map((item, index) => (
              <li key={item?.ids}>
                <NavLink className=" rounded-md" to={item.path} id={item.id}>
                  <span
                    className={`flex py-2 items-center gap-2 rounded-md px-2 text-xs text-black hover:text-foreground ${
                      location.pathname === item.path
                        ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                        : "hover:bg-[#F3F4F6]"
                    }`}
                  >
                    <div className="flex justify-center">{item.icon}</div>
                    {item.title}
                  </span>
                </NavLink>
              </li>
            ))}

            <Divider className="my-4" />
            {Nav2.filter((item) =>
              Array.from(sideItems1).includes(item.title)
            ).map((item, index) => (
              <li key={item?.ids}>
                <NavLink className=" rounded-md" to={item.path} id={item.id}>
                  <span
                    className={`flex py-2 items-center gap-2 rounded-md px-2 text-xs  text-black hover:text-foreground ${
                      location.pathname.includes(item.path)
                        ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                        : "hover:bg-[#F3F4F6]"
                    }`}
                  >
                    <div className="flex justify-center">{item.icon}</div>
                    {item.title}
                  </span>
                </NavLink>
              </li>
            ))}
            <Divider className="my-4" />
            {Nav3.filter((item) =>
              Array.from(sideItems1).includes(item.title)
            ).map((item) => (
              <li key={item?.ids}>
                <NavLink className=" rounded-md" to={item.path} id={item.id}>
                  <div
                    className={`flex py-2 items-center gap-2 rounded-md px-2 text-xs  text-black hover:text-foreground ${
                      location.pathname.includes(item.path)
                        ? "bg-[#E3E3E8] hover:bg-[#E3E3E8]"
                        : "hover:bg-[#F3F4F6]"
                    }`}
                  >
                    <div className="flex justify-center">{item.icon}</div>
                    <div>{item.title}</div>
                  </div>
                </NavLink>
              </li>
            ))}
            {Array.from(sideItems1).includes("Sprint Board") && (
              <>
                <Divider className="my-4" />
                <div className="">
                  <div className="text-xs">
                    <Sprint
                      Update={userPermSprint.privileges[0].update}
                      Delete={userPermSprint.privileges[0].delete}
                      org={org}
                      orgId={orgData?.id}
                    />
                  </div>
                </div>
              </>
            )}
          </ul>
        </div>
      </div>
      <div className={`flex flex-col gap-2 w-full`}>
        {UserLogged && <Outlet />}
      </div>
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <Drawer
            query={location.pathname.includes("issue") ? "issue" : "ticket"}
            users={users}
            res={resData}
            tenant={{ name: org, id: orgData?.id }}
            dropdownref={tagsref}
            modalref={modalref}
            toggleDrawer={toggleDrawer}
          />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
