import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { getFilteredData } from "../../controllers/strapiController";
import Profile from "./Profile";

function Chat({ convo, userId, temp,assignedId,members,org }) {
  const messagesEndRef = useRef(null);
  function removePrefix(fullString, prefix) {
    if (fullString === null) {
      return "";
    }
    if (typeof(fullString) === "string" && fullString.startsWith(prefix)) {
      return fullString.substring(prefix.length);
    }
    return fullString; // return the full string if the prefix doesn't match
  }
  function timeAgo(timestamp) {
    const now = Date.now();
    const timeDiff = now - timestamp;
  
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else {
      return `${days} days ago`;
    }
  }
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [convo.length, temp.length]);

  useEffect(() => {
    const fetchIds = async(arr) => {
      try{
        const converted = {};
        const promises = arr.map((item) => getFilteredData("users","email",removePrefix(item,`${org}_`)));
        const resp = await Promise.all(promises);
        resp.map((item) => {
          if(item[0]){
            converted[item[0].email] = {
              id : item[0].id,
              ProfilePicture : item[0].ProfilePicture,
            }
          }
        })
      }
      catch(error){

      }
    }
    if(assignedId){
      const arr = members;
      arr.push(assignedId);
      fetchIds(arr);

    }
  },[assignedId,members])
  return (
    <div
      ref={messagesEndRef}
      className="w-full h-full flex flex-col gap-2 overflow-y-auto"
    >
      <div className="w-full flex items-center justify-center text-sm text-gray-400">{ timeAgo(convo[0].timestamp)}</div>
      {convo.map((item, index) => (
        <div
          key={index}
          className={`w-full flex items-center gap-2 ${
            item.senderId === userId ? " justify-end" : " justify-start"
          }`}
        >
          {(item.senderId !== userId && (index ===0 || convo[index-1].senderId !== item.senderId )) && <Profile insideChat={true} org={org} assignedId={item.senderId}  /> }
          <div className=" border border-divider rounded-lg p-2 flex flex-col">
            <div
              className="mydiv"
              dangerouslySetInnerHTML={{ __html: item.text }}
            ></div>
          </div>
        </div>
      ))}
      {temp.map((item, index) => (
        <div
          key={index}
          className={`w-full flex items-center gap-2 ${
            item.senderId === userId ? " justify-end" : " justify-start"
          }`}
        >
          <div className=" border border-divider rounded-lg p-2 flex flex-col">
            <div
              className="mydiv"
              dangerouslySetInnerHTML={{ __html: item.text }}
            ></div>
            {/* <div className=' flex justify-end text-xs text-gray-400'>
                    {item.timeStamp}
                </div> */}
          </div>
        </div>
      ))}
      <div className=" h-0" ref={messagesEndRef} />
    </div>
  );
}

export default Chat;
