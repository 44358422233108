import React, { useState } from "react";
import BlueprintHeader from "./BlueprintHeader";
import { Divider } from "@nextui-org/react";
import { Outlet } from "react-router-dom";

function Blueprints() {

  return (
    <div className="h-full overflow-y-auto flex flex-col w-full">
      <BlueprintHeader />
      <Divider />
      <Outlet />
    </div>
  );
}

export default Blueprints;
