import { Button, CardBody, Divider } from "@nextui-org/react";
import { EditorProvider } from "@tiptap/react";
import React, { useEffect, useRef, useState } from "react";
import {
  extensions,
  MenuBar,
  props,
} from "../../components/markdown/MessageEditor";
import Chat from "./Chat";
import { Message } from "../../utils/Message";
import { getSingleDocument, postDataInbox } from "../../controllers/vectorDB";

function Conversations({ setToggleConvo, setToggle, hex,socket,convo,setConvo,userId,threadId,org,email }) {
  const [imageIds, setImageIds] = useState([]);
  const [temp,setTemp] = useState([]);
  const [start, setStart] = useState(true);
  const [descriptionContent, setDescriptionContent] = useState("");
  const [members,setMembers] = useState([]);
  const [assigned,setAssigned] = useState(null)
  const [clear,setClear] = useState(0);
  const submitRef = useRef();
  const handleSubmit = async() => {
    if(socket === null || descriptionContent==="" || descriptionContent==="<p></p>"){
      return;
    }
    const newMes = new Message(userId,Number(convo.length) +1,descriptionContent);
    if(convo.length === 0){
      const formData = {
        assignedId:null,
        source : "Widget",
        createdAt:Date.now(),
        customerId : userId,
        description : `${userId} started this convo.`,
        index : 1,
        tags :[],
        tickets : [],
        members:[],
        title: "Untitled",
        customerMail : email,
        stage : "New",
        messages : [newMes],
        updatedAt : Date.now()
      }
      try{
        setTemp((prev) => [...prev,newMes]);
        setClear(clear+1);
        await postDataInbox(threadId,formData,org);
        setTemp([]);
        setConvo((prev) => [...prev,newMes]);
        setDescriptionContent("");
        
      }
      catch(error){
        console.log("Error:",error)
      }
    }
    else{
      if(socket){
        setTemp((prev) => [...prev,{senderId :userId , text : descriptionContent}]);
        socket.send(JSON.stringify({
          type:"text",
          threadId : threadId,
          payload : {
            text : descriptionContent
          }
        }));
        setClear(clear+1);
      }
    }
  };
  useEffect(() => {
    if(socket){
      socket.onmessage = (data) => {
        const message = JSON.parse(data.data);
        if(message.type === "text" && String(message.threadId) === String(threadId)){
          setConvo((prev) => [...prev,message.payload.message]);
          let arr = temp.filter((item) => item.text !== message.payload.message.text);
          setTemp(arr);
        }
      }
    }
  },[socket]);
  useEffect(() => {
    const fetchThread = async() => {
      try{
        const resp = await getSingleDocument(threadId,org);
        if(resp[0]){
          setMembers(resp[0].payload.members);
          setAssigned(resp[0].payload.assignedId)
        }
      }
      catch(error){

      }
    }
    if(threadId && org){
      fetchThread()
    }
  },[threadId,org])
  return (
    <div className=" pt-4 w-full h-full">
      <div className=" flex items-center justify-between px-2 pb-2">
        <div className=" flex items-center gap-4">
          <Button
            variant="light"
            size="sm"
            onClick={() => setToggleConvo()}
            isIconOnly
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </Button>
          <p>Chat with team</p>
        </div>
        <Button
          onClick={() => setToggle()}
          size="sm"
          radius="full"
          variant="light"
          isIconOnly
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </Button>
      </div>
      <Divider />
      <CardBody className="relative h-full items-center flex w-full flex-col gap-4">
        <div className="h-[440px] w-full">
          <Chat org={org} assignedId={assigned} members={members} temp={temp} userId={userId} convo={convo}/>
        </div>

        <div className=" h-[110px] overflow-y-auto flex flex-col absolute bottom-12 w-[95%] border border-divider rounded-lg">
          <EditorProvider
            editorProps={props()}
            slotBefore={
              <MenuBar
                descriptionContent={descriptionContent}
                setDescriptionContent={setDescriptionContent}
                imageArray={imageIds}
                setImageArray={setImageIds}
                initial={start}
                setInitial={setStart}
                clear={clear}
              />
            }
            extensions={extensions(submitRef)}
            content={descriptionContent}
          ></EditorProvider>
          <div className=" pr-2 flex items-center justify-end pb-1">
            <Button
              onClick={handleSubmit}
              ref={submitRef}
              style={{ backgroundColor: hex }}
              isIconOnly
              size="sm"
              className="text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                />
              </svg>
            </Button>
          </div>
        </div>
      </CardBody>
    </div>
  );
}

export default Conversations;
