import React from 'react';
import { Helmet } from 'react-helmet-async';

const OGP = ({ title, description, url, image }) => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content={title || "Resolv | Product Management"} />
        <meta property="og:description" content={description || "Product Management for SaaS"} />
        <meta property="og:url" content={url || "http://localhost:3001"} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image || "https://avatars.githubusercontent.com/u/170258109?s=200&v=4"} />
      </Helmet>
    </>
  );
};

OGP.defaultProps = {
  title: "Resolv | Product Management",
  description: "Product Management for SaaS",
  url: "http://localhost:3001/org",
  image: "https://avatars.githubusercontent.com/u/170258109?s=200&v=4"
};

export default OGP;
