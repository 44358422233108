import React, { useEffect, useState } from "react";
import {
  Chip,
  Input,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
} from "@nextui-org/react";
import { NavLink } from "react-router-dom";
import AddTagModal from "./tagModal";

const Tags = ({
  tags,
  setTags,
  tagsDetail,
  setTagsDetail,
  dropdownref,
  modalref,
  customTags,
  org,
  param,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [tagsDetailMap, setTagsDetailMap] = useState(tagsDetail);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isNewTag, setIsNewTag] = useState(false);
  const [search, setSearch] = useState("");

  const handleSelect = (item) => {
    if (!tagsDetailMap.includes(item)) {
      setTagsDetailMap((prev) => [...prev, item]);
    }
    if (!tagsDetail.includes(item)) {
      setTagsDetail((prev) => [...prev, item]);
    }
    if (!tags.includes(item?.id)) {
      setTags((prev) => [...prev, item?.id]);
    }
  };

  const deleteTag = (item) => {
    if (tags.includes(item.id)) {
      setTags((prevItems) => prevItems.filter((i) => i !== item.id));
    }
    if (tagsDetail.includes(item)) {
      setTagsDetail((prevItems) => prevItems.filter((i) => i !== item));
    }
  };

  useEffect(() => {
    if (customTags && customTags.length > 0) {
      setFilteredOptions(customTags);
      setIsNewTag(false);
    }
  }, [customTags]);

  useEffect(() => {
    const filtered =
      customTags &&
      customTags.filter((option) =>
        option?.attributes?.Name.toLowerCase().includes(search.toLowerCase())
      );
    const newTag =
      customTags &&
      customTags.filter(
        (option) =>
          option?.attributes?.Name.toLowerCase() === search.toLowerCase()
      );
    setFilteredOptions(filtered);
    setIsNewTag(newTag && newTag.length === 0);
  }, [search]);

  useEffect(() => {
    setTagsDetailMap(tagsDetail);
  }, [tagsDetail]);

  return (
    <div className="w-40">
      <AddTagModal
        org={org}
        modalref={modalref}
        name={search}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
      <Dropdown>
        <DropdownTrigger>
          <div className="rounded-lg cursor-pointer min-h-6 h-auto flex flex-col">
            <div className="gap-2 flex flex-col pt-[2px] px-2">
              {tags.length > 0 ? (
                tagsDetailMap.map((item) => (
                  <Chip
                    size="sm"
                    key={item?.id}
                    startContent={
                      <div
                        style={{ backgroundColor: item?.attributes?.Color }}
                        className="w-2 h-2 rounded-full"
                      ></div>
                    }
                    onClose={() => {
                      deleteTag(item);
                    }}
                    className="cursor-pointer bg-white rounded-full border border-divider flex justify-between gap-2"
                  >
                    {item?.attributes?.Name}
                  </Chip>
                ))
              ) : (
                <p className="text-xs text-start ml-1">Add tags</p>
              )}
            </div>

            {tags.length !== 0 && (
              <Button
                size="sm"
                onClick={() => {
                  setTags([]);
                  setTagsDetail([]);
                }}
                isIconOnly
                variant="light"
              >
                &times;
              </Button>
            )}
          </div>
        </DropdownTrigger>
        <DropdownMenu
          ref={dropdownref}
          className="h-auto"
          style={{ width: "200px" }}
          aria-label="Static Actions"
        >
          <DropdownSection>
            <DropdownItem isReadOnly key="new">
              <Input
                size="sm"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search tags.."
                style={{ fontSize: "12px" }}
              ></Input>
            </DropdownItem>
          </DropdownSection>

          {filteredOptions?.length > 0 && (
            <DropdownSection>
              <DropdownItem isReadOnly>
                <div className="flex cursor-default flex-wrap gap-2 items-center justify-around overflow-y-auto max-h-[100px] w-full">
                  {filteredOptions?.map((item) => (
                    <Chip
                      variant="bordered"
                      key={item?.id}
                      className="cursor-pointer bg-white rounded-full border border-divider gap-2 text-xs"
                      onClick={() => handleSelect(item)}
                      startContent={
                        <div
                          style={{ backgroundColor: item?.attributes?.Color }}
                          className="w-2 h-2 rounded-full"
                        ></div>
                      }
                    >
                      {item?.attributes?.Name}
                    </Chip>
                  ))}
                </div>
              </DropdownItem>
            </DropdownSection>
          )}

          {search !== "" && isNewTag && (
            <DropdownSection>
              <DropdownItem onClick={() => setOpen(true)}>
                Add {search} to tags
              </DropdownItem>
            </DropdownSection>
          )}
          <DropdownSection>
            <DropdownItem>
              <NavLink to={`/${param}/settings/tags`}>
                <p className="text-xs">Manage Tags</p>
              </NavLink>
            </DropdownItem>
          </DropdownSection>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Tags;
