import React, { useEffect, useState } from 'react'
import { getFilteredData } from '../../controllers/strapiController';
import { Avatar } from '@nextui-org/react';

function Profile({assignedId,org,insideChat}) {
    const [name,setName] = useState("");
    const [url,setUrl] = useState("");
    const [id,setId] = useState(null)
    const Colors = ["secondary", "success", "warning", "danger", "primary"];
const numColors = Colors.length;
    function removePrefix(fullString, prefix) {
        if (fullString === null) {
          return "";
        }
        if (typeof(fullString) === "string" && fullString.startsWith(prefix)) {
          return fullString.substring(prefix.length);
        }
        return fullString; // return the full string if the prefix doesn't match
      }

      useEffect(() => {
        const fetchUser = async(email) => {
            try{
                const resp = await getFilteredData("users","email",email);
                if(resp.length>0){
                    if(resp[0].FirstName === null || resp[0].FirstName ===""){
                        setName(resp[0].username)
                    }
                    else{
                        setName(resp[0].FirstName);
                    }
                    setUrl(resp[0].ProfilePicture);
                    setId(resp[0].id)
                }
            }
            catch(error){

            }
        }
        if(assignedId === null){
            setName("Unassigned")
        }
        else{
            const email = removePrefix(assignedId,`${org}_`);
            fetchUser(email);
        }

      },[assignedId])
  return (
    <div className=' flex items-center gap-2'>
        <Avatar src={`${url?.formats?.small?.url || ""}`} showFallback size='sm' name={name === "Unassigned" ? "" : name?.toUpperCase()?.charAt(0)} color={id ? Colors[Number(id) % numColors] : "default"} />
        {!insideChat && <p className=' text-sm'> {name ?? ""}</p>}
    </div>
  )
}

export default Profile
