import React, { useState } from "react";
import { basicIcons } from "../../../utils/default";

const Icon = ({ onSelectIcon, setIsOpen, isOpen }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIcon, setSelectedIcon] = useState(null);

  const handleIconSelect = (icon) => {
    setSelectedIcon(icon);
    onSelectIcon(icon); 
    setIsOpen(false);
  };

  const filteredIcons = basicIcons.filter((icon) =>
    icon.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  console.log("Selected Icon:", selectedIcon);

  return (
    <div className="p-2">
      <div className="relative w-full mb-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="absolute left-2 top-1/2 transform -translate-y-1/2 size-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>

        <input
          type="text"
          placeholder="Search..."
          className="w-full pl-8 text-xs gap-4 p-2 border rounded-md"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="grid grid-cols-7">
        {filteredIcons.map((icon) => (
          <div
            key={icon.name}
            className={`cursor-pointer p-2 rounded-md hover:bg-[#F2F2F3] ${
              selectedIcon?.name === icon.name ? "bg-blue-100" : ""
            }`}
            onClick={() => handleIconSelect(icon)}
          >
            <svg
              xmlns={icon.xmlns || "http://www.w3.org/2000/svg"}
              fill={icon.fill || "none"}
              viewBox={icon.viewBox || "0 0 24 24"}
              strokeWidth={icon.strokeWidth || 1.5}
              stroke={icon.stroke || "currentColor"}
              className={icon.className || ""}
            >
              <path d={icon.path?.d || ""} />
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Icon;
