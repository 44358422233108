import { Avatar } from '@nextui-org/react'
import './MentionList.css'
import React, {
  forwardRef, useEffect, useImperativeHandle,
  useState,
} from 'react'

const Colors = ["secondary", "success", "warning", "danger", "primary"];
  
  const numColors = Colors.length;
export default forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = index => {
    const item = props.items[index]

    if (item) {
      props.command({ id: item.name }) // Adjust this if you need the whole object passed back
    }
  }

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className="dropdown-menu">
      {props.items.length
        ? props.items.map((item, index) => (
          <button
            className={index === selectedIndex ? 'is-selected' : ''}
            key={index}
            onClick={() => selectItem(index)}
          >
            <div className=' flex items-center gap-2'>
              <Avatar className="w-6 h-6" color={Colors[Number(item.id) % numColors]} src={item.url} showFallback name={item.name.toUpperCase().charAt(0)} radius='sm' size='sm'/>
            
            {item.name}
            </div>
          </button>
        ))
        : <div className="item">No result</div>
      }
    </div>
  )
})
