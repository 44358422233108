import React, { useState } from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  Chip,
  Avatar,
  Accordion,
  AccordionItem,
} from "@nextui-org/react";
import { renderGroup } from "../../utils/constant";
import { basicIcons } from "../../utils/default";

const SprintIcon = ({ icon }) => {
  const iconDetail = basicIcons.find(
    (i) => i.name.toLowerCase() === icon?.toLowerCase()
  );
  if (iconDetail) {
    return (
      <svg
        xmlns={iconDetail?.xmlns}
        fill={iconDetail?.fill || "none"}
        viewBox={iconDetail?.viewBox || "0 0 24 24"}
        strokeWidth={iconDetail?.strokeWidth || 1.5}
        stroke={iconDetail?.stroke || "currentColor"}
        className={iconDetail?.className || "size-4"}
      >
        <path
          strokeLinecap={iconDetail?.path?.d || "round"}
          strokeLinejoin={iconDetail?.path?.d || "round"}
          d={iconDetail?.path?.d || "m4.5 12.75 6 6 9-13.5"}
        />
      </svg>
    );
  } else {
    return <></>;
  }
};
const TagsChip = ({ id, customTags }) => {
  const tagDetail = customTags.find((item) => String(item.id) === String(id));
  if (tagDetail) {
    return (
      <Chip
        variant="bordered"
        
        className="cursor-pointer bg-white rounded-full border border-divider gap-2 text-xs"
        
        startContent={
          <div
            style={{ backgroundColor: tagDetail?.attributes?.Color }}
            className="w-2 h-2 rounded-full"
          ></div>
        }
      >
        {tagDetail?.attributes?.Name}
      </Chip>
    );
  } else {
    return <></>;
  }
};
const GroupIssues = ({
  tickets,
  res,
  toggleTicket,
  orderby,
  customizeHeaderList,
  parsedParts,
  customTags,
  handleDelete,
  deletePerm,
  parsedSprints,
}) => {
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  return (
    <div className="flex h-full overflow-y-auto relative">
      <div className=" w-64 max-h-fit overflow-y-auto border-r-1 border-divider">
        {Object.keys(tickets || {}).map((owner) => (
          <div className="flex justify-between p-2 gap-2 cursor-pointer hover:bg-gray-200 w-full items-center capitalize">
            {orderby === "owner" || orderby === "assigned" ? (
              <div className="flex items-center gap-2">
                <Avatar
                  color={Colors[Number(owner) % numColors]}
                  showFallback
                  name={
                    res[owner]?.FirstName
                      ? res[owner]?.FirstName[0]
                      : res[owner]?.UserName
                      ? res[owner]?.UserName[0]
                      : ""
                  }
                  size="sm"
                  radius="sm"
                  src={
                    res[owner]?.ProfilePicture?.formats?.small?.url
                      ? process.env.REACT_APP_STAGE === "Dev"
                        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[owner].ProfilePicture.formats.small.url}`
                        : `${res[owner].ProfilePicture.formats.small.url}`
                      : ""
                  }
                />

                <p className="text-xs">
                  {res[owner]?.FirstName
                    ? res[owner]?.FirstName
                    : res[owner]?.UserName
                    ? res[owner]?.UserName
                    : "Unassigned"}
                </p>
              </div>
            ) : orderby === "sprint" ? (
              <div className="flex items-center gap-2">
                <SprintIcon icon={parsedSprints[owner]?.Icon} />
                <p className="text-xs">
                  {parsedSprints[owner]?.Name ?? "Unassigned"}
                </p>
              </div>
            ) : orderby === "tags" ? (
              <div className="flex items-center gap-2">
                <TagsChip id={owner} customTags={customTags} />
                
              </div>
            ) : orderby === "created by" ? (
              <div className="flex items-center gap-2">
                <Avatar
                  color={Colors[Number(owner) % numColors]}
                  showFallback
                  name={
                    res[owner]?.FirstName
                      ? res[owner]?.FirstName[0]
                      : res[owner]?.UserName
                      ? res[owner]?.UserName[0]
                      : ""
                  }
                  size="sm"
                  radius="sm"
                  src={
                    res[owner]?.ProfilePicture?.formats?.small?.url
                      ? process.env.REACT_APP_STAGE === "Dev"
                        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[owner].ProfilePicture.formats.small.url}`
                        : `${res[owner].ProfilePicture.formats.small.url}`
                      : ""
                  }
                />

                <p className="text-xs">
                  {res[owner]?.FirstName
                    ? res[owner]?.FirstName
                    : res[owner]?.UserName
                    ? res[owner]?.UserName
                    : "Unassigned"}
                </p>
              </div>
            ) : orderby === "part" ? (
              <p className="text-xs capitalize">
                {parsedParts[Number(owner)]?.Name}
              </p>
            ) : (
              <p className="text-xs capitalize">{owner}</p>
            )}
            <Chip size="sm" color="default" className="" radius="full">
              {tickets[owner] ? tickets[owner].length : 0}
            </Chip>
          </div>
        ))}
      </div>
      <div className="w-full max-h-fit overflow-y-auto">
        {Object.keys(tickets || {}).map((owner, ind) => (
          <div className=" px-4 mb-10" key={ind}>
            <Accordion defaultExpandedKeys={["1"]}>
              <AccordionItem
                key="1"
                title={
                  <div className="flex pl-2 my-2 gap-2 h-[40px] rounded-full p-2 items-center capitalize">
                    {orderby === "owner" || orderby === "assigned" ? (
                      <div className="flex items-center gap-2">
                        <Avatar
                          color={Colors[Number(owner) % numColors]}
                          showFallback
                          name={
                            res[owner]?.FirstName
                              ? res[owner]?.FirstName[0]
                              : res[owner]?.UserName
                              ? res[owner]?.UserName[0]
                              : ""
                          }
                          size="sm"
                          radius="sm"
                          src={
                            res[owner]?.ProfilePicture?.formats?.small?.url
                              ? process.env.REACT_APP_STAGE === "Dev"
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[owner].ProfilePicture.formats.small.url}`
                                : `${res[owner].ProfilePicture.formats.small.url}`
                              : ""
                          }
                        />

                        <p className="text-xs ">
                          {res[owner]?.FirstName
                            ? res[owner]?.FirstName
                            : res[owner]?.UserName
                            ? res[owner]?.UserName
                            : "Unassigned"}
                        </p>
                      </div>
                    ) : orderby === "sprint" ? (
                      <div className="flex items-center gap-2">
                        <SprintIcon icon={parsedSprints[owner]?.Icon} />
                        <p className="text-xs">
                          {parsedSprints[owner]?.Name ?? "Unassigned"}
                        </p>
                      </div>
                    ) : orderby === "tags" ? (
                      <div className="flex items-center gap-2">
                        <TagsChip id={owner} customTags={customTags} />
                        
                      </div>
                    )  : orderby === "created by" ? (
                      <div className="flex items-center gap-2">
                        <Avatar
                          color={Colors[Number(owner) % numColors]}
                          showFallback
                          name={
                            res[owner]?.FirstName
                              ? res[owner]?.FirstName[0]
                              : res[owner]?.UserName
                              ? res[owner]?.UserName[0]
                              : ""
                          }
                          size="sm"
                          radius="sm"
                          src={
                            res[owner]?.ProfilePicture?.formats?.small?.url
                              ? process.env.REACT_APP_STAGE === "Dev"
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[owner].ProfilePicture.formats.small.url}`
                                : `${res[owner].ProfilePicture.formats.small.url}`
                              : ""
                          }
                        />

                        <p className="text-xs">
                          {res[owner]?.FirstName
                            ? res[owner]?.FirstName
                            : res[owner]?.UserName
                            ? res[owner]?.UserName
                            : "Unassigned"}
                        </p>
                      </div>
                    ) : orderby === "part" ? (
                      <p className="text-xs capitalize">
                        {parsedParts[Number(owner)]?.Name}
                      </p>
                    ) : (
                      <p className="text-xs capitalize">{owner}</p>
                    )}
                  </div>
                }
              >
                <Table
                  removeWrapper
                  radius="none"
                  className="mt-4 text-xs"
                  color="default"
                  aria-label="Example static collection table"
                >
                  <TableHeader columns={customizeHeaderList}>
                    {(column) => (
                      <TableColumn key={column.id} className="text-xs">
                        {column.label}
                      </TableColumn>
                    )}
                  </TableHeader>
                  <TableBody emptyContent={"No tickets. Time for coffee."}>
                    {tickets[owner]?.map((row, index) => (
                      <TableRow
                        className="cursor-pointer  border-b border-divider  hover:bg-gray-100 text-xs"
                        onClick={(e) => toggleTicket(row)}
                        key={`${index + 1}`}
                      >
                        {customizeHeaderList.map((item) => {
                          return renderGroup(
                            row,
                            item.id,
                            res,
                            parsedParts,
                            handleDelete,
                            customTags,
                            deletePerm
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionItem>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupIssues;
