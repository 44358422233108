import {
  TableCell,
  Chip,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Avatar,
  AvatarGroup,
} from "@nextui-org/react";
const Colors = ["secondary", "success", "warning", "danger", "primary"];

const numColors = Colors.length;
function removePrefix(fullString, prefix) {
    if (fullString === null) {
      return "";
    }
    if (fullString.startsWith(prefix)) {
      return fullString.substring(prefix.length);
    }
    return fullString; // return the full string if the prefix doesn't match
  }
export const tableCell = (res,col, thread, customTags, handleDelete,org) => {
  switch (col) {
    case "customer":
      return (
        <TableCell>
          <div className=" flex gap-2 items-center">
            <Avatar
              size="sm"
              radius="sm"
              src=""
              showFallback
              name={thread?.payload?.customerMail?.toUpperCase().charAt(0)}
              color={Colors[thread?.payload?.customerMail.length % numColors]}
            />
            <p className='text-xs'>{thread?.payload?.customerMail}</p>
          </div>
        </TableCell>
      );
      case "owner" :
        return (
            <TableCell>
                {thread?.payload?.assignedId === null ? "-" : <Avatar
              color={
                res[removePrefix(thread?.payload?.assignedId, `${org}_`)]
                  ? Colors[
                      Number(
                        res[
                          removePrefix(thread?.payload?.assignedId, `${org}_`)
                        ].id
                      ) % numColors
                    ]
                  : ""
              }
              className="w-6 h-6"
              radius="sm"
              src={
                res[removePrefix(thread?.payload?.assignedId, `${org}_`)]
                  ? res[removePrefix(thread?.payload?.assignedId, `${org}_`)]
                      .ProfilePicture
                  : ""
              }
              showFallback
              name={
                thread?.payload?.assignedId === null
                  ? ""
                  : res[removePrefix(thread?.payload?.assignedId, `${org}_`)]
                  ? res[
                      removePrefix(thread?.payload?.assignedId, `${org}_`)
                    ]?.FirstName?.toUpperCase()?.charAt(0)
                  : ""
              }
            />}
            </TableCell>
        );
        case "members" :
            return(
                <TableCell>
                    {thread.payload.members.length === 0 ? (
                <></>
              ) : (
                <AvatarGroup max={6}>
                  {thread.payload.members.map((item) => (
                    <Avatar
                      color={
                        res[removePrefix(item, `${org}_`)]
                          ? Colors[
                              Number(res[removePrefix(item, `${org}_`)].id) %
                                numColors
                            ]
                          : ""
                      }
                      style={{ height: "25px", width: "25px" }}
                      radius="full"
                      src={
                        res[removePrefix(item, `${org}_`)]
                          ? res[removePrefix(item, `${org}_`)].ProfilePicture
                          : ""
                      }
                      showFallback
                      name={
                        res[removePrefix(item, `${org}_`)]
                          ? res[
                              removePrefix(item, `${org}_`)
                            ].FirstName?.toUpperCase().charAt(0)
                          : ""
                      }
                    />
                  ))}
                </AvatarGroup>
              )}
                </TableCell>
            );
    case "source":
      return (
        <TableCell>
          <div className=" flex items-center gap-2">
            {thread?.payload?.source === "Widget" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                />
              </svg>
            )}
            <p className='text-xs'>{thread?.payload?.source}</p>
          </div>
        </TableCell>
      );

    case "stage":
      return (
        <TableCell>
          <p className='text-xs'>{thread?.payload?.stage}</p>
        </TableCell>
      );
    case "tags":
      return (
        <TableCell>
          {thread.payload.tags && thread.payload.tags.length > 0 ? (
            customTags.filter((item) => thread.payload.tags.includes(item.id))
              .length < 3 ? (
              <div className="flex gap-2 items-center">
                {customTags
                  .filter((item) => thread.payload.tags.includes(item.id))
                  .map((item) => (
                    <Chip
                      variant="bordered"
                      key={item.id}
                      size="sm"
                      className="border border-divider gap-2 text-xs"
                      startContent={
                        <div
                          style={{ backgroundColor: item.attributes.Color }}
                          className="w-2 h-2 rounded-full"
                        ></div>
                      }
                    >
                      {item.attributes.Name}
                    </Chip>
                  ))}{" "}
              </div>
            ) : (
              <div className="flex gap-2 items-center">
                <Chip
                  variant="bordered"
                  key={
                    customTags.filter((item) =>
                      thread.payload.tags.includes(item.id)
                    )[0].id
                  }
                  size="sm"
                  className="border border-divider gap-2 text-xs"
                  startContent={
                    <div
                      style={{
                        backgroundColor: customTags.filter((item) =>
                          thread.payload.tags.includes(item.id)
                        )[0].attributes.Color,
                      }}
                      className="w-2 h-2 rounded-full"
                    ></div>
                  }
                >
                  {
                    customTags.filter((item) =>
                      thread.payload.tags.includes(item.id)
                    )[0].attributes.Name
                  }
                </Chip>
                <Chip
                  variant="bordered"
                  key={
                    customTags.filter((item) =>
                      thread.payload.tags.includes(item.id)
                    )[1].id
                  }
                  size="sm"
                  className="border border-divider gap-2 text-xs"
                  startContent={
                    <div
                      style={{
                        backgroundColor: customTags.filter((item) =>
                          thread.payload.tags.includes(item.id)
                        )[1].attributes.Color,
                      }}
                      className="w-2 h-2 rounded-full"
                    ></div>
                  }
                >
                  {
                    customTags.filter((item) =>
                      thread.payload.tags.includes(item.id)
                    )[1].attributes.Name
                  }
                </Chip>{" "}
                +
                {customTags.filter((item) =>
                  thread.payload.tags.includes(item.id)
                ).length - 2}
              </div>
            )
          ) : (
            "-"
          )}
        </TableCell> // Display a placeholder if there are no tags
      );
    case "delete":
      return (
        <TableCell className='flex justify-end'>
          <Dropdown>
            <DropdownTrigger>
              <Button size="sm" variant="light" color="danger" isIconOnly>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </Button>
            </DropdownTrigger>
            <DropdownMenu>
              <DropdownItem
                color="danger"
                variant="flat"
                onClick={() => handleDelete(thread.payload.id)}
              >
                Delete Permanently
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </TableCell>
      );
    default:
      return (
        <TableCell>
          <></>
        </TableCell>
      );
  }
};
