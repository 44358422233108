import React, { useEffect, useState } from "react";
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import PartChips from "./PartChips";
import IssueChips from "./IssueChips";
import { getFilteredBySingleRelation } from "../../../controllers/strapiController";

const SprintTable = ({ sprints, orgId, customizeItems, search, partKey, owners}) => {

  const [partData, setPartData] = useState([]);
  const [parsedParts, setParsedParts] = useState(null)
  const [filteredSprints, setFilteredSprints] = useState(sprints);
  const columnDef = Array.from(customizeItems).map((name) => ({
    id: name,
    label: name,
  }));

  const customizeHeaderList = [
    { id: "id", label: "ID" },
    { id: "title", label: "Title" },
    { id: "impacted parts", label: "Impacted Parts" },
    { id: "pending issues", label: "Pending Issues" },
    ...columnDef,
  ];

  const renderCell = (row, key) => {
    switch (key) {
      case "id":
        return (
          <Chip
            color="success"
            className="text-xs"
            variant="flat"
          >
            Sprint-{row?.id}
          </Chip>
        );
      case "title":
        return row?.title || "";
      case "impacted parts":
        return row?.impactedParts || "";
      case "pending issues":
        return row?.pendingIssues || "";
      case "Priority":
        return row?.Priority || "";
      case "Tags":
        return row?.Tags.length > 0 ? (
          <div className="flex flex-wrap gap-2">
            {row?.Tags.map((tag, index) => (
              <Chip
                key={index}
                variant="bordered"
                color={tag.color}
                className="gap-2"
                startContent={
                  <div
                    style={{ backgroundColor: tag.color }}
                    className="w-2 h-2 rounded-full"
                  ></div>
                }
              >
                {tag.name}
              </Chip>
            ))}
          </div>
        ) : null;
      default:
        if (customizeItems.has(key)) {
          return row?.[key] || "";
        }
        return "";
    }
  };

  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const results = await getFilteredBySingleRelation("parts", "organization", orgId);
        setPartData(results.data);
      } catch (error) {
        console.error("Error fetching data for all IDs:", error);
      }
    };
    if (orgId) {
      getAllDataByIds();
    }
  }, [orgId]);
  useEffect(() => {
    if (partData.length > 0) {
      const convertedParts = {};
      partData?.forEach((item) => {
        convertedParts[item?.id] = {
          Name: item?.attributes?.Name,
          Type: item?.attributes?.Type,
          ParentPath: item?.attributes?.ParentPath,
          Owner: item?.attributes?.users_permissions_user?.data?.id
        };
      });
      setParsedParts(convertedParts);

    }
  }, [partData]);
  useEffect(() => {
    if (sprints.length > 0) {
      let filterData = sprints;
      filterData = filterData.filter((item) => item.title.toLowerCase().includes(search.toLowerCase()));
      if (Array.from(partKey).length > 0) {
        filterData = filterData.filter((item) => {
          const ids = item.impactedParts.map((id) => { return String(id.id) });
          return Array.from(partKey).every(element => ids.includes(element))
        })
      }
      if (Array.from(owners).length > 0) {
        filterData = filterData.filter((item) => {
          const ids = item.owners.map((id) => { return String(id.id) });
          return Array.from(owners).every(element => ids.includes(element))
        })
      }
      setFilteredSprints(filterData);
    }
  }, [search, sprints, partKey, owners])
  
  return (
    <div className=" px-8">

      <Table removeWrapper aria-label="Example static collection table">
        <TableHeader columns={customizeHeaderList}>
          {(column) => (
            <TableColumn key={column.id}>{column.label}</TableColumn>
          )}
        </TableHeader>
        <TableBody>
          {filteredSprints.map((sprint) => (
            <TableRow key={sprint.id} className=" border-b border-divider">
              {customizeHeaderList.map(({ id }) => (
                <TableCell key={id}>
                  {id === "Description" ? (
                    <div>{sprint[id]}</div>
                  )
                    : id === "impacted parts" ?
                      <PartChips part={sprint.impactedParts} parsedParts={parsedParts} />
                      : id === "pending issues" ?
                        <IssueChips sprintId={sprint.id} />
                        :
                        (
                          renderCell(sprint, id)
                        )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default SprintTable;
