import React, { useState, useEffect, useRef } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownTrigger,
  Breadcrumbs,
  BreadcrumbItem,
  Button,
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  Chip,
  Switch,
  Pagination,
  DropdownSection,
} from "@nextui-org/react";
import GroupIssues from "../../components/Grouping/issuegroup";
import { PiCoffeeThin } from "react-icons/pi";
import { MdFormatListBulletedAdd } from "react-icons/md";
import Drawer from "../../components/Drawer/drawer";
import { deleteData, getDocument } from "../../controllers/vectorDB";
import {
  DeleteSingleAttribute,
  getFilteredBySingleRelation,
} from "../../controllers/strapiController";
import { renderCell } from "../../utils/constant";
import Issue from "./issuedetails";
import { useParams, useNavigate } from "react-router-dom";
import HelixLoader from "../../components/Loader/HelixLoader";
import { PermissionGroupSingleRole } from "../../utils/permission";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { useSelector } from "react-redux";
import {
  selectOrgData,
  selectResData,
  selectUserData,
  selectUserNames,
} from "../../redux/actions";
import {
  downloadCSV,
  downloadJSON,
  fetchSprints,
  parseIssues,
} from "../../utils/export";

const customizeCompleteList = [
  "Stage",
  "Owner",
  "Priority",
  "Target Closing Date",
  "Estimated days",
  "Tags",
  "part",
];
const open = ["Queued"];
const progress = [
  "Work in progress",
  "Awaiting Product assist",
  "Awaiting Development",
  "In Development",
  "Awaiting Customer Response",
];
const closed = ["Accepted", "Resolved", "Cancelled"];

const Issues = () => {
  const navigate = useNavigate();
  const { org } = useParams();

  const drawerRef = useRef();
  const ticketRef = useRef();
  const tagsref = useRef();
  const modalref = useRef();
  const searchRef = useRef();
  const inputRef = useRef();
  const divRef = useRef();
  const sprintRef = useRef();
  const [searchTicket, setSearchTicket] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [loader, setLoader] = useState({
    fir: true,
    sec: true,
    thi: true,
  });

  const [ticketDataComplete, setTicketDataComplete] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [userTicket, setUserTicket] = useState([]);
  const users = useSelector(selectUserData);
  const res = useSelector(selectResData);
  const [tableData, setTableData] = useState([]);
  const [sortOrder, setSortOrder] = useState(new Set(["latest"]));
  const userNames = useSelector(selectUserNames);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTicketOpen, setIsTicketOpen] = useState(false);
  const [ticket, setTicket] = useState();
  const [page, setPage] = useState(1);
  const orgDetails = useSelector(selectOrgData);
  const [customizeItems, setCustomizeItems] = useState(new Set(["Stage"]));
  const [userPerm, setUserPerm] = useState({
    name: "Role-2",
    class: "Issues",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });

  const [filterPriority, setFilterPriority] = useState(new Set([]));
  const [parsedParts, setParsedParts] = useState({});
  const [customTags, setCustomTags] = useState([]);
  const [filterDropdown, setFilterDropdowm] = useState(new Set(["Work Type"]));
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [isCreatedTickets, setIsCreatedTickets] = useState(false);
  const [parsedSprints, setParsedSprints] = useState(null);
  const coulmnDef = Array.from(customizeItems).map((name) => {
    return {
      id: name.toLowerCase(),
      label: name,
    };
  });
  const customizeHeaderList = [
    {
      id: "items",
      label: "Issue ID",
    },

    {
      id: "title",
      label: "Title",
    },
    {
      id: "assigned to",
      label: "Assigned to",
    },
    ...coulmnDef,
    {
      id: "delete",
      label: "",
    },
  ];
  const [selectedGroup, setSelectedGroup] = useState(new Set(["None"]));
  const groupItems = [
    "None",
    "owner",
    "created by",
    "assigned",
    "stage",
    "part",
    "sprint",
    "priority",
    "channels",
    "group",
    "needs response",
    "severity",
    "SLA Name",
    "Source Channel",
    "Subtype",
    "CSA Rating",
    "tags",
  ];
  const rowsPerPage = 13;

  const pages = Math.ceil(ticketData.length / rowsPerPage);

  const groupByField = (items, groupBy) => {
    if (groupBy === "created by") {
      groupBy = "created_by";
    }
    if (groupBy === "tags") {
      const group = {};
      items.map((item) => {
        item?.payload?.tags?.map((t) => {
          if (!group[String(t)]) {
            group[String(t)] = [];
          }
          group[String(t)].push(item);
        });
      });
      Object.keys(group).forEach((key) => {
        const find = customTags.find((tag) => String(tag.id) === String(key));
        if (!find) {
          delete group[key];
        }
      });
      return group;
    } else {
      const groups = items.reduce((groups, item) => {
        const key = item?.payload[groupBy] || "unassigned";
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(item);
        return groups;
      }, {});
      const entries = Object.entries(groups);
      entries.sort(([keyA], [keyB]) => {
        if (keyA === "unassigned") return 1;
        if (keyB === "unassigned") return -1;
        return 0;
      });
      const sortedGroups = Object.fromEntries(entries);
      return sortedGroups;
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const toggleTicket = (e) => {
    setTicket(e);
    setIsTicketOpen(!isTicketOpen);
  };

  const handleDelete = async (item) => {
    try {
      await deleteData(item.id, org);
      const promises = item.images.map((id) =>
        DeleteSingleAttribute("upload/files", Number(id.id))
      );
      await Promise.all(promises);

      window.location.reload();
    } catch (error) {
      window.location.reload();
    }
  };
  const [selectedStageKeys, setSelectedStageKeys] = useState(new Set([]));
  const handleStage = (e) => {
    let items = Array.from(selectedStageKeys);
    if (e.every((element) => items.includes(element))) {
      items = items.filter((element) => !e.includes(element));
    } else {
      items = items.concat(e);
    }

    setSelectedStageKeys(new Set(items));
  };
  const handleDeleteKey = (item) => {
    let updatedKeys = new Set(selectedStageKeys);
    updatedKeys.delete(item);
    setSelectedStageKeys(updatedKeys);
  };
  const handleExportCsv = async () => {
    if (parsedParts === null || res === null) {
      return;
    }
    const parsedSprints = await fetchSprints(orgDetails.id);
    const data = ticketDataComplete.map((item) =>
      parseIssues(item.payload, parsedParts, customTags, res, parsedSprints)
    );

    downloadCSV(data, "issues");
  };
  const handleExportJSON = () => {
    if (parsedParts === null || res === null) {
      return;
    }
    const data = ticketDataComplete.map((item) =>
      parseIssues(item.payload, parsedParts, customTags, res, parsedSprints)
    );
    downloadJSON(data, "issues");
  };
  const handleClearFilters = () => {
    setFilterPriority(new Set([]));
    setSelectedStageKeys(new Set([]));
    setIsCreatedTickets(false);
    setSearchTicket("");
  };
  const handleCloseTicket = () => {
    setIsTicketOpen(false);
  };
  useEffect(() => {
    const fetchAllData = async () => {
      if (orgDetails?.id > 0) {
        try {
          // Fetch parts data
          const partsResp = await getFilteredBySingleRelation(
            "parts",
            "organization",
            orgDetails.id
          );
          const sprints = await fetchSprints(orgDetails.id);
          setParsedSprints(sprints);
          const convertedParts = {};
          partsResp?.data?.forEach((item) => {
            convertedParts[item?.id] = {
              Name: item?.attributes?.Name,
              Type: item?.attributes?.Type,
              ParentPath: item?.attributes?.ParentPath,
              Owner: item?.attributes?.users_permissions_user?.data?.id,
            };
          });
          setParsedParts(convertedParts);

          // Fetch tags data
          const tagsResp = await getFilteredBySingleRelation(
            "tags",
            "organization",
            orgDetails.id
          );
          setCustomTags(tagsResp.data);
          setLoader((prevLoader) => ({ ...prevLoader, fir: false }));
        } catch (error) {
          console.error("Error fetching data:", error.status, error.message);
          throw error;
        }
      }
    };

    fetchAllData();
  }, [orgDetails]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!sprintRef.current || !sprintRef.current.contains(event.target))
      ) {
        toggleDrawer();
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ticketRef.current &&
        !ticketRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!sprintRef.current || !sprintRef.current.contains(event.target))
      ) {
        handleCloseTicket();
      }
    };
    if (isTicketOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTicketOpen]);
  useEffect(() => {
    async function fetchDocument() {
      try {
        const tickData = await getDocument("type", "issue", org);
        if (tickData) {
          setTicketDataComplete(
            tickData.sort((a, b) => b.payload.latest - a.payload.latest)
          );
          setTicketData(
            tickData.sort((a, b) => b.payload.latest - a.payload.latest)
          );
          setLoader((prevLoader) => ({ ...prevLoader, sec: false }));
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    }
    fetchDocument();
  }, []);

  useEffect(() => {
    if (ticketDataComplete.length > 0) {
      const filteredTickets = ticketDataComplete.filter(
        (ticket) => Number(ticket.payload.assigned) === userDataId
      );
      setUserTicket(filteredTickets);
    }
  }, [ticketDataComplete]);
  useEffect(() => {
    const fetchPerm = async () => {
      try {
        const resp = await PermissionGroupSingleRole(
          userDataId,
          orgDetails.id,
          "Role-2",
          "dev"
        );

        if (resp !== false) {
          setUserPerm(resp);
        }
        setLoader((prevLoader) => ({ ...prevLoader, thi: false }));
      } catch (error) {}
    };
    if (orgDetails?.id) {
      fetchPerm();
    }
  }, [orgDetails, userDataId]);
  useEffect(() => {
    const handlePageChange = () => {
      let data = [];
      if (page === pages) {
        for (let i = (page - 1) * rowsPerPage; i < ticketData.length; i++) {
          data.push(ticketData[i]);
        }
      } else {
        for (let i = (page - 1) * rowsPerPage; i < page * rowsPerPage; i++) {
          data.push(ticketData[i]);
        }
      }
      setTableData(data);
    };
    if (ticketData.length > 0) {
      handlePageChange();
    }
  }, [ticketData, page, pages, rowsPerPage]);
  useEffect(() => {
    let filteredTickets = [...ticketDataComplete];
    if (Array.from(selectedStageKeys).length !== 0) {
      filteredTickets = ticketDataComplete.filter((ticket) =>
        Array.from(selectedStageKeys).includes(ticket.payload.stage)
      );
    }
    if (isCreatedTickets) {
      filteredTickets = filteredTickets.filter((item) =>
        userTicket.includes(item)
      );
    }
    if (searchTicket !== "") {
      filteredTickets = filteredTickets.filter((item) =>
        item.payload.title.toLowerCase().includes(searchTicket.toLowerCase())
      );
    }

    if (Array.from(filterPriority).length > 0) {
      filteredTickets = filteredTickets.filter((item) =>
        Array.from(filterPriority).includes(item.payload.priority)
      );
    }
    if (Array.from(sortOrder)[0] === "oldest") {
      filteredTickets = filteredTickets.sort(
        (a, b) => a.payload.latest - b.payload.latest
      );
    }
    if (Array.from(sortOrder)[0] === "latest") {
      filteredTickets = filteredTickets.sort(
        (a, b) => b.payload.latest - a.payload.latest
      );
    }

    setTicketData(filteredTickets);
  }, [
    selectedStageKeys,
    isCreatedTickets,
    searchTicket,
    filterPriority,
    sortOrder,
  ]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && searchTicket === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, searchTicket]);
  return (
    <div className="h-full flex flex-col overflow-y-auto w-full">
      {isTicketOpen && (
        <div ref={ticketRef}>
          <Issue
            inputRef={sprintRef}
            users={users}
            tenant={{ name: org, id: orgDetails?.id }}
            pos="issue"
            divRef={divRef}
            dropdownref={tagsref}
            ticket={ticket}
            res={res}
            handleCloseTicket={handleCloseTicket}
            modalref={modalref}
            permission={userPerm.privileges[0].update}
            userNames={userNames}
          />
        </div>
      )}
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <Drawer
            inputRef={sprintRef}
            users={users}
            tenant={{ name: org, id: orgDetails?.id }}
            modalref={modalref}
            res={res}
            dropdownref={tagsref}
            query="issue"
            toggleDrawer={toggleDrawer}
            userNames={userNames}
          />
        </div>
      )}

      <div className=" h-[130px] bg-white">
        <div className="flex mx-8 justify-between pt-8">
          <div className=" flex text-center justify-center items-center">
            <Breadcrumb />
            <Breadcrumbs size="sm" isDisabled>
              <BreadcrumbItem>Issues</BreadcrumbItem>
            </Breadcrumbs>
          </div>
          <div className="flex items-center">
            <Button
              className="mx-2 border border-divider"
              size="sm"
              color="default"
              variant="light"
              startContent={<MdFormatListBulletedAdd />}
            >
              Save as
            </Button>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  size="sm"
                  isIconOnly
                  color="default"
                  variant="light"
                  className="mx-2 border border-divider"
                >
                  <svg
                    style={{ width: "15px", height: "15px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                    />
                  </svg>
                </Button>
              </DropdownTrigger>
              <DropdownMenu className="w-[250px]">
                <DropdownSection showDivider>
                  <DropdownItem
                    isReadOnly
                    startContent={
                      <div className=" flex justify-center items-center p-2 rounded-md bg-purple-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#AC70FF"
                          className="size-6"
                          style={{ width: "17px", height: "17px" }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                          />
                        </svg>
                      </div>
                    }
                    endContent={
                      <Switch size="sm" aria-label="Automatic updates" />
                    }
                  >
                    <div className="flex">
                      <p className=" font-semibold text-xs">Smart Cluster</p>
                    </div>
                  </DropdownItem>
                </DropdownSection>
                <DropdownSection title="Export view">
                  <DropdownItem
                    onClick={handleExportCsv}
                    startContent={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#6E9CFD"
                        className="size-6"
                        style={{ width: "17px", height: "17px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                        />
                      </svg>
                    }
                  >
                    <p className="text-xs"> Export as CSV</p>
                  </DropdownItem>
                  <DropdownItem
                    onClick={handleExportJSON}
                    startContent={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#6E9CFD"
                        className="size-6"
                        style={{ width: "17px", height: "17px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                        />
                      </svg>
                    }
                  >
                    <p className="text-xs"> Export as JSON</p>
                  </DropdownItem>
                </DropdownSection>
              </DropdownMenu>
            </Dropdown>

            {userPerm.privileges[0].create === true && (
              <Button
                size="sm"
                className="mx-2 text-white bg-[rgb(51,51,245)]"
                onClick={toggleDrawer}
              >
                + Issue
              </Button>
            )}
          </div>
        </div>
        <div className="z-0 flex pb-4 px-8 border-b-1 border-divider mt-4 items-center justify-between">
          <div className="flex items-center gap-4">
            <div
              ref={searchRef}
              onClick={() => setIsSearchOpen(true)}
              className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${
                isSearchOpen
                  ? "pl-2 w-40 gap-2 justify-start"
                  : "w-8 justify-center"
              } rounded-full  h-8 `}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              {isSearchOpen && (
                <input
                  ref={inputRef}
                  value={searchTicket}
                  onChange={(e) => setSearchTicket(e.target.value)}
                  style={{ height: "20px", outline: "none" }}
                  className="w-full px-2 text-xs rounded-full"
                  placeholder="Search"
                />
              )}
            </div>
            <div
              onClick={(e) => setIsCreatedTickets(!isCreatedTickets)}
              className={`border cursor-pointer border-divider w-8 h-8 rounded-full flex ${
                isCreatedTickets ? " bg-[#DBDADF]" : " hover:bg-[#DBDADF]"
              } items-center justify-center`}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                />
              </svg>
            </div>

            {Array.from(filterDropdown).includes("Work Type") && (
              <div className="flex border rounded-sm  ">
                <div className=" flex items-center">
                  <h2 className="text-xs ml-2 mr-2"> Work Type</h2>
                  <div className="border-l border-gray-300 h-full " />
                </div>
                <div className="  rounded-r-md text-xs">
                  <Button
                    size="sm"
                    radius="none"
                    color="primary"
                    variant="light"
                  >
                    Issue
                  </Button>
                </div>
              </div>
            )}
            {Array.from(filterDropdown).includes("Priority") && (
              <div className="flex border rounded-sm">
                <div className="flex items-center">
                  <h2 className="text-xs ml-2 mr-2">Priority</h2>
                  <div className="border-l border-gray-300 h-full" />
                </div>
                <div className="rounded-r-md text-xs">
                  <Dropdown>
                    <DropdownTrigger>
                      <Button size="sm" radius="none" variant="light">
                        {Array.from(filterPriority).length === 0
                          ? "Add"
                          : Array.from(filterPriority)[0]}{" "}
                        {filterPriority.size - 1 <= 0
                          ? ""
                          : `+${filterPriority.size - 1}`}
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      variant="flat"
                      closeOnSelect={false}
                      selectionMode="multiple"
                      selectedKeys={Array.from(filterPriority)}
                      onSelectionChange={(keys) => {
                        setFilterPriority(new Set(keys));
                      }}
                    >
                      <DropdownItem key="P1">P1</DropdownItem>
                      <DropdownItem key="P2">P2</DropdownItem>
                      <DropdownItem key="P3">P3</DropdownItem>
                      <DropdownItem key="P4">P4</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            )}
            {Array.from(filterDropdown).includes("Stage") && (
              <div className="flex border rounded-sm  ">
                <div className=" flex items-center">
                  <h2 className="text-xs ml-2 mr-2">Stage</h2>
                  <div className="border-l border-gray-300 h-full " />
                </div>
                <div className="  rounded-r-md text-xs">
                  <Dropdown>
                    <DropdownTrigger>
                      <Button
                        className=" capitalize"
                        size="sm"
                        radius="none"
                        variant="light"
                      >
                        {Array.from(selectedStageKeys).length === 0
                          ? "Add"
                          : Array.from(selectedStageKeys)[0]}{" "}
                        {selectedStageKeys.size - 1 <= 0
                          ? ""
                          : `+${selectedStageKeys.size - 1}`}
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      className="h-80 w-[300px] overflow-y-auto"
                      variant="flat"
                      closeOnSelect={false}
                      selectionMode="multiple"
                      selectedKeys={selectedStageKeys}
                      onSelectionChange={setSelectedStageKeys}
                    >
                      {selectedStageKeys.size > 0 && (
                        <DropdownItem
                          isReadOnly
                          className="cursor-default border"
                        >
                          <div className=" h-auto w-full p-2 flex flex-wrap gap-2 overflow-y-auto">
                            {Array.from(selectedStageKeys).map((item) => (
                              <Chip
                                variant="bordered"
                                size="sm"
                                key={item}
                                onClick={() => handleDeleteKey(item)}
                                className="cursor-pointer text-gray-600 border border-divider gap-2"
                                endContent={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-4"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18 18 6M6 6l12 12"
                                    />
                                  </svg>
                                }
                              >
                                {item}
                              </Chip>
                            ))}
                          </div>
                        </DropdownItem>
                      )}
                      <DropdownSection
                        showDivider
                        title={
                          <Button
                            onClick={() => handleStage(open)}
                            className="w-full justify-start"
                            size="sm"
                            variant="light"
                          >
                            Open
                          </Button>
                        }
                      >
                        <DropdownItem key="Queued">Queued</DropdownItem>
                      </DropdownSection>
                      <DropdownSection
                        showDivider
                        title={
                          <Button
                            onClick={() => handleStage(progress)}
                            className="w-full justify-start"
                            size="sm"
                            variant="light"
                          >
                            In progress
                          </Button>
                        }
                      >
                        <DropdownItem key="Work in progress">
                          Work in progress
                        </DropdownItem>
                        <DropdownItem key="Awaiting Product assist">
                          Awaiting Product assist
                        </DropdownItem>
                        <DropdownItem key="Awaiting Development">
                          Awaiting Development
                        </DropdownItem>
                        <DropdownItem key="In Development">
                          In Development
                        </DropdownItem>
                        <DropdownItem key="Awaiting Customer Response">
                          Awaiting Customer Response
                        </DropdownItem>
                      </DropdownSection>
                      <DropdownSection
                        title={
                          <Button
                            onClick={() => handleStage(closed)}
                            className="w-full justify-start"
                            size="sm"
                            variant="light"
                          >
                            Closed
                          </Button>
                        }
                      >
                        <DropdownItem key="Accepted">Accepted</DropdownItem>
                        <DropdownItem key="Resolved">Resolved</DropdownItem>
                        <DropdownItem key="Cancelled">Cancelled</DropdownItem>
                      </DropdownSection>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            )}
            <Dropdown>
              <DropdownTrigger>
                <Button
                  size="sm"
                  variant="light"
                  className=" border border-divider"
                  isIconOnly
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Multiple selection"
                variant="flat"
                closeOnSelect={false}
                selectionMode="multiple"
                disallowEmptySelection={false}
                selectedKeys={filterDropdown}
                onSelectionChange={setFilterDropdowm}
              >
                <DropdownItem key="Work Type">Work Type</DropdownItem>
                <DropdownItem key="Stage">Stage</DropdownItem>
                <DropdownItem key="Priority">Priority</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Button
              onClick={() => handleClearFilters()}
              size="sm"
              variant="light"
            >
              Clear
            </Button>
          </div>
          <div className="flex gap-2 items-center">
            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" variant="light">
                  Sort By
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Sort options"
                variant="flat"
                selectionMode="single"
                selectedKeys={sortOrder}
                onSelectionChange={setSortOrder}
              >
                <DropdownItem key="latest">Latest</DropdownItem>
                <DropdownItem key="oldest">Oldest</DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" variant="light">
                  Group
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                className=" h-[200px] overflow-y-auto"
                aria-label="Single selection"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedGroup}
                onSelectionChange={setSelectedGroup}
              >
                {groupItems.map((group) => (
                  <DropdownItem key={group}>
                    <div className=" capitalize">{group}</div>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" variant="light">
                  Customize
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Multiple selection"
                variant="flat"
                closeOnSelect={false}
                selectionMode="multiple"
                disallowEmptySelection={false}
                selectedKeys={customizeItems}
                onSelectionChange={setCustomizeItems}
              >
                {customizeCompleteList.map((name) => (
                  <DropdownItem key={name}>{name}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
      {loader.fir || loader.sec || loader.thi ? (
        <div className="flex flex-col items-center justify-center mt-64">
          <HelixLoader />
        </div>
      ) : ticketData.length === 0 ? (
        <div className="flex mt-60 flex-col w-full items-center justify-center">
          <PiCoffeeThin
            style={{
              width: "50px",

              height: "50px",

              color: "#6B7280",

              opacity: "90%",
            }}
          />

          <p className="text-xs text-opacity-90 text-gray-500">
            No Issues. Time for coffee.
          </p>
        </div>
      ) : (
        <div className=" flex-1 flex-col gap-4 overflow-y-auto w-full">
          {Array.from(selectedGroup)[0] === "None" ? (
            <Table
              removeWrapper
              // radius="none"
              // selectionMode="single"
              className=" text-xs pt-4 px-8"
              // color="default"
              // hideHeader
              aria-label="Custom Table"
            >
              <TableHeader columns={customizeHeaderList}>
                {(column) => (
                  <TableColumn key={column.id} className="text-xs">
                    {column.label}
                  </TableColumn>
                )}
              </TableHeader>

              <TableBody
                emptyContent={"No tickets. Time for coffee."}
                className="[&>tr]:first:rounded-lg [&>tr]:last:rounded-lg"
              >
                {tableData?.map((row, ind) => (
                  <TableRow
                    className=" cursor-pointer text-xs rounded-lg hover:bg-gray-100 border-b border-divider"
                    onClick={(e) => toggleTicket(row)}
                    key={(page - 1) * rowsPerPage + ind}
                  >
                    {customizeHeaderList.map((item) => {
                      return renderCell(
                        row,
                        item.id,
                        res,
                        parsedParts,
                        handleDelete,
                        customTags,
                        false,
                        userPerm.privileges[0].delete
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <GroupIssues
              parsedSprints={parsedSprints}
              parsedParts={parsedParts}
              handleDelete={handleDelete}
              customTags={customTags}
              customizeHeaderList={customizeHeaderList}
              tickets={groupByField(ticketData, Array.from(selectedGroup)[0])}
              orderby={Array.from(selectedGroup)[0]}
              res={res}
              tenant={{ name: org, id: orgDetails?.id }}
              toggleTicket={toggleTicket}
            />
          )}

          {ticketData.length >= rowsPerPage &&
            Array.from(selectedGroup)[0] === "None" && (
              <div className={`flex w-full justify-center items-center `}>
                <Pagination
                  isCompact
                  showControls
                  showShadow
                  color="danger"
                  page={page}
                  total={pages}
                  onChange={setPage}
                  className="mt-4"
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default Issues;
