import { Button, Card } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { IoIosClose } from 'react-icons/io'
import CheckboxGroup from './CheckboxGroup'
import { UpdateData } from '../../controllers/strapiController';

function PrivilegeDrawer({category,Class,closeDrawer,roles,detail,update,setUpdate,groupId,type}) {
    const [perm, setPerm] = useState(JSON.parse(JSON.stringify(detail)));
    const handleSave = async() =>{
        let formData = {} ;
        if(category==="Dev"){
            formData = {
                dev_privileges : perm
            }
        }
        if(category==="Product"){
            formData = {
                product_privileges : perm
            }
        }
        if(category==="Org Related"){
            formData = {
                org_privileges: perm
            }
        }
        if(category==="Support"){
            formData = {
                support_privileges:perm
            }
        }
        if(category==="Analytics"){
            formData ={
                analytics_privileges : perm
            }
        }
        try{
            const resp = await UpdateData("groups",groupId,formData);
         
            setUpdate(!update);
            closeDrawer();
        }
        catch(error){
            
        }
    }
  return (
    <div className=" w-96 p-4 shadow-lg overflow-y-auto absolute top-0 right-0 h-full bg-white z-50">
      <div className=' pb-4 flex items-center justify-between'>
        <p className=' text-base'>{`${category}/ ${Class}`}</p>
        <Button isIconOnly variant="light" size="sm" onClick={closeDrawer}>
            <IoIosClose style={{ width: "50px" }} />
          </Button>
      </div>
        <div className='flex flex-col gap-2'>
            {roles.filter((item) => item?.attributes?.Class === Class).map((r) => (
                <CheckboxGroup category={category} type={type} role={r} perm={perm} setPerm={setPerm} />
            )) }
        </div>
        <div className='flex items-center justify-end pt-4'>
            <Button disabled={type === "Admin"} onClick={()=> handleSave()} color={type==="Admin" ? "default" : "primary"} size='sm'>Save</Button>
        </div>
    </div>
  )
}

export default PrivilegeDrawer
