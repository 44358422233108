import { Card, CardBody } from '@nextui-org/react'
import React from 'react'
import Profile from './Profile';

function ConvoList({threads,setToggle,setThreadId,setConvo,org}) {
  function timeAgo(timestamp) {
    const now = Date.now();
    const timeDiff = now - timestamp;
  
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else {
      return `${days} days ago`;
    }
  }
    const handleSelect = (item) => {
        setThreadId(item.payload.id);
        setConvo(item.payload.messages);
        setToggle(true);
    }

  return (
    <div className=' h-[400px] w-full overflow-y-auto'>
      {threads.sort((a,b) => b.payload.updatedAt - a.payload.updatedAt).map((item) => (
        <Card onClick={() => handleSelect(item)} shadow='none' className=' border border-divider my-2' fullWidth isPressable>
            <CardBody className=' flex flex-col gap-2'>
                  <div className='flex items-center justify-between'>
                    <Profile insideChat={false} assignedId={item.payload.assignedId} org={org} />
                    <p className=' text-xs text-gray-400'>{timeAgo(item.payload.messages[item.payload.messages?.length - 1].timestamp)}</p>
                  </div>
                <p className=' text-xs pl-2'>{item.payload.messages[item.payload.messages?.length - 1].text.replace(/<[^>]*>/g, '').slice(0, 40)}</p>
            </CardBody>
        </Card>
      ))}
    </div>
  )
}

export default ConvoList
