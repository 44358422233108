import React from 'react'
import Breadcrumb from '../../components/BreadCrumb/Breadcrumb'
import { BreadcrumbItem, Breadcrumbs, Button } from '@nextui-org/react'

function InboxNav() {
  return (
    <div className=" pt-8 mx-8 flex items-center justify-between ">
        <div className=" flex items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm" isDisabled>
            <BreadcrumbItem>Inbox</BreadcrumbItem>
          </Breadcrumbs>
        </div>
        <Button size="sm" className="capitalize text-white bg-[rgb(51,51,245)]">
          Export
        </Button>
      </div>
  )
}

export default InboxNav
