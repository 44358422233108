import React from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@nextui-org/react";

const DeleteModal = ({ isOpen, onClose, onConfirm, userName, partName }) => {
  const deleteMessage = userName
    ? `Are you sure you want to delete the user: ${userName}?`
    : partName
    ? `Are you sure you want to delete the part: ${partName}?`
    : 'Are you sure you want to delete?';

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>Confirm Deletion</ModalHeader>
        <ModalBody>
          <p>{deleteMessage}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
