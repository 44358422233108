import React, { useState, useEffect } from "react";
import {patchData } from "../../controllers/vectorDB";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownTrigger,
  DatePicker,
  Input,
  Button,
  Avatar,
  Divider,
  Chip,
  DropdownSection,
} from "@nextui-org/react";
import { extensions, props, MenuBar } from "../../components/markdown/markdown";
import { EditorProvider } from "@tiptap/react";
import {  parseDate} from "@internationalized/date";
import {
  DeleteSingleAttribute,
  getFilteredBySingleRelation,
} from "../../controllers/strapiController";
import Tags from "../../components/Tag/tags";
import PartPicker from "../../components/Drawer/PartPicker";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectOrgData } from "../../redux/actions";
import LinkIssues from "../../components/Drawer/LinkIssues";
import { issuesTicketLink } from "../../utils/linkissues";
import { sendUpdateTickets } from "../../utils/mentions";
const Ticket = ({
  ticket,
  res,
  handleCloseTicket,
  dropdownref,
  modalref,
  pos,
  inputRef,
  divRef,
  tenant,
  users,
  permission,
  userNames,
}) => {
  const [descriptionContent, setDescriptionContent] = useState(
    ticket?.payload?.description
  );
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const progress = [
    "Work in progress",
    "Awaiting Product assist",
    "Awaiting Development",
    "In Development",
    "Awaiting Customer Response",
  ];
  const closed = ["Accepted", "Resolved", "Cancelled"];
  const [logs, setLogs] = useState(ticket?.payload?.logs ?? []);
  const [ownerInfo, setOwnerInfo] = useState();
  const [title, setTitle] = useState(ticket?.payload?.title);
  const [tags, setTags] = useState(ticket?.payload?.tags);
  const [selectedPriority, setSelectedPriority] = useState(
    ticket?.payload?.priority
      ? new Set([ticket?.payload?.priority])
      : new Set([])
  );

  const [tagsDetail, setTagsDetail] = useState([]);
  const priorityData = {
    Urgent: {
      color: "text-red-500",
      label: "Urgent",
      id: "P1",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="size-5 text-red-500"
        >
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    High: {
      color: "text-yellow-500",
      id: "P2",
      label: "High",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="size-5 text-yellow-500"
        >
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    Normal: {
      color: "text-blue-500",
      label: "Normal",
      id: "P3",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="size-5 text-blue-500"
        >
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    Low: {
      color: "text-gray-500",
      id: "P4",
      label: "Low",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="size-5 text-gray-500"
        >
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
  };
  const selected = Array.from(selectedPriority)[0];
  const selectedPriorityData = selected
    ? priorityData[
        Object.keys(priorityData).find(
          (key) => priorityData[key].id === selected
        )
      ]
    : null;

  const selectedd = Array.from(selectedPriority)[0];
  const selectedData = priorityData[selectedd] || {};

  const [selectedReporter, setSelectedReporter] = useState(
    new Set([ticket?.payload?.assigned])
  );
  const [imageIds, setImageIds] = useState(ticket?.payload?.imageIds);
  const [reporterInfo, setReporterInfo] = useState({
    username: res[ticket?.payload?.assigned]?.UserName,
    id: ticket?.payload?.assigned,
    name: res[ticket?.payload?.assigned]?.FirstName,
    url:
      process.env.REACT_APP_STAGE === "Dev"
        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
            res[ticket?.payload?.assigned]?.ProfilePicture
          }`
        : `${res[ticket?.payload?.assigned]?.ProfilePicture}`,
  });
  const [selectedDate, setSelectedDate] = useState(
    ticket?.payload?.targetclosedate
  );
  const [selectedIssueIds, setSelectedIssueIds] = useState(
    ticket?.payload?.linkedIssues
      ? new Set(ticket?.payload?.linkedIssues)
      : new Set([])
  );
  const [selectedKeys, setSelectedKeys] = useState(new Set([]));
  const [selectedEstimatedDays, setSelectedEstimatedDays] = useState(
    ticket?.payload?.estimatedays
  );
  const orgData = useSelector(selectOrgData);
  const [selectedPart, setSelectedPart] = useState(
    new Set([Number(ticket?.payload?.part)])
  );

  const [start, setStart] = useState(false);
  const [selectedType, setSelectedType] = useState(["ticket"]);
  const [partData, setPartData] = useState();
  const [stage, setStage] = useState(new Set([ticket?.payload?.stage]));
  const location = useLocation();

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        window.location.origin +
          location.pathname +
          `?ticket=${ticket?.payload?.latest}`
      );
      toast("Link copied", {
        duration: 3000,
        position: "top-center",
      });
    } catch (err) {
      toast("Cannot copy link!", {
        duration: 3000,
        position: "top-center",
      });
    }
  };
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors?.length;
  function convertToDateStr(dateObj) {
    const { year, month, day } = dateObj;
    const monthStr = String(month).padStart(2, "0");
    const dayStr = String(day).padStart(2, "0");
    return `${year}-${monthStr}-${dayStr}`;
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const updateReporter = (user) => {
    setReporterInfo({
      username: user.username,
      id: user.id,
      name: user.FirstName,
      url: user.ProfilePicture
        ? process.env.REACT_APP_STAGE === "Dev"
          ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user.ProfilePicture}`
          : `${user.ProfilePicture}`
        : null,
    });
  };
  const checkLogs = () => {
    function areArraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
    
      // Sort both arrays
      const sortedArr1 = arr1.slice().sort();
      const sortedArr2 = arr2.slice().sort();
    
      // Compare sorted arrays
      return sortedArr1.every((value, index) => value === sortedArr2[index]);
    }
    const logTimestamp = Date.now();
    const changes = [];
    const fieldsToCheck = [
      { field: "title", current: title, previous: ticket?.payload?.title },
      {
        field: "description",
        current: descriptionContent,
        previous: ticket?.payload?.description,
      },
      {
        field: "stage",
        current: Array.from(stage)[0],
        previous: ticket?.payload?.stage,
      },
      {
        field: "priority",
        current: selectedData.id,
        previous: ticket?.payload?.priority,
      },
      { field: "tags", current: tags, previous: ticket?.payload?.tags },
      { field: "linked issues", current: Array.from(selectedIssueIds) , previous: ticket?.payload?.linkedIssues },
      {
        field: "assigned",
        current: Array.from(selectedReporter)[0],
        previous: ticket?.payload?.assigned,
      },
      {
        field: "estimated days",
        current: selectedEstimatedDays,
        previous: ticket?.payload?.estimatedays,
      },
      {
        field: "part",
        current: String(Array.from(selectedPart)[0]),
        previous: String(ticket?.payload?.part),
      },
    ];

    fieldsToCheck.forEach(({ field, current, previous }) => {
      if (current !== previous) {
        changes.push({
          operation: "updated",
          field,
          to: current,
          by: userDataId,
          timestamp: logTimestamp,
        });
      }
    });
    
    if(!areArraysEqual(Array.from(selectedIssueIds), ticket?.payload?.linkedIssues)){
      changes.push({
        operation: "updated",
        field: "linked issues",
        to: Array.from(selectedIssueIds),
        by: userDataId,
        timestamp: logTimestamp,
      });
    }
    // Check for date change
    if (
      selectedDate?.day !== ticket?.payload?.targetclosedate?.day ||
      selectedDate?.month !== ticket?.payload?.targetclosedate?.month ||
      selectedDate?.year !== ticket?.payload?.targetclosedate?.year
    ) {
      changes.push({
        operation: "updated",
        field: "target close date",
        to: selectedDate,
        by: userDataId,
        timestamp: logTimestamp,
      });
    }

    return changes;
  };
  const handleSubmit = async () => {
    const arr = imageIds.filter((item) =>
      descriptionContent.includes(item.url)
    );
    const deletearr = imageIds.filter(
      (item) => !descriptionContent.includes(item.url)
    );
    const logArray = checkLogs();
    if (logArray.length > 0) {
      setLogs((prev) => [...prev, ...logArray]);
    } else {
      return;
    }
    const formData = {
      title: title,
      linkedIssues : Array.from(selectedIssueIds) ,
      imageIds: arr,
      imagePresent: arr?.length === 0 ? false : true,
      description: descriptionContent,
      owner: String(ownerInfo.id),
      stage: Array.from(stage)[0],
      priority: selectedData.id,
      tags: tags,
      logs: logs,
      assigned: Array.from(selectedReporter)[0],
      targetclosedate: selectedDate,
      estimatedays: selectedEstimatedDays,
      part: Array.from(selectedPart)[0],
    };
    try {
      await issuesTicketLink("ticket",Array.from(selectedIssueIds),ticket.payload.id, tenant.name);
      await sendUpdateTickets(ownerInfo.id,Array.from(selectedReporter)[0],descriptionContent,userDataId,"ticket","update",users,res,`issues/${ticket?.payload?.latest}`,Array.from(selectedPart)[0],ticket?.payload?.latest , tenant.id)
      await patchData(formData, ticket.payload.id, tenant.name);
      const promises = deletearr.map((id) =>
        DeleteSingleAttribute("upload/files", Number(id.id))
      );
      await Promise.all(promises);
      window.location.reload();
    } catch (error) {}
  };
  useEffect(() => {
    let arr = [];
    if (ticket.payload.tags && ticket.payload.tags?.length !== 0) {
      arr.push("tags");
    }
    if (
      ticket?.payload?.targetclosedate &&
      ticket?.payload?.targetclosedate !== ""
    ) {
      arr.push("date");
    }
    if (ticket?.payload?.estimatedays && ticket?.payload?.estimatedays !== "") {
      arr.push("days");
    }
    setSelectedKeys(new Set(arr));
  }, [ticket]);
  const [customTags, setCustomTags] = useState([]);
  const [parsedParts, setParsedParts] = useState(null);
  useEffect(() => {
    const getAllDataByIds = async () => {
      if (!orgData?.id) return;

      try {
        // Fetch parts
        const partsResp = await getFilteredBySingleRelation(
          "parts",
          "organization",
          orgData.id
        );
        setPartData(partsResp.data);

        // Process and convert parts after fetching them
        const convertedParts = {};
        partsResp?.data?.forEach((item) => {
          convertedParts[item?.id] = {
            Name: item?.attributes?.Name,
            Type: item?.attributes?.Type,
            ParentPath: item?.attributes?.ParentPath,
            Owner: item?.attributes?.users_permissions_user?.data?.id,
          };
        });
        setParsedParts(convertedParts);

        // Fetch tags
        const tagsResp = await getFilteredBySingleRelation(
          "tags",
          "organization",
          orgData.id
        );
        setCustomTags(tagsResp.data);
        const filtered = tagsResp.data.filter((option) =>
          ticket?.payload?.tags.includes(option.id)
        );
        setTagsDetail(filtered);
      } catch (error) {
        console.error("Error fetching data for all IDs:", error);
        throw error;
      }
    };

    getAllDataByIds();
  }, [orgData]);
  useEffect(() => {
    if (Array.from(selectedPart)?.length > 0 && parsedParts) {
      const idOwner = String(parsedParts[Array.from(selectedPart)[0]]?.Owner);
      setOwnerInfo((prev) => ({
        ...prev,
        id: idOwner,
        username: res[idOwner]?.UserName,
        name: res[idOwner]?.FirstName,
        url:
          process.env.REACT_APP_STAGE === "Dev"
            ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[idOwner]?.ProfilePicture}`
            : `${res[idOwner]?.ProfilePicture}`,
      }));
    }
  }, [selectedPart, parsedParts]);
  return (
    <div
      className={`w-96 p-4 shadow-lg overflow-y-auto absolute top-0 ${
        pos === "ticket" ? "right-0" : "right-96"
      } h-full bg-white z-50`}
    >
      <Toaster />
      <div className="flex items-center justify-between">
        <Chip size="sm" variant="flat" radius="full" color="danger">
          TKT-{ticket?.payload?.latest}
        </Chip>
        <div className=" flex items-center">
          <Button
            onClick={() => handleCopyLink()}
            isIconOnly
            size="sm"
            variant="light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
              />
            </svg>
          </Button>

          <Button
            isIconOnly
            variant="light"
            size="sm"
            onClick={handleCloseTicket}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </Button>
        </div>
      </div>
      <Input
        className="my-4"
        variant="bordered"
        type="text"
        classNames={{
          input: ["bg-white", "hover:bg-white"],
          innerWrapper: ["bg-white", "hover:bg-white"],
          inputWrapper: [
            "shadow-none",
            "border",
            "border -divider",
            "bg-white",
            "dark:bg-white",
            "hover:bg-white",
            "dark:hover:bg-white",
            "group-data-[focus=true]:bg-white",
            "dark:group-data-[focus=true]:bg-white",
            "data-[hover=true]:bg-white",
          ],
        }}
        style={{ fontSize: "12px" }}
        placeholder="Add Title"
        size="sm"
        name="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Divider />
      <div className=" h-auto my-4 border-[2px] rounded-lg">
        <EditorProvider
          editorProps={props()}
          slotBefore={
            <MenuBar
              descriptionContent={descriptionContent}
              setDescriptionContent={setDescriptionContent}
              imageArray={imageIds}
              setImageArray={setImageIds}
              initial={start}
              setInitial={setStart}
            />
          }
          extensions={extensions(userNames)}
          content={descriptionContent}
        ></EditorProvider>
      </div>
      <Divider />
      <div className="h-4"></div>
      <div className="flex gap-2 text-center items-center">
        <p className="flex w-32 text-xs gap-2 text-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
            />
          </svg>
          Part<span className="text-red-500">*</span>
        </p>
        <div className="  text-xs  flex ">
          {partData?.length > 0 && (
            <PartPicker
              divRef={divRef}
              inputRef={inputRef}
              parsedParts={parsedParts}
              selected={selectedPart}
              setSelected={setSelectedPart}
              parts={partData}
            />
          )}
        </div>
      </div>
      {!(selectedPart.size === 0) && (
        <div className=" mt-2 flex flex-col gap-4">
          <Divider className="mt-2" />
          <p className=" text-xs">Attributes</p>
          <div className=" flex items-center gap-2">
            <p className="text-xs w-32 gap-2 text-center flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.867 19.125h.008v.008h-.008v-.008Z"
                />
              </svg>
              Stage<span className=" text-red-600 "> *</span>
            </p>
            <div className="w-40">
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className="w-fit   text-xs text-center justify-start flex items-center"
                  >
                    {Array.from(stage)[0]}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={stage}
                  onSelectionChange={setStage}
                >
                  <DropdownSection title="Open">
                    <DropdownItem key="Queued">Queued</DropdownItem>
                  </DropdownSection>
                  <DropdownSection title="In progress">
                    {progress.map((item) => (
                      <DropdownItem key={item}> {item}</DropdownItem>
                    ))}
                  </DropdownSection>
                  <DropdownSection title="Closed">
                    {closed.map((item) => (
                      <DropdownItem key={item}> {item}</DropdownItem>
                    ))}
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <p className="w-32 gap-2   text-xs text-center flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              Assigned to<span className=" text-red-600 "> *</span>
            </p>
            <div className="w-40">
              <Dropdown size="sm">
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className=" justify-start capitalize"
                  >
                    {selectedReporter === "" ? (
                      <p className=" text-xs ">Assign user</p>
                    ) : (
                      <div className="flex gap-2 items-center">
                        <Avatar
                          color={Colors[Number(reporterInfo.id) % numColors]}
                          style={{ width: "22px", height: "22px" }}
                          showFallback
                          radius="sm"
                          name={
                            reporterInfo.name
                              ? reporterInfo.name?.charAt(0).toUpperCase()
                              : reporterInfo.username?.charAt(0).toUpperCase()
                          }
                          className="flex-shrink-0"
                          size="sm"
                          src={reporterInfo.url}
                        />
                        <div className="flex flex-col">
                          <span className="text-xs">
                            {reporterInfo.name
                              ? reporterInfo.name?.length > 25
                                ? `${reporterInfo.name.slice(0, 25)}...`
                                : reporterInfo.name
                              : reporterInfo.username?.length > 25
                              ? `${reporterInfo.username.slice(0, 25)}...`
                              : reporterInfo.username}
                          </span>
                        </div>
                      </div>
                    )}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Select Tenant"
                  variant="flat"
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={selectedReporter}
                  onSelectionChange={setSelectedReporter}
                >
                  {users?.map((report) => {
                    const colorIndex = report && report.id % numColors;
                    return (
                      <DropdownItem
                        onClick={(e) => updateReporter(report)}
                        key={report.id}
                      >
                        <div className="flex gap-2 items-center">
                          <Avatar
                            style={{ width: "22px", height: "22px" }}
                            showFallback
                            name={
                              report.FirstName
                                ? report.FirstName?.charAt(0).toUpperCase()
                                : report.username?.charAt(0).toUpperCase()
                            }
                            className="flex-shrink-0"
                            radius="sm"
                            size="sm"
                            color={Colors[colorIndex]}
                            src={
                              report.ProfilePicture
                                ? process.env.REACT_APP_STAGE === "Dev"
                                  ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${report.ProfilePicture.formats.small.url}`
                                  : `${report.ProfilePicture.formats.small.url}`
                                : null
                            }
                          />
                          <div className="flex flex-col">
                            <span className="text-xs">
                              {report.FirstName
                                ? report.FirstName
                                : report.username}{" "}
                              {report?.LastName}
                            </span>
                          </div>
                        </div>
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <p className="w-32 gap-2 text-xs text-center flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
                />
              </svg>
              Priority<span className=" text-red-600"> *</span>
            </p>
            <div className="w-40 ">
              <Dropdown variant="light" size="sm">
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className="capitalize justify-start"
                  >
                    {selectedPriorityData ? (
                      <div className="flex gap-2">
                        {selectedPriorityData.icon}
                        <p className="text-xs">{selectedPriorityData.label}</p>
                      </div>
                    ) : (
                      <p className="text-xs text-start">Select Priority</p>
                    )}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Priority selection"
                  variant="flat"
                  disallowEmptySelection
                  className="w-40 text-xs text-start flex items-start"
                  selectionMode="single"
                  selectedKeys={selectedPriority}
                  onSelectionChange={setSelectedPriority}
                >
                  {Object.keys(priorityData).map((priority) => (
                    <DropdownItem
                      key={priority}
                      className="flex gap-2"
                      onClick={() => setSelectedPriority(new Set([priority]))}
                    >
                      <div className="flex  gap-2">
                        {priorityData[priority].icon}
                        <p className="text-xs">{priority}</p>
                      </div>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          {Array.from(selectedKeys).includes("tags") && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2   text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 6h.008v.008H6V6Z"
                  />
                </svg>
                Tags
              </p>
              <Tags
                param={tenant.name}
                customTags={customTags}
                org={tenant.id}
                dropdownref={dropdownref}
                tags={tags}
                setTags={setTags}
                tagsDetail={tagsDetail}
                setTagsDetail={setTagsDetail}
                modalref={modalref}
              />
            </div>
          )}
          {Array.from(selectedKeys).includes("date") && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2   text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>
                Target Close Date
              </p>
              <div className="w-40">
                <DatePicker
                  variant="bordered"
                  value={
                    selectedDate === ""
                      ? null
                      : parseDate(convertToDateStr(selectedDate))
                  }
                  selectedDate={selectedDate}
                  style={{
                    fontSize: "12px",
                    backgroundColor: "",
                  }}
                  classNames={{
                    input: ["bg-white", "hover:bg-white"],
                    innerWrapper: ["bg-white", "hover:bg-white"],
                    inputWrapper: [
                      "shadow-none",
                      "border",
                      "border-divider",

                      "bg-white",
                      "dark:bg-white",
                      "hover:bg-white",
                      "dark:hover:bg-white",
                      "group-data-[focus=true]:bg-white",
                      "dark:group-data-[focus=true]:bg-white",
                      "data-[hover=true]:bg-white",
                    ],
                  }}
                  onChange={handleDateChange}
                  size="sm"
                />
              </div>
            </div>
          )}
          {Array.from(selectedKeys).includes("owner") && (
            <div className="flex gap-2 items-center">
              <p className=" w-32 gap-2 text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                Owner
              </p>
              <div className="w-40">
                <Button
                  size="sm"
                  variant="light"
                  className="w-40 justify-start"
                >
                  <div className="flex gap-2 items-center">
                    <Avatar
                      color={Colors[Number(ownerInfo?.id) % numColors]}
                      style={{ width: "22px", height: "22px" }}
                      showFallback
                      radius="sm"
                      name={
                        ownerInfo.name
                          ? ownerInfo?.name?.charAt(0).toUpperCase()
                          : ownerInfo?.username?.charAt(0).toUpperCase()
                      }
                      className="flex-shrink-0"
                      size="sm"
                      src={ownerInfo.url}
                    />
                    <div className="flex flex-col">
                      <span className="text-xs">
                        {ownerInfo.name ? ownerInfo.name : ownerInfo.username}
                      </span>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          )}
          {Array.from(selectedKeys).includes("days") && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2   text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                  />
                </svg>
                Estimated Days
              </p>
              <div className="w-40">
                <Input
                  value={selectedEstimatedDays}
                  variant="bordered"
                  onChange={(e) => setSelectedEstimatedDays(e.target.value)}
                  type="number"
                  style={{
                    fontSize: "12px",
                    backgroundColor: "",
                  }}
                  placeholder="Add days"
                  classNames={{
                    input: ["bg-white", "hover:bg-white"],
                    innerWrapper: ["bg-white", "hover:bg-white"],
                    inputWrapper: [
                      "shadow-none",
                      "border",
                      "border-divider",

                      "bg-white",
                      "dark:bg-white",
                      "hover:bg-white",
                      "dark:hover:bg-white",
                      "group-data-[focus=true]:bg-white",
                      "dark:group-data-[focus=true]:bg-white",
                      "data-[hover=true]:bg-white",
                    ],
                  }}
                  size="sm"
                />
              </div>
            </div>
          )}
          <div className="w-24">
            <Dropdown>
              <DropdownTrigger>
                <Button variant="light" size="sm" className="capitalize">
                  + Add Attributes
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Multiple selection example"
                variant="flat"
                closeOnSelect={false}
                selectionMode="multiple"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
              >
                <DropdownItem key="tags">Tags</DropdownItem>
                <DropdownItem key="owner">Owner</DropdownItem>
                <DropdownItem key="date">Target Closing Date </DropdownItem>
                <DropdownItem key="days">Estimated Days</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      )}
      <Divider className="my-4" />
      <LinkIssues
       popoverRef={modalref} inputRef={inputRef}
        type={"issue"}
        selected={selectedIssueIds}
        setSelected={setSelectedIssueIds}
      />
      <div className="flex items-center mt-2 justify-end">
        {permission && (
          <Button
            size="sm"
            className={`ml-2 text-white ${
              title === "" ||
              descriptionContent === "" ||
              descriptionContent === "<p></p>" ||
              Array.from(selectedPart)?.length === 0 ||
              Array.from(selectedPriority)?.length === 0
                ? ``
                : `bg-[rgb(51,51,245)]`
            } `}
            disabled={
              title === "" ||
              descriptionContent === "" ||
              descriptionContent === "<p></p>" ||
              Array.from(selectedPart)?.length === 0 ||
              Array.from(selectedPriority)?.length === 0
            }
            onClick={handleSubmit}
          >
            Update
          </Button>
        )}
      </div>
    </div>
  );
};

export default Ticket;
