import React from "react";
import { useSelector } from "react-redux";
import { selectResData } from "../../redux/actions";
import { Avatar } from "@nextui-org/react";
import { getMonthName } from "../../utils/constant";
import { basicIcons, priorityData } from "../../utils/default";
import { renderIcon } from "../../components/Drawer/PartPicker";

const TargetDateRender = ({ obj }) => {
  if (!obj) {
    return <></>;
  }
  return (
    <p>to {`${getMonthName(Number(obj.month))} ${obj.day} ${obj.year}`}</p>
  );
};

const RenderSprint = ({ id, num, parsedSprints }) => {
  const icon = basicIcons.find((item) => item.name === parsedSprints[id]?.Icon);
  return (
    <div className="flex items-center gap-2">
      {icon && (
        <svg
          xmlns={icon?.xmlns}
          fill={icon?.fill}
          viewBox={icon?.viewBox}
          strokeWidth={icon?.strokeWidth}
          stroke={icon?.stroke}
          className="size-6"
        >
          <path
            strokeLinecap={icon?.path?.strokeLinecap}
            strokeLinejoin={icon?.path?.strokeLinejoin}
            d={icon?.path?.d}
          ></path>
        </svg>
      )}
      <p>{parsedSprints[id]?.Name}</p>
      <p>Sprint</p>
      <p>{parsedSprints[id]?.Curr}</p>
    </div>
  );
};

function Logs({ logs, parsedSprints, parsedParts, type }) {
  const res = useSelector(selectResData);
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "short", day: "2-digit" };

    // Format the date and remove the comma
    return date.toLocaleDateString("en-US", options).replace(",", "");
  }
  const numColors = Colors.length;
  return (
    <div className="flex flex-col text-xs gap-4 overflow-y-auto p-2 border border-divider h-[500px] rounded-lg">
      {logs
        .sort((a, b) => b.timestamp - a.timestamp)
        .map((item) => (
          <div className="flex items-center gap-2">
            <Avatar
              showFallback
              name={
                res &&
                res[item?.by] &&
                (res[item?.by].FirstName
                  ? `${res[item?.by].FirstName?.charAt(0)}`
                  : `${res[item?.by].UserName?.charAt(0).toUpperCase()}`)
              }
              radius="sm"
              color={Colors[Number(item?.by) % numColors]}
              style={{ height: "25px", width: "25px" }}
              src={res?.[item?.by]?.ProfilePicture ?? ""}
            />
            <p>
              {res[item?.by] &&
                (res[item?.by].FirstName
                  ? `${res[item?.by].FirstName}`
                  : `${res[item?.by].UserName}`)}
            </p>
            {item?.operation === "create" ? (
              <p> created this {`${type}`}</p>
            ) : (
              <>
                <p>updated the {`${item.field}`}</p>
                {item.field === "title" ||
                item.field === "stage" ||
                item.field === "estimated days" ? (
                  <p>to {`${item.to}`}</p>
                ) : item.field === "target close date" ? (
                  <TargetDateRender obj={item.to} />
                ) : item.field === "priority" ? (
                  <div className="flex items-center gap-2">
                    {" "}
                    <p>to</p>
                    <div>{priorityData[item.to]?.icon}</div>{" "}
                    <p>{priorityData[item.to]?.label}</p>
                  </div>
                ) : item.field === "assigned" ? (
                  <div className="flex items-center gap-2">
                    <p>to</p>
                    <Avatar
                      showFallback
                      name={
                        res &&
                        res[item?.to] &&
                        (res[item?.to].FirstName
                          ? `${res[item?.to].FirstName?.charAt(0)}`
                          : `${res[item?.to].UserName?.charAt(
                              0
                            ).toUpperCase()}`)
                      }
                      radius="sm"
                      color={Colors[Number(item?.to) % numColors]}
                      style={{ height: "25px", width: "25px" }}
                      src={res?.[item?.to]?.ProfilePicture ?? ""}
                    />
                    <p>
                      {res[item?.to] &&
                        (res[item?.by].FirstName
                          ? `${res[item?.to].FirstName}`
                          : `${res[item?.to].UserName}`)}
                    </p>
                  </div>
                ) : item.field === "part" ? (
                  <>
                    <p>to</p>
                    <div>{renderIcon(parsedParts[Number(item.to)]?.Type)}</div>
                    <p>{parsedParts[Number(item.to)]?.Name}</p>
                  </>
                ) : item.field === "sprint" ? (
                  <>
                    <div className="flex items-center gap-2">
                      <p>to</p>
                      <RenderSprint parsedSprints={parsedSprints} id={item.to.sprint} num={item.to.currentSprint} />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            <p>at {`${formatTimestampToDate(item.timestamp)}`}</p>
          </div>
        ))}
    </div>
  );
}

export default Logs;
