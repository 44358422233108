import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { NextUIProvider } from "@nextui-org/react";
import { HelmetProvider } from 'react-helmet-async'; 
import { Provider } from 'react-redux';
import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <NextUIProvider>
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
        <App />
        </Provider>
      </HelmetProvider>
    </React.StrictMode>
  </NextUIProvider>
);

reportWebVitals();
