import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Chip,
  Avatar,
  Accordion,
  AccordionItem,
} from '@nextui-org/react';
import { renderGroup } from '../../utils/constant';
import { getOneData } from '../../controllers/strapiController';

const Colors = ['secondary', 'success', 'warning', 'danger', 'primary'];
const numColors = Colors.length;
const UserInfo = ({ user, color, username }) => (
  <div className="flex items-center gap-2">
    <Avatar
      showFallback
      name={user?.FirstName ? user?.FirstName.charAt(0) : user?.UserName?.charAt(0).toUpperCase()}
      size="sm"
      radius="sm"
      color={color}
      src={
        user?.ProfilePicture?.formats?.small?.url
          ? `${process.env.REACT_APP_STAGE === 'Dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user?.ProfilePicture.formats.small.url}` : `${user?.ProfilePicture.formats.small.url}`}`
          : null
      }
    />
    <p className="text-xs">
      {username || user?.FirstName || user?.UserName || 'Unassigned'}
    </p>
  </div>
);

const GroupTickets = ({
  tickets,
  res,
  toggleTicket,
  orderby,
  customizeHeaderList,
  parsedParts,
  customTags,
  handleDelete,
  deletePerm
}) => {
  const [usernames, setUsernames] = useState({});

  const fetchPartData = async () => {
    try {
      const partIds = [...new Set(Object.values(tickets).flat().map(ticket => ticket.payload.part))];

      const partDataPromises = partIds.map(id => getOneData("parts", id));
      const partDataResults = await Promise.all(partDataPromises);

      const userMap = {};
      partDataResults.forEach(result => {
        const part = result.data;
        const user = part.attributes.users_permissions_user?.data;
        if (user) {
          userMap[user.id] = user.attributes.username;
        }
      });

      setUsernames(userMap);
    } catch (error) {
      console.error("Error fetching part data:", error);
    }
  };

  useEffect(() => {
    fetchPartData();
  }, [tickets]);

  return (
    <div className="flex h-full overflow-y-auto relative">
      <div className="w-64 max-h-fit overflow-y-auto border-r-1 border-divider">
        {Object.keys(tickets || {}).map((owner) => (
          <div
            className="flex justify-between p-2 gap-2 cursor-pointer mt-4 hover:bg-gray-200 w-full items-center capitalize"
            key={owner}
          >
            {orderby === "owner" ? (
              <div className="flex items-center gap-2">
                <Avatar
                  showFallback
                  color={Colors[Number(owner) % numColors]}
                  name={
                    res[owner]?.FirstName
                      ? res[owner]?.FirstName.charAt(0).toUpperCase()
                      : res[owner]?.UserName?.charAt(0).toUpperCase()
                  }
                  size="sm"
                  radius="sm"
                  src={
                    res[owner]?.ProfilePicture?.formats?.small?.url
                      ? process.env.REACT_APP_STAGE === "Dev"
                        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[owner].ProfilePicture.formats.small.url}`
                        : `${res[owner].ProfilePicture.formats.small.url}`
                      : null
                  }
                />
                <p className="text-xs">
                  {res[owner]?.FirstName || res[owner]?.UserName || 'Unassigned'}
                </p>
              </div>
            ) : orderby === "assigned to" ? (
              <div className="flex items-center gap-2">


                <div className="flex items-center gap-2">
                  {Object.keys(usernames).length > 0 ? (
                    <div>
                      {Object.keys(usernames).map((key) => (
                        <UserInfo
                          key={key}
                          user={res[key]}
                          color={Colors[Number(key) % numColors]}
                          username={usernames[key]}
                        />
                      ))}
                    </div>
                  ) : (
                    <p className="text-xs">Unassigned</p>
                  )}
                </div>

              </div>
            ) : orderby === "created by" ? (
              <div className="flex items-center gap-2">
                <Avatar
                  showFallback
                  name={
                    res[owner]?.FirstName
                      ? res[owner]?.FirstName.charAt(0)
                      : res[owner]?.UserName?.charAt(0).toUpperCase()
                  }
                  size="sm"
                  radius="sm"
                  color={Colors[Number(owner) % numColors]}
                  src={
                    res[owner]?.ProfilePicture?.formats?.small?.url
                      ? process.env.REACT_APP_STAGE === "Dev"
                        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[owner].ProfilePicture.formats.small.url}`
                        : `${res[owner].ProfilePicture.formats.small.url}`
                      : null
                  }
                />
                <p className="text-xs">
                  {res[owner]?.FirstName || res[owner]?.UserName || 'Unassigned'}
                </p>
              </div>
            ) : orderby === "part" ? (
              <p className="text-xs capitalize">{parsedParts[owner]?.Name || 'Unassigned'}</p>
            ) : (
              <p className="text-xs capitalize">{owner}</p>
            )}
            <Chip
              size="sm"
              color="default"
              className="border border-divider"
              radius="full"
            >
              {tickets[owner] ? tickets[owner].length : 0}
            </Chip>
          </div>
        ))}
      </div>
      <div className="w-full max-h-fit overflow-y-auto">
        {Object.keys(tickets || {}).map((owner, ind) => (
          <div className="px-4 mb-10" key={ind}>
            <Accordion defaultExpandedKeys={["1"]}>
              <AccordionItem
                key="1"
                title={
                  <div className="flex pl-2 my-2 gap-2 w-[200px] h-[40px] p-2 items-center capitalize">
                    {orderby === "owner" ? (
                      <div className="flex items-center gap-2">
                        <Avatar
                          showFallback
                          name={
                            res[owner]?.FirstName
                              ? res[owner]?.FirstName.charAt(0)
                              : res[owner]?.UserName?.charAt(0).toUpperCase()
                          }
                          size="sm"
                          radius="sm"
                          color={Colors[Number(owner) % numColors]}
                          src={
                            res[owner]?.ProfilePicture?.formats?.small?.url
                              ? process.env.REACT_APP_STAGE === "Dev"
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[owner].ProfilePicture.formats.small.url}`
                                : `${res[owner].ProfilePicture.formats.small.url}`
                              : null
                          }
                        />
                        <p className="text-xs">
                          {res[owner]?.FirstName || res[owner]?.UserName || 'Unassigned'}
                        </p>
                      </div>
                    ) : orderby === "assigned to" ? (
                      <div className="flex items-center gap-2">


                        <div className="flex items-center gap-2">
                          {Object.keys(usernames).length > 0 ? (
                            <div>
                              {Object.keys(usernames).map((key) => (
                                <UserInfo
                                  key={key}
                                  user={res[key]}
                                  color={Colors[Number(key) % numColors]}
                                  username={usernames[key]}
                                />
                              ))}
                            </div>
                          ) : (
                            <p className="text-xs">Unassigned</p>
                          )}
                        </div>

                      </div>
                    ) : orderby === "created by" ? (
                      <div className="flex items-center gap-2">
                        <Avatar
                          showFallback
                          name={
                            res[owner]?.FirstName
                              ? res[owner]?.FirstName.charAt(0)
                              : res[owner]?.UserName?.charAt(0).toUpperCase()
                          }
                          size="sm"
                          radius="sm"
                          color={Colors[Number(owner) % numColors]}
                          src={
                            res[owner]?.ProfilePicture?.formats?.small?.url
                              ? process.env.REACT_APP_STAGE === "Dev"
                                ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[owner].ProfilePicture.formats.small.url}`
                                : `${res[owner].ProfilePicture.formats.small.url}`
                              : null
                          }
                        />
                        <p className="text-xs">
                          {res[owner]?.FirstName || res[owner]?.UserName || 'Unassigned'}
                        </p>
                      </div>
                    ) : (
                      <p className="text-xs capitalize">{owner}</p>
                    )}
                  </div>
                }
              >
                <Table
                  removeWrapper
                  radius="none"
                  className="mt-4 text-xs"
                  color="default"
                  aria-label="Example static collection table"
                >
                  <TableHeader columns={customizeHeaderList}>
                    {(column) => (
                      <TableColumn key={column.id} className="text-xs">
                        {column.label}
                      </TableColumn>
                    )}
                  </TableHeader>
                  <TableBody emptyContent={"No tickets. Time for coffee."}>
                    {tickets[owner]?.map((row, index) => (
                      <TableRow
                        className="cursor-pointer hover:bg-gray-100 text-xs  border-b border-divider"
                        onClick={(e) => toggleTicket(row)}
                        key={`${index + 1}`}
                      >
                        {customizeHeaderList.map((item) => {
                          return renderGroup(
                            row,
                            item.id,
                            res,
                            parsedParts,
                            handleDelete,
                            customTags,
                            deletePerm
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionItem>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupTickets;
