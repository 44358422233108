import { Image } from "@nextui-org/react";
import React from "react";

const Errorpage = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <main className='mx-auto w-full flex flex-col items-center justify-center'>
        <div>
          <Image
            width={50}
            radius="sm"
            alt="NextUI"
            src="https://avatars.githubusercontent.com/u/170258109?s=200&v=4"

          />
          <h1 className='mb-1.5 text-md mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold '>
            500: INTERNAL SERVER ERROR
          </h1>
          <p className='mt-8 text-sm' >Internal server issue. Backend is not connected.</p>
        </div>
      </main>
    </div>
  );
};
export default Errorpage;
