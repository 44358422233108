import React, { useEffect, useState } from "react";
import {
  Chip,
  Input,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Avatar,
  Tooltip,
} from "@nextui-org/react";

const UserModal = ({ member, setMember, users,divRef,inpuRef ,single}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [res,setRes] = useState({});
  const [search, setSearch] = useState("");
  const Colors = ['secondary', 'success', 'warning', 'danger', 'primary']
  const numColors = Colors.length;
  const handleDelete = (item) => {
    let arr = Array.from(member).filter((id) => Number(id) !== Number(item));
    setMember(new Set(arr));
    // setMember(new Set(Array.from(member).filter((id) => Number(id) !== Number(item.id))));
  }
  useEffect(() => {
    const convertedUsers = {};
    users.forEach((user) => {
      convertedUsers[user.id] = {
        UserName : user.username,
        FirstName: user.FirstName,
        LastName: user.LastName,
        ProfilePicture: user.ProfilePicture?.formats?.small?.url || "",
      };
    });
    setRes(convertedUsers);
  }, [users]);
  useEffect(() => {
    const filtered = users.filter((option) =>
      option.FirstName ? `${option.FirstName} ${option?.LastName}`.toLowerCase().includes(search.toLowerCase()) : option.username.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [search, users]);

  
  return (
    <div className="w-full flex items-center py-2 min-h-[36px] rounded-lg">
      <Dropdown>
        <DropdownTrigger>
          <div className=" rounded-lg w-full cursor-pointer min-h-4 h-auto flex items-center">
            <div className="gap-2 flex items-center h-auto w-full flex-wrap pt-[2px] px-2">
              {Array.from(member)?.length >0 ? Array.from(member).length <4  ? (
                Array.from(member)?.map((item) => (
                  <Chip
                    size="sm"
                    // variant="bordered"
                    key={item}
                  
                    startContent={
                        <Avatar
                        style={{height:"15px",width:"15px"}}
                        showFallback
                        name={res[Number(item)]?.UserName?.slice(0,1).toUpperCase()}
                        radius="full"
                        
                        color={Colors[Number(item) % numColors]}
                        size="sm"
                        src={`${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[Number(item)]?.ProfilePicture}`}
                      />
                    }
                    onClose={() => {
                      handleDelete(item);
                    }}
                    className="cursor-pointer"
                  >
                    {res[Number(item)]?.FirstName ? `${res[Number(item)]?.FirstName} ${res[Number(item)]?.LastName}` : res[Number(item)]?.UserName}
                  </Chip>
                ))
              ) : 
              (<>{Array.from(member)?.slice(0, 3)?.map((item) => (
                <Chip
                  size="sm"
                  // variant="bordered"
                  key={item}
                
                  startContent={
                      <Avatar
                      style={{height:"15px",width:"15px"}}
                      showFallback
                      name={res[Number(item)]?.UserName?.slice(0,1).toUpperCase()}
                      radius="full"
                      
                      color={Colors[Number(item) % numColors]}
                      size="sm"
                      src={`${process.env.REACT_APP_STRAPI_IP_ADDRESS}${res[Number(item)]?.ProfilePicture}`}
                    />
                  }
                  onClose={() => {
                    handleDelete(item);
                  }}
                  className="cursor-pointer"
                >
                  {res[Number(item)]?.FirstName ? `${res[Number(item)]?.FirstName} ${res[Number(item)]?.LastName}` : res[Number(item)]?.UserName}
                </Chip>
              ))}
              <Tooltip content={<div className="flex flex-col "> 
                {Array.from(member).slice(3).map((item) => (res[Number(item)]?.UserName))} </div>}><p className="text-xs">+{Array.from(member).length -3}</p></Tooltip>
              
             </>
            ) : (
                <p className=" text-xs w-full text-start ">Add User</p>
              )}
            </div>
          </div>
        </DropdownTrigger>
        <DropdownMenu
          selectionMode={single ? "single" : "multiple"}
          ref={divRef}
          selectedKeys={member}
          onSelectionChange={setMember}
          className=" w-fit  h-auto"
          aria-label="Static Actions"
          closeOnSelect={false}
        >
          <DropdownSection>
            <DropdownItem
             classNames={{base : "gap-0 data-[selectable=true]:focus:bg-white", selectedIcon:"w-0"}}
              isReadOnly key="new">
              <Input
                ref={inpuRef}
                size="sm"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search user.."
                style={{ fontSize: "12px" }}
                className="w-100"
              ></Input>
            </DropdownItem>
          </DropdownSection>
          {filteredOptions?.length > 0 ? (
            <DropdownSection className=" max-h-[200px] overflow-y-auto">
              {filteredOptions?.map((item) => (
                <DropdownItem key={item?.id} startContent={<Avatar
                    showFallback
                    name={item?.username?.slice(0,1).toUpperCase()}
                    radius="full"
                    color={Colors[Number(item.id) % numColors]}
                    style={{height:"20px",width:"20px"}}
                    size="sm"
                    src={process.env.REACT_APP_STAGE === 'Dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${item?.ProfilePicture?.formats?.small?.url}`: `${item?.ProfilePicture?.formats?.small?.url}`}
                  />}>{item.FirstName ? `${item.FirstName} ${item?.LastName}` : item.username}</DropdownItem>
              ))}
            </DropdownSection>
          ) : <DropdownItem className=" cursor-default" isReadOnly> No matching records.</DropdownItem>}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default UserModal;