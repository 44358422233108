import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { BreadcrumbItem, Breadcrumbs } from "@nextui-org/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSingleDoc } from "../../controllers/vectorDB";


function BlueprintHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const { org, docId } = useParams();
  const [title, setTitle] = useState(null);
  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const resp = await getSingleDoc(docId, org);
        console.log("API Response:", resp); // Debugging log
        if (resp?.length > 0) {
          setTitle(resp[0].payload.title);
        } else {
          console.warn("No title found in response");
        }
      } catch (error) {
        console.error("Error fetching title:", error);
      }
    };

    if (org && docId) {
      fetchTitle();
    }
  }, [docId, org]);
  return (
    <div className=" mx-8 pt-10 pb-8 flex flex-col gap-8">
      <div className="flex text-center items-center">
        <Breadcrumb />
        <Breadcrumbs size="sm">
          <BreadcrumbItem onClick={() => navigate(`/${org}/blueprints`)}>
            Blueprints
          </BreadcrumbItem>
          {location.pathname.includes(`/${org}/blueprints/create`) && (
            <BreadcrumbItem>Create</BreadcrumbItem>
          )}
          {docId && <BreadcrumbItem><table>{title}</table></BreadcrumbItem>}
        </Breadcrumbs>


      </div>
      {(location.pathname === `/${org}/blueprints` ||
        location.pathname === `/${org}/blueprints/create`) && (
          <div className="flex flex-col gap-4">
            {/* <h1 className="text-lg">Blueprints</h1> */}
            <p className="text-xs">
              Utilize your team's knowledge as a key resource to improve
              efficiency and optimize the way tasks are completed.
            </p>
          </div>
        )}
    </div>
  );
}

export default BlueprintHeader;
