import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteData, getMultipleFilters } from "../../controllers/vectorDB";
import {
  Avatar,
  AvatarGroup,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { useSelector } from "react-redux";
import { selectResData } from "../../redux/actions";
import Icon from "../../components/Tag/Icon";
import HelixLoader from "../../components/Loader/HelixLoader";

const Colors = ["secondary", "success", "warning", "danger", "primary"];
const numColors = Colors.length;
function timeAgo(timestamp) {
  const now = new Date();
  const timeDifference = now - new Date(timestamp); // Time difference in milliseconds
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);

  const pluralize = (value, unit) => {
    return `${value} ${unit}${value === 1 ? "" : "s"} ago`;
  };

  if (seconds < 60) {
    return pluralize(seconds, "second");
  } else if (minutes < 60) {
    return pluralize(minutes, "minute");
  } else if (hours < 24) {
    return pluralize(hours, "hour");
  } else if (days < 365) {
    return pluralize(days, "day");
  } else {
    return pluralize(years, "year");
  }
}

function BlueprintTable() {
  const [loader, setLoader] = useState(true);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const res = useSelector(selectResData);
  const { org } = useParams();
  const navigate = useNavigate();
  const [prints, setPrints] = useState([]);

  const handleDelete = async (id) => {
    try {
      console.log("delete");
      await deleteData(id, org);
      const arr = prints.filter((item) => item.payload.id !== id);
      setPrints(arr);
    } catch (error) {
      console.error("Error deleting blueprint:", error);
    }
  };

  useEffect(() => {
    const fetchBlueprints = async () => {
      try {
        const resp = await getMultipleFilters(
          [{ key: "type", value: "blueprint" }],
          org
        );
        const arr = resp.filter((item) => {
          if (item?.payload?.access?.public) {
            return true;
          } else {
            const find = item?.payload?.permissions?.find(
              (it) => String(it?.id) === String(userDataId)
            );
            if (find && find?.permissions?.read) {
              return true;
            } else {
              return false;
            }
          }
        });
        setPrints(arr);
        setLoader(false);
      } catch (error) {
        console.log("Error", error);
      }
    };
    if (org) {
      fetchBlueprints();
    }
  }, [org]);
  if (loader) {
    return (
      <div className="flex flex-col items-center justify-center mt-64">
        <HelixLoader />
        
      </div>
    );
  }
  return (
    <div>
      <div className="flex flex-col">
        <div className="mt-12 flex justify-end mx-8">
          <Button
            size="sm"
            onClick={() => navigate(`/${org}/blueprints/create`)}
            color="primary"
            className="mx-2 text-white bg-[rgb(51,51,245)]"
          >
            + Blueprint
          </Button>
        </div>
      </div>

      {/* Check if prints array is empty and display null state */}
      {prints.length === 0 ? (
        <div className="flex flex-col mt-60 text-xs gap-2 justify-center items-center text-slate-400">
          <Icon />
          Blueprints is Empty
        </div>
      ) : (
        <Table className="pt-8 px-8" removeWrapper>
          <TableHeader>
            <TableColumn>Blueprint</TableColumn>
            <TableColumn>Collaborators</TableColumn>
            <TableColumn>Last updated at</TableColumn>
            <TableColumn></TableColumn>
          </TableHeader>
          <TableBody>
            {prints
              .sort((a, b) => b.payload.updatedAt - a.payload.updatedAt)
              .map((item) => (
                <TableRow
                  key={item.payload.id}
                  onClick={() =>
                    navigate(`/${org}/blueprints/docs/${item.payload.id}`)
                  }
                  className="cursor-pointer hover:bg-gray-100 text-xs  border-b border-divider"
                >
                  <TableCell className="w-[50%]">
                    <div className="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                        />
                      </svg>
                      <p className="text-xs">{item.payload.title}</p>
                    </div>
                  </TableCell>
                  <TableCell className="w-[20%]">
                    <AvatarGroup max={6}>
                      {item.payload.authors.map((auth) => (
                        <Avatar
                          key={auth}
                          color={Colors[Number(auth) % numColors]}
                          style={{ height: "25px", width: "25px" }}
                          radius="full"
                          src={
                            res && res[auth] ? res[auth]?.ProfilePicture : ""
                          }
                          showFallback
                          name={
                            res && res[auth]
                              ? res[auth]?.FirstName?.toUpperCase()?.charAt(
                                  0
                                ) ?? ""
                              : ""
                          }
                        />
                      ))}
                    </AvatarGroup>
                  </TableCell>
                  <TableCell className="w-[20%]">
                    <p className="text-sm">{timeAgo(item.payload.updatedAt)}</p>
                  </TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          size="sm"
                          variant="light"
                          className="text-red-500"
                          isIconOnly
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.166m-1.022.165L5.84 19.673A2.25 2.25 0 0 0 8.084 21.75h7.832a2.25 2.25 0 0 0 2.244-2.077L19.228 5.79m-14.456 0a48.108 48.108 0 0 1 3.478-.397M9.75 5.25v-1.5a1.5 1.5 0 0 1 1.5-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1.5M9.75 5.25h4.5"
                            />
                          </svg>
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu aria-label="Actions" variant="flat">
                        <DropdownItem
                          onClick={() => {
                            handleDelete(item.payload.id);
                          }}
                          key="delete"
                          color="danger"
                          variant="flat"
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
}

export default BlueprintTable;
