import React from "react";
import Lottie from 'react-lottie';
import { animationData } from "./lottie";

function HelixLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // Your Lottie JSON data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div className="loader-container">
    <Lottie options={defaultOptions} height={200} width={200} />
    </div>
      );
}

export default HelixLoader;
