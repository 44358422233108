import React, { useState, useEffect, useRef } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownTrigger,
  Breadcrumbs,
  BreadcrumbItem,
  Button,
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  Chip,
  Switch,
  Pagination,
  DropdownSection,
  Divider,
} from "@nextui-org/react";
import { PiCoffeeThin } from "react-icons/pi";
import { MdFormatListBulletedAdd } from "react-icons/md";
import Drawer from "../../components/Drawer/drawer";
import { deleteData, getDocument } from "../../controllers/vectorDB";
import Ticket from "../Tickets/ticketdetails";
import { renderCell } from "../../utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteSingleAttribute,
  getAllData,
  getFilteredBySingleRelation,
  getOneData,
} from "../../controllers/strapiController";
import HelixLoader from "../../components/Loader/HelixLoader";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import GroupIssues from "../../components/Grouping/issuegroup";
import { useSelector } from "react-redux";
import { selectOrgData, selectResData, selectUserData } from "../../redux/actions";
const customizeCompleteList = [
  "Stage",
  "Owner",
  "Priority",
  "Target Closing Date",
  "Estimated days",
  "Tags",
  "Part",
];
const open = ["Queued"];
const progress = [
  "Work in progress",
  "Awaiting Product assist",
  "Awaiting Development",
  "In Development",
  "Awaiting Customer Response",
];
const closed = ["Accepted", "Resolved", "Cancelled"];

const TeamActivity = () => {
  const { org } = useParams();
  const drawerRef = useRef();
  const ticketRef = useRef();
  const tagsref = useRef();
  const modalref = useRef();
  const orgData = useSelector(selectOrgData);
  const users = useSelector(selectUserData);
  const res = useSelector(selectResData);
  const [searchTicket, setSearchTicket] = useState("");
  const searchRef = useRef();
  const inputRef = useRef();
  const sprintRef = useRef();
  const [filterDropdown, setFilterDropdowm] = useState(new Set(["Work Type"]));
  const [ticketDataComplete, setTicketDataComplete] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [userTicket, setUserTicket] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTicketOpen, setIsTicketOpen] = useState(false);
  const [ticket, setTicket] = useState();
  const [loader, setLoader] = useState({fir:true,sec:true});
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [parsedParts, setParsedParts] = useState({});
  const [filterPriority, setFilterPriority] = useState(new Set([]));

  const [customTags, setCustomTags] = useState([]);
  const [customizeItems, setCustomizeItems] = useState(new Set(["Stage"]));
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [isCreatedTickets, setIsCreatedTickets] = useState(false);

  const coulmnDef = Array.from(customizeItems).map((name) => {
    return {
      id: name.toLowerCase(),
      label: name,
    };
  });

  const customizeHeaderList = [
    {
      id: "items",
      label: "Items",
    },
    {
      id: "title",
      label: "Title",
    },
    {
      id: "assigned to",
      label: "Assigned to",
    },
    ...coulmnDef,
    {
      id: "delete",
      label: "",
    },
  ];
  const [selectedStageKeys, setSelectedStageKeys] = useState(new Set([]));

  const groupByField = (items, groupBy) => {
    if (groupBy === "created by") {
      groupBy = "created_by";
    }
    const groups = items.reduce((groups, item) => {
      const key = item.payload[groupBy] || "unassigned";
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
      return groups;
    }, {});
    const entries = Object.entries(groups);
    entries.sort(([keyA], [keyB]) => {
      if (keyA === "unassigned") return 1;
      if (keyB === "unassigned") return -1;
      return 0;
    });
    const sortedGroups = Object.fromEntries(entries);
    return sortedGroups;
  };
  const handleDelete = async (item) => {
    try {
       await deleteData(item.id, org);
      const promises = item.images.map((id) =>
        DeleteSingleAttribute("upload/files", Number(id.id))
      );
       await Promise.all(promises);
      window.location.reload();
    } catch (error) {
      window.location.reload();
    }
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const toggleTicket = (e) => {
    setTicket(e);
    setIsTicketOpen(!isTicketOpen);
  };
  const handleCloseTicket = () => {
    setIsTicketOpen(!isTicketOpen);
  };
  const handleClearFilters = () => {
    setFilterPriority(new Set([]));
    setSelectedStageKeys(new Set([]));
    setIsCreatedTickets(false);
    setSearchTicket("");
  };
  const handleStage = (e) => {
    let items = Array.from(selectedStageKeys);
    if (e.every((element) => items.includes(element))) {
      items = items.filter((element) => !e.includes(element));
    } else {
      items = items.concat(e);
    }

    setSelectedStageKeys(new Set(items));
  };
  const handleDeleteKey = (item) => {
    let updatedKeys = new Set(selectedStageKeys);
    updatedKeys.delete(item);
    setSelectedStageKeys(updatedKeys);
  };

  useEffect(() => {
    const getAllDataByIds = async () => {
      if (!orgData?.id) return;

      try {
        // Fetch parts
        const partsResp = await getFilteredBySingleRelation(
          "parts",
          "organization",
          orgData.id
        );

        // Process and convert parts after fetching them
        const convertedParts = {};
        partsResp?.data?.forEach((item) => {
          convertedParts[item?.id] = {
            Name: item?.attributes?.Name,
            Type: item?.attributes?.Type,
            ParentPath: item?.attributes?.ParentPath,
            Owner: item?.attributes?.users_permissions_user?.data?.id,
          };
        });
        setParsedParts(convertedParts);

        // Fetch tags
        const tagsResp = await getFilteredBySingleRelation(
          "tags",
          "organization",
          orgData.id
        );
        setCustomTags(tagsResp.data);
        setLoader((prevLoader) => ({ ...prevLoader, sec: false }));
      } catch (error) {
        console.error("Error fetching data for all IDs:", error);
        throw error;
      }
    };

    getAllDataByIds();
  }, [orgData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!sprintRef.current || !sprintRef.current.contains(event.target))
      ) {
        toggleDrawer();
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && searchTicket === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, searchTicket]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ticketRef.current &&
        !ticketRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target))
      ) {
        setIsTicketOpen(!isTicketOpen);
      }
    };
    if (isTicketOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTicketOpen]);

  useEffect(() => {
    async function fetchDocument() {
      try {
        const tickData = await getDocument("type", "ticket", org);
        if (tickData) {
          setTicketDataComplete(
            tickData.sort((a, b) => a.payload.latest - b.payload.latest)
          );
          setTicketData(
            tickData.sort((a, b) => a.payload.latest - b.payload.latest)
          );
          
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
      finally{
        setLoader((prev) => ({...prev,fir:false}));
      }
    }
    if (org) {
      fetchDocument();
    }
  }, [org]);
  useEffect(() => {
    if (ticketDataComplete.length > 0) {
      const filteredTickets = ticketDataComplete.filter(
        (ticket) => Number(ticket.payload.owner) === userDataId
      );
      setUserTicket(filteredTickets);
    }
  }, [ticketDataComplete]);
  useEffect(() => {
    let filteredTickets = ticketDataComplete;
    if (Array.from(selectedStageKeys).length !== 0) {
      filteredTickets = ticketDataComplete.filter((ticket) =>
        Array.from(selectedStageKeys).includes(ticket.payload.stage)
      );
    }
    if (isCreatedTickets) {
      filteredTickets = filteredTickets.filter((item) =>
        userTicket.includes(item)
      );
    }
    if (searchTicket !== "") {
      filteredTickets = filteredTickets.filter((item) =>
        item.payload.title.toLowerCase().includes(searchTicket.toLowerCase())
      );
    }
    if (Array.from(filterPriority).length > 0) {
      filteredTickets = filteredTickets.filter((item) =>
        Array.from(filterPriority).includes(item.payload.priority)
      );
    }
    setTicketData(filteredTickets);
  }, [selectedStageKeys, isCreatedTickets, searchTicket, filterPriority]);
  return (
    <div className="h-[100%] flex flex-col">
      {isTicketOpen && (
        <div ref={ticketRef}>
          <Ticket
            users={users}
            tenant={{ name: org, id: orgData.id }}
            pos="ticket"
            dropdownref={tagsref}
            ticket={ticket}
            res={res}
            handleCloseTicket={handleCloseTicket}
            modalref={modalref}
          />
        </div>
      )}
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <Drawer
            inputRef={sprintRef}
            users={users}
            tenant={{ name: org, id: orgData.id }}
            res={res}
            dropdownref={tagsref}
            query="ticket"
            toggleDrawer={toggleDrawer}
            modalref={modalref}
          />
        </div>
      )}
      
        <div className="top-0 h-[130px] z-10 sticky bg-white">
          <div className="flex mx-8 justify-between pt-8">
            <div className=" flex text-center justify-center items-center">
              <Breadcrumb />
              <Breadcrumbs size="sm" isDisabled>
                <BreadcrumbItem>Team Activity</BreadcrumbItem>
              </Breadcrumbs>
            </div>
            <div className="flex items-center">
              <Button
                size="sm"
                className="mx-2 text-white bg-[rgb(51,51,245)]"
                onClick={toggleDrawer}
              >
                + Ticket
              </Button>
            </div>
          </div>
          <div className="z-0 flex pb-4 px-8 border-b-1 border-divider mt-4 items-center justify-between">
            <div className="flex flex-wrap items-center gap-4">
              <div
                ref={searchRef}
                onClick={() => setIsSearchOpen(true)}
                className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${isSearchOpen
                    ? "pl-2 w-40 gap-1 justify-start"
                    : "w-8 justify-center"
                  } rounded-full  h-8 `}
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
                {isSearchOpen && (
                  <input
                    ref={inputRef}
                    value={searchTicket}
                    onChange={(e) => setSearchTicket(e.target.value)}
                    style={{ height: "20px", outline: "none" }}
                    className="w-full px-2 text-xs rounded-full"
                    placeholder="Search"
                  />
                )}
              </div>

              {Array.from(filterDropdown).includes("Work Type") && (
                <div className="flex border rounded-sm  ">
                  <div className=" flex items-center">
                    <h2 className="text-xs ml-2 mr-2">Type</h2>
                    <div className="border-l border-gray-300 h-full " />
                  </div>

                  <div className="  rounded-r-md text-xs">
                    <Button
                      size="sm"
                      radius="none"
                      color="warning"
                      variant="light"
                    >
                      Tickets
                    </Button>
                  </div>
                </div>
              )}
              {Array.from(filterDropdown).includes("Priority") && (
                <div className="flex border rounded-sm">
                  <div className="flex items-center">
                    <h2 className="text-xs ml-2 mr-2">Priority</h2>
                    <div className="border-l border-gray-300 h-full" />
                  </div>
                  <div className="rounded-r-md text-xs">
                    <Dropdown>
                      <DropdownTrigger>
                        <Button size="sm" radius="none" variant="light">
                          {Array.from(filterPriority).length === 0
                            ? "Add"
                            : Array.from(filterPriority)[0]}{" "}
                          {filterPriority.size - 1 <= 0
                            ? ""
                            : `+${filterPriority.size - 1}`}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        variant="flat"
                        closeOnSelect={false}
                        selectionMode="multiple"
                        selectedKeys={Array.from(filterPriority)}
                        onSelectionChange={(keys) => {
                          setFilterPriority(new Set(keys));
                        }}
                      >
                        <DropdownItem key="P1">P1</DropdownItem>
                        <DropdownItem key="P2">P2</DropdownItem>
                        <DropdownItem key="P3">P3</DropdownItem>
                        <DropdownItem key="P4">P4</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              )}
              {Array.from(filterDropdown).includes("Stage") && (
                <div className="flex border rounded-sm  ">
                  <div className=" flex items-center">
                    <h2 className="text-xs ml-2 mr-2">Stage</h2>
                    <div className="border-l border-gray-300 h-full " />
                  </div>
                  <div className="  rounded-r-md text-xs">
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          className=" capitalize"
                          size="sm"
                          radius="none"
                          variant="light"
                        >
                          {Array.from(selectedStageKeys).length === 0
                            ? "Add"
                            : Array.from(selectedStageKeys)[0]}{" "}
                          {selectedStageKeys.size - 1 <= 0
                            ? ""
                            : `+${selectedStageKeys.size - 1}`}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        className="h-80 w-[300px] overflow-y-auto"
                        variant="flat"
                        closeOnSelect={false}
                        selectionMode="multiple"
                        selectedKeys={selectedStageKeys}
                        onSelectionChange={setSelectedStageKeys}
                      >
                        {selectedStageKeys.size > 0 && (
                          <DropdownItem
                            isReadOnly
                            className="cursor-default border"
                          >
                            <div className=" h-auto w-full p-2 flex flex-wrap gap-2 overflow-y-auto">
                              {Array.from(selectedStageKeys).map((item) => (
                                <Chip
                                  variant="bordered"
                                  size="sm"
                                  key={item}
                                  onClick={() => handleDeleteKey(item)}
                                  className="cursor-pointer text-gray-600 gap-2 border border-divider"
                                  endContent={
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="size-4"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18 18 6M6 6l12 12"
                                      />
                                    </svg>
                                  }
                                >
                                  {item}
                                </Chip>
                              ))}
                            </div>
                          </DropdownItem>
                        )}
                        <DropdownSection
                          showDivider
                          title={
                            <Button
                              onClick={() => handleStage(open)}
                              className="w-full justify-start"
                              size="sm"
                              variant="light"
                            >
                              Open
                            </Button>
                          }
                        >
                          <DropdownItem key="Queued">Queued</DropdownItem>
                        </DropdownSection>
                        <DropdownSection
                          showDivider
                          title={
                            <Button
                              onClick={() => handleStage(progress)}
                              className="w-full justify-start"
                              size="sm"
                              variant="light"
                            >
                              In progress
                            </Button>
                          }
                        >
                          <DropdownItem key="Work in progress">
                            Work in progress
                          </DropdownItem>
                          <DropdownItem key="Awaiting Product assist">
                            Awaiting Product assist
                          </DropdownItem>
                          <DropdownItem key="Awaiting Development">
                            Awaiting Development
                          </DropdownItem>
                          <DropdownItem key="In Development">
                            In Development
                          </DropdownItem>
                          <DropdownItem key="Awaiting Customer Response">
                            Awaiting Customer Response
                          </DropdownItem>
                        </DropdownSection>
                        <DropdownSection
                          title={
                            <Button
                              onClick={() => handleStage(closed)}
                              className="w-full justify-start"
                              size="sm"
                              variant="light"
                            >
                              Closed
                            </Button>
                          }
                        >
                          <DropdownItem key="Accepted">Accepted</DropdownItem>
                          <DropdownItem key="Resolved">Resolved</DropdownItem>
                          <DropdownItem key="Cancelled">Cancelled</DropdownItem>
                        </DropdownSection>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              )}
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className=" border border-divider"
                    isIconOnly
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Multiple selection"
                  variant="flat"
                  closeOnSelect={false}
                  selectionMode="multiple"
                  disallowEmptySelection={false}
                  selectedKeys={filterDropdown}
                  onSelectionChange={setFilterDropdowm}
                >
                  <DropdownItem key="Work Type">Work Type</DropdownItem>
                  <DropdownItem key="Stage">Stage</DropdownItem>
                  <DropdownItem key="Priority">Priority</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Button
                onClick={() => handleClearFilters()}
                size="sm"
                variant="light"
              >
                Clear
              </Button>
            </div>

            <div className="flex gap-2 items-center">
              <Dropdown>
                <DropdownTrigger>
                  <Button size="sm" variant="light">
                    Customize
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Multiple selection"
                  variant="flat"
                  closeOnSelect={false}
                  selectionMode="multiple"
                  disallowEmptySelection={false}
                  selectedKeys={customizeItems}
                  onSelectionChange={setCustomizeItems}
                >
                  {customizeCompleteList.map((name) => (
                    <DropdownItem key={name}>{name}</DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
        {(loader.fir || loader.sec || res===null) ? (
          <div className="flex flex-col items-center justify-center mt-52">
            <HelixLoader />
          </div>
        ) : ticketData.length === 0 ? (
          <div className="flex mt-60 flex-col w-full items-center justify-center">
            <PiCoffeeThin
              style={{
                width: "50px",
                height: "50px",
                color: "#6B7280",
                opacity: "90%",
              }}
            />
            <p className="text-xs text-opacity-90 text-gray-500">
              No Tickets. Time for coffee.
            </p>
          </div>
        ) : (
          <div className="h-full flex-1 overflow-y-auto w-full ">
            <GroupIssues
              parsedParts={parsedParts}
              handleDelete={handleDelete}
              customTags={customTags}
              customizeHeaderList={customizeHeaderList}
              tickets={groupByField(ticketData, "assigned")}
              orderby={"assigned"}
              res={res}
              tenant={{ name: org, id: orgData?.id }}
              toggleTicket={toggleTicket}
            />
          </div>
        )}

        
      
    </div>
  );
};

export default TeamActivity;
