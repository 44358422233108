import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteSingleAttribute,
  getFilteredData,
  getOneData,
} from "../../controllers/strapiController";
import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownSection,
  Chip,
  DropdownMenu,
  DropdownItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  Divider,
  Breadcrumbs,
  BreadcrumbItem,
  Switch,
  Tabs,
  Tab,
  Tooltip,
} from "@nextui-org/react";
import { deleteData, getMultipleFilters } from "../../controllers/vectorDB";
import { renderCell } from "../../utils/constant";
import Issue from "../Issues/issuedetails";
import { PiCoffeeThin } from "react-icons/pi";
import { PermissionGroupSingleRole } from "../../utils/permission";
import Drawer from "../../components/Drawer/drawer";
import SprintDrawer from "../SprintBoard/Drawer/CreateSprint";
import InsightsDrawer from "./InsightsDrawer";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import WarningIcon from "../../Images/Icons/WarningIcon";
import BackArrow from "../../Images/Icons/BackArrow";
import ScredriverWrench from "../../Images/Icons/ScredriverWrench";
import ChevronArrow from "../../Images/Icons/ChevronArrow";
const customizeCompleteList = [
  "Stage",
  "Owner",
  "Priority",
  "Target Closing Date",
  "Estimated days",
  "Tags",
];
const open = ["Queued"];
const progress = [
  "Work in progress",
  "Awaiting Product assist",
  "Awaiting Development",
  "In Development",
  "Awaiting Customer Response",
];
const closed = ["Accepted", "Resolved", "Cancelled"];
const SprintDetails = () => {
  const [toggle, setToggle] = useState("Sprint1");
  const [isTicketOpen, setIsTicketOpen] = useState(false);
  const { id } = useParams();
  const drawerRef = useRef();
  const updateRef = useRef();
  const ticketRef = useRef();
  const tagsref = useRef();
  const modalref = useRef();
  const searchRef = useRef();
  const inputRef = useRef();
  const sprintRef = useRef();
  const insightRef = useRef();
  const [ticket, setTicket] = useState();
  const [updateDrawer, setUpdateDrawer] = useState(false);
  const [filterPriority, setFilterPriority] = useState(new Set([]));
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTicket, setSearchTicket] = useState("");
  const [finalIssues, setFinalIssues] = useState([]);
  const [filterDropdown, setFilterDropdowm] = useState(new Set(["Work Type"]));
  const { org } = useParams();
  const [partIds, setPartIds] = useState([]);
  const [ownerIds, setOwnerIds] = useState([]);
  const [sprintTags, setSprintTags] = useState([]);
  const [pendingShown, setPendingShown] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [sprint1End, setSprint1End] = useState("");
  const [sprint2End, setSprint2End] = useState("");
  const [sprint2Start, setSprint2Start] = useState("");
  const [sprint1Start, setSprint1Start] = useState("");
  const [issues, setIssues] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [sprint, setSprint] = useState(null);
  const navigate = useNavigate()
  const [current, setCurrent] = useState(null);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [customizeItems, setCustomizeItems] = useState(new Set(["Stage"]));
  const handleCloseTicket = () => {
    setIsTicketOpen(!isTicketOpen);
  };
  const [userNames, setUserNames] = useState([]);
  const [pendingIssues, setPendingIssues] = useState([]);
  const [users, setUsers] = useState([]);
  const [res, setRes] = useState({});
  const [userIds, setUserIds] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const [userTicket, setUserTicket] = useState([]);
  const resolved = ["Accepted", "Resolved", "Cancelled"];
  const [startDate, setStartDate] = useState(null);
  const [isCreatedTickets, setIsCreatedTickets] = useState(false);
  const [duration, setDuration] = useState(null);
  const [prevIssues, setPrevIssues] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Current");
  const [moreSprints, setMoreSprints] = useState(new Set([]));
  const [issuesShown, setIssuesShown] = useState([]);
  const [insightDrawer, setInsightDrawer] = useState(false);
  const [userPerm, setUserPerm] = useState({
    name: "Role-2",
    class: "Issues",
    privileges: [
      {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
    ],
  });
  function addDaysToDate(dateString, daysToAdd) {
    const [day, month, year] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate() + daysToAdd);
    const newDay = String(date.getDate()).padStart(2, "0");
    const newMonth = String(date.getMonth() + 1).padStart(2, "0");
    const newYear = date.getFullYear();

    return `${newDay}-${newMonth}-${newYear}`;
  }
  const [selectedStageKeys, setSelectedStageKeys] = useState(new Set([]));
  const handleStage = (e) => {
    let items = Array.from(selectedStageKeys);
    if (e.every((element) => items.includes(element))) {
      items = items.filter((element) => !e.includes(element));
    } else {
      items = items.concat(e);
    }

    setSelectedStageKeys(new Set(items));
  };
  const handleDeleteKey = (item) => {
    let updatedKeys = new Set(selectedStageKeys);
    updatedKeys.delete(item);
    setSelectedStageKeys(updatedKeys);
  };
  const handleClearFilters = () => {
    setFilterPriority(new Set([]));
    setSelectedStageKeys(new Set([]));
    setIsCreatedTickets(false);
    setSearchTicket("");
  };
  const handleSprint1 = () => {
    setToggle("Sprint1");
  };
  const handleSprint2 = () => {
    setToggle("Sprint2");
  };
  const handleBacklog = () => {
    setToggle("Backlog");
  };
  function formatDate(dateString) {
    const [day, month, year] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]
      } ${date.getFullYear()}`;
    return formattedDate;
  }
  const [selectedPriority, setSelectedPriority] = useState(new Set());
  const priorityData = {
    Urgent: {
      color: 'text-red-500',
      id: "P1",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 text-red-500">
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    High: {
      color: 'text-yellow-500',
      id: "P2",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 text-yellow-500">
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    Normal: {
      color: 'text-blue-500',
      id: "P3",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 text-blue-500">
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    Low: {
      color: 'text-gray-500',
      id: "P4",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 text-gray-500">
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
  };

  const coulmnDef = Array.from(customizeItems).map((name) => {
    return {
      id: name.toLowerCase(),
      label: name,
    };
  });
  const customizeHeaderList = [
    {
      id: "items",
      label: "Items",
    },
    {
      id: "title",
      label: "Title",
    },
    {
      id: "assigned to",
      label: "Assigned to",
    },
    ...coulmnDef,
    {
      id: "delete",
      label: "",
    },
  ];
  const toggleTicket = (e) => {
    setTicket(e);
    setIsTicketOpen(!isTicketOpen);
  };
  const findRange = (item) => {
    const sd = addDaysToDate(startDate, (item - 1) * duration);
    const ed = addDaysToDate(startDate, item * duration - 1);
    return `${formatDate(sd)} - ${formatDate(ed)}`;
  };
  const handleDelete = async (item) => {
    try {
      const resp = await deleteData(item.id, org);
      const promises = item.images.map((id) =>
        DeleteSingleAttribute("upload/files", Number(id.id))
      );
      const results = await Promise.all(promises);

      window.location.reload();
    } catch (error) {
      window.location.reload();
    }
  };
  const parseStart = (d) => {
    const splitDate = d.split("-");
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  };
  useEffect(() => {
    const fetchSprints = async () => {
      setToggle("Sprint1");
      setIsTicketOpen(false);
      setSelectedTab("Current");
      try {
        const resp = await getFilteredData("sprints", "path", id);
        setSprint(resp.data[0]);
        setCurrent(Number(resp.data[0].attributes.Current));
        if (Number(resp.data[0].attributes.Current) > 1) {
          setMoreSprints(
            new Set([String(Number(resp.data[0].attributes.Current) - 1)])
          );
        }
        setStartDate(resp.data[0].attributes.StartDate);
        setDuration(Number(resp.data[0].attributes.Duration));
        setPartIds(
          resp.data[0].attributes.parts.data.map((item) => {
            return String(item.id);
          })
        );
        setOwnerIds(
          resp.data[0].attributes.owners.data.map((item) => {
            return String(item.id);
          })
        );
        setSprintTags(
          resp.data[0].attributes.Tags.data.map((item) => {
            return Number(item.id);
          })
        );
        let start = resp.data[0].attributes.StartDate;
        let duration = Number(resp.data[0].attributes.Duration);
        let curr = Number(resp.data[0].attributes.Current);
        let nxt = curr + 1;
        if (
          resp.data[0].attributes.StartDate &&
          resp.data[0].attributes.Duration
        ) {
          setSprint1Start(addDaysToDate(start, (curr - 1) * duration));
          setSprint1End(addDaysToDate(start, curr * duration - 1));
          setSprint2Start(addDaysToDate(start, (nxt - 1) * duration));
          setSprint2End(addDaysToDate(start, nxt * duration - 1));
        }
      } catch (error) {
        console.error("Error fetching sprints:", error);
      }
    };
    if (id) {
      fetchSprints();
    }
  }, [id]);
  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const issue = await getMultipleFilters(
          [
            { key: "type", value: "issue" },
            { key: "sprint", value: String(sprint.id) },
          ],
          org
        );
        setIssues(issue);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };
    if (sprint?.id && org && current) {
      fetchIssues();
    }
  }, [sprint?.id, org, id, current]);
  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        const resp = await getFilteredData("organizations", "Name", org);
        setOrgId(resp?.data[0]?.id);
      } catch (error) { }
    };
    if (org) {
      fetchOrgDetails();
    }
  }, [org]);
  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        const resp = await getOneData("organizations", orgId);

        let userArray = [];
        let tagsArray = [];
        for (let i = 0; i < resp.data.attributes.users.data.length; i++) {
          userArray.push(resp.data.attributes.users.data[i].id);
        }
        for (let i = 0; i < resp.data.attributes.tags.data.length; i++) {
          tagsArray.push(resp.data.attributes.tags.data[i].id);
        }
        setTagIds(tagsArray);
        setUserIds(userArray);
      } catch (error) { }
    };
    if (orgId) {
      fetchOrgDetails();
    }
  }, [orgId]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const promises = userIds.map((id) => getOneData("users", id));
        const results = await Promise.all(promises);
        setUsers(results);
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
        throw error;
      }
    };
    if (userIds.length > 0) {
      getAllDataByIds();
    }
  }, [userIds]);
  useEffect(() => {
    const getAllDataByIds = async () => {
      try {
        const promises = tagIds.map((id) => getOneData("tags", id));
        const results = await Promise.all(promises);
        let partarray = [];
        for (let i = 0; i < results.length; i++) {
          partarray.push(results[i].data);
        }
        setCustomTags(partarray);
      } catch (error) {
        console.error(
          "Error fetching data for all IDs:",
          error.status,
          error.message
        );
        throw error;
      }
    };
    if (tagIds.length > 0) {
      getAllDataByIds();
    }
  }, [tagIds]);
  useEffect(() => {
    if (issues.length > 0 && current) {
      setPendingIssues(
        issues.filter(
          (item) =>
            Number(item?.payload?.currentSprint) < current &&
            !resolved.includes(String(item?.payload?.stage))
        )
      );
      setPrevIssues(
        issues.filter((item) => Number(item?.payload?.currentSprint) < current)
      );
    }
  }, [issues, current]);
  const handleNavigate = () => {
    navigate(`/${org}/sprint-boards`);
  };
  useEffect(() => {
    if (Array.from(moreSprints).length > 0 && prevIssues.length > 0) {
      let filteredTickets = prevIssues;
      filteredTickets = filteredTickets.filter(
        (item) =>
          Number(item?.payload?.currentSprint) ===
          Number(Array.from(moreSprints)[0])
      );
      if (Array.from(selectedStageKeys).length !== 0) {
        filteredTickets = filteredTickets.filter((ticket) =>
          Array.from(selectedStageKeys).includes(ticket?.payload?.stage)
        );
      }
      if (isCreatedTickets) {
        filteredTickets = filteredTickets.filter((item) =>
          userTicket.includes(item)
        );
      }
      if (searchTicket !== "") {
        filteredTickets = filteredTickets.filter((item) =>
          item?.payload?.title
            .toLowerCase()
            .includes(searchTicket.toLowerCase())
        );
      }
      if (Array.from(filterPriority).length > 0) {
        filteredTickets = filteredTickets.filter((item) =>
          Array.from(filterPriority).includes(item?.payload?.priority)
        );
      }
      setIssuesShown(filteredTickets);
    }
  }, [
    moreSprints,
    prevIssues,
    selectedStageKeys,
    isCreatedTickets,
    searchTicket,
    filterPriority,
    current,
    sprint,
    id,
  ]);
  useEffect(() => {
    let filteredTickets = issues;
    let pend = pendingIssues;
    if (toggle === "Backlog") {
      filteredTickets = issues.filter(
        (item) => String(item?.payload?.currentSprint) === "Backlog"
      );
    }
    if (toggle === "Sprint1") {
      filteredTickets = issues.filter(
        (item) => Number(item?.payload?.currentSprint) === current
      );
    }
    if (toggle === "Sprint2") {
      filteredTickets = issues.filter(
        (item) => Number(item?.payload?.currentSprint) === current + 1
      );
    }
    if (Array.from(selectedStageKeys).length !== 0) {
      filteredTickets = filteredTickets.filter((ticket) =>
        Array.from(selectedStageKeys).includes(ticket?.payload?.stage)
      );
      pend = pend.filter((ticket) =>
        Array.from(selectedStageKeys).includes(ticket?.payload?.stage)
      );
    }
    if (isCreatedTickets) {
      filteredTickets = filteredTickets.filter((item) =>
        userTicket.includes(item)
      );
      pend = pend.filter((item) => userTicket.includes(item));
    }
    if (searchTicket !== "") {
      filteredTickets = filteredTickets.filter((item) =>
        item?.payload?.title.toLowerCase().includes(searchTicket.toLowerCase())
      );
      pend = pend.filter((item) =>
        item?.payload?.title.toLowerCase().includes(searchTicket.toLowerCase())
      );
    }
    if (Array.from(filterPriority).length > 0) {
      filteredTickets = filteredTickets.filter((item) =>
        Array.from(filterPriority).includes(item?.payload?.priority)
      );
      pend = pend.filter((item) =>
        Array.from(filterPriority).includes(item?.payload?.priority)
      );
    }
    setFinalIssues(filteredTickets);
    setPendingShown(pend);
  }, [
    selectedStageKeys,
    isCreatedTickets,
    searchTicket,
    filterPriority,
    toggle,
    issues,
    current,
    sprint,
    id,
    pendingIssues,
  ]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!sprintRef.current || !sprintRef.current.contains(event.target))
      ) {
        setIsDrawerOpen(false);
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (insightRef.current && !insightRef.current.contains(event.target)) {
        setInsightDrawer(false);
      }
    };
    if (insightDrawer) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [insightDrawer]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        updateRef.current &&
        !updateRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target))
      ) {
        setUpdateDrawer(false);
      }
    };
    if (updateDrawer) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [updateDrawer]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && searchTicket === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, searchTicket]);
  useEffect(() => {
    if (issues.length > 0) {
      const filteredTickets = issues.filter(
        (ticket) => Number(ticket?.payload?.assigned) === userDataId
      );
      setUserTicket(filteredTickets);
    }
  }, [issues]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ticketRef.current &&
        !ticketRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target)) &&
        (!sprintRef.current || !sprintRef.current.contains(event.target))
      ) {
        setIsTicketOpen(!isTicketOpen);
      }
    };
    if (isTicketOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTicketOpen]);

  useEffect(() => {
    const convertedUsers = {};
    const userN = [];
    users.forEach((user) => {
      userN.push({
        id: user.id,
        name: user.FirstName ? `${user.FirstName} ${user.LastName ? user.LastName : ""}` : user.username,
        url: user.ProfilePicture?.formats?.small?.url || "",
      })
      convertedUsers[user.id] = {
        UserName: user.username,
        FirstName: user.FirstName,
        LastName: user.LastName,
        ProfilePicture: user.ProfilePicture?.formats?.small?.url || "",
      };
    });
    setUserNames(userN);
    setRes(convertedUsers);
  }, [users]);
  useEffect(() => {
    const fetchPerm = async () => {
      try {
        const resp = await PermissionGroupSingleRole(
          userDataId,
          orgId,
          "Role-2",
          "dev"
        );
        if (resp !== false) {
          setUserPerm(resp);
        }
      } catch (error) { }
    };
    if (orgId) {
      fetchPerm();
    }
  }, [orgId, userDataId]);
  return (
    <div className=" overflow-y-auto">
      {isTicketOpen && (
        <div ref={ticketRef}>
          <Issue
            userNames={userNames}
            inputRef={sprintRef}
            users={users}
            tenant={{ name: org, id: orgId }}
            pos="issue"
            dropdownref={tagsref}
            ticket={ticket}
            res={res}
            handleCloseTicket={handleCloseTicket}
            modalref={modalref}
            permission={userPerm.privileges[0].update}
          />
        </div>
      )}
      {isDrawerOpen && (
        <div ref={drawerRef}>
          <Drawer
            inputRef={sprintRef}
            users={users}
            tenant={{ name: org, id: orgId }}
            modalref={modalref}
            res={res}
            dropdownref={tagsref}
            query="issue"
            toggleDrawer={() => setIsDrawerOpen(false)}
          />
        </div>
      )}
      {insightDrawer && (
        <div ref={insightRef}>
          <InsightsDrawer />
        </div>
      )}
      {updateDrawer && (
        <div ref={updateRef}>
          <SprintDrawer
            handleDrawerClose={() => setUpdateDrawer(false)}
            divRef={tagsref}
            inputRef={modalref}
            type="update"
            sprint={{
              id: sprint.id,
              owners: ownerIds,
              part: partIds,
              tags: sprintTags,
              title: sprint.attributes.Title,
              description: sprint.attributes.Description,
              date: parseStart(sprint.attributes.StartDate),
              duration: Number(sprint.attributes.Duration),
              priority: sprint.attributes.Priority,
              icon: sprint.attributes.Icon
            }}

          />
        </div>
      )}
      <div className="mb-4 pt-8 mx-8 flex items-center justify-between ">
        <div className=" flex text-center justify-center cursor-pointer items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm" isDisabled>
            <BreadcrumbItem onClick={handleNavigate}>Sprint Boards</BreadcrumbItem>
            <BreadcrumbItem>{sprint?.attributes?.Title}</BreadcrumbItem>
          </Breadcrumbs>
        </div>
        <Tabs
          disabledKeys={current && current > 1 ? [] : ["Previous"]}
          size="sm"
          selectedKey={selectedTab}
          onSelectionChange={setSelectedTab}
        >
          <Tab key="Current" title="Current" />
          <Tab
            key="Previous"
            title={
              current && current > 1 ? (
                "Previous"
              ) : (
                <Tooltip
                  content={current && current > 1 ? "" : "No completed sprints"}
                >
                  <p>Previous</p>
                </Tooltip>
              )
            }
          />
        </Tabs>
        <div className=" flex items-center">
          <Button
            onClick={() => setUpdateDrawer(true)}
            variant="light"
            className=" mx-2 border border-divider"
            size="sm"
            isIconOnly
            startContent={
              <lord-icon
                src="https://cdn.lordicon.com/lecprnjb.json"
                className="ml-4 w-full"
                style={{ width: "18px", height: "18px" }}
              ></lord-icon>
            }
          ></Button>
          <Button
            onClick={() => setInsightDrawer(true)}
            variant="light"
            className=" mx-2 border border-divider"
            size="sm"
            startContent={
              <lord-icon
                src="https://cdn.lordicon.com/whrxobsb.json"
                colors={`primary: "#000000"`}
                style={{ width: "16px", height: "16px" }}
              ></lord-icon>
            }
          >
            Insights
          </Button>
          <Dropdown>
            <DropdownTrigger>
              <Button
                size="sm"
                isIconOnly
                color="default"
                variant="light"
                className="mx-2 border border-divider"
              >
                <svg
                  style={{ width: "15px", height: "15px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                  />
                </svg>
              </Button>
            </DropdownTrigger>
            <DropdownMenu className="w-[250px]">
              <DropdownSection showDivider>
                <DropdownItem
                  isReadOnly
                  startContent={
                    <div className=" flex justify-center items-center p-2 rounded-md bg-purple-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#AC70FF"
                        className="size-6"
                        style={{ width: "17px", height: "17px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                        />
                      </svg>
                    </div>
                  }
                  endContent={
                    <Switch size="sm" aria-label="Automatic updates" />
                  }
                >
                  <div className="flex">
                    <p className=" font-semibold text-xs">Smart Cluster</p>
                  </div>
                </DropdownItem>
              </DropdownSection>
              <DropdownSection title="Export view">
                <DropdownItem
                  startContent={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#6E9CFD"
                      className="size-6"
                      style={{ width: "17px", height: "17px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                  }
                >
                  <p className="text-xs"> Export as CSV</p>
                </DropdownItem>
                <DropdownItem
                  startContent={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#6E9CFD"
                      className="size-6"
                      style={{ width: "17px", height: "17px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                  }
                >
                  <p className="text-xs">Export as JSON</p>
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>
          {userPerm.privileges[0].create === true && (
            <Button
              size="sm"
              className="mx-2 text-white bg-[rgb(51,51,245)]"
              onClick={() => setIsDrawerOpen(true)}
            >
              + Issue
            </Button>
          )}
        </div>
      </div>

      {selectedTab === "Current" ? (
        <>
          <div className="w-full py-8 px-4 gap-4 flex justify-around">
            <Button
              onClick={() => handleSprint1()}
              endContent={
                <p className=" text-end text-xs">{`${formatDate(
                  sprint1Start
                )} - ${formatDate(sprint1End)}`}</p>
              }
              className={`w-full ${toggle === "Sprint1"
                ? "bg-white border hover:bg-[rgb(238,238,241)] border-divider"
                : "bg-[rgb(238,238,241)]"
                }`}
            >
              <ScredriverWrench />
              <p className={`w-full text-start `}>
                Sprint-{current ? current : ""}
              </p>
            </Button>
            <Button
              onClick={() => handleSprint2()}
              endContent={
                <p className=" text-xs text-end">{`${formatDate(
                  sprint2Start
                )} - ${formatDate(sprint2End)}`}</p>
              }
              className={`w-full ${toggle === "Sprint2"
                ? "bg-white border border-divider hover:bg-[rgb(238,238,241)]"
                : "bg-[rgb(238,238,241)]"
                }`}
            >
              <ChevronArrow />
              <p className={`w-full text-start`}>
                Sprint-{current ? current + 1 : ""}
              </p>
            </Button>
            <Button
              onClick={() => handleBacklog()}
              className={`w-full ${toggle === "Backlog"
                ? "bg-white border border-divider hover:bg-[rgb(238,238,241)]"
                : "bg-[rgb(238,238,241)]"
                }`}
            >

              <WarningIcon />

              Backlog
            </Button>
          </div>

          {(finalIssues.length === 0 && toggle !== "Sprint1") ||
            (toggle === "Sprint1" &&
              finalIssues.length === 0 &&
              pendingIssues.length === 0) ? (
            <div className="flex mt-60 flex-col w-full items-center justify-center">
              <PiCoffeeThin
                style={{
                  width: "50px",
                  height: "50px",
                  color: "#6B7280",
                  opacity: "90%",
                }}
              />
              <p className="text-xs text-opacity-90 text-gray-500">
                No Issues. Time for coffee.
              </p>
            </div>
          ) : (
            <>
              <div className="z-0 flex px-8 mt-4 items-center justify-between">
                <div className="flex items-center gap-4">
                  <div
                    ref={searchRef}
                    onClick={() => setIsSearchOpen(true)}
                    className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${isSearchOpen
                      ? "pl-2 w-40 gap-2 justify-start"
                      : "w-8 justify-center"
                      } rounded-full  h-8 `}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                      />
                    </svg>
                    {isSearchOpen && (
                      <input
                        ref={inputRef}
                        value={searchTicket}
                        onChange={(e) => setSearchTicket(e.target.value)}
                        style={{ height: "20px", outline: "none" }}
                        className="w-full px-2 text-xs rounded-full"
                        placeholder="Search"
                      />
                    )}
                  </div>
                  <div
                    onClick={(e) => setIsCreatedTickets(!isCreatedTickets)}
                    className={`border cursor-pointer border-divider w-8 h-8 rounded-full flex ${isCreatedTickets ? " bg-[#DBDADF]" : " hover:bg-[#DBDADF]"
                      } items-center justify-center`}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                  </div>

                  {Array.from(filterDropdown).includes("Work Type") && (
                    <div className="flex">
                      <div className="p-2 border-b border-t border-l rounded-l-md text-xs">
                        <h2>Work Type</h2>
                      </div>
                      <div className=" border rounded-r-md text-xs">
                        <Button
                          size="sm"
                          radius="none"
                          color="primary"
                          variant="light"
                        >
                          Issue
                        </Button>
                      </div>
                    </div>
                  )}
                  {Array.from(filterDropdown).includes("Priority") && (
                    <div className="flex">
                      <div className="p-2 border-b border-t border-l rounded-l-md text-xs">
                        <h2>Priority</h2>
                      </div>
                      <div className=" border rounded-r-md text-xs">
                        <Dropdown>
                          <DropdownTrigger>
                            <Button size="sm" radius="none" variant="light">
                              {Array.from(filterPriority).length === 0
                                ? "Add"
                                : Array.from(filterPriority)[0]}{" "}
                              {filterPriority.size - 1 <= 0
                                ? ""
                                : `+${filterPriority.size - 1}`}
                            </Button>
                          </DropdownTrigger>
                          <DropdownMenu
                            variant="flat"
                            closeOnSelect={false}
                            selectionMode="multiple"
                            selectedKeys={filterPriority}
                            onSelectionChange={setFilterPriority}
                          >
                            <DropdownItem key="P1">P1</DropdownItem>
                            <DropdownItem key="P2">P2</DropdownItem>
                            <DropdownItem key="P3">P3</DropdownItem>
                            <DropdownItem key="P4">P4</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                  {Array.from(filterDropdown).includes("Stage") && (
                    <div className="flex">
                      <div className="p-2 border-b border-t border-l rounded-l-md text-xs">
                        <h2>Stage</h2>
                      </div>
                      <div className=" border rounded-r-md text-xs">
                        <Dropdown>
                          <DropdownTrigger>
                            <Button
                              className=" capitalize"
                              size="sm"
                              radius="none"
                              variant="light"
                            >
                              {Array.from(selectedStageKeys).length === 0
                                ? "Add"
                                : Array.from(selectedStageKeys)[0]}{" "}
                              {selectedStageKeys.size - 1 <= 0
                                ? ""
                                : `+${selectedStageKeys.size - 1}`}
                            </Button>
                          </DropdownTrigger>
                          <DropdownMenu
                            className="h-80 w-[300px] overflow-y-auto"
                            variant="flat"
                            closeOnSelect={false}
                            selectionMode="multiple"
                            selectedKeys={selectedStageKeys}
                            onSelectionChange={setSelectedStageKeys}
                          >
                            {selectedStageKeys.size > 0 && (
                              <DropdownItem
                                isReadOnly
                                className="cursor-default border"
                              >
                                <div className=" h-auto w-full p-2 flex flex-wrap gap-2 overflow-y-auto">
                                  {Array.from(selectedStageKeys).map((item) => (
                                    <Chip
                                      variant="bordered"
                                      size="sm"
                                      key={item}
                                      onClick={() => handleDeleteKey(item)}
                                      className="cursor-pointer text-gray-600 border border-divider gap-2"
                                      endContent={
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="size-4"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18 18 6M6 6l12 12"
                                          />
                                        </svg>
                                      }
                                    >
                                      {item}
                                    </Chip>
                                  ))}
                                </div>
                              </DropdownItem>
                            )}
                            <DropdownSection
                              showDivider
                              title={
                                <Button
                                  onClick={() => handleStage(open)}
                                  className="w-full justify-start"
                                  size="sm"
                                  variant="light"
                                >
                                  Open
                                </Button>
                              }
                            >
                              <DropdownItem key="Queued">Queued</DropdownItem>
                            </DropdownSection>
                            <DropdownSection
                              showDivider
                              title={
                                <Button
                                  onClick={() => handleStage(progress)}
                                  className="w-full justify-start"
                                  size="sm"
                                  variant="light"
                                >
                                  In progress
                                </Button>
                              }
                            >
                              <DropdownItem key="Work in progress">
                                Work in progress
                              </DropdownItem>
                              <DropdownItem key="Awaiting Product assist">
                                Awaiting Product assist
                              </DropdownItem>
                              <DropdownItem key="Awaiting Development">
                                Awaiting Development
                              </DropdownItem>
                              <DropdownItem key="In Development">
                                In Development
                              </DropdownItem>
                              <DropdownItem key="Awaiting Customer Response">
                                Awaiting Customer Response
                              </DropdownItem>
                            </DropdownSection>
                            <DropdownSection
                              title={
                                <Button
                                  onClick={() => handleStage(closed)}
                                  className="w-full justify-start"
                                  size="sm"
                                  variant="light"
                                >
                                  Closed
                                </Button>
                              }
                            >
                              <DropdownItem key="Accepted">
                                Accepted
                              </DropdownItem>
                              <DropdownItem key="Resolved">
                                Resolved
                              </DropdownItem>
                              <DropdownItem key="Cancelled">
                                Cancelled
                              </DropdownItem>
                            </DropdownSection>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                  <Dropdown>
                    <DropdownTrigger>
                      <Button
                        size="sm"
                        variant="light"
                        className=" border border-divider"
                        isIconOnly
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                          />
                        </svg>
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Multiple selection"
                      variant="flat"
                      closeOnSelect={false}
                      selectionMode="multiple"
                      disallowEmptySelection={false}
                      selectedKeys={filterDropdown}
                      onSelectionChange={setFilterDropdowm}
                    >
                      <DropdownItem key="Work Type">Work Type</DropdownItem>
                      <DropdownItem key="Stage">Stage</DropdownItem>
                      <DropdownItem key="Priority">Priority</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    onClick={() => handleClearFilters()}
                    size="sm"
                    variant="light"
                  >
                    Clear
                  </Button>
                </div>
                <div className="flex gap-2 items-center">
                  <Dropdown>
                    <DropdownTrigger>
                      <Button size="sm" variant="light">
                        Sort
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu></DropdownMenu>
                  </Dropdown>
                  <Dropdown>
                    <DropdownTrigger>
                      <Button size="sm" variant="light">
                        Customize
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Multiple selection"
                      variant="flat"
                      closeOnSelect={false}
                      selectionMode="multiple"
                      disallowEmptySelection={false}
                      selectedKeys={customizeItems}
                      onSelectionChange={setCustomizeItems}
                    >
                      {customizeCompleteList.map((name) => (
                        <DropdownItem key={name}>{name}</DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="mt-4">
              <Divider />
              </div>
              <div className="w-full py-8">
                <Table
                  removeWrapper
                  radius="none"
                  className="mt-4 px-4 text-xs"
                  color="default"
                  aria-label="Custom Table"
                >
                  <TableHeader columns={customizeHeaderList}>
                    {(column) => (
                      <TableColumn key={column.id} className="text-xs">
                        {column.label}
                      </TableColumn>
                    )}
                  </TableHeader>
                  <TableBody emptyContent={"No tickets. Time for coffee."}>
                    {toggle === "Sprint1" &&
                      pendingShown.map((row, index) => (
                        <TableRow
                          className="cursor-pointer hover:bg-gray-100 text-xs  border-b border-divider"
                          onClick={(e) => toggleTicket(row)}
                          key={index + finalIssues.length}
                        >
                          {customizeHeaderList.map((item) => {
                            return renderCell(
                              row,
                              item.id,
                              res,

                              handleDelete,
                              customTags,
                              "pending",
                              false
                            );
                          })}
                        </TableRow>
                      ))}
                    {finalIssues.map((row, ind) => (
                      <TableRow
                        className="cursor-pointer hover:bg-gray-100 text-xs  border-b border-divider"
                        onClick={(e) => toggleTicket(row)}
                        key={ind}
                      >
                        {customizeHeaderList.map((item) => {
                          return renderCell(
                            row,
                            item.id,
                            res,
                            handleDelete,
                            customTags,
                            false,
                            false
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="w-full py-8 px-4 gap-4 flex justify-around">
            {current &&
              startDate &&
              duration &&
              Number(current) - 1 <= 5 &&
              Array.from({ length: Number(current) - 1 }, (_, i) => i + 1)
                .sort((a, b) => b - a)
                .slice(0, 5)
                .map((item) => (
                  <Button
                    onClick={() => setMoreSprints(new Set([String(item)]))}
                    endContent={
                      <p className=" text-end text-xs">{findRange(item)}</p>
                    }
                    className={`w-full ${String(Array.from(moreSprints)[0]) === String(item)
                      ? "bg-white border hover:bg-[rgb(238,238,241)] border-divider"
                      : "bg-[rgb(238,238,241)]"
                      }`}
                  >
                    <p className={`w-full text-start flex gap-2 items-center `}>
                      <BackArrow />
                      Sprint-{item ? item : ""}
                    </p>
                  </Button>
                ))}
            {current &&
              startDate &&
              duration &&
              Number(current) - 1 > 5 &&
              Array.from({ length: Number(current) - 1 }, (_, i) => i + 1)
                .sort((a, b) => b - a)
                .slice(0, 4)
                .map((item) => (
                  <Button
                    onClick={() => setMoreSprints(new Set([String(item)]))}
                    endContent={
                      <p className=" text-end text-xs">{findRange(item)}</p>
                    }
                    className={`w-full ${String(Array.from(moreSprints)[0]) === String(item)
                      ? "bg-white border hover:bg-[rgb(238,238,241)] border-divider"
                      : "bg-[rgb(238,238,241)]"
                      }`}
                  >
                    <p className={`w-full text-start `}>
                      Sprint-{item ? item : ""}
                    </p>
                  </Button>
                ))}
            {current && startDate && duration && Number(current) - 1 > 5 && (
              <Dropdown>
                <DropdownTrigger>
                  <Button className="bg-[rgb(238,238,241)] w-full">
                    View More
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  className=" w-full"
                  selectionMode="single"
                  selectedKeys={moreSprints}
                  onSelectionChange={setMoreSprints}
                >
                  {current &&
                    startDate &&
                    duration &&
                    Array.from({ length: Number(current) - 5 }, (_, i) => i + 1)
                      .sort((a, b) => b - a)
                      .map((item) => (
                        <DropdownItem className=" w-full" key={item}>
                          <div
                            className={`w-full flex justify-between gap-16 items-center `}
                          >
                            <p>Sprint-{item ? item : ""}</p>
                            <p className=" text-end text-xs">
                              {findRange(item)}
                            </p>
                          </div>
                        </DropdownItem>
                      ))}
                </DropdownMenu>
              </Dropdown>
            )}
          </div>

          {issuesShown.length === 0 ? (
            <div className="flex mt-60 flex-col w-full items-center justify-center">
              <PiCoffeeThin
                style={{
                  width: "50px",
                  height: "50px",
                  color: "#6B7280",
                  opacity: "90%",
                }}
              />
              <p className="text-xs text-opacity-90 text-gray-500">
                No Issues. Time for coffee.
              </p>
            </div>
          ) : (
            <>
              <div className="z-0 flex px-8 mt-4 items-center justify-between">
                <div className="flex items-center gap-4">
                  <div
                    ref={searchRef}
                    onClick={() => setIsSearchOpen(true)}
                    className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${isSearchOpen
                      ? "pl-2 w-40 gap-2 justify-start"
                      : "w-8 justify-center"
                      } rounded-full  h-8 `}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                      />
                    </svg>
                    {isSearchOpen && (
                      <input
                        ref={inputRef}
                        value={searchTicket}
                        onChange={(e) => setSearchTicket(e.target.value)}
                        style={{ height: "20px", outline: "none" }}
                        className="w-full px-2 text-xs rounded-full"
                        placeholder="Search"
                      />
                    )}
                  </div>
                  <div
                    onClick={(e) => setIsCreatedTickets(!isCreatedTickets)}
                    className={`border cursor-pointer border-divider w-8 h-8 rounded-full flex ${isCreatedTickets ? " bg-[#DBDADF]" : " hover:bg-[#DBDADF]"
                      } items-center justify-center`}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                  </div>

                  {Array.from(filterDropdown).includes("Work Type") && (
                    <div className="flex">
                      <div className="p-2 border rounded-l-md text-xs">
                        <h2>Work Type</h2>
                      </div>
                      <div className=" border rounded-r-md text-xs">
                        <Button
                          size="sm"
                          radius="none"
                          color="primary"
                          variant="light"
                        >
                          Issue
                        </Button>
                      </div>
                    </div>
                  )}
                  {Array.from(filterDropdown).includes("Priority") && (
                    <div className="flex">
                      <div className="p-2 border rounded-l-md text-xs">
                        <h2>Priority</h2>
                      </div>
                      <div className=" border rounded-r-md text-xs">
                        <Dropdown>
                          <DropdownTrigger>
                            <Button size="sm" radius="none" variant="light">
                              {Array.from(filterPriority).length === 0
                                ? "Add"
                                : Array.from(filterPriority)[0]}{" "}
                              {filterPriority.size - 1 <= 0
                                ? ""
                                : `+${filterPriority.size - 1}`}
                            </Button>
                          </DropdownTrigger>
                          <DropdownMenu
                            variant="flat"
                            closeOnSelect={false}
                            selectionMode="multiple"
                            selectedKeys={filterPriority}
                            onSelectionChange={setFilterPriority}
                          >
                            <DropdownItem key="P1">P1</DropdownItem>
                            <DropdownItem key="P2">P2</DropdownItem>
                            <DropdownItem key="P3">P3</DropdownItem>
                            <DropdownItem key="P4">P4</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                  {Array.from(filterDropdown).includes("Stage") && (
                    <div className="flex">
                      <div className="p-2 border rounded-l-md text-xs">
                        <h2>Stage</h2>
                      </div>
                      <div className=" border rounded-r-md text-xs">
                        <Dropdown>
                          <DropdownTrigger>
                            <Button
                              className=" capitalize"
                              size="sm"
                              radius="none"
                              variant="light"
                            >
                              {Array.from(selectedStageKeys).length === 0
                                ? "Add"
                                : Array.from(selectedStageKeys)[0]}{" "}
                              {selectedStageKeys.size - 1 <= 0
                                ? ""
                                : `+${selectedStageKeys.size - 1}`}
                            </Button>
                          </DropdownTrigger>
                          <DropdownMenu
                            className="h-80 w-[300px] overflow-y-auto"
                            variant="flat"
                            closeOnSelect={false}
                            selectionMode="multiple"
                            selectedKeys={selectedStageKeys}
                            onSelectionChange={setSelectedStageKeys}
                          >
                            {selectedStageKeys.size > 0 && (
                              <DropdownItem
                                isReadOnly
                                className="cursor-default border"
                              >
                                <div className=" h-auto w-full p-2 flex flex-wrap gap-2 overflow-y-auto">
                                  {Array.from(selectedStageKeys).map((item) => (
                                    <Chip
                                      variant="bordered"
                                      size="sm"
                                      key={item}
                                      onClick={() => handleDeleteKey(item)}
                                      className="cursor-pointer text-gray-600 border border-divider gap-2"
                                      endContent={
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="size-4"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18 18 6M6 6l12 12"
                                          />
                                        </svg>
                                      }
                                    >
                                      {item}
                                    </Chip>
                                  ))}
                                </div>
                              </DropdownItem>
                            )}
                            <DropdownSection
                              showDivider
                              title={
                                <Button
                                  onClick={() => handleStage(open)}
                                  className="w-full justify-start"
                                  size="sm"
                                  variant="light"
                                >
                                  Open
                                </Button>
                              }
                            >
                              <DropdownItem key="Queued">Queued</DropdownItem>
                            </DropdownSection>
                            <DropdownSection
                              showDivider
                              title={
                                <Button
                                  onClick={() => handleStage(progress)}
                                  className="w-full justify-start"
                                  size="sm"
                                  variant="light"
                                >
                                  In progress
                                </Button>
                              }
                            >
                              <DropdownItem key="Work in progress">
                                Work in progress
                              </DropdownItem>
                              <DropdownItem key="Awaiting Product assist">
                                Awaiting Product assist
                              </DropdownItem>
                              <DropdownItem key="Awaiting Development">
                                Awaiting Development
                              </DropdownItem>
                              <DropdownItem key="In Development">
                                In Development
                              </DropdownItem>
                              <DropdownItem key="Awaiting Customer Response">
                                Awaiting Customer Response
                              </DropdownItem>
                            </DropdownSection>
                            <DropdownSection
                              title={
                                <Button
                                  onClick={() => handleStage(closed)}
                                  className="w-full justify-start"
                                  size="sm"
                                  variant="light"
                                >
                                  Closed
                                </Button>
                              }
                            >
                              <DropdownItem key="Accepted">
                                Accepted
                              </DropdownItem>
                              <DropdownItem key="Resolved">
                                Resolved
                              </DropdownItem>
                              <DropdownItem key="Cancelled">
                                Cancelled
                              </DropdownItem>
                            </DropdownSection>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                  <Dropdown>
                    <DropdownTrigger>
                      <Button
                        size="sm"
                        variant="light"
                        className=" border border-divider"
                        isIconOnly
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                          />
                        </svg>
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Multiple selection"
                      variant="flat"
                      closeOnSelect={false}
                      selectionMode="multiple"
                      disallowEmptySelection={false}
                      selectedKeys={filterDropdown}
                      onSelectionChange={setFilterDropdowm}
                    >
                      <DropdownItem key="Work Type">Work Type</DropdownItem>
                      <DropdownItem key="Stage">Stage</DropdownItem>
                      <DropdownItem key="Priority">Priority</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    onClick={() => handleClearFilters()}
                    size="sm"
                    variant="light"
                  >
                    Clear
                  </Button>
                </div>
                <div className="flex gap-2 items-center">
                  <Dropdown>
                    <DropdownTrigger>
                      <Button size="sm" variant="light">
                        Sort
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu></DropdownMenu>
                  </Dropdown>
                  <Dropdown>
                    <DropdownTrigger>
                      <Button size="sm" variant="light">
                        Customize
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Multiple selection"
                      variant="flat"
                      closeOnSelect={false}
                      selectionMode="multiple"
                      disallowEmptySelection={false}
                      selectedKeys={customizeItems}
                      onSelectionChange={setCustomizeItems}
                    >
                      {customizeCompleteList.map((name) => (
                        <DropdownItem key={name}>{name}</DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>

              </div>
              <div className="w-full py-8">
                <Table
                  removeWrapper
                  radius="none"
                  className="mt-4 px-4 text-xs"
                  color="default"
                  aria-label="Custom Table"
                >
                  <TableHeader columns={customizeHeaderList}>
                    {(column) => (
                      <TableColumn key={column.id} className="text-xs">
                        {column.label}
                      </TableColumn>
                    )}
                  </TableHeader>
                  <TableBody emptyContent={"No tickets. Time for coffee."}>
                    {issuesShown.map((row, ind) => (
                      <TableRow
                        className="cursor-pointer hover:bg-gray-100 text-xs"
                        onClick={(e) => toggleTicket(row)}
                        key={ind}
                      >
                        {customizeHeaderList.map((item) => {
                          return renderCell(
                            row,
                            item.id,
                            res,
                            handleDelete,
                            customTags,
                            false,
                            false
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default SprintDetails;