import { CreateData, getFilteredBySingleRelation, getFilteredData } from "../controllers/strapiController";
import { postData } from "../controllers/vectorDB";
import { defaultGroups, defaultIssue, defaultTag, defaultTicket, defaultWidget } from "./default";

const createTags = async (orgId) => {
  const formData = defaultTag.map((item, index) => {
    return {
      Name: `Custom tag ${index + 1}`,
      Description: "This is a custom tag",
      Color: item.hex,
      ColorName: item.name,
      organization: {
        disconnect: [],
        connect: [
          {
            id: Number(orgId),
            position: {
              end: true,
            },
          },
        ],
      },
    };
  });
  try {
    const promises = formData.map((item) => CreateData("tags", item));
    const resp = await Promise.all(promises);
    if (resp) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const createPart = async (tagIds, orgId, userId) => {
  const formData = {
    Type: "Product",
    Name: "Default Product",
    Description: "<p>This is a default product</p>",
    Tags: tagIds,
    TotalTickets: "1",
    TotalIssues: "1",
    latest_part: 1,
    organization: {
      disconnect: [],
      connect: [
        {
          id: Number(orgId),
          position: {
            end: true,
          },
        },
      ],
    },
    users_permissions_user: {
      disconnect: [],
      connect: [
        {
          id: Number(userId),
          position: {
            end: true,
          },
        },
      ],
    },
  };
  try{
    const resp = await CreateData("parts",formData);
    if(resp){
        return true;
    }
    else{
        return false;
    }
  }
  catch(error){
    
    return false;
  }
};
const createWidget = async(orgId) => {
  const formData = defaultWidget(orgId);
  try{
    const resp = await CreateData("widgets",formData);
    if(resp){
      return true;
  }
  else{
      return false;
  }
  }
  catch(error){
    return false;
  }
}
const createGroups = async(orgId,userIds,ownnerId) => {
    const formData = defaultGroups(orgId,userIds,ownnerId);
    try{
        const promises = formData.map((item) => CreateData("groups",item));
        const resp = await Promise.all(promises);
        if(resp){
            return true;
        }
        else{
            return false;
        }
    }
    catch(error){
       
        return false;
    }
}

const createTicket = async (org, tagIds, partId, assignedId, ownerId) => {
  const formData = defaultTicket(tagIds, partId, assignedId, ownerId);
  try {
    const promises = formData.map(async (item,index) => {
      try {
        const resp = await postData(Date.now()+index, item, org);
        return resp;
      } catch (error) {
        console.error("Failed to create ticket:", item, error);
        return null; // or handle it as needed
      }
    });
    const responses = await Promise.all(promises);
    const successful = responses.filter(resp => resp !== null);
    return successful.length === formData.length;
  } catch (error) {
    console.error("Error in createTicket:", error);
    return false;
  }
};

const createIssue = async (org, tagIds, partId, assignedId, ownerId) => {
  const formData = defaultIssue(tagIds, partId, assignedId, ownerId);
  try {
    const promises = formData.map(async (item,index) => {
      try {
        const resp = await postData(Date.now()+index, item, org);
        return resp;
      } catch (error) {
        console.error("Failed to create issue:", item, error);
        return null; // or handle it as needed
      }
    });
    const responses = await Promise.all(promises);
    const successful = responses.filter(resp => resp !== null);
    return successful.length === formData.length;
  } catch (error) {
    console.error("Error in createIssue:", error);
    return false;
  }
};

export const createDefault = async (org, userId, setLoading) => {
  setLoading(0);
  let orgId;

  try {
    const orgDetails = await getFilteredData("organizations", "Name", org);
    if (orgDetails.data.length === 0) {
      return false;
    }
    orgId = orgDetails.data[0].id;
  } catch (error) {
    console.error("Error fetching organization details:", error);
    return false;
  }

  setLoading(10);
  const addTags = await createTags(orgId);
  if (addTags === false) {
    return false;
  }

  setLoading(20);
  const orgTags = await getFilteredBySingleRelation("tags", "organization", orgId);
  if (orgTags.data.length === 0) {
    return false;
  }

  setLoading(30);
  const tagIds = orgTags.data.map((item) => item.id);

  const addPart = await createPart(tagIds, orgId, userId);
  if (addPart === false) {
    return false;
  }

  setLoading(40);
  const orgPart = await getFilteredBySingleRelation("parts", "organization", orgId);
  if (orgPart.data.length === 0) {
    return false;
  }

  setLoading(50);
  const partId = orgPart.data[0].id;

  const memberIds = [userId];
  const addGroups = await createGroups(orgId, memberIds, userId);
  if (addGroups === false) {
    return false;
  }

  setLoading(60);
  const addWidget = await createWidget(orgId);
  if (addWidget === false) {
    return false;
  }

  setLoading(70);
  const addTicket = await createTicket(org, tagIds, partId, userId, userId);
  if (addTicket === false) {
    return false;
  }

  setLoading(80);
  const addIssue = await createIssue(org, tagIds, partId, userId, userId);
  if (addIssue === false) {
    return false;
  }

  setLoading(100);
  return true;
};
