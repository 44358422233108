// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dropdown menu */
.dropdown-menu-blueprints {
    background: white;
    border: 1px solid #DCDCDC;
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    overflow-y: auto;
    width: 270px;
    padding: 0.4rem;
    position: relative;
    /* box-shadow: #DCDCDC; */
  }
  
  .dropdown-menu-blueprints button {
    align-items: center;
    background-color: transparent;
    display: flex;
    gap: 0.25rem;
    padding: 0.2rem;
    text-align: left;
    width: 100%;
    font-size: small;
  }
  
  .dropdown-menu-blueprints button:hover,
  .dropdown-menu-blueprints button:hover.is-selected {
    background-color: #F4F4F5;
  }
  
  .dropdown-menu-blueprints button.is-selected {
    background-color: #F4F4F5;
  }
  
  
 
  `, "",{"version":3,"sources":["webpack://./src/components/BlurprintEditor/commandstyles.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,iBAAiB;IACjB,yBAAyB;IACzB,qBAAqB;IACrB,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;IACnB,6BAA6B;IAC7B,aAAa;IACb,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,gBAAgB;EAClB;;EAEA;;IAEE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Dropdown menu */\n.dropdown-menu-blueprints {\n    background: white;\n    border: 1px solid #DCDCDC;\n    border-radius: 0.7rem;\n    display: flex;\n    flex-direction: column;\n    gap: 0.1rem;\n    overflow-y: auto;\n    width: 270px;\n    padding: 0.4rem;\n    position: relative;\n    /* box-shadow: #DCDCDC; */\n  }\n  \n  .dropdown-menu-blueprints button {\n    align-items: center;\n    background-color: transparent;\n    display: flex;\n    gap: 0.25rem;\n    padding: 0.2rem;\n    text-align: left;\n    width: 100%;\n    font-size: small;\n  }\n  \n  .dropdown-menu-blueprints button:hover,\n  .dropdown-menu-blueprints button:hover.is-selected {\n    background-color: #F4F4F5;\n  }\n  \n  .dropdown-menu-blueprints button.is-selected {\n    background-color: #F4F4F5;\n  }\n  \n  \n \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
