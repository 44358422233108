// STRING LIMIT FUNCTION
import pluralize from "pluralize";
export const doPluralize = (text) => {
  // let temp = text;
  if (text === pluralize(text)) {
    return `${text}`;
  }
  return pluralize(text);
};

// STRING LIMIT FUNCTION

export const StringLimit = (string, maxLength) => {
  if (string.length > maxLength) {
    return string.substring(0, maxLength - 3) + "...";
  } else {
    return string;
  }
};

export const timeDifference = (previous) => {
  const current = new Date();
  const previousDate = new Date(previous);

  const elapsed = current - previousDate;

  const seconds = Math.floor(elapsed / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days + " days ago";
  } else if (hours > 0) {
    return hours + " hours ago";
  } else if (minutes > 0) {
    return minutes + " mins ago";
  } else {
    return "just now";
  }
};

// Create an entry
export const CreateData = async (MetricName, newData) => {
  const payload = { data: newData };
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  try {
    const response = await fetch(URL, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });

    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error(errorData?.message || "Error fetching data");
      error.status = response.status;
      error.data = errorData;
      throw error;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in CreateData:", error);
    throw error;
  }
};

// UPDATE an entry in a collection
export const UpdateData = async (MetricName, id, newData) => {
  const payload = { data: newData };
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}/${id}`;
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "PUT",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = await response.json();
  return data;
};

// Get a particular entry in a collection
export const getOneData = async (MetricName, id) => {
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}/${id}/?populate=*`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const UpdateOneUserData = async (MetricName, id, newData) => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}/${id}`,
    {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(newData),
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let updatedData = await response.json();
  return updatedData;
};
export const getAllData = async (MetricName) => {
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}/?populate=*`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
      // Authorization: `Bearer ${jwt}`
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
  
  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = response.json();
  return data;
};

export const getUserCollections = async () => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }
  const userid = userData.user.id;

  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/metrics/?populate=*&filters[author][$eq]=${userid}`;

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const getPublicCollections = async () => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/metrics/?populate=*&filters[Public][$eq]=true`;

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

// Delete an entry in a collection
export const DeleteSingleAttribute = async (MetricName, id) => {
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}/${id}`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

// FILE UPLOAD TO STRAPI
export const uploadFile = async (file) => {
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload`;
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }
  const response = await fetch(URL, {
    method: "POST",
    body: file,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  let data = response.json();

  return data;
};

// ROLES APIs

export const getAllRoles = async () => {
  let URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users-permissions/roles`;

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = await response.json().then((role) => {
    return role.roles;
  });

  const FilterData = data && data.find((role) => role.name === "Public");
  return FilterData;
};

export const getMetricData = async () => {
  let url = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/metrics?filters[Category][$eq]=social`;

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
  let data = await response.json();
  return data;
};

export const getAllCollectionData = async (MetricName) => {
  var URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}?filters[Name][$eq]=CRCL%20Community&[populate][author][on]&&populate[author][populate][Picture][fields][0]=name&populate[author][populate][Picture][fields][1]=alternativeText&populate[author][populate][Picture][fields][2]=caption&populate[author][populate][Picture][fields][3]=url`;

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();

  return data;
};

export const getAllDataUsers = async (metrics, Userid) => {
  var URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${metrics}?filters[author][id][$eq]=${Userid}&[populate][author][on]`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const InviteUser = async (payload) => {
  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send-email`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error sending invite");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

// Get all media files

export const GetAllMedia = async () => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload/files/?populate=*&pagination[limit]=1000000000000000000000`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching media");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const getDataWithFilterUrl = async (MetricName, filteredValue) => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}?${filteredValue}&populate=*&pagination[limit]=10000000000000000000000000`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(
      errorData?.message || "Error fetching data with filter"
    );
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const UpdateSingleCollectionData = async (MetricName, newData) => {
  const payload = { data: newData };
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "PUT",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(
      errorData?.message || "Error updating collection data"
    );
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const getFilteredData = async (MetricName, filterKey, filterValue) => {
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}/?populate=*&filters[${filterKey}][$eq]=${filterValue}`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(
      errorData?.message || "Error fetching filtered data"
    );
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const getFilteredByTwoData = async (
  MetricName,
  filterKey1,
  filterValue1,
  filterKey2,
  filterValue2
) => {
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}/?populate=*&filters[${filterKey1}][$eq]=${filterValue1}&filters[${filterKey2}][$eq]=${filterValue2}`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(
      errorData?.message || "Error fetching data with two filters"
    );
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const getFilteredByTwoRelation = async (
  MetricName,
  filterKey1,
  filterValue1,
  filterKey2,
  filterValue2
) => {
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}/?populate=*&filters[${filterKey1}][id][$eq]=${filterValue1}&filters[${filterKey2}][id][$eq]=${filterValue2}`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(
      errorData?.message || "Error fetching data with two relations"
    );
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const getFilteredBySingleRelation = async (
  MetricName,
  filterKey1,
  filterValue1,
  fields
) => {
  let URL;
  if(fields && fields.length >0){
    let str = "";
    for(let i=0 ; i<fields.length ;i++){
      str = str + `&fields[${i}]=${fields[i]}`
    };
    URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}/?filters[${filterKey1}][id][$eq]=${filterValue1}${str}&pagination[limit]=1000000000000000000000`;
  }
  else{
    URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}/?populate=*&filters[${filterKey1}][id][$eq]=${filterValue1}&pagination[limit]=1000000000000000000000`;
  }
  

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const getFilteredBySingleRelationSingleData = async (
  MetricName,
  filterKey1,
  filterValue1,
  filterKey2,
  filterValue2
) => {
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${MetricName}/?populate=*&filters[${filterKey1}][id][$eq]=${filterValue1}&filters[${filterKey2}][$eq]=${filterValue2}`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};
