import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Image, Input } from "@nextui-org/react";

import { FilterUser } from "../../controllers/APIController";
import { passwordlessCode } from "../../controllers/SupertokenController";

const PasswordlessSignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [ListOfUsers, setListOfUsers] = useState(null);
  const [verificationError, setVerificationError] = useState("");
  const [validateTest, setValidateTest] = useState(false);
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [ValidationConditions, setValidationConditions] = useState(false);

  const handleOnNavigate = () => {
    navigate("/login");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    FilterUser(email)
      .then((data) => {
        setListOfUsers(data);
      })
      .catch((err) => {
      });
  }, [email]);

  const validation = () => {
    let isValid = true;

    if (!email) {
      setVerificationError("Email is required");
      isValid = false;
    } else if (!EmailRegex.test(email)) {
      setVerificationError("Enter a valid email format");
      isValid = false;
    } else if (ListOfUsers === false) {
      setVerificationError("Email does not exist. Create an account.");
      isValid = false;
    } else {
      setVerificationError(null);
    }
    return isValid;
  };

  const navigateOTP = async () => {
    const isValid = validation();
    if (isValid) {
      setValidateTest(false);
      const OTPBody = await passwordlessCode(email);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("deviceId", OTPBody.deviceId);
      sessionStorage.setItem("preAuthSessionId", OTPBody.preAuthSessionId);
      navigate("/verify-signin");
    } else {
      setValidateTest(true);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="max-w-md w-full p-6 justify-center  ">
        <Image
          width={50}
          alt="NextUI hero Image"
          src="https://avatars.githubusercontent.com/u/170258109?s=200&v=4"
        />
        <h1 className="mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold ">
          Sign in to Scrum
        </h1>
        <div className="mb-6 mt-16">
          <Input
            radius="sm"
            className="border-slate-6"
            // isInvalid={ValidationConditions && (LoginDetails.email === '' || !EmailRegex.test(LoginDetails.email)) && LoginDetails.email !== null ? ValidationConditions && (LoginDetails.email === '' || !EmailRegex.test(LoginDetails.email)) : ""}
            // errorMessage={ValidationConditions && (LoginDetails.email === '' || !EmailRegex.test(LoginDetails.email)) && LoginDetails.email !== null ? LoginError.emailError : ''}
            key={"outside"}
            type="email"
            label="Email"
            variant={"faded"}
            // onChange={(e) => setLoginDetails({ ...email, email: e.target.value })}
            labelPlacement={"outside"}
            placeholder="Enter your email"
          />
        </div>

        <Button
          className="w-full bg-black  text-white font-normal py-2 px-4 border  rounded-md flex items-center justify-center"
          onClick={navigateOTP}
        >
          Receive your verification code
        </Button>

        <div className="flex items-center mt-4">
          <span className="text-small text-default-400 ">
            We recommend using your work email
          </span>
        </div>

        <div className="mt-6 text-center text-small text-default-400">
          By continuing, you acknowledge that you have read and understood, and
          agree to Resolv’s <span className="bold-link">Terms & Conditions</span>{" "}
          and <span className="bold-link">Privacy Policy.</span>
        </div>
      </div>
    </div>
  );
};

export default PasswordlessSignIn;
