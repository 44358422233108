import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CardHeader,
  Chip,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownSection,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";
import { Tabs, Tab, Card, CardBody } from "@nextui-org/react";
import { UpdateData } from "../../controllers/strapiController.js";
import { IoIosClose } from "react-icons/io";
import { EditorProvider } from "@tiptap/react";
import "../../index.css";
import {
  extensions,
  props,
  MenuBar,
} from "../../components/markdown/componenteditor.jsx";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "@nextui-org/react";
import { RiVipDiamondLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Tags from "../../components/Tag/tags.jsx";
import { sendUpdateTickets } from "../../utils/mentions.js";
import { renderIcon } from "../../components/Drawer/PartPicker.jsx";
const UpdateDrawer = ({
  data,
  dropdownref,
  handleclose,
  res,
  modalref,
  handleClickTicket,
  handleClickIssue,
  users,
  customTags,
  orgid,
  param,
  partData,
  parsedParts,
  userNames,
}) => {
  const [descriptionContent, setDescriptionContent] = useState(
    data?.description
  );
  const [ownerInfo, setOwnerInfo] = useState({
    username: res[data?.owner]?.UserName,
    name: res[data?.owner]?.FirstName,
    url:
      process.env.REACT_APP_STAGE === "Dev"
        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
            res[data?.owner]?.ProfilePicture
          }`
        : `${res[data?.owner]?.ProfilePicture}`,
    id: data?.owner,
  });
  const parentId = data?.parent?.split("/")[data.parent.split("/").length - 1];
  const [start, setStart] = useState(false);
  const [owner, setOwner] = useState(new Set([]));
  const [tags, setTags] = useState(data?.tags);
  const [tagsDetail, setTagsDetail] = useState([]);
  const [name, setName] = useState(data?.name);
  const [titlePRD, setTitlePRD] = useState(data?.documentation?.PRD?.Title);
  const [titleOther, setTitleOther] = useState(
    data?.documentation?.Other?.Title
  );
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const open = ["Ideation", "Prioritised", "Deprioritised"];
  const progress = ["UX Design Phase", "In development", "Testing"];
  const deployed = ["Staging", "Production"];
  const Inactive = ["Deprecated", "Won't do"];
  const [selectedStage, setSelectedStage] = useState(new Set([data?.stage]));
  const [titleDesign, setTitleDesign] = useState(
    data?.documentation?.Design?.Title || ""
  );
  console.log(data, "dataaaaaaaaaaaaaaaaa");
  const [urlPRD, setUrlPRD] = useState(data?.documentation?.PRD?.URL);
  const [urlOther, setUrlOther] = useState(data?.documentation?.Other?.URL);
  const [urlDesign, setUrlDesign] = useState(data?.documentation?.Design?.URL);
  const [prd, setPrd] = useState([]);
  const [design, setDesign] = useState([]);
  const [other, setOther] = useState([]);
  const [isOpenPRD, setIsOpenPRD] = useState(false);
  const [isOpenDesign, setIsOpenDesign] = useState(false);
  const [isOpenOther, setIsOpenOther] = useState(false);
  const [errors, setErrors] = useState({
    titlePRD: "",
    urlPRD: "",
    titleDesign: "",
    urlDesign: "",
    titleOther: "",
    urlOther: "",
  });

  const urlRegex =
    /^(https?:\/\/)?([\w\d-]+\.)+[a-z]{2,6}(:\d{1,5})?(\/[\w\d-]*)*(\?[^\s]*)?$/;

  const handleRemovePRD = (title) => {
    setPrd(prd.filter((item) => item.Title !== title));
  };

  const handleRemoveDesign = (title) => {
    setDesign(design.filter((item) => item.Title !== title));
  };

  const handleRemoveOther = (title) => {
    setOther(other.filter((item) => item.Title !== title));
  };

  const handleSubmitt = () => {
    const newErrors = {
      titlePRD: "",
      urlPRD: "",
      titleDesign: "",
      urlDesign: "",
      titleOther: "",
      urlOther: "",
    };

    if (!titlePRD) {
      newErrors.titlePRD = "Title PRD cannot be empty";
    }
    if (!titleDesign) {
      newErrors.titleDesign = "Title Design cannot be empty";
    }
    if (!titleOther) {
      newErrors.titleOther = "Title Other cannot be empty";
    }

    if (!urlRegex.test(urlPRD)) {
      newErrors.urlPRD = "Invalid URL for PRD";
    } else if (titlePRD === urlPRD) {
      newErrors.urlPRD = "URL for PRD cannot be the same as the title";
    }

    if (!urlRegex.test(urlDesign)) {
      newErrors.urlDesign = "Invalid URL for Design";
    } else if (titleDesign === urlDesign) {
      newErrors.urlDesign = "URL for Design cannot be the same as the title";
    }

    if (!urlRegex.test(urlOther)) {
      newErrors.urlOther = "Invalid URL for Other";
    } else if (titleOther === urlOther) {
      newErrors.urlOther = "URL for Other cannot be the same as the title";
    }
    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
    } else {
    }
  };

  const handleAddItem = (prop) => {
    if (prop === "PRD") {
      if (titlePRD === "" || urlPRD === "") {
        return;
      }
      setPrd((prev) => [{ Title: titlePRD, URL: urlPRD }, ...prev]);
      setTitlePRD("");
      setUrlPRD("");
      setIsOpenPRD(false);
    } else if (prop === "Design") {
      setDesign([...design, { Title: titleDesign, URL: urlDesign }]);
    } else if (prop === "Other") {
      if (titleOther === "" || urlOther === "") {
        return;
      }
      setOther((prev) => [{ Title: titleOther, URL: urlOther }, ...prev]);
      setTitleOther("");
      setUrlOther("");
      setIsOpenOther(false);
    }
  };

  const resetModalErrors = (modalType) => {
    if (modalType === "PRD") {
      setErrors((prevErrors) => ({ ...prevErrors, titlePRD: "", urlPRD: "" }));
    } else if (modalType === "Design") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        titleDesign: "",
        urlDesign: "",
      }));
    } else if (modalType === "Other") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        titleOther: "",
        urlOther: "",
      }));
    }
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  useEffect(() => {
    if (isDrawerOpen) {
      setName(data?.name || "");
      setDescriptionContent(data?.description || "");
    }
  }, [isDrawerOpen, data]);
  const handleUpdate = (user) => {
    setOwnerInfo({
      username: user.username,
      id: user.id,
      name: user.FirstName,
      url: user.ProfilePicture
        ? process.env.REACT_APP_STAGE === "Dev"
          ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user.ProfilePicture.formats.small.url}`
          : `${user.ProfilePicture.formats.small.url}`
        : "",
    });
  };
  const handleSubmit = async () => {
    if (!name || !descriptionContent || !data) {
      console.error("Name, description, and data are required");
      return;
    }
    const formData = {
      users_permissions_user: {
        disconnect: [],
        connect: [
          {
            id: Number(ownerInfo.id),
            position: {
              end: true,
            },
          },
        ],
      },
      Name: name,
      Tags: tags,
      Description: descriptionContent,
      Stage: data?.type === "Enhancement" ? Array.from(selectedStage)[0] : "",
    };
    try {
      await UpdateData("parts", data.id, formData);
      await sendUpdateTickets(ownerInfo.id,null,descriptionContent,userDataId,"part","update",users,res,"components",data.id,null,orgid.id);
      window.location.reload();
    } catch (error) {}
  };
  return (
    <div className="w-96 p-4 shadow-lg overflow-y-auto absolute top-0 right-0 h-full bg-white z-50">
      <div className="flex items-center justify-between">
        <Chip
          size="sm"
          variant="flat"
          color={
            data.type === "Product"
              ? "primary"
              : data.type === "Capability"
              ? "secondary"
              : data.type === "Feature"
              ? "success"
              : data.type === "Enhancement"
              ? "warning"
              : "danger"
          }
        >
          {data.type === "Product"
            ? "PRO"
            : data.type === "Capability"
            ? "CAP"
            : data.type === "Feature"
            ? "FEA"
            : data.type === "Enhancement"
            ? "ENH"
            : "RUN"}
          -{data.part}
        </Chip>
        <Button
          isIconOnly
          variant="light"
          size="sm"
          className=" flex justify-end"
          onClick={() => handleclose(false)}
        >
          <IoIosClose style={{ width: "50px" }} />
        </Button>
      </div>

      <div className=" mt-4 mb-4 text-xs">
        <Input
          className="my-4 text-xs"
          type="text"
          classNames={{
            input: ["bg-white", "hover:bg-white"],
            innerWrapper: ["bg-white", "hover:bg-white"],
            inputWrapper: [
              "shadow-none",
              "border",
              "border -divider",
              "bg-white",
              "dark:bg-white",
              "hover:bg-white",
              "dark:hover:bg-white",
              "group-data-[focus=true]:bg-white",
              "dark:group-data-[focus=true]:bg-white",
              "data-[hover=true]:bg-white",
            ],
          }}
          variant="bordered"
          style={{ fontSize: "12px" }}
          placeholder="Add Name"
          size="sm"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <Divider />
      <div className=" h-auto my-4 border rounded-lg ">
        <EditorProvider
          placeholder="Enter your description"
          editorProps={props}
          extensions={extensions(userNames)}
          slotBefore={
            <MenuBar
              descriptionContent={descriptionContent}
              setDescriptionContent={setDescriptionContent}
              initial={start}
              setInitial={setStart}
            />
          }
          content=""
        />
      </div>
      <Divider />
      <div className="mt-4 flex flex-col gap-4">
        <div className=" flex ">
          <p className=" gap-2 w-32 text-xs mt-2  flex ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
            Owner
            <span className=" text-red-600">*</span>
          </p>
          <Dropdown variant="light" size="sm">
            <DropdownTrigger>
              <Button
                className="text-start justify-start w-40"
                variant="light"
                size="sm"
              >
                {owner === "" ? (
                  <p className=" text-xs ">Select Owner</p>
                ) : (
                  <div className="flex gap-2 items-center">
                    <Avatar
                      color={Colors[Number(ownerInfo.id) % numColors]}
                      style={{ width: "22px", height: "22px" }}
                      showFallback
                      radius="sm"
                      name={
                        ownerInfo.name
                          ? ownerInfo.name?.charAt(0).toUpperCase()
                          : ownerInfo.username?.charAt(0).toUpperCase()
                      }
                      className="flex-shrink-0"
                      size="sm"
                      src={ownerInfo.url}
                    />
                    <div className="flex flex-col">
                      <span className="text-xs">
                        {ownerInfo.name ? ownerInfo.name : ownerInfo?.username}
                      </span>
                    </div>
                  </div>
                )}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Select Tenant"
              variant="flat"
              disallowEmptySelection
              selectionMode="single"
              selectedKeys={owner}
              onSelectionChange={setOwner}
            >
              {users.map((user) => (
                <DropdownItem onClick={() => handleUpdate(user)} key={user.id}>
                  <div className="flex gap-2 items-center">
                    <Avatar
                      color={Colors[Number(user.id) % numColors]}
                      style={{ width: "22px", height: "22px" }}
                      showFallback
                      radius="sm"
                      name={
                        user.FirstName
                          ? user.FirstName?.charAt(0).toUpperCase()
                          : user.username?.charAt(0).toUpperCase()
                      }
                      className="flex-shrink-0"
                      size="sm"
                      src={
                        user.ProfilePicture
                          ? process.env.REACT_APP_STAGE === "Dev"
                            ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user.ProfilePicture.formats.small.url}`
                            : `${user.ProfilePicture.formats.small.url}`
                          : ""
                      }
                    />
                    <div className="flex flex-col">
                      <span className="text-xs">
                        {user.FirstName ? user.FirstName : user.username}{" "}
                        {user?.LastName}
                      </span>
                    </div>
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        {data.type === "Product" ? (
          <></>
        ) : (
          <div className="flex my-2 gap-2 items-center">
            <p className="w-32 gap-2 flex text-xs">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                />
              </svg>
              Select Part<span className=" text-red-600">*</span>
            </p>
            <div className="w-40">
              <div className="flex items-center gap-2">
                <div>{renderIcon(parsedParts[parentId].Type)}</div>
                <p className="text-sm">{parsedParts[parentId].Name}</p>
              </div>
            </div>
          </div>
        )}
        {data?.type === "Enhancement" ? (
          <div>
            <div className="flex  gap-2 items-center">
              <p className="w-32 text-xs flex gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
                  />
                </svg>
                Status
                {/* <span className=" text-red-600">*</span> */}
              </p>
              <div className="w-40">
                <Dropdown>
                  <DropdownTrigger>
                    <Button
                      variant="light"
                      size="sm"
                      className="w-full flex justify-start"
                    >
                      {Array.from(selectedStage).length === 0
                        ? "Select status"
                        : Array.from(selectedStage)[0]}
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    className="h-[200px] overflow-y-auto"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={selectedStage}
                    onSelectionChange={setSelectedStage}
                  >
                    <DropdownSection title={"Open"}>
                      {open.map((item) => (
                        <DropdownItem key={item}>{item}</DropdownItem>
                      ))}
                    </DropdownSection>
                    <DropdownSection title={"In progress"}>
                      {progress.map((item) => (
                        <DropdownItem key={item}>{item}</DropdownItem>
                      ))}
                    </DropdownSection>
                    <DropdownSection title={"Deployed"}>
                      {deployed.map((item) => (
                        <DropdownItem key={item}>{item}</DropdownItem>
                      ))}
                    </DropdownSection>
                    <DropdownSection title={"In active"}>
                      {Inactive.map((item) => (
                        <DropdownItem key={item}>{item}</DropdownItem>
                      ))}
                    </DropdownSection>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="flex gap-2 items-center">
          <p className="w-32 gap-2 flex text-xs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 6h.008v.008H6V6Z"
              />
            </svg>
            Tags
          </p>
          <Tags
            param={param}
            customTags={customTags}
            org={orgid.id}
            dropdownref={dropdownref}
            tags={tags}
            setTags={setTags}
            tagsDetail={tagsDetail}
            setTagsDetail={setTagsDetail}
            modalref={modalref}
          />
        </div>
      </div>
      <div className="w-full flex flex-col">
        <Tabs aria-label="Options" className="mt-8" size="sm">
          <Tab key="docs" title="Docs 0">
            <div>
              <div>
                <div className="flex gap-10 text-xs ">
                  <p className=" text-xs text-center flex justify-center items-center">
                    PRD
                  </p>
                  <Button
                    onClick={() => setIsOpenPRD(true)}
                    size="sm"
                    className="bg-transparent text-center flex justify-center items-center"
                  >
                    Select
                  </Button>
                </div>
                <div>
                  {data?.delete?.attributes?.Documentation?.PRD?.map(
                    (item, index) => (
                      <div key={index} className="flex text-xs items-center">
                        <Link
                          showAnchorIcon
                          href={
                            item.URL.startsWith("http")
                              ? item.URL
                              : `https://${item.URL}`
                          }
                          passHref
                        >
                          {item.URL}
                        </Link>
                        <button
                          onClick={() => handleRemovePRD(item.Title)}
                          className="ml-2 text-red-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </button>
                      </div>
                    )
                  )}
                </div>

                {prd.length > 0 &&
                  prd.map((item) => (
                    <div className="flex text-xs items-center" key={item.Title}>
                      <p className="mr-2">{item.Title}</p>
                      <Link
                        showAnchorIcon
                        href={
                          item.URL.startsWith("http")
                            ? item.URL
                            : `https://${item.URL}`
                        }
                        passHref
                      ></Link>
                      <button
                        onClick={() => handleRemovePRD(item.Title)}
                        className="ml-2 text-red-500 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
              </div>
              <Modal
                ref={modalref}
                isOpen={isOpenPRD}
                onClose={() => {
                  setIsOpenPRD(false);
                  resetModalErrors("PRD");
                }}
              >
                <ModalContent>
                  <ModalHeader className="flex flex-col gap-1">
                    Add PRD
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-2">
                        <p className="">Title</p>

                        <Input
                          radius="sm"
                          className="border-slate-6 bg-white"
                          variant="bordered"
                          value={titlePRD}
                          onChange={(e) => setTitlePRD(e.target.value)}
                          placeholder="Enter title"
                        />
                      </div>
                      {errors.titlePRD && (
                        <p className="text-red-500 text-xs">
                          {errors.titlePRD}
                        </p>
                      )}
                      <div className="flex flex-col gap-2">
                        <p className="">URL</p>
                        <Input
                          radius="sm"
                          className="border-slate-6 bg-white"
                          variant="bordered"
                          value={urlPRD}
                          onChange={(e) => setUrlPRD(e.target.value)}
                          placeholder="Enter URL"
                        />
                      </div>
                      {errors.urlPRD && (
                        <p className="text-red-500 text-xs">{errors.urlPRD}</p>
                      )}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      variant="light"
                      onClick={() => setIsOpenPRD(false)}
                      size="sm"
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        handleSubmitt();
                        handleAddItem("PRD");
                      }}
                    >
                      Add PRD
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </div>
            <div>
              <div>
                <div className="flex gap-6 text-xs">
                  <p className="text-xs text-center flex justify-center items-center">
                    Design
                  </p>
                  <Button
                    onClick={() => setIsOpenDesign(true)}
                    size="sm"
                    className="bg-transparent text-center flex justify-center items-center"
                  >
                    Select
                  </Button>
                </div>
                <div>
                  {data?.delete?.attributes?.Documentation?.Design?.map(
                    (item, index) => (
                      <div key={index} className="flex text-xs items-center">
                        <p className="mr-2">{item.Title}</p>
                        <Link
                          isBlock
                          showAnchorIcon
                          color="primary"
                          href={
                            item.URL.startsWith("http")
                              ? item.URL
                              : `https://${item.URL}`
                          }
                          passHref
                        ></Link>
                        <button
                          onClick={() => handleRemoveDesign(item.Title)}
                          className="ml-2 text-red-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </button>
                      </div>
                    )
                  )}
                </div>

                {design.length > 0 &&
                  design.map((item) => (
                    <div className="flex text-xs items-center" key={item.Title}>
                      <p className="mr-2">{item.Title}</p>
                      <Link
                        showAnchorIcon
                        href={
                          item.URL.startsWith("http")
                            ? item.URL
                            : `https://${item.URL}`
                        }
                        passHref
                      ></Link>
                      <button
                        onClick={() => handleRemoveDesign(item.Title)}
                        className="ml-2 text-red-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
              </div>
              <Modal
                ref={modalref}
                isOpen={isOpenDesign}
                onClose={() => {
                  setIsOpenDesign(false);
                  resetModalErrors("Design");
                }}
              >
                <ModalContent>
                  <ModalHeader className="flex flex-col gap-1">
                    Add Design
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-2">
                        <p className="">Title</p>
                        <p>{titleDesign}</p>
                        <Input
                          radius="sm"
                          className="border-slate-6 bg-white"
                          variant="bordered"
                          value={titleDesign}
                          onChange={(e) => setTitleDesign(e.target.value)}
                          placeholder="Enter title"
                        />
                      </div>
                      {errors.titleDesign && (
                        <p className="text-red-500 text-xs">
                          {errors.titleDesign}
                        </p>
                      )}
                      <div className="flex flex-col gap-2">
                        <p className="">URL</p>
                        <Input
                          radius="sm"
                          className="border-slate-6 bg-white"
                          variant="bordered"
                          value={urlDesign}
                          onChange={(e) => setUrlDesign(e.target.value)}
                          placeholder="Enter URL"
                        />
                      </div>
                      {errors.urlDesign && (
                        <p className="text-red-500 text-xs">
                          {errors.urlDesign}
                        </p>
                      )}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      variant="light"
                      onClick={() => setIsOpenDesign(false)}
                      size="sm"
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        handleSubmitt();
                        handleAddItem("Design");
                      }}
                    >
                      Add Design
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </div>
            <div>
              <div>
                <div className="flex gap-8 text-xs ">
                  <p className=" text-xs text-center flex justify-center items-center">
                    Other
                  </p>
                  <Button
                    onClick={() => setIsOpenOther(true)}
                    size="sm"
                    className="bg-transparent text-center flex justify-center items-center"
                  >
                    Select
                  </Button>
                </div>
                <div>
                  {data?.delete?.attributes?.Documentation?.Other?.map(
                    (item, index) => (
                      <div key={index} className="flex text-xs items-center">
                        <Link
                          showAnchorIcon
                          href={
                            item.URL.startsWith("http")
                              ? item.URL
                              : `https://${item.URL}`
                          }
                          passHref
                        >
                          {item.URL}
                        </Link>
                        <button
                          onClick={() => handleRemoveOther(item.Title)}
                          className="ml-2 text-red-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </button>
                      </div>
                    )
                  )}
                </div>
                {other.length > 0 &&
                  other.map((item) => (
                    <div className="flex text-xs items-center" key={item.Title}>
                      <p className="mr-2">{item.Title}</p>
                      <Link
                        showAnchorIcon
                        href={
                          item.URL.startsWith("http")
                            ? item.URL
                            : `https://${item.URL}`
                        }
                        passHref
                      ></Link>
                      <button
                        onClick={() => handleRemoveOther(item.Title)}
                        className="ml-2 text-red-500 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
              </div>
              <Modal
                ref={modalref}
                isOpen={isOpenOther}
                onClose={() => {
                  setIsOpenOther(false);
                  resetModalErrors("Other");
                }}
              >
                <ModalContent>
                  <ModalHeader className="flex flex-col gap-1">
                    Add Other
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-2">
                        <p className="">Title</p>
                        <p>{titleOther}</p>
                        <Input
                          radius="sm"
                          className="border-slate-6 bg-white"
                          variant="bordered"
                          value={titleOther}
                          onChange={(e) => setTitleOther(e.target.value)}
                          placeholder="Enter title"
                        />
                      </div>
                      {errors.titleOther && (
                        <p className="text-red-500 text-xs">
                          {errors.titleOther}
                        </p>
                      )}
                      <div className="flex flex-col gap-2">
                        <p className="">URL</p>
                        <Input
                          radius="sm"
                          className="border-slate-6 bg-white"
                          variant="bordered"
                          value={urlOther}
                          onChange={(e) => setUrlOther(e.target.value)}
                          placeholder="Enter URL"
                        />
                      </div>
                      {errors.urlOther && (
                        <p className="text-red-500 text-xs">
                          {errors.urlOther}
                        </p>
                      )}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      variant="light"
                      onClick={() => setIsOpenOther(false)}
                      size="sm"
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        handleSubmitt();
                        handleAddItem("Other");
                      }}
                    >
                      Add Other
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </div>
          </Tab>
          <Tab key="issues" title={`Issues ${data?.totalissues?.length}`}>
            {data?.totalissues?.length === 0 ? (
              <div className="w-full flex flex-col text-center">No Issues</div>
            ) : (
              <div className="flex flex-col gap-2 items-center justify-center">
                {data?.totalissues?.map((issue) => (
                  <Card
                    className=" cursor-pointer"
                    isPressable
                    onPress={() => handleClickIssue(issue)}
                    fullWidth
                  >
                    <CardHeader>
                      <Chip
                        variant="flat"
                        color="primary"
                        size="sm"
                        radius="full"
                        className="border border-divider"
                      >
                        ISS-{issue?.payload?.latest}
                      </Chip>
                    </CardHeader>
                    <CardBody>
                      <p className="mb-2 text-xs">{issue?.payload?.title}</p>
                      <p className=" text-xs font-light">
                        {issue.payload.description.replace(/<[^>]*>/g, "")
                          .length > 25
                          ? `${issue.payload.description
                              .replace(/<[^>]*>/g, "")
                              .slice(0, 25)}...`
                          : issue.payload.description.replace(/<[^>]*>/g, "")}
                      </p>
                    </CardBody>
                  </Card>
                ))}
              </div>
            )}
          </Tab>
          <Tab key="tickets" title={`Tickets ${data?.totaltickets?.length}`}>
            {data?.totaltickets?.length === 0 ? (
              <div className="w-full flex flex-col text-center">No Tickets</div>
            ) : (
              <div className="flex flex-col items-center gap-2 justify-center">
                {data?.totaltickets?.map((ticket) => (
                  <Card
                    className=" cursor-pointer"
                    isPressable
                    onPress={() => handleClickTicket(ticket)}
                    fullWidth
                  >
                    <CardHeader>
                      <Chip
                        variant="flat"
                        color="danger"
                        className=""
                        size="sm"
                        radius="full"
                      >
                        TKT-{ticket?.payload?.latest}
                      </Chip>
                    </CardHeader>
                    <CardBody>
                      <p className="mb-2 text-xs">{ticket?.payload?.title}</p>
                      <p className=" text-xs font-light">
                        {ticket.payload.description.replace(/<[^>]*>/g, "")
                          .length > 25
                          ? `${ticket.payload.description
                              .replace(/<[^>]*>/g, "")
                              .slice(0, 25)}...`
                          : ticket.payload.description.replace(/<[^>]*>/g, "")}
                      </p>
                    </CardBody>
                  </Card>
                ))}
              </div>
            )}
          </Tab>
        </Tabs>
      </div>
      <div className="flex justify-end">
        <Button
          size="sm"
          disabled={
            name === "" ||
            descriptionContent === "" ||
            descriptionContent === "<p></p>"
          }
          className={`text-white size-2 flex justify-end my-8 ${
            name === "" ||
            descriptionContent === "" ||
            descriptionContent === "<p></p>"
              ? "bg-gray-300"
              : "bg-[rgb(51,51,245)]"
          }`}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default UpdateDrawer;
