
import { getMonthName } from "./constant";
import { getFilteredBySingleRelation } from "../controllers/strapiController";

export const fetchSprints = async (orgId) => {
    try {
      const resp = await getFilteredBySingleRelation(
        "sprints",
        "organization",
        orgId
      );
      const sprints = resp.data;
      const convertedParts = {};
      sprints?.forEach((item) => {
        convertedParts[item?.id] = {
          Name: item?.attributes?.Title,
          Icon: item?.attributes?.Icon,
          Curr: item?.attributes?.current,
        };
      });
      return convertedParts;
    } catch (error) {}
  };

  const convertToCSV = (objArray) => {
    const array = Array.isArray(objArray) ? objArray : [objArray];
    let str = "";
  
    // Check if array is not empty
    if (array.length > 0) {
      // Extract headers (keys) from the first object in the array
      const headers = Object.keys(array[0]);
      str += headers.join(",") + "\r\n"; // Create CSV header row
      
      // Convert each object to a CSV row
      array.forEach((item) => {
        const line = headers.map((header) => item[header]).join(",");
        str += line + "\r\n";
      });
    }
  
    return str;
  };
  



export const downloadCSV = (data, fileName) => {
  const csvData = new Blob([convertToCSV(data)], { type: "text/csv" });
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement("a");
  link.href = csvURL;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const parseTickets = (obj, parsedParts, customTags,res) => {
  const tagsArray =
    obj?.tags?.length > 0
      ? obj?.tags.map((item) => {
          const find = customTags.find((t) => String(t.id) === String(item));
          if (find) {
            return find.attributes.Name;
          }
        })
      : "No tags";
  const parsedData = {};
  parsedData["Id"] = obj?.id;
  parsedData["Chip_id"] = obj?.latest;
  parsedData["App_id"] = obj.app_id;
  parsedData["Title"] = obj?.title;
  parsedData["Description"] = obj?.description;
  parsedData["Type"] = obj?.type;
  parsedData["Part_id"] = obj?.part;
  parsedData["Part_Name"] = `${parsedParts[obj?.part]?.Name ?? ""}`;
  parsedData["Part_Type"] = `${parsedParts[obj?.part]?.Type ?? ""}`;
  parsedData["Assigned_id"] = obj?.assigned;
  parsedData["Assigned_name"] = `${
    res[String(obj.assigned)]?.FirstName ?? ""
  } ${res[String(obj.assigned)]?.LastName ?? ""}`;
  parsedData["Assigned_email"] = `${res[String(obj.assigned)]?.Email}`;
  parsedData["Creator_id"] = obj?.created_by;
  parsedData["Creator_name"] = `${
    res[String(obj.created_by)]?.FirstName ?? ""
  } ${res[String(obj.created_by)]?.LastName ?? ""}`;
  parsedData["Creator_email"] = `${res[String(obj.created_by)]?.Email}`;
  parsedData["Owner_id"] = obj?.owner;
  parsedData["Owner_name"] = `${res[String(obj.owner)]?.FirstName ?? ""} ${
    res[String(obj.owner)]?.LastName ?? ""
  }`;
  parsedData["Owner_email"] = `${res[String(obj.owner)]?.Email}`;
  parsedData["Estimated_Days"] = obj?.estimatedays;
  parsedData["Priority"] = obj?.priority;
  parsedData["Stage"] = obj?.stage;
  parsedData["Target_Close_Date"] = `${
    obj?.targetclosedate?.day
  } ${getMonthName(obj?.targetclosedate?.month)} ${obj?.targetclosedate?.year}`;
  parsedData["Tags"] = tagsArray.isArray() ? tagsArray.join(" | ") : tagsArray;
  
  return parsedData;
};

export const parseIssues = (obj, parsedParts, customTags,res,parsedSprints) => {
  const parsedData = parseTickets(obj, parsedParts, customTags,res);
  const sprintString = obj?.sprint ? `${parsedSprints[obj.sprint]?.Name ?? ""} ${obj.currentSprint ? `Sprint ${obj.currentSprint}` : ""}` : "No sprint attched"
  return {...parsedData,Sprint: sprintString};
};

export const downloadJSON = (data,fileName) => {
    const jsonData = new Blob([JSON.stringify(data)], { type: 'application/json' });
    const jsonURL = URL.createObjectURL(jsonData);
    const link = document.createElement('a');
    link.href = jsonURL;
    link.download = `${fileName}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
