import React, { useEffect, useRef, useState } from "react";
import AccountsHeader from "./Header";
import AccountsDrawer from "./AccountsDrawer";
import { useSelector } from "react-redux";
import { selectOrgData, selectUserNames } from "../../redux/actions";
import { useParams } from "react-router-dom";
import { getFilteredBySingleRelation } from "../../controllers/strapiController";
import AccountTable from "./AccountTable";

const Accounts = () => {
  const tagsref = useRef();
  const modalref = useRef();
  const drawerRef = useRef();
  const accountsRef = useRef();
  const { org } = useParams();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const orgData = useSelector(selectOrgData);
  const [customTags, setCustomTags] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(false);
  const searchRef = useRef();
  const inputRef = useRef();
  const [search,setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const userNames = useSelector(selectUserNames);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target))
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountsRef.current &&
        !accountsRef.current.contains(event.target) &&
        (!tagsref.current || !tagsref.current.contains(event.target)) &&
        (!modalref.current || !modalref.current.contains(event.target))
      ) {
        setIsOpenUpdate(false);
        setSelectedAccount(null);
      }
    };
    if (isOpenUpdate) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenUpdate]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        (!inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setIsSearchOpen(false);
      }
    };
    if (isSearchOpen && search === "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen, search]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const tags = await getFilteredBySingleRelation(
          "tags",
          "organization",
          orgData.id
        );
        // const customers = await getFilteredBySingleRelation(
        //   "customers",
        //   "organization",
        //   orgData.id
        // );
        // setCustomers(customers.data);
        setCustomTags(tags.data);
      } catch (error) {}
    };
    if (orgData?.id) {
      fetchData();
    }
  }, [orgData]);
  return (
    <div className="h-full overflow-y-auto flex flex-col w-full">
      
      {isOpen && (
        <div ref={drawerRef}>
          <AccountsDrawer
            toggleDrawer={() => setIsOpen(false)}
            dropdownref={tagsref}
            modalref={modalref}
            tenant={{ name: org, id: orgData?.id }}
            customTags={customTags}
            userNames={userNames}
            data={selectedAccount}
            type={"create"}
          />
        </div>
      )}
      {isOpenUpdate && (
        <div ref={accountsRef}>
          <AccountsDrawer
            toggleDrawer={() => setIsOpenUpdate(false)}
            dropdownref={tagsref}
            modalref={modalref}
            tenant={{ name: org, id: orgData?.id }}
            customTags={customTags}
            userNames={userNames}
            data={selectedAccount}
            type={"update"}
          />
        </div>
      )}
      <div className="h-[130px] bg-white">
        <AccountsHeader setIsOpen={setIsOpen} />
        <div className="z-0 flex pb-4 px-8 border-b-1 mt-4 border-divider items-center justify-between">
          <div className="flex items-center gap-4">
            <div
              ref={searchRef}
              onClick={() => setIsSearchOpen(true)}
              className={`transition-all duration-300 ease-in-out cursor-pointer border border-divider flex items-center ${
                isSearchOpen
                  ? "pl-2 w-40 gap-2 justify-start"
                  : "w-8 justify-center"
              } rounded-full  h-8`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              {isSearchOpen && (
                <input
                  ref={inputRef}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ height: "20px", outline: "none" }}
                  className="w-full px-2 text-xs rounded-full"
                  placeholder="Search"
                />
              )}
            </div>
          </div>
        </div>
      </div>
             <AccountTable search={search} setSelectedAccount={setSelectedAccount} setIsOpenUpdate={setIsOpenUpdate} /> 
    </div>
  );
};

export default Accounts;
