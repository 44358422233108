import CryptoJS from "crypto-js";
import { UpdateData, UpdateOneUserData, getFilteredData } from "./strapiController";

export const LoginUserGrabJwt = async (email) => {
  const Payload = {
    identifier: email,
    password: `${process.env.REACT_APP_DEFAULT_USER_PASSWORD}`,
  };

  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/auth/local/?populate=*`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(Payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = response.json();

  return data;
};

export const RegisterUserGrabJwt = async (email, password2) => {
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/auth/local/register?populate=*`;
  const secretKey = `${process.env.REACT_APP_SECRET_KEY}`;
  const inputString = password2;
  const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
  const AESHash = encrypted.toString();

  const payload = {
    email: email,
    password: `${process.env.REACT_APP_DEFAULT_USER_PASSWORD}`,
    Password2: btoa(AESHash),
    username: email,
    displayName:  email.split('@')[0].length <3 ? email : email.split('@')[0],
    Theme: "Light",
  };

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-type": "Application/JSOn",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  let data = response.json();

  return data;
};

export const NavigateSign = async (email, password2) => {
  // Check if user exists.
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  let emailExists = false;
  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users/?populate=*&filters[email][$eq]=${email}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );

  const isEmailExist = await response.json();

  if (isEmailExist.length !== 0) {
    emailExists = true;
  }

  let navigate_route;
  const password = `${process.env.REACT_APP_DEFAULT_USER_PASSWORD}`;

  if (!emailExists) {
    
    const Signin = await RegisterUserGrabJwt(email, password2);
    if (Signin) {
      // const PublicRole=await getAllRoles()
      //     const roles={
      //         "role": {
      //         "disconnect": [
      //             {
      //                 "id": Signin.user.id
      //             }
      //         ],
      //         "connect": [
      //             {
      //                 "id": PublicRole.id,
      //                 "position": {
      //                     "end": true
      //                 }
      //             }
      //         ]
      //     }
      // }
      // await UpdateOneUserData("users",Signin.user.id,roles);
    }
    const login = await LoginUserGrabJwt(email, password);
    console.log("LOGIN",login);
    if(login){
      delete login.user.Password2;
    }
    sessionStorage.setItem("userData", JSON.stringify(login));
    const resp = await getFilteredData("invitations", "Email", email);
    if (resp.data.length === 0) {
      navigate_route = "/onboarding";
    } else {
      const invitation = resp.data.sort((a, b) => b.id - a.id);
      let orgIds = [];
      for (let i = 0; i < invitation.length; i++) {
        orgIds.push(invitation[i].attributes.organization.data?.id);
      }
      const formData = {
        OrgRoute: `/${invitation[0].attributes.organization.data.attributes.Name}`,
        organizations: {
          disconnect: [],
          connect: orgIds.map((item) => {
            return {
              id: Number(item),
              position: {
                end: true,
              },
            };
          }),
        },
      };
      const post = await UpdateOneUserData("users", Number(login.user.id), formData);
      const promises = resp.data.map((item) => UpdateData("invitations",item.id,{Status : "Accepted"}));
      const result = await Promise.all(promises);
      if(post && result){
        navigate_route = `/${invitation[0].attributes.organization.data.attributes.Name}`;
      }
      else{
        navigate_route = "/onboarding";
      }
    }
  } else {
    const base64Decoded = atob(isEmailExist[0].Password2);
    const decrypted = CryptoJS.AES.decrypt(base64Decoded, secretKey).toString(
      CryptoJS.enc.Utf8
    );
    if (decrypted === password2) {
      const login = await LoginUserGrabJwt(email, password);
      delete login.user.Password2;
      sessionStorage.setItem("userData", JSON.stringify(login));
      navigate_route =
        isEmailExist[0].OrgRoute === null
          ? "/onboarding"
          : `${isEmailExist[0].OrgRoute}`;
    } else {
      navigate_route = "Incorrect";
    }
  }

  return navigate_route;
};
