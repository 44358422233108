import { Button, Modal, ModalBody, ModalContent } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import UserModal from '../../components/UserModal/UserPicker'
import { UpdateData } from '../../controllers/strapiController';

function UserGroupModal({isOpen,isClose,users ,groupId}) {
    const [newMembers,setNewMembers] = useState(new Set([]));
    const handleAdd = async() => {
        if(newMembers.length ===0){
            return;
        }
        const formData = {
            members: {
                disconnect: [],
                connect: Array.from(newMembers).map((item) => {
                  return {
                    id: Number(item),
                    position: {
                      end: true,
                    },
                  };
                }),
              },
        }
        try{
            const resp = await UpdateData("groups",groupId,formData);
            
            window.location.reload();
        }
        catch(error){
           
        }
    }
    useEffect(() => {
        setNewMembers(new Set([]));
    },[isOpen]);
  return (
    <Modal isOpen={isOpen} onOpenChange={isClose}>
        <ModalContent>
            <ModalBody className='flex flex-col gap-4'>
                <div className='w-full'>Add Users</div>
                <UserModal member={newMembers} setMember={setNewMembers} users={users} />
                <div className='w-full flex items-center justify-end'>
                    <Button onClick={()=> handleAdd()} className=" bg-[rgb(51,51,245)] text-xs text-white" size='sm' >+Add</Button>
                </div>
            </ModalBody>
        </ModalContent>
    </Modal>
  )
}

export default UserGroupModal
