import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  Input,
  Dropdown,
  Button,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Textarea,
  Select,
  SelectItem,
  Avatar,
  ModalHeader,
} from "@nextui-org/react";
import { CreateData, getOneData } from "../../controllers/strapiController";
import UserModal from "../../components/UserModal/UserPicker";
import { GroupData } from "../../utils/default";

function GroupModal({ isOpen, onClose, users, userId,orgId,names }) {
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const [name, setname] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState(new Set([]));
  const [ownerId, setOwnerId] = useState(new Set([]));
  const [member, setMember] = useState(new Set([]));
  const [owner, setOwner] = useState({});
  const [error,setError] = useState("");
  const generateURL = (name) => {
    const lowercaseName = name.toLowerCase().replace(/\s+/g, "-");
    return lowercaseName;
  };
  const handleSend = async () => {
    if (name==="" || description==="" || Array.from(type).length ===0 || Array.from(ownerId).length===0 || Array.from(member).length === 0 ) {
      setError("Please enter all fields.")
      return;
    }
    let mem = Array.from(member);
    mem.push(Array.from(ownerId)[0])
    const formData = {
      Name : name,
      Description : description,
      path: generateURL(name),
      Type : Array.from(type)[0],
      organization: {
        disconnect: [],
        connect: [
          {
            id: orgId,
            position: {
              end: true,
            },
          },
        ],
      },
      owner: {
        disconnect: [],
        connect: [
          {
            id: Number(Array?.from(ownerId)[0]),
            position: {
              end: true,
            },
          },
        ],
      },
      members: {
        disconnect: [],
        connect: mem.map((item) => {
          return {
            id: Number(item),
            position: {
              end: true,
            },
          };
        }),
      },
      ...GroupData
    }
    try {
      const resp = await CreateData("groups",formData);
      window.location.reload();
    } catch (error) {
    }
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const resp = await getOneData("users", userId);
        setOwnerId(new Set([String(resp?.id)]));
        setOwner(resp);
      } catch (error) {
      }
    };
    if (userId) {
      fetchUserData();
    }
  }, [userId]);
  return (
    <Modal isOpen={isOpen} onOpenChange={onClose}>
      <ModalContent className="py-8">
        <ModalHeader>Create new User group</ModalHeader>
        <ModalBody className="flex flex-col gap-4 w-full">
          <Input
            size="sm"
            value={name}
            color={names.includes(generateURL(name)) ? "danger" : "default"}
            onChange={(e) => setname(e.target.value)}
            type="email"
            label="Name"
            labelPlacement="outside"
            placeholder="Enter Group Name"
            classNames={{
              label: " text-xs font-light",
              placeholder: " text-xs font-light",
            }}
          />
          <Textarea
            value={description}
            onChange={(e)=> setDescription(e.target.value)}
            size="sm"
            label="Description"
            labelPlacement="outside"
            placeholder="Enter your description"
            className="w-full"
            classNames={{
              label: " text-xs font-light",
              placeholder: " text-xs font-light",
            }}
          />
          <Select
            classNames={{
              label: " text-xs font-light",
              placeholder: " text-xs font-light",
            }}
            label="Group Type"
            labelPlacement="outside"
            size="sm"
            placeholder="Add group type"
            selectedKeys={type}
            onSelectionChange={setType}
          >
            <SelectItem key="Internal">Internal User Group</SelectItem>
            <SelectItem key="External">External User Group</SelectItem>
          </Select>

          <div className="flex flex-col gap-2">
            <p className=" text-xs font-light">Owner</p>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  className="w-full flex justify-start"
                  variant="flat"
                  startContent={
                    <Avatar
                      showFallback
                      name={owner?.username?.slice(0,1).toUpperCase() }
                      radius="full"
                      color={Colors[Number(owner?.id) % numColors]}
                      style={{height:"25px",width:"25px"}}
                      src={
                        process.env.REACT_APP_STAGE === 'Dev'
                          ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${owner?.ProfilePicture?.formats?.small?.url}`
                          : `${owner?.ProfilePicture?.formats?.small?.url}`
                      }
                    />
                  }
                >
                  {owner?.username}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
               className=" max-h-[400px] overflow-y-auto w-96"
                selectedKeys={ownerId}
                onSelectionChange={setOwnerId}
                disallowEmptySelection
                selectionMode="single"
              >
                {users?.map((user) => (
                  <DropdownItem
                    startContent={<Avatar
                      showFallback
                      name={user?.username?.slice(0,1).toUpperCase()}
                      radius="full"
                      color={Colors[Number(user?.id) % numColors]}
                      style={{height:"25px",width:"25px"}}
                      src={
                        process.env.REACT_APP_STAGE === 'Dev'
                          ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user?.ProfilePicture?.formats?.small?.url}`
                          : `${user?.ProfilePicture?.formats?.small?.url}`
                      }
                    />}
                    onClick={() => setOwner(user)}
                    key={String(user?.id)}
                  >
                    {user.username}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="flex flex-col gap-2">
          <p className=" text-xs font-light">Users</p>
          <UserModal users={users} member={member} setMember={setMember} />
          </div>
          <div className="w-full flex items-center justify-end">
                <Button disabled={names.includes(generateURL(name)) || name==="" || description==="" || Array.from(type).length ===0 || Array.from(ownerId).length===0 || member.length === 0 } onClick={()=> handleSend()} size="sm" className={` ${names.includes(generateURL(name)) ||name==="" || description==="" || Array.from(type).length ===0 || Array.from(ownerId).length===0 || member.length === 0 ? "" : "text-white bg-[rgb(51,51,245)]" } text-xs  `} >Create</Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default GroupModal;
