import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getMultipleFilters } from "../../controllers/vectorDB";
import {
  UpdateData,
  UpdateOneUserData,
  getFilteredByTwoData,
  getFilteredData,
} from "../../controllers/strapiController";
import { BreadcrumbItem, Breadcrumbs, Button, Chip } from "@nextui-org/react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";

function Update() {
  const { org } = useParams();
  const email = JSON.parse(sessionStorage.getItem("userData")).user.email;
  const [assigned, setAssigned] = useState([]);
  const [notifications,setNotifications] = useState([]);
  const [invitations, setInvitaions] = useState([]);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [pressed,setPressed] = useState(true);
  const [orgIds,setOrgIds] = useState([]);
  const handleAccept = async(id,orgId) =>{
    const formData = {
      organizations: {
        disconnect: [],
        connect: [
          {
            id: Number(orgId),
            position: {
              end: true,
            },
          },
        ],
      },
    }
    try{
      const resp = await UpdateData("invitations",id,{Status:"Accepted"});
      const resp2 = await UpdateOneUserData("users",userDataId,formData)
      if(resp && resp2){
        let arr = invitations;
        let ind = invitations.findIndex((item) => String(item.id) === String(id));
        let newinv = invitations[ind];
        arr.splice(ind,1);
        newinv.attributes.Status = "Accepted";
        arr.push(newinv);
        await setInvitaions(arr);
        setPressed(!pressed);
        window.location.reload();
      }
    }
    catch(error){
     
    }
  }
  const handleDecline = async(id) =>{
    try{
      const resp = await UpdateData("invitations",id,{Status:"Deleted"})
      if(resp){
        let arr = invitations;
        let ind = invitations.findIndex((item) => String(item.id) === String(id));
        let newinv = invitations[ind];
        arr.splice(ind,1);
        newinv.attributes.Status = "Deleted";
        await setInvitaions(arr);
        setPressed(!pressed)
      }
    }
    catch(error){
      
    }
  }
  const arrange = (tic, inv) => {
    let arr = [];
    const inv1 = inv.map((item) => {
      const date = new Date(item?.attributes.createdAt);
      const unixTimestampMillis = date.getTime();
      return { ...item, type: "invitation", time: unixTimestampMillis };
    });
    const tic1 = tic.map((item) => {
      return { ...item, type: "ticket", time: item.payload.id };
    });
    arr = [...inv1, ...tic1];
    return arr.sort((a, b) => b.time - a.time);
  };
  useEffect(() => {
    const fetchOwnerTickets = async () => {
      try {
        const tickets = await getMultipleFilters(
          [{ key: "assigned", value: String(userDataId) }],
          org
        );
        const owner = await getMultipleFilters(
          [{ key: "owner", value: String(userDataId) }],
          org
        );
        const array = [...tickets, ...owner];
        const uniqueIds = new Set();
        const uniqueArray = array.filter((item) => {
          if (!uniqueIds.has(item.payload.id)) {
            uniqueIds.add(item.payload.id);
            return true;
          }
          return false;
        });
        setAssigned(uniqueArray);
      } catch (error) {
        
      }
    };
    if (userDataId) {
      fetchOwnerTickets();
    }
  }, []);
  useEffect(() => {
    const fetchInvitation = async () => {
      try {
        const resp1 = await getFilteredByTwoData(
          "invitations",
          "Email",
          email,
          "Status",
          "Pending"
        );
        const resp2 = await getFilteredByTwoData(
          "invitations",
          "Email",
          email,
          "Status",
          "Accepted"
        );
        setInvitaions([...resp1.data, ...resp2.data]);
      } catch (error) {
        
      }
    };
    if (userDataId) {
      fetchInvitation();
    }
  }, []);
  useEffect(() => {
    if(assigned.length >0 || invitations.length>0){
      setNotifications(arrange(assigned, invitations));
    }
     
  }, [invitations, assigned,pressed]);
  return (
    <div className="flex flex-col h-screen overflow-y-auto  pt-8">
      <div className=" flex p-4 ml-4">
            <Breadcrumb />
            <Breadcrumbs size="sm" isDisabled>
              <BreadcrumbItem>Updates</BreadcrumbItem>
            </Breadcrumbs>
          </div>
     
      <div className=" flex flex-col gap-4 w-full  p-4 h-full overflow-y-auto">
        {notifications?.map((item) => {
          if (item.type === "ticket") {
            return (
              <div className="w-full hover:bg-[#E3E3E8] bg-gray-100 min-h-[100px] h-auto border border-divider rounded-lg p-2 flex flex-col gap-2 ">
                <div className=" flex items-center gap-4">
                  <Chip
                    size="sm"
                    variant="flat"
                    color={
                      item.payload.type === "ticket" ? "danger" : "primary"
                    }
                  >
                    {item.payload.type === "ticket" ? "TKT" : "ISS"}-
                    {item.payload.latest}
                  </Chip>
                  <p className=" font-light text-base">{item.payload.title}</p>
                </div>
                <p className=" font-light text-sm">
                  {item.payload.description.replace(/<[^>]*>/g, "")}
                </p>
              </div>
            );
          } else {
            return (
              <div className="w-full hover:bg-[#E3E3E8] bg-gray-100 min-h-[100px] h-auto border border-divider rounded-lg p-2 px-8 flex justify-between items-center gap-4 ">
                <div className=" flex items-center gap-4">
                {item?.attributes.Status === "Pending" && (
                  <div className=" h-2 w-2 rounded-full bg-yellow-400"></div>
                )}
                <p className=" font-light">
                  {item?.attributes.invitedBy.data?.attributes?.FirstName
                    ? item?.attributes.invitedBy.data?.attributes?.FirstName
                    : item?.attributes.invitedBy.data?.attributes?.username}
                  {" "}has invited you to join{" "}
                  {item.attributes.organization.data?.attributes?.Tenant}
                </p>
                </div>
                {item?.attributes?.Status === "Pending" ? (
                  <div className="flex items-center gap-8">
                    <Button
                      onClick={()=> handleAccept(item.id,item.attributes.organization.data.id)}
                      variant="flat"
                      startContent={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m4.5 12.75 6 6 9-13.5"
                          />
                        </svg>
                      }
                      isIconOnly
                      color="success"
                    ></Button>
                    <Button
                      onClick={()=> handleDecline(item.id)}
                      variant="flat"
                      startContent={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      }
                      isIconOnly
                      color="danger"
                    ></Button>
                  </div>
                )
              :
              item?.attributes?.Status === "Accepted" ? 
            <Chip variant="flat" color="success">Accepted</Chip> : <></>
            }
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Update;
