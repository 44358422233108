import {
  Accordion,
  AccordionItem,
  Card,
  CardBody,
  CardHeader,
  Chip,
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { getAllData } from "../../controllers/strapiController";
import PrivilegeDrawer from "./PrivilegeDrawer";

function Privileges({orgPrivileges,devPrivileges,productPrivileges,supportPrivileges,analyticsPrivileges,update,groupId,setUpdate,type}) {
  const [category, setCategory] = useState("");
  const [nameClass, setNameClass] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [detail,setDetail] = useState()
  const drawerRef = useRef();
  const Dev = ["Issues", "Road Maps", "Queues", "Sprint Boards"];
  const Product = ["Components"];
  const Org = [
    "Tags",
    "User Management",
    "Organization details",
    "Integrations",
  ];
  const Support = ["Tickets", "Conversations"];
  const Analytics = ["Analytics", "Team Activity"];
  const [roles, setRoles] = useState([]);
  const handleEdit = async (category, Class) => {
    await setCategory(category);
    await setNameClass(Class);
    if(category === "Dev"){
        await setDetail(devPrivileges)
    }
    if(category === "Product"){
        await setDetail(productPrivileges)
    }
    if(category === "Org Related"){
        await setDetail(orgPrivileges)
    }
    if(category === "Support"){
        await setDetail(supportPrivileges)
    }
    if(category === "Analytics"){
        await setDetail(analyticsPrivileges)
    }
    setIsOpen(true);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const resp = await getAllData("user-group-roles");
        setRoles(resp.data);
       
      } catch (error) {
       
      }
    };
    fetchRoles();
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target)
      ) {
        setIsOpen(!isOpen);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  const handleCloseDrawer = () => {
    setIsOpen(false);
  }
  return (
    <div className=" flex flex-col gap-2 p-4">
      {isOpen && (
        <div ref={drawerRef}>
          <PrivilegeDrawer
            type={type}
            setUpdate={setUpdate}
            groupId={groupId}
            category={category}
            Class={nameClass}
            closeDrawer={handleCloseDrawer}
            roles={roles}
            detail={detail}
            update={update}
          />
        </div>
      )}
      <Card shadow="none" className="border border-divider bg-white">
        <CardBody className=" w-full">
          <Accordion>
            <AccordionItem className=" text-sm font-light w-full" title="Dev">
              {Dev.map((dev) => (
                <Card shadow="none" className=" my-2 border border-divider">
                    <CardBody className="w-full">
                  <div className=" w-full flex items-center p-2 justify-between">
                    <p className=" text-base font-light">{dev}</p>
                    <div onClick={()=>handleEdit("Dev",dev)} className=" flex gap-2 items-center cursor-pointer">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                    <p  className="  text-base font-light">Edit</p>
                    </div>
                    
                  </div>
                  <div className="w-full p-2  flex justify-start gap-4">
                    {roles
                      ?.filter((item) => item.attributes.Class === dev)
                      .map((r) => (
                        <Chip color="primary" variant="flat" size="sm">{r.attributes.Name}</Chip>
                      ))}
                  </div>
                  </CardBody>
                </Card>
              ))}
            </AccordionItem>
          </Accordion>
          </CardBody>
          </Card>
          <Card shadow="none" className="border border-divider bg-white">
        <CardBody className=" w-full">
          <Accordion>
            <AccordionItem
              className=" text-sm font-light w-full"
              title="Org Related"
            >
              {Org.map((dev) => (
                <Card shadow="none" className=" my-2 border border-divider">
                    <CardBody className="w-full">
                  <div className=" w-full flex items-center p-2 justify-between">
                    <p className=" text-base font-light">{dev}</p>
                    <div onClick={()=>handleEdit("Org Related",dev)} className=" flex gap-2 items-center cursor-pointer">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                    <p  className="  text-base font-light">Edit</p>
                    </div>
                  </div>
                  <div className="w-full p-2  flex justify-start gap-4">
                    {roles
                      ?.filter((item) => item.attributes.Class === dev)
                      .map((r) => (
                        <Chip color="secondary" variant="flat" size="sm">{r.attributes.Name}</Chip>
                      ))}
                  </div>
                  </CardBody>
                </Card>
              ))}
            </AccordionItem>
          </Accordion>
          </CardBody>
          </Card>
          <Card shadow="none" className="border border-divider bg-white">
        <CardBody className=" w-full">
          <Accordion>
            <AccordionItem
              className=" text-sm font-light w-full"
              title="Support"
            >
              {Support.map((dev) => (
                <Card shadow="none" className=" my-2 border border-divider">
                    <CardBody className="w-full">
                  <div className=" w-full flex items-center p-2 justify-between">
                    <p className=" text-base font-light">{dev}</p>
                    <div onClick={()=>handleEdit("Support",dev)} className=" flex gap-2 items-center cursor-pointer">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                    <p  className="  text-base font-light">Edit</p>
                    </div>
                  </div>
                  <div className="w-full p-2  flex justify-start gap-4">
                    {roles
                      ?.filter((item) => item.attributes.Class === dev)
                      .map((r) => (
                        <Chip color="danger" variant="flat" size="sm">{r.attributes.Name}</Chip>
                      ))}
                  </div>
                </CardBody>
                </Card>
              ))}
            </AccordionItem>
          </Accordion>
          </CardBody>
          </Card>
          <Card shadow="none" className="border border-divider bg-white">
        <CardBody className=" w-full">
          <Accordion>
            <AccordionItem
              className=" text-sm font-light w-full"
              title="Analytics"
            >
              {Analytics.map((dev) => (
                <Card shadow="none" className="my-2 border border-divider">
                    <CardBody className="w-full">
                  <div className=" w-full flex items-center p-2 justify-between">
                    <p className=" text-base font-light">{dev}</p>
                    <div onClick={()=>handleEdit("Analytics",dev)} className=" flex gap-2 items-center cursor-pointer">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                    <p  className="  text-base font-light">Edit</p>
                    </div>
                  </div>
                  <div className="w-full p-2  flex justify-start gap-4">
                    {roles
                      ?.filter((item) => item.attributes.Class === dev)
                      .map((r) => (
                        <Chip color="warning" variant="flat" size="sm">{r.attributes.Name}</Chip>
                      ))}
                  </div>
                </CardBody>
                </Card>
              ))}
            </AccordionItem>
          </Accordion>
          </CardBody>
          </Card>
          <Card shadow="none" className="border border-divider bg-white">
        <CardBody className=" w-full">
          <Accordion>
            <AccordionItem
              className=" text-sm font-light w-full"
              title="Product"
            >
              {Product.map((dev) => (
                <Card shadow="none" className=" my-2 border border-divider">
                    <CardBody className="w-full">
                  <div className=" w-full flex items-center p-2 justify-between">
                    <p className=" text-base font-light">{dev}</p>
                    <div onClick={()=>handleEdit("Product",dev)} className=" flex gap-2 items-center cursor-pointer">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                    <p  className="  text-base font-light">Edit</p>
                    </div>
                  </div>
                  <div className="w-full p-2  flex justify-start gap-4">
                    {roles
                      ?.filter((item) => item.attributes.Class === dev)
                      .map((r) => (
                        <Chip color="success" variant="flat" size="sm">{r.attributes.Name}</Chip>
                      ))}
                  </div>
                </CardBody>
                </Card>
              ))}
            </AccordionItem>
          </Accordion>
          </CardBody>
          </Card>
        
    </div>
  );
}

export default Privileges;
