import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

export const NoNewLine = Extension.create({
  name: 'no_new_line',
  addOptions() {
    return {
      handleEnter: null,
    }
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
            handleKeyDown: (view, event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                    if (this.options.handleEnter) {
                        return this.options.handleEnter();
                      }
                    return true
                }
          }
        },
      }),
    ]
  },
})