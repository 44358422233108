import {
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Tab,
  Tabs,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import Config from "./Config";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFilteredBySingleRelation,
  getFilteredBySingleRelationSingleData,
  UpdateData,
} from "../../controllers/strapiController";
import HelixLoader from "../../components/Loader/HelixLoader";
import Chatbox from "./Chatbox";
import Theme from "./Theme";
import { basicIcons } from "../../utils/default";
import { getMultipleFiltersInbox } from "../../controllers/vectorDB";
import { useDispatch, useSelector } from "react-redux";
import { selectOrgData, setHexData } from "../../redux/actions";

function Widget() {
  const [reFetch, setRefetch] = useState(true);
  const [toggle, setToggle] = useState(new Set(["config"]));
  const [widgetProps, setWidgetProps] = useState(null);
  const { org } = useParams();
  const [toggleChat, setToggleChat] = useState(true);
  const emailId = JSON.parse(sessionStorage.getItem("userData")).user.email;
  const [chatTabs, setChatTabs] = useState([]);
  const [buttonText, setButtonText] = useState("");
  const [icon, setIcon] = useState(null);
  const [themeHex, setThemeHex] = useState(null);
  const [image, setImage] = useState(null);
  const orgData = useSelector(selectOrgData);
  useEffect(() => {
    const fetchWidget = async () => {
      try {
        const resp = await getFilteredBySingleRelation(
          "widgets",
          "organization",
          orgData.id
        );
        setWidgetProps(resp.data[0]);
        setIcon(
          basicIcons.find((item) => item.name === resp.data[0].attributes.Icon)
        );
        setThemeHex(resp.data[0].attributes.Theme);
        setChatTabs(resp.data[0].attributes.Tabs);
        setButtonText(resp.data[0].attributes.ButtonText);
        setImage(resp.data[0].attributes.Logo);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    if (orgData?.id) {
      fetchWidget();
    }
  }, [orgData, reFetch]);
  useEffect(() => {
    const handleUpdate = async () => {
      const formData = {
        Tabs: chatTabs,
        ButtonText: buttonText,
        Theme: themeHex,
        Icon: icon.name,
      };
      try {
        await UpdateData("widgets", widgetProps.id, formData);
      } catch (error) {
        console.log("error:", error);
      }
    };
    if (
      widgetProps &&
      widgetProps.attributes &&
      chatTabs.length !== 0 &&
      buttonText !== "" &&
      themeHex &&
      icon
    ) {
      handleUpdate();
    }
  }, [widgetProps, chatTabs, buttonText, themeHex, icon]);
  if (!widgetProps) {
    return (
      <div className="flex flex-col items-center justify-center mt-64">
        <HelixLoader />
        <p>Loading....</p>
      </div>
    );
  }
  return (
    <div className=" min-h-screen flex flex-col">
      <div className="flex justify-between pt-8 pb-4 px-8">
        <div className=" flex text-center justify-center items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm" isDisabled>
            <BreadcrumbItem>Settings</BreadcrumbItem>
            <BreadcrumbItem>Widget</BreadcrumbItem>
          </Breadcrumbs>
        </div>

        <Button className=" text-white bg-[rgb(51,51,245)]" size="sm">
          Publish
        </Button>
      </div>
      <Divider />
      <div className="flex-grow flex">
        <div className=" border-r border-r-divider w-[70%] p-4 overflow-y-auto h-full">
          <Tabs selectedKey={toggle} onSelectionChange={setToggle}>
            <Tab key={"config"} title="Configuration">
              <div className=" flex flex-col">
                <Config
                  hex={themeHex}
                  chatTabs={chatTabs}
                  setChatTabs={setChatTabs}
                  buttonText={buttonText}
                  setButtonText={setButtonText}
                  appId={widgetProps.attributes.appid}
                />
              </div>
            </Tab>
            <Tab key={"theme"} title="Theme">
              <Theme
                fetch={reFetch}
                setFetch={setRefetch}
                id={widgetProps?.id}
                image={image}
                hex={themeHex}
                setHex={setThemeHex}
                icon={icon}
                setIcon={setIcon}
              />
            </Tab>
          </Tabs>
        </div>
        <div className="w-[30%] h-full flex flex-col items-center gap-4 justify-center">
          <div className="w-full flex items-center justify-center h-[650px]">
            {toggleChat && (
              <Chatbox
                email={emailId}
                org={org}
                setToggle={() => setToggleChat(false)}
                image={image}
                hex={themeHex}
                chatTabs={chatTabs}
                buttonText={buttonText}
              />
            )}
          </div>
          <div className=" flex items-center w-[80%] justify-end">
            <Button
              style={{ backgroundColor: themeHex ? themeHex : "#000000" }}
              onClick={() => setToggleChat(!toggleChat)}
              className="w-10 h-10 "
              isIconOnly
              radius="full"
            >
              {toggleChat ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              ) : (
                icon && (
                  <svg
                    xmlns={icon?.xmlns}
                    fill={icon?.fill}
                    viewBox={icon?.viewBox}
                    strokeWidth={icon?.strokeWidth}
                    stroke={icon?.stroke}
                    className="size-6 text-white"
                  >
                    <path
                      strokeLinecap={icon?.path?.strokeLinecap}
                      strokeLinejoin={icon?.path?.strokeLinejoin}
                      d={icon?.path?.d}
                    ></path>
                  </svg>
                )
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Widget;
