import React, { useState, useEffect } from "react";

import { Input, Button, Divider } from "@nextui-org/react";
import { IoIosClose } from "react-icons/io";
import { extensions, props, MenuBar } from "../../components/markdown/markdown";
import { EditorProvider } from "@tiptap/react";
import Tags from "../../components/Tag/tags"
import { CreateData } from "../../controllers/strapiController";
import { orgInfo, selectOrgData } from "../../redux/actions";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


const CustomerDrawer = ({
    toggleDrawer,
    query,
    res,
    dropdownref,
    modalref,
    part,
    tenant,
    userNames
}) => {
    const orgDetails = useSelector(selectOrgData)
    const [descriptionContent, setDescriptionContent] = useState("");
    const [ownerInfo, setOwnerInfo] = useState({ name: "", url: "", id: "", username: "" });
    const [title, setTitle] = useState("");
    const [tags, setTags] = useState([]);
    const [tagsDetail, setTagsDetail] = useState([]);
    const [customTags, setCustomTags] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState(new Set());
    const [showSimilar, setShowSimilar] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [imageArray, setImageArray] = useState([]);
    const [start, setStart] = useState(false);
    const [clearEditor, setClearEditor] = useState(0);
    const Colors = ["secondary", "success", "warning", "danger", "primary"];
    const [toggleSimilar, setToggleSimilar] = useState(false);
    const [selectedSimilar, setSelectedSimilar] = useState(null);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const collection_name = "customers"




    const handleClear = () => {

        setShowSimilar(false);
        setTitle("");
        setDescriptionContent("");

        setTags([]);
        setImageArray([]);
        setClearEditor(clearEditor + 1);
        setTagsDetail([]);
        setOwnerInfo({ name: "", url: "", id: "", username: "" });
        setSelectedDate("");
        setSelectedPriority(new Set([]));
    };
    const [isEmailValid, setIsEmailValid] = useState(false);
    const handleSubmit = async () => {
        if (isEmailValid) {

            const customer_data = {
                "Name": title,
                "Description": descriptionContent,
                "Email": email,
                "PhoneNumber": phone,
                "organization": {
                    disconnect: [],
                    connect: [
                        {
                            id: orgDetails?.id,
                            position: {
                                end: true
                            }
                        }
                    ]
                }
            }
            const response = await CreateData(collection_name, customer_data)
            window.location.reload()

        }
    }
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };


    // Effect to check email validity whenever the email state changes
    useEffect(() => {
        setIsEmailValid(validateEmail(email));
    }, [email]);
    return (
        <div className="w-96 p-4 shadow-lg overflow-y-auto absolute top-0 right-0 h-full bg-white z-50">
            <div className="flex items-center justify-between">
                <Button size="sm" variant="flat" color="secondary">
                    New Customer
                </Button>
                <div>

                    <Button className="bg-white" size="sm" onClick={handleClear}>
                        Reset
                    </Button>
                    <Button isIconOnly variant="light" size="sm" onClick={toggleDrawer}>
                        <IoIosClose style={{ width: "50px" }} />
                    </Button>
                </div>
            </div>
            {/* Add rest of your JSX */}
            <div className="text-xs bg-white ">
                <Input
                    className="mt-4 text-xs rounded-lg bg-white"
                    style={{
                        fontSize: "12px",
                        backgroundColor: "",
                    }}
                    classNames={{
                        input: ["bg-white", "hover:bg-white"],
                        innerWrapper: ["bg-white", "hover:bg-white"],
                        inputWrapper: [
                            "shadow-none",
                            "border",
                            "border-divider",

                            "bg-white",
                            "dark:bg-white",
                            "hover:bg-white",
                            "dark:hover:bg-white",
                            "group-data-[focus=true]:bg-white",
                            "dark:group-data-[focus=true]:bg-white",
                            "data-[hover=true]:bg-white",
                        ],
                    }}
                    type="text"
                    placeholder="Customer Name"
                    size="sm"
                    variant="bordered"
                    name="title"
                    value={title}
                    onChange={(e) => { setToggleSimilar(false); setTitle(e.target.value) }}
                // onBlur={() => handleShow()}
                />
                <Divider className={showSimilar ? "" : "mt-4"} />
                <div className=" h-auto my-4 border-[2px] rounded-lg">
                    <EditorProvider
                        placeholder="Enter your description"
                        editorProps={props()}
                        slotBefore={
                            <MenuBar
                                imageArray={imageArray}
                                setImageArray={setImageArray}
                                descriptionContent={descriptionContent}
                                setDescriptionContent={setDescriptionContent}
                                clear={clearEditor}
                                initial={start}
                                setInitial={setStart}
                            />
                        }
                        extensions={extensions(userNames)}
                        content={descriptionContent}
                    ></EditorProvider>
                </div>
                <Divider />

                <div className=" mt-4 flex gap-2 text-center items-center">
                    <p className="flex w-32 gap-2 text-center items-center">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                        </svg>

                        Email<span className="text-red-500">*</span>
                    </p>
                    <div className=" w-full  flex ">
                        <Input
                            className="text-xs rounded-lg bg-white"
                            style={{
                                fontSize: "12px",
                                backgroundColor: "",
                            }}
                            classNames={{
                                input: ["bg-white", "hover:bg-white"],
                                innerWrapper: ["bg-white", "hover:bg-white"],
                                inputWrapper: [
                                    "shadow-none",
                                    "border",
                                    "border-divider",

                                    "bg-white",
                                    "dark:bg-white",
                                    "hover:bg-white",
                                    "dark:hover:bg-white",
                                    "group-data-[focus=true]:bg-white",
                                    "dark:group-data-[focus=true]:bg-white",
                                    "data-[hover=true]:bg-white",
                                ],
                            }}
                            type="text"
                            placeholder="Customer Email"
                            size="sm"
                            variant="bordered"
                            name="title"
                            value={email}
                            onChange={(e) => { setToggleSimilar(false); setEmail(e.target.value) }}
                        // onBlur={() => handleShow()}
                        />
                    </div>

                </div>
                <div className=" mt-4 flex gap-2 text-center items-center">
                    <p className="flex w-32 gap-2 text-center items-center">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                        </svg>


                        Phone
                    </p>
                    <div className=" w-full  flex ">
                        <Input
                            className="text-xs rounded-lg bg-white"
                            style={{
                                fontSize: "12px",
                                backgroundColor: "",
                            }}
                            classNames={{
                                input: ["bg-white", "hover:bg-white"],
                                innerWrapper: ["bg-white", "hover:bg-white"],
                                inputWrapper: [
                                    "shadow-none",
                                    "border",
                                    "border-divider",

                                    "bg-white",
                                    "dark:bg-white",
                                    "hover:bg-white",
                                    "dark:hover:bg-white",
                                    "group-data-[focus=true]:bg-white",
                                    "dark:group-data-[focus=true]:bg-white",
                                    "data-[hover=true]:bg-white",
                                ],
                            }}
                            type="text"
                            placeholder="Add Phone number"
                            size="sm"
                            variant="bordered"
                            name="title"
                            value={phone}
                            onChange={(e) => { setShowSimilar(false); setSelectedSimilar(null); setToggleSimilar(false); setPhone(e.target.value) }}
                        // onBlur={() => handleShow()}
                        />
                    </div>
                </div>
                <div className="flex gap-2 mt-4 items-center">
                    <p className="w-32 gap-2   text-xs text-center flex items-center ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 6h.008v.008H6V6Z"
                            />
                        </svg>
                        Tags
                    </p>

                    {/* Add tags here. */}
                    <Tags
                        param={tenant.name}
                        customTags={customTags}
                        org={tenant.id}
                        dropdownref={dropdownref}
                        tags={tags}
                        className="text-xs text-start flex flex-row  w-10"
                        setTags={setTags}
                        tagsDetail={tagsDetail}
                        setTagsDetail={setTagsDetail}
                        modalref={modalref}
                    />
                </div>
            </div>
            <div className="flex items-center mt-2 justify-end">
                <div className="flex items-center">
                    <Button
                        size="sm"
                        className={`ml-2 text-white ${title === "" ||
                            descriptionContent === "" ||
                            descriptionContent === "<p></p>" ||
                            !isEmailValid || // Ensure email is valid
                            email === ""
                            ? ``
                            : `bg-[rgb(51,51,245)]`
                            }`}
                        disabled={
                            title === "" ||
                            descriptionContent === "" ||
                            descriptionContent === "<p></p>" ||
                            !email || // Ensure email is provided
                            !isEmailValid // Ensure email is valid
                        }
                        onClick={handleSubmit}
                    >
                        + Create
                    </Button>

                </div>
            </div>
        </div >
    );
};

export default CustomerDrawer;
