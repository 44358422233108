import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "@nextui-org/react";
import ColorPicker from "../../components/Tag/ColorPicker.jsx";
import { CreateData } from "../../controllers/strapiController.js";

const AddTagModal = ({ isOpen, onClose, name, modalref, org }) => {
  const [tagName, setTagName] = useState(name);
  const [loader, setLoader] = useState(false);
  const [tagDescription, setTagDescription] = useState("");
  const [selectedColor, setSelectedColor] = useState({
    name: "PASTEL BLUE",
    hex: "#A5BCFB",
  });

  useEffect(() => {
    setTagName(name);
  }, [name]);

  const handleAddTag = async () => {
    const name = tagName.trim();
    const description = tagDescription.trim();

    if (name !== "" && description !== "" && selectedColor !== "") {
      setLoader(true);
      const newTag = {
        Name: name,
        Description: description,
        Color: selectedColor?.hex,
        ColorName: selectedColor.name,
        organization: {
          disconnect: [],
          connect: [
            {
              id: Number(org),
              position: {
                end: true,
              },
            },
          ],
        },
      };
      try {
        const data = await CreateData("tags", newTag);
        window.location.reload();
      } catch (error) {
      } finally {
        setLoader(false);
      }
    }
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  return (
    <Modal ref={modalref} isOpen={isOpen} onOpenChange={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-2 text-sm">
          Create Tag
        </ModalHeader>
        <p className=" ml-4 text-xs">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo iusto,
          officia ipsam minima voluptate omnis esse! Nisi exercitationem
          officiis velit.
        </p>
        <ModalBody>
          <div className="mt-2 text-xs">Name</div>
          <Input
           key="outside"
           className=" text-xs rounded-lg bg-white"
           style={{
             fontSize: "12px",
             backgroundColor: "",
           }}
           classNames={{
             input: ["bg-white", "hover:bg-white"],
             innerWrapper: ["bg-white", "hover:bg-white"],
             inputWrapper: [
               "shadow-none",
               "border",
               "border-divider",

               "bg-white",
               "dark:bg-white",
               "hover:bg-white",
               "dark:hover:bg-white",
               "group-data-[focus=true]:bg-white",
               "dark:group-data-[focus=true]:bg-white",
               "data-[hover=true]:bg-white",
             ],
           }}
           type="text"
           size="sm"
           variant="bordered"
           label=""
            placeholder=""
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
          
          />
          <div className="mt-4 text-xs">Description</div>
          <Input
          key="outside"
          className=" text-xs rounded-lg bg-white"
          style={{
            fontSize: "12px",
            backgroundColor: "",
          }}
          classNames={{
            input: ["bg-white", "hover:bg-white"],
            innerWrapper: ["bg-white", "hover:bg-white"],
            inputWrapper: [
              "shadow-none",
              "border",
              "border-divider",

              "bg-white",
              "dark:bg-white",
              "hover:bg-white",
              "dark:hover:bg-white",
              "group-data-[focus=true]:bg-white",
              "dark:group-data-[focus=true]:bg-white",
              "data-[hover=true]:bg-white",
            ],
          }}
          type="text"
          size="sm"
          variant="bordered"
          label=""
            placeholder=""
            value={tagDescription}
            onChange={(e) => setTagDescription(e.target.value)}
          
          />
          <div className="mt-4 text-xs">Choose Color</div>
          <Dropdown className="w-96">
            <DropdownTrigger>
              <Button
                variant="light"
                className="w-[170px] border border-divider justify-start"
              >
                <div className="flex items-center justify-between gap-2 text-xs">
                  <div
                    className="w-4 h-4 rounded-full"
                    style={{ backgroundColor: selectedColor?.hex }}
                  ></div>
                  {selectedColor.name}
                </div>
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              <DropdownItem>
                <ColorPicker onColorSelect={handleColorSelect} />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" size="sm" onPress={onClose}>
            Cancel
          </Button>
          <Button
            disabled={loader || tagName === "" || tagDescription === ""}
            color="secondary"
            size="sm"
            onPress={handleAddTag}
            className={`ml-2 text-white ${
              tagName !== "" && tagDescription !== ""
                ? `bg-[rgb(51,51,245)]`
                : `bg-[rgb(200,200,200)]`
            }`}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddTagModal;
